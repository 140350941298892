import React, { useReducer } from "react";
import { ADD_PROVIDER, ADD_PROVIDER_BANK_ACCOUNT, ADD_PROVIDER_CONTACT, EDIT_PROVIDER, GET_ENTITIES, GET_MUNICIPALITIES, GET_PROVIDERS, REMOVE_PROVIDER, REMOVE_PROVIDER_BANK_ACCOUNT, REMOVE_PROVIDER_CONTACT, SHOW_PROVIDER, UPDATE_PROVIDER, UPDATE_PROVIDER_BANK_ACCOUNT, UPDATE_PROVIDER_CONTACT } from '../../types';
import ProvidersContext from "./providersContext";
import providersReducer from "./providersReducer";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const ProvidersState = (props) => {
  // estado inicial
  const initialSate = {
    providers: [],
    selectedProvider: [],
    entities: [],
    municipalities: [],
    success: false,
  };

  const [state, dispatch] = useReducer(providersReducer, initialSate);

  //obtener los proveedores
  const getProviders = () => {
    let url = `providers`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_PROVIDERS,
        payload: response.data,
      });
    });
  };

  // obtener el proveedor
  const getProvider = (id) => {
    let url = `providers/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: SHOW_PROVIDER,
        payload: response.data,
      });
    });
  };

  const addProvider = (provider) => {
    let url = "/providers";
    MethodPost(url, provider)
      .then((response) => {
        dispatch({
          type: ADD_PROVIDER,
        });
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR PROVEEDOR A EDITAR
  const editProvider = (provider) => {
    dispatch({
      type: EDIT_PROVIDER,
      payload: provider,
    });
  };

  //Actualizar un PROVEEDOR
  const updateProvider = (data) => {
    let url = `providers/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        dispatch({
          type: UPDATE_PROVIDER,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar PROVEEDOR
  const removeProvider = (id) => {
    let url = `providers/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: "Eliminado",
        text: response.data.message,
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
      });
      dispatch({
        type: REMOVE_PROVIDER,
        payload: id,
      });
    });
  };

  //Obtener la data para el select Estados
  const getEntities = () => {
    let url = "entities";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_ENTITIES,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Obtener la data para el select Municipios
  const getMunicipalities = (state) => {
    let url = `municipalities/${state}`;
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_MUNICIPALITIES,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // funcion para actualizar el estatus de un proveedor
  const updateStatus = (id) => {
    let url = `provider/${id}/estatus`;
    MethodPut(url)
      .then((response) => {
        Swal.fire({
          title: "Actualizado",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        getProviders();
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addProviderContact = (id, data) => {
    let url = `provider/${id}/contact`;
    MethodPost(url, data)
      .then((response) => {
        dispatch({
          type: ADD_PROVIDER_CONTACT,
        });
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const updateProviderContact = (id, data) => {
    let url = `provider/contact/${id}`;
    MethodPut(url, data)
      .then((response) => {
        dispatch({
          type: UPDATE_PROVIDER_CONTACT,
        });
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const deleteProviderContact = (id) => {
    let url = `provider/contact/${id}`;
    MethodDelete(url)
      .then((response) => {
        dispatch({
          type: REMOVE_PROVIDER_CONTACT,
        });
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Agregar cuenta bancaria proveedor
  const addProviderBankAccount = (id, data) => {
    let url = `provider/${id}/bank-account`;
    MethodPost(url, data)
      .then((response) => {
        dispatch({
          type: ADD_PROVIDER_BANK_ACCOUNT,
        });
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  const updateProviderBankAccount = (id, data) => {
    let url = `provider/bank-account/${id}`;
    MethodPut(url, data)
      .then((response) => {
        dispatch({
          type: UPDATE_PROVIDER_BANK_ACCOUNT,
        });
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        console.log(error);
      });
  };
  
  // eliminar cuenta
  const deleteProviderBankAccount = (id) => {
    let url = `provider/bank-account/${id}`;
    MethodDelete(url)
      .then((response) => {
        dispatch({
          type: REMOVE_PROVIDER_BANK_ACCOUNT,
        });
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

          

  return (
    <ProvidersContext.Provider
      value={{
        providers: state.providers,
        success: state.success,
        selectedProvider: state.selectedProvider,
        entities: state.entities,
        municipalities: state.municipalities,
        getProviders,
        addProvider,
        editProvider,
        removeProvider,
        updateProvider,
        getEntities,
        getMunicipalities,
        updateStatus,
        getProvider,
        addProviderContact,
        updateProviderContact,
        deleteProviderContact,
        addProviderBankAccount,
        updateProviderBankAccount,
        deleteProviderBankAccount,
      }}
    >
      {props.children}
    </ProvidersContext.Provider>
  );
};

export default ProvidersState;
