import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import CouponsContext from "../../contexts/coupons/couponsContext";

const AddCoupon = () => {
  const { addCoupon } = useContext(CouponsContext);
  const { register, errors, handleSubmit, reset } = useForm();
  const [code, setCode] = useState("");

  const generateRandomString = () => {
    const characters = "ABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789";
    let result1 = "";
    const charactersLength = characters.length;
    for (let i = 0; i < 8; i++) {
      result1 += characters.charAt(
        Math.floor(Math.random() * charactersLength)
      );
    }
    setCode(result1);
  };

  const handleChangeCode = (e) => {
    let result2 = e.target.value;
    setCode(result2);
  };

  const handleCloseModal = () => {
    window.$("#addCoupon").modal("hide");
  };

  const onSubmit = (data) => {
    addCoupon(data);
    reset();
    setCode("");
    handleCloseModal();
  };

  return (
    <>
      <div className="modal fade" id="addCoupon" tabIndex={-1} role="dialog">
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title" id="defaultModalLabel">
                Agregar Cupón
              </h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Descripción</label>
                      <input
                        name="description"
                        ref={register({
                          required: {
                            value: true,
                            message: "La descripción es requerida",
                          },
                          maxLength: {
                            value: 50,
                            message: "Maximo 50 caracteres",
                          },
                        })}
                        className={
                          errors.description
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Descripción corta *"
                      />
                      {errors?.description ? (
                        <label className="error">
                          {errors?.description?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-8">
                    <div className="form-group">
                      <label>Tipo</label>
                      <select
                        name="type"
                        className={
                          errors.type
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        ref={register({
                          required: {
                            value: true,
                            message: "El tipo de cupón es requerido",
                          },
                        })}
                      >
                        <option value={1}>Descuento Fijo</option>
                        <option value={2}>Descuento Porcentual</option>
                      </select>
                      {errors?.type ? (
                        <label className="error">{errors?.type?.message}</label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Descuento</label>
                        <input
                          type="text"
                          name="discount"
                          ref={register({
                            required: {
                              value: true,
                              message: "El descuento es requerido",
                            },
                            pattern: {
                              value: /^[0-9\b]+$/,
                              message: "El campo debe ser numerico",
                            },
                          })}
                          className={
                            errors.discount
                              ? "form-control is-invalid"
                              : "form-control "
                          }
                          placeholder="Descuento"
                        />

                      {errors?.discount ? (
                        <label className="error">
                          {errors?.discount?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label>Limíte de uso</label>
                      <input
                        type="number"
                        name="usage_limit"
                        min={1}
                        className={
                          errors.usage_limit
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        ref={register({
                          required: {
                            value: true,
                            message: "El limite de uso es requerido",
                          },
                        })}
                      />
                      {errors?.usage_limit ? (
                        <label className="error">{errors?.usage_limit?.message}</label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <label>Código</label>
                      <div className="input-group">
                        <input
                          type="text"
                          name="code"
                          value={code}
                          ref={register({
                            required: {
                              value: true,
                              message: "El código del cupón es requerido",
                            },
                            maxLength: {
                              value: 16,
                              message: "Maximo 16 caracteres",
                            },
                            pattern: {
                              value: /^[0-9a-zA-Z]+$/,
                              message:
                                "Solo letras en mayusculas/minusculas y numeros",
                            },
                          })}
                          className={
                            errors.code
                              ? "form-control is-invalid"
                              : "form-control "
                          }
                          onChange={(e) => handleChangeCode(e)}
                          placeholder="PBKIMPRESOS"
                        />
                        <div className="input-group-append">
                          <span
                            className="input-group-text"
                            onClick={generateRandomString}
                          >
                            <i className="zmdi zmdi-replay" />
                          </span>
                        </div>
                      </div>

                      {errors?.code ? (
                        <label className="error">{errors?.code?.message}</label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Fecha inicio</label>
                      <input
                        name="from"
                        type="date"
                        ref={register({
                          required: {
                            value: true,
                            message:
                              "La fecha de inicio del cupón es requerida",
                          },
                        })}
                        className={
                          errors.from
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.from ? (
                        <label className="error">{errors?.from?.message}</label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Fecha Expiración</label>
                      <input
                        name="expires"
                        type="date"
                        ref={register({
                          required: {
                            value: true,
                            message:
                              "La fecha de expiración del cupón es requerida",
                          },
                        })}
                        className={
                          errors.expires
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.expires ? (
                        <label className="error">
                          {errors?.expires?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  GUARDAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddCoupon;
