import React, { useContext } from 'react'
import { useForm } from "react-hook-form";
import OrdersContext from '../../../contexts/orders/ordersContext';
import CustomersContext from "../../../contexts/customers/customersContext";

const AddCustomer = () => {
    const { addCustomer } = useContext(OrdersContext);
    const { opcionesComoSeEnteraron } = useContext(CustomersContext);
    const { register, errors, handleSubmit, reset } = useForm();

    //funcion que se encarga de listar las opciones de como se enteraron
    const optionsReferences = opcionesComoSeEnteraron.map((referencia, index) => (
      <option key={index} value={referencia.id}>
        {referencia.opcion}
      </option>
    ));

    const handleCloseModal = () => {
      window.$('#addCustomer').modal('hide');
    }
  
    const onSubmit = (data) => {
      addCustomer(data);
      reset();
      handleCloseModal();
    }

  return (
    <>
         <div className="modal fade" id="addCustomer" tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-cyan">
              <h4 className="title" id="defaultModalLabel">
                Agregar Cliente
              </h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                        <label>Nombre</label>
                      <input
                        name="full_name"
                        ref={register({
                          required: {
                            value: true,
                            message: "El nombre del Cliente es requerido",
                          },
                        })}
                        className={
                          errors.full_name
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Nombre *"
                      />
                      {errors?.full_name ? (
                        <label className="error">{errors?.full_name?.message}</label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Teléfono</label>
                      <input
                        name="phone"
                        ref={register({
                          required: {
                            value: true,
                            message: "El Teléfono es requerido",
                          },
                          maxLength: {
                            value: 10,
                            message: "Maximo 10 digitos",
                          },
                          minLength: {
                            value: 10,
                            message: "Minimo 10 digitos",
                          },
                          pattern: {
                            value: /^[0-9\b]+$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.phone
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Teléfono *"
                      />
                      {errors?.phone ? (
                        <label className="error">
                          {errors?.phone?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                        <label>Email</label>
                      <input
                        name="email"
                        ref={register({ required: {
                            value: true,
                            message: "El Email es requerido",
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Dirección de correo electrónico no válida",
                          }, })}
                        className={
                          errors.email
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Email *"
                      />
                      {errors?.email ? (
                        <label className="error">
                          {errors?.email?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                        <label>¿Cómo se enteraron de nosotros?</label>
                        <select
                        name="opcion_como_se_enteraron_id"
                        ref={register({ required: { 
                          value: true,
                          message: "Este campo es requerido",
                          }, })}
                          className={
                            errors.opcion_como_se_enteraron_id
                            ? "form-control is-invalid"
                            : "form-control "
                            }
                            >
                              <option value="">Selecciona una opción</option>
                              {optionsReferences}
                            </select>
                      {errors?.opcion_como_se_enteraron_id ? (
                        <label className="error">
                          {errors?.opcion_como_se_enteraron_id?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  GUARDAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddCustomer