import React, { useReducer } from "react";
import MethodGet, { MethodPut } from "../../services/api";
import Swal from "sweetalert2";
import productionReducer from "./productionReducer";
import ProductionContext from "./productionContext";
import { GET_ORDERS_PRODUCTION, SHOW_ORDER_PRODUCTION, UPDATE_STATUS_ORDER_PRODUCTION } from "../../types";

const ProductionSatate = (props) => {
  // estado inicial
  const initialSate = {
    orders: [],
    order: [],
    details: [],
    success: false,
    current_page: null,
    total: null,
    per_page: null,
  };

  const [state, dispatch] = useReducer(productionReducer, initialSate);

  //obtener todas las ordenes de producción
  const getOrders = (page = 1, filters = '') => {
    let url = `production?page=${page}&order_id=${
      filters && filters.order_id ? filters.order_id : ""
    }&customer=${
      filters && filters.customer ? filters.customer : ""
    }&created_at=${filters && filters.created_at ? filters.created_at : ""}
    &order_status=${
      filters && filters.order_status ? filters.order_status : ""
    }`;
    MethodGet(url, filters).then((response) => {
      dispatch({
        type: GET_ORDERS_PRODUCTION,
        payload: response.data.data,
      });
    });
  };



    //obtener detalle de la orden
    const getOrder = (order) => {
      let url = `orders/${order}`;
      MethodGet(url).then((response) => {
        dispatch({
          type: SHOW_ORDER_PRODUCTION,
          payload: response.data,
        });
      });
    };

    //actualizar el status de una orden
    const updateStatus = (id, status) => {
      let url = `/update-status`;
      MethodPut(url, {order_id: id, status})
        .then((response) => {
          Swal.fire({
            title: 'Correcto!',
            text:  response.data.message,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
        });
          dispatch({
            type: UPDATE_STATUS_ORDER_PRODUCTION,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };


  return (
    <ProductionContext.Provider
      value={{
        orders: state.orders,
        order: state.order,
        details: state.details,
        success: state.success,
        successOrder: state.successOrder,
        current_page: state.current_page,
        total: state.total,
        per_page: state.per_page,
        getOrders,
        getOrder,
        updateStatus,
      }}
    >
      {props.children}
    </ProductionContext.Provider>
  );
};

export default ProductionSatate;
