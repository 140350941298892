import { GET_DATA_DASHBOARD, GET_DATA_GENERAL_REPORT } from "../../types";

export default function dashboardReducer(state, action) {
  switch (action.type) {
    case GET_DATA_DASHBOARD:
      return {
        ...state,
        local_customers: action.payload.local_customers,
        online_customers: action.payload.online_customers,
        total_orders_local: action.payload.total_orders_local,
        total_orders_online: action.payload.total_orders_online,
        today_income: action.payload.today_income,
        today_expense: action.payload.today_expense,
        today_utility: action.payload.today_utility,
        bestSellers: action.payload.bestSellers,
        recent_orders: action.payload.recent_orders,
        dataset: action.payload.dataset
      };

      case GET_DATA_GENERAL_REPORT:
      return {
        ...state,
        expense: action.payload.expense,
        income: action.payload.income,
        utility: action.payload.utility,
        total_ingresos_efectivo: action.payload.total_ingresos_efectivo,
        total_ingresos_transferencia: action.payload.total_ingresos_transferencia,
        movimientos: action.payload.movimientos,
      };

    default:
      return state;
  }
}
