import React, { useReducer } from "react";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import $ from "jquery";
import "bootstrap-notify";
import Swal from "sweetalert2";
import CouponsContext from "./couponsContext";
import couponsReducer from "./couponsReducer";
import { ADD_COUPON, EDIT_COUPON, GET_COUPONS, REMOVE_COUPON, UPDATE_COUPON } from "../../types";


const CouponsState = (props) => {
 // estado inicial
 const initialSate = {
    coupons: [],
    selectedCoupon: [],
    success: false,
  };

  const [state, dispatch] = useReducer( couponsReducer, initialSate);

  //obtener los cupones de descuento
  const getCoupons = () => {
    let url = `coupons`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_COUPONS,
        payload: response.data.data,
      });
    });
  };

  const addCoupon = (coupon) => {
    let url = "/coupons";
    MethodPost(url, coupon)
      .then((response) => {
        dispatch({
          type: ADD_COUPON,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR CUPON A EDITAR
  const editCoupon = (coupon) => {
    dispatch({
      type: EDIT_COUPON,
      payload: coupon,
    });
  };

  //Actualizar un CUPON
  const updateCoupon = (data) => {
    let url = `coupons/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
        dispatch({
          type: UPDATE_COUPON,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar cupon
  const removeCoupon = (id) => {
    let url = `coupons/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: 'Eliminado',
        text:  response.data.message,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false
    });
      dispatch({
        type: REMOVE_COUPON,
        payload: id,
      });
    });
  };

  return (
    <CouponsContext.Provider
      value={{
        coupons: state.coupons,
        success: state.success,
        selectedCoupon: state.selectedCoupon,
        getCoupons,
        addCoupon,
        editCoupon,
        removeCoupon,
        updateCoupon,
      }}
    >
      {props.children}
    </CouponsContext.Provider>
  );
};

export default CouponsState;
