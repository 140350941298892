import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import ProvidersContext from "../../contexts/providers/providersContext";
import { TagsInput } from "react-tag-input-component";

const EditProvider = ({ proveedor }) => {
  const { updateProvider } = useContext(ProvidersContext);
  const { nombre_comercial, razon_social, giro, etiquetas, observaciones } =
    proveedor;
  const [tags, setTags] = useState([]);
  const { register, errors, handleSubmit, reset } = useForm();

  useEffect(() => {
    if (proveedor.etiquetas) {
      setTags(etiquetas);
    }
    // eslint-disable-next-line
  }, [proveedor]);

  const handleCloseModal = () => {
    window.$("#editProvider").modal("hide");
  };

  const onSubmit = (data) => {
    data.id = proveedor.id;
    data.etiquetas = tags;
    updateProvider(data);
    reset();
    handleCloseModal();
  };

  return (
    <>
      <div className="modal fade" id="editProvider" tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-cyan">
              <h4 className="title">Actualizar Información</h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Nombre Comercial *</label>
                      <input
                        name="nombre_comercial"
                        autoComplete="off"
                        defaultValue={nombre_comercial}
                        ref={register({
                          required: {
                            value: true,
                            message: "El campo nombre comercial es requerido",
                          },
                        })}
                        className={
                          errors.nombre_comercial
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Nombre Comercial"
                      />
                      {errors?.nombre_comercial ? (
                        <label className="error">
                          {errors?.nombre_comercial?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Razón Social *</label>
                      <input
                        name="razon_social"
                        defaultValue={razon_social}
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: true,
                            message: "El campo razón social es requerido",
                          },
                        })}
                        className={
                          errors.razon_social
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Razón social"
                      />
                      {errors?.razon_social ? (
                        <label className="error">
                          {errors?.razon_social?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Rubro/Giro*</label>
                      <input
                        name="giro"
                        defaultValue={giro}
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: true,
                            message: "El campo rubro/giro es requerido",
                          },
                        })}
                        className={
                          errors.giro
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Rubro/Giro"
                      />
                      {errors?.giro ? (
                        <label className="error">{errors?.giro?.message}</label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>
                        Categorías{" "}
                        <small>(lona, vinil, DTF, promocionales, papel)</small>
                      </label>
                      <TagsInput
                        value={tags}
                        onChange={setTags}
                        name="etiquetas"
                        placeHolder="presiona enter para agregar"
                      />
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Observaciones</label>
                      <input
                        name="observaciones"
                        autoComplete="off"
                        defaultValue={observaciones}
                        ref={register({
                          required: {
                            value: false,
                            message: "El campo observaciones es requerido",
                          },
                        })}
                        className={
                          errors.observaciones
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Observaciones "
                      />
                      {errors?.observaciones ? (
                        <label className="error">
                          {errors?.observaciones?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  ACTUALIZAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditProvider;
