import React, { useContext, useEffect, useState } from 'react'
import InventoriesContext from '../../contexts/inventories/inventoriesContext';
import { useForm } from 'react-hook-form';

const AdjustStock = () => {

    const { selectedInventory, ajustarStock } = useContext(InventoriesContext);
    const { register, errors, handleSubmit, reset, setValue } = useForm();
    const [type, setSelectedType] = useState(null);

    useEffect(() => {
      if (selectedInventory?.material) {
        setValue(
          "material", selectedInventory.material.name,
          { shouldDirty: true }
        );
      }
      // eslint-disable-next-line
    }, [selectedInventory]);


    const handleChangeType = (e) => {
        setSelectedType(e.target.value);
      };

    const handleCloseModal = () => {
      window.$("#ajustarStock").modal("hide");
    };

    const onSubmit = (data) => {
      data.id = selectedInventory.id;
      ajustarStock(data);
      reset();
      setSelectedType(null);
      handleCloseModal();
    };

  return (
    <>
    <div className="modal fade" id="ajustarStock" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="defaultModalLabel">
              Añadir ajuste de Stock
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                  <label>Material</label>
                    <input
                      name="material"
                      type="text"
                      ref={register({
                          required: {
                            value: false,
                            message: "Nombre del material",
                          },
                        })}
                      className="form-control"
                      defaultValue={selectedInventory ? selectedInventory.id : ''}
                      readOnly
                    />
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>Tipo de ajuste</label>
                  <div className="form-group">
                    <div className="radio inlineblock m-r-20">
                      <input
                        type="radio"
                        name="type"
                        id="added"
                        className="with-gap"
                        value="add"
                        ref={register({
                          required: true,
                        })}
                        checked={type === "add"}
                        onChange={handleChangeType}
                      />
                      <label htmlFor="added">Sumar inventario</label>
                    </div>
                    <div className="radio inlineblock">
                      <input
                        type="radio"
                        name="type"
                        id="sebstract"
                        className="with-gap"
                        value="sub"
                        ref={register({
                          required: true,
                        })}
                        checked={type === "sub"}
                        onChange={handleChangeType}
                      />
                      <label htmlFor="sebstract">Restar inventario</label>
                    </div>{" "}
                    <br />
                    {errors?.type ? (
                      <label className="error">
                        Selecciona el tipo de ajuste
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                  <label>Cantidad</label>
                    <div className="input-group">
                      <input
                        name="quantity"
                        type="text"
                        defaultValue=""
                        ref={register({
                          required: {
                            value: true,
                            message: "La cantidad es requerida",
                          },
                          min:{
                            value:0.1,
                            message:'El campo no puede ser negativo'
                            },
                        pattern: {
                            value: /^[0-9]+([.])?([0-9]+)?$/,
                            message: "El campo debe ser numerico",
                            },
                        })}
                        className={
                          errors.quantity
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Cantidad *"
                      />

                    <div className="input-group-append">
                        <span
                          className="input-group-text"
                        >
                          {selectedInventory ? selectedInventory.material?.sale_unit?.acronym : ''}
                        </span>
                      </div>
                    </div>
                      {errors?.quantity ? (
                        <label className="error">{errors?.quantity?.message}</label>
                      ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                  <label>Motivo</label>
                  <textarea
                    name="reason"
                    rows={3}
                    ref={register({
                      required: {
                        value: true,
                        message: "El motivo es requerido",
                      },
                    })}
                    className={
                      errors.reason
                        ? "form-control is-invalid no-resize"
                        : "form-control no-resize"
                    }
                  ></textarea>
                    {errors?.reason ? (
                      <label className="error">{errors?.reason?.message}</label>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-danger btn-round waves-effect"
              >
                AGREGAR AJUSTE
              </button>
              <button
                type="button"
                className="btn btn-default btn-round waves-effect"
                onClick={handleCloseModal}
              >
                CANCELAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </>
  )
}

export default AdjustStock
