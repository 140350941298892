import React, { useContext, useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ProductsContext from "../../../contexts/products/productsContext";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";

const UpdateProduct = () => {
  const { product, families, updateProduct } = useContext(ProductsContext);
  const { register, errors, handleSubmit, reset, control, setValue } =
    useForm();

  const [selected, setSelected] = useState(null);
  const [product_type, setProductType] = useState("");
  const [units, setUnits] = useState([]);

  const { id } = useParams();

  const [url_product, setUrlProduct] = useState("");

  //funcion que se encarga de listar las familias en el select
  const optionsFamilies = families.map((fam) => {
    return { value: fam.id, label: fam.name };
  });

  useEffect(() => {
    if (product.family) {
      setUrlProduct(product.slug);
      setValue(
        "family_id",
        { value: product.family.id, label: product.family.name },
        { shouldDirty: true }
      );
      setProductType(product.product_type);
      setUnits(product.units);
      setSelected(product.status);
    }
    // eslint-disable-next-line
  }, [product]);

  const handleCloseModal = () => {
    window.$("#updateProduct").modal("hide");
  };

  const handleChange = (e) => {
    setSelected(Number(e.target.value));
  };

  const handleChangeUrlName = (e) => {
    let srt = e.target.value;

    let srt_spaces = srt.replace(/\s+/g, "-");
    let str_normalice = srt_spaces
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    let str_final = str_normalice.toLowerCase();

    setUrlProduct(str_final);
  };

  const handleProductType = (e) => {
    setProductType(e.target.value);
  };

  const onSubmit = (data) => {
    if (selected === 1 && product_type === "offset" && !units) {
      toast.error("Agrega las unidades de venta del producto", {
        theme: "colored",
      });
      return false;
    }
    if (product_type === "offset") {
      data.units = units;
    }
    data.id = id;
    updateProduct(data);
    reset({});
    setUrlProduct("");
    handleCloseModal();
  };

  return (
    <div className="modal fade" id="updateProduct" tabIndex={-1} role="dialog">
      <div className="modal-dialog modal-lg" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="largeModalLabel">
              Actualizar Información del Producto
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-6">
                  <label>Nombre</label>
                  <div className="form-group">
                    <input
                      type="text"
                      name="name"
                      defaultValue={product ? product.name : ""}
                      placeholder="Nombre del producto"
                      ref={register({
                        required: {
                          value: true,
                          message: "El Nombre del producto es requerido",
                        },
                      })}
                      className={
                        errors.name
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      onChange={(e) => handleChangeUrlName(e)}
                    />
                    {errors?.name ? (
                      <label className="error">{errors?.name?.message}</label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6">
                  <label>Url</label>
                  <div className="form-group">
                    <input
                      type="text"
                      name="slug"
                      defaultValue={url_product}
                      placeholder="Url del producto"
                      readOnly
                      ref={register({
                        required: {
                          value: true,
                          message: "La url del producto es requerida",
                        },
                      })}
                      className={
                        errors.slug
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                    />
                    {errors?.slug ? (
                      <label className="error">{errors?.slug?.message}</label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>Familia</label>
                  <div className="form-group">
                    <Controller
                      name="family_id"
                      control={control}
                      placeholder="Selecciona una opción *"
                      defaultValue
                      options={optionsFamilies}
                      as={<Select />}
                      rules={{ required: "La Familia es un campo Requerido " }}
                    />
                    {errors?.family_id ? (
                      <label className="error">
                        {errors?.family_id?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>Descripción</label>
                  <div className="form-group">
                    <textarea
                      name="description"
                      defaultValue={product ? product.description : ""}
                      rows={4}
                      placeholder="Descripción del producto"
                      ref={register({
                        required: {
                          value: true,
                          message: "La descripción del producto es requerida",
                        },
                      })}
                      className={
                        errors.description
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                    />
                    {errors?.description ? (
                      <label className="error">
                        {errors?.description?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>Estatus</label>
                  <div className="form-group">
                    <div className="radio inlineblock m-r-20">
                      <input
                        type="radio"
                        name="status"
                        id="published"
                        className="with-gap"
                        value={1}
                        ref={register({
                          required: true,
                        })}
                        checked={selected === 1}
                        onChange={handleChange}
                      />
                      <label htmlFor="published">Online</label>
                    </div>
                    <div className="radio inlineblock">
                      <input
                        type="radio"
                        name="status"
                        id="draft"
                        className="with-gap"
                        value={2}
                        ref={register({
                          required: true,
                        })}
                        checked={selected === 2}
                        onChange={handleChange}
                      />
                      <label htmlFor="draft">Local</label>
                    </div>{" "}
                    <br />
                    {errors?.status ? (
                      <label className="error">
                        Selecciona el estatus del producto
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <label>Tipo de producto</label>
                </div>
                <div className="col-lg-3">
                  <div className="radio inlineblock">
                    <input
                      name="product_type"
                      id="simple"
                      type="radio"
                      ref={register}
                      className="with-gap"
                      value="simple"
                      onChange={(e) => handleProductType(e)}
                      checked={product_type === "simple"}
                    />
                    <label htmlFor="simple">
                      Unidad <br />
                      <small className="text-muted">(venta por unidad)</small>
                    </label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="radio inlineblock">
                    <input
                      name="product_type"
                      id="byarea"
                      type="radio"
                      ref={register}
                      className="with-gap"
                      value="byarea"
                      onChange={(e) => handleProductType(e)}
                      checked={product_type === "byarea"}
                    />
                    <label htmlFor="byarea">
                      Metro Cuadrado <br />
                      <small className="text-muted">("base x altura" )</small>
                    </label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="radio inlineblock">
                    <input
                      name="product_type"
                      id="offset"
                      type="radio"
                      ref={register}
                      className="with-gap"
                      value="offset"
                      onChange={(e) => handleProductType(e)}
                      checked={product_type === "offset"}
                    />
                    <label htmlFor="offset">
                      Unidades <br />
                      <small className="text-muted">
                        (e.j. 100, 500, 1000 uds )
                      </small>
                    </label>
                  </div>
                </div>
                <div className="col-lg-3">
                  <div className="radio inlineblock">
                    <input
                      name="product_type"
                      id="service"
                      type="radio"
                      ref={register}
                      className="with-gap"
                      value="service"
                      onChange={(e) => handleProductType(e)}
                      checked={product_type === "service"}
                    />
                    <label htmlFor="service" className="name">
                      Servicio <br />
                      <small className="text-muted">
                        (e.j. instalación, diseño )
                      </small>
                    </label>
                  </div>
                </div>
                {selected === 1 ? (
                  <>
                    {product_type === "service" || product_type === "byarea" ? (
                      <div className="col-lg-12">
                        <label>Precio</label>
                        <div className="form-group">
                          <input
                            type="text"
                            name="price"
                            placeholder="100"
                            defaultValue={
                              product && product.price ? product.price : ""
                            }
                            ref={register({
                              required: {
                                value: true,
                                message: "El Precio del servicio es requerido",
                              },
                            })}
                            className={
                              errors.price
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                          />
                          {errors?.price ? (
                            <label className="error">
                              {errors?.price?.message}
                            </label>
                          ) : null}
                        </div>
                      </div>
                    ) : null}
                    {product_type === "offset" ? (
                      <div className="col-lg-12">
                        <label>Unidades</label>
                        <div className="form-group">
                          <TagsInput
                            value={units}
                            onChange={setUnits}
                            name="units"
                            placeHolder="presiona enter para agregar"
                          />
                        </div>
                      </div>
                    ) : null}
                  </>
                ) : (
                  <div className="col-lg-12">
                    <label>Precio</label>
                    <div className="form-group">
                      <input
                        type="text"
                        name="price"
                        placeholder="100"
                        defaultValue={
                          product && product.price ? product.price : ""
                        }
                        ref={register({
                          required: {
                            value: true,
                            message: "El Precio del servicio es requerido",
                          },
                        })}
                        className={
                          errors.price
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.price ? (
                        <label className="error">
                          {errors?.price?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-danger btn-round waves-effect"
              >
                ACTUALIZAR
              </button>
              <button
                type="button"
                className="btn btn-default btn-round waves-effect"
                onClick={handleCloseModal}
              >
                CANCELAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdateProduct;
