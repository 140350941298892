/* eslint-disable jsx-a11y/anchor-is-valid */
import React , { useState } from "react";
import { Link } from "react-router-dom";

const SidebarSales = () => {

  const [openMenus, setOpenMenus] = useState({});

  const handleToggle = (menuId) => {
    setOpenMenus((prevState) => ({
      ...prevState,
      [menuId]: !prevState[menuId],
    }));
  };

  return (
    <>

        <Link to="/">
          <li className="active open">
            <i className="ti-layout-grid2"></i>
            <span>Dashboard</span>
          </li>
        </Link>
        <Link to="/orders">
          <li className="active open">
            <i className="ti-package"></i>
            <span>Ordenes</span>
          </li>
        </Link>
        <li>
          <a
            href="#"
            className={`menu-toggle ${openMenus["productos"] ? "toggled" : ""}`}
            onClick={() => handleToggle("productos")}
          >
            <i className="ti-shopping-cart-full" />
            <span>Productos</span>
          </a>
          <ul
            className="ml-menu"
            style={{ display: openMenus["productos"] ? "block" : "none" }}
          >
            <li>
              <Link to="/products">Productos</Link>
            </li>
            <li>
              <Link to="/sizes">Tamaños</Link>
            </li>
            <li>
              <Link to="/printing">Impresion</Link>
            </li>
            <li>
              <Link to="/options">Acabados</Link>
            </li>
            <li>
              <Link to="/materiales">Materiales</Link>
            </li>
          </ul>
        </li>
        <Link to="/families">
          <li className="active open">
            <i className="ti-bookmark"></i>
            <span>Familias</span>
          </li>
        </Link>
        <Link to="/inventories">
          <li className="active open">
            <i className="ti-write"></i>
            <span>Invetario</span>
          </li>
        </Link>
        <Link to="/compras">
              <li className="active open">
                <i className="ti-receipt"></i>
                <span>Compras</span>
              </li>
            </Link>
            <li>
              <a
                href="#"
                className={`menu-toggle ${openMenus["catalogos"] ? "toggled" : ""}`}
                onClick={() => handleToggle("catalogos")}
              >
                <i className="ti-view-list-alt" />
                <span>Catalogos</span>
              </a>
              <ul
                className="ml-menu"
                style={{ display: openMenus["catalogos"] ? "block" : "none" }}
              >
                <li>
                  <Link to="/customers">Clientes</Link>
                </li>
                <li>
                  <Link to="/proveedores">Proveedores</Link>
                </li>
                <li>
                  <Link to="/gastos">Gastos</Link>
                </li>
              </ul>
            </li>
    </>
  );
};

export default SidebarSales;
