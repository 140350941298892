import React, { useReducer } from "react";
import ProductsContext from "./productsContext";
import productsReducer from "./productsReducer";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import $ from "jquery";
import "bootstrap-notify";
import Swal from "sweetalert2";
import {
  ADD_IMAGES_PRODUCT,
  ADD_MATERIAL_PRODUCT,
  ADD_OPTION_PRODUCT,
  ADD_PRICE_PRODUCT,
  ADD_PRINTING_PRODUCT,
  ADD_PRODUCT,
  ADD_SIZE_PRODUCT,
  DELETE_IMAGE_PRODUCT,
  DELETE_MATERIAL_PRODUCT,
  DELETE_OPTION_PRODUCT,
  DELETE_PRICE_PRODUCT,
  DELETE_PRINTING_PRODUCT,
  DELETE_SIZE_PRODUCT,
  EDIT_PRICE_PRODUCT,
  EDIT_PRODUCT,
  GET_FAMILIES_SELECT,
  GET_MATERIALS_SELECT,
  GET_MEASUREMENTS_SELECT,
  GET_PRINTINGS_SELECT,
  GET_OPTIONS_SELECT,
  GET_PRODUCTS,
  GET_SIZES_SELECT,
  REMOVE_PRODUCT,
  SHOW_PRODUCT,
  UPDATE_PRICE_PRODUCT,
  UPDATE_PRODUCT,
  EDIT_OPTION_PRODUCT,
  UPDATE_OPTION_PRODUCT,
} from "../../types";


const ProductsState = (props) => {
 // estado inicial
 const initialSate = {
    products: [],
    families: [],
    measurements: [],
    materials: [],
    selectedProduct: [],
    product: [],
    sizes: [],
    printing: [],
    printings: [],
    options: [],
    prices: [],
    materialProduct: [],
    sizesProduct: [],
    printingProduct: [],
    optionsProduct: [],
    imagesProduct: [],
    success: false,
    selectedPrice: [],
    selectedOption: []
  };

  const [state, dispatch] = useReducer( productsReducer, initialSate);

  //obtener los productos
  const getProducts = () => {
    let url = `products`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_PRODUCTS,
        payload: response.data.data,
      });
    });
  };

  //AGREGAR NUEVO PRODUCTO
  const addProduct = (product) => {
    let url = "/products";
    MethodPost(url, product)
      .then((response) => {
        dispatch({
          type: ADD_PRODUCT,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR PRODUCTO A EDITAR
  const editProduct = (product) => {
    dispatch({
      type: EDIT_PRODUCT,
      payload: product,
    });
  };

  //Actualizar un Producto
  const updateProduct = (data) => {
    let url = `products/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
        dispatch({
          type: UPDATE_PRODUCT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar UN PRODUCTO
  const removeProduct = (id) => {
    let url = `products/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: 'Eliminado',
        text:  response.data.message,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false
    });
      dispatch({
        type: REMOVE_PRODUCT,
        payload: id,
      });
    });
  };

  //obtener detalle del producto
  const getProduct = (product) => {
    let url = `products/${product}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: SHOW_PRODUCT,
        payload: response.data,
      });
    });
  };

   //Obtener la data para el select de familias
   const getFamilies = () => {
    let url = "getFamilies";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_FAMILIES_SELECT,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

    //Obtener la data para el select de unidades
    const getMeasurements = () => {
      let url = "measurements-all";
      MethodGet(url)
        .then((response) => {
          dispatch({
            type: GET_MEASUREMENTS_SELECT,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

    //Obtener la data para el select de materiales
    const getMaterials = () => {
      let url = "materials-all";
      MethodGet(url)
        .then((response) => {
          dispatch({
            type: GET_MATERIALS_SELECT,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

  //Obtener la data para el select de tamaños
   const getSizes = () => {
    let url = "getSizes";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_SIZES_SELECT,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

   //Obtener la data para el select de tipos de impresion
   const getPrintings = () => {
    let url = "getPrintings";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_PRINTINGS_SELECT,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

     //Obtener la data para el select de tipos de impresion
     const getOptions = () => {
      let url = "getOptions";
      MethodGet(url)
        .then((response) => {
          dispatch({
            type: GET_OPTIONS_SELECT,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

  //AGREGAR NUEVO PRECIO DE PRODUCTO
  const addPriceProduct = (data) => {
    let url = "/price-product";
    MethodPost(url, data)
      .then((response) => {
        dispatch({
          type: ADD_PRICE_PRODUCT,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

   //AGREGAR NUEVO TAMAÑO DE PRODUCTO
   const addSizeProduct = (data) => {
    let url = "/add-product-size";
    MethodPost(url, data)
      .then((response) => {
        dispatch({
          type: ADD_SIZE_PRODUCT,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

   //AGREGAR NUEVO MATERIAL DE PRODUCTO
   const addMaterialProduct = (data) => {
    let url = "/material-product";
    MethodPost(url, data)
      .then((response) => {
        dispatch({
          type: ADD_MATERIAL_PRODUCT,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

    //AGREGAR NUEVA IMPRESIÓN DE PRODUCTO
    const addPrintingProduct = (data) => {
      let url = "/add-product-printing";
      MethodPost(url, data)
        .then((response) => {
          dispatch({
            type: ADD_PRINTING_PRODUCT,
          });
          $.notify(
            {
              message: response.data.message,
            },
            {
              type: "alert-success",
              allow_dismiss: true,
              newest_on_top: true,
              timer: 5000,
              placement: {
                from: "bottom",
                align: "left",
              },
              animate: {
                enter: "animated fadeInDown",
                exit: "animated fadeOutUp",
              },
              template:
                '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
                (true ? "" : "") +
                '" role="alert">' +
                '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                '<span data-notify="icon"></span> ' +
                '<span data-notify="title">{1}</span> ' +
                '<span data-notify="message">{2}</span>' +
                '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                "</div>" +
                '<a href="{3}" target="{4}" data-notify="url"></a>' +
                "</div>",
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };


     //AGREGAR NUEVO ACABADO DE PRODUCTO
     const addOptionProduct = (data) => {
      let url = "/add-product-option";
      MethodPost(url, data)
        .then((response) => {
          dispatch({
            type: ADD_OPTION_PRODUCT,
          });
          $.notify(
            {
              message: response.data.message,
            },
            {
              type: "alert-success",
              allow_dismiss: true,
              newest_on_top: true,
              timer: 5000,
              placement: {
                from: "bottom",
                align: "left",
              },
              animate: {
                enter: "animated fadeInDown",
                exit: "animated fadeOutUp",
              },
              template:
                '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
                (true ? "" : "") +
                '" role="alert">' +
                '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
                '<span data-notify="icon"></span> ' +
                '<span data-notify="title">{1}</span> ' +
                '<span data-notify="message">{2}</span>' +
                '<div class="progress" data-notify="progressbar">' +
                '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
                "</div>" +
                '<a href="{3}" target="{4}" data-notify="url"></a>' +
                "</div>",
            }
          );
        })
        .catch((error) => {
          console.log(error);
        });
    };

     //AGREGAR IMAGENES DE PRODUCTO
  const addImagesProduct = (data) => {
    let url = "/product-image";
    MethodPost(url, data, {headers:{ 'Content-Type' : 'multipart/form-data'} })
      .then((response) => {
        dispatch({
          type: ADD_IMAGES_PRODUCT,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

     //Eliminar una presentación de procucto
  const deletePrice = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Una Presentación de producto eliminada no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        let url = `price-product/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire({
            title: 'Eliminado',
            text:  response.data.message,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
        });
          dispatch({
            type: DELETE_PRICE_PRODUCT,
            payload: id,
          });
        });
      }
    });
  };


   //Eliminar un tamaño
   const deleteSize = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Un Tamaño eliminado no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        let url = `delete-product-size/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire({
            title: 'Eliminado',
            text:  response.data.message,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
        });
          dispatch({
            type: DELETE_SIZE_PRODUCT,
            payload: id,
          });
        });
      }
    });

  };

   //Eliminar un material
   const deleteMaterial= (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Una Material eliminado no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        let url = `material-product/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire({
            title: 'Eliminado',
            text:  response.data.message,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
        });
          dispatch({
            type: DELETE_MATERIAL_PRODUCT,
            payload: id,
          });
        });
      }
    });

  };

   //Eliminar un tipo de impresión
   const deletePrinting = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Un tipo de Impresión eliminado no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        let url = `delete-product-printing/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire({
            title: 'Eliminado',
            text:  response.data.message,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
        });
          dispatch({
            type: DELETE_PRINTING_PRODUCT,
            payload: id,
          });
        });
      }
    });

  };

   //Eliminar un acabado
   const deleteOption = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Un Acabado eliminado no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        let url = `delete-product-option/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire({
            title: 'Eliminado',
            text:  response.data.message,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
        });
          dispatch({
            type: DELETE_OPTION_PRODUCT,
            payload: id,
          });
        });
      }
    });
  };


  //Eliminar una imagen de producto
  const deleteImage = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Una Imagen eliminada no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        let url = `product-image/${id}`;
        MethodDelete(url).then((response) => {
          Swal.fire({
            title: 'Eliminado',
            text:  response.data.message,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
        });
          dispatch({
            type: DELETE_IMAGE_PRODUCT,
            payload: id,
          });
        });
      }
    });
  };

    //SELECCIONAR PRECIO A EDITAR
    const editPrice = (price) => {
      dispatch({
        type: EDIT_PRICE_PRODUCT,
        payload: price,
      });
    };

      //Actualizar un Producto
  const updatePrice = (data) => {
    let url = `price-product/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
        dispatch({
          type: UPDATE_PRICE_PRODUCT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

    //SELECCIONAR ACABADO A EDITAR
    const editOption = (option) => {
      dispatch({
        type: EDIT_OPTION_PRODUCT,
        payload: option,
      });
    };

      //Actualizar el precio de un acabado
  const updatePriceOption = (data) => {
    let url = `update-product-option/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
        dispatch({
          type: UPDATE_OPTION_PRODUCT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };


  return (
    <ProductsContext.Provider
      value={{
        products: state.products,
        families: state.families,
        measurements: state.measurements,
        materials: state.materials,
        product: state.product,
        sizes: state.sizes,
        printing: state.printing,
        printings: state.printings,
        options: state.options,
        prices: state.prices,
        materialProduct: state.materialProduct,
        sizesProduct: state.sizesProduct,
        printingProduct: state.printingProduct,
        optionsProduct: state.optionsProduct,
        imagesProduct: state.imagesProduct,
        success: state.success,
        selectedProduct: state.selectedProduct,
        selectedPrice: state.selectedPrice,
        selectedOption: state.selectedOption,
        getProducts,
        getFamilies,
        getMeasurements,
        getMaterials,
        addProduct,
        editProduct,
        removeProduct,
        updateProduct,
        getProduct,
        addPriceProduct,
        addSizeProduct,
        addMaterialProduct,
        addPrintingProduct,
        addOptionProduct,
        addImagesProduct,
        deletePrice,
        deleteSize,
        deleteMaterial,
        deletePrinting,
        deleteOption,
        deleteImage,
        editPrice,
        updatePrice,
        getSizes,
        getPrintings,
        getOptions,
        editOption,
        updatePriceOption
      }}
    >
      {props.children}
    </ProductsContext.Provider>
  );
};

export default ProductsState;
