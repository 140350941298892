import React, { useReducer } from "react";
import OrdersContext from "./ordersContext";
import ordersReducer from "./ordersReducer";
import MethodGet, { MethodPost, MethodPut } from "../../services/api";
import $ from "jquery";
import "bootstrap-notify";
import Swal from "sweetalert2";
import fileDownload from "js-file-download";

import {
  ADD_CUSTOMER,
  ADD_DATE_DELIVERY,
  ADD_ITEM_TO_ORDER,
  ADD_ORDER,
  ADD_PAYMENT,
  ADD_PRODUCT_TO_CART,
  BACKUP_ORDERS_CLIENT,
  CANCEL_ORDER,
  CLOSE_MODAL_OUT_STOCK,
  DELETE_PRODUCT_FROM_SALE,
  EDIT_ORDER,
  GET_CUSTOMERS_SELECT,
  GET_ORDERS,
  GET_PRODUCT,
  GET_PRODUCTS_SELECT,
  REMOVE_PRODUCTS_FROM_ORDER,
  REORDER_ITEMS,
  RESET_PRODUCT_SELECTED,
  SAVE_ORDER_EDITION,
  SET_INVOICE_NUMBER,
  SHOW_ORDER,
  TOTAL_ORDER_EDIT,
  TOTAL_SALE_ORDER,
  UPDATE_COMMISSION,
  UPDATE_DISCOUNT_RATE,
  UPDATE_PRODUCT_FROM_ORDER,
  UPDATE_STATUS_ORDER,
  UPLOAD_FILES_ORDER,
  UPDATE_ORDER_DATE,
  ADD_NOTES_TO_ORDER,
  ADD_OBSERVATIONS_TO_ORDER
} from "../../types";

const OrdersSatate = (props) => {
  // estado inicial
  const initialSate = {
    orders: [],
    order: [],
    details: [],
    products: [],
    customers: [],
    product: null,
    sizes: [],
    materialProduct: [],
    printing: [],
    options: [],
    prices: [],
    productsList: [],
    subtotal: 0,
    shipping_costs: 0,
    discount: 0,
    iva: 0,
    totalSale: 0,
    discount_rate: 0,
    successOrder: false,
    success: false,
    current_page: null,
    total: null,
    per_page: null,
    deletedItems: [],
    addedItems: [],
    updatedItems: [],
    successEditing: false,
    commission: 0,
    upDiscount: false,
    upCommission: false,
    insufficient_material: [],
    out_stock: false,
    orders_backups: [],
    cantidad_pdfs: 0
  };

  const [state, dispatch] = useReducer(ordersReducer, initialSate);

  //obtener todas las ordenes
  const getOrders = (page = 1, filters = '') => {
    let url = `orders?page=${page}&order_id=${
      filters && filters.order_id ? filters.order_id : ""
    }&customer=${
      filters && filters.customer ? filters.customer : ""
    }&created_at=${filters && filters.created_at ? filters.created_at : ""}
    &order_status=${
      filters && filters.order_status ? filters.order_status : ""
    }&payment_status=${
      filters && filters.payment_status ? filters.payment_status : ""
    }`;
    MethodGet(url, filters).then((response) => {
      dispatch({
        type: GET_ORDERS,
        payload: response.data.data,
      });
    });
  };

  //Obtener la data para el select de productos
  const getProducts = () => {
    let url = "products-local";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_PRODUCTS_SELECT,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //obtener detalle del producto
  const getProduct = (product) => {
    let url = `products/${product}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_PRODUCT,
        payload: response.data,
      });
    });
  };


  const addProduct = (data) => {
    dispatch({
      type: ADD_PRODUCT_TO_CART,
      payload: data,
    });

    dispatch({
      type: TOTAL_SALE_ORDER,
    });
  };

  //funcion para calcular el total de la venta
  const calculateTotalSale = () => {
    dispatch({
      type: TOTAL_SALE_ORDER,
    });
  };

  const deleteProductSale = (id) => {
    dispatch({
      type: DELETE_PRODUCT_FROM_SALE,
      payload: id,
    });

    dispatch({
      type: TOTAL_SALE_ORDER,
    });
  };

  const updateDiscountRate = (discount) => {
    dispatch({
      type: UPDATE_DISCOUNT_RATE,
      payload: discount,
    });

    dispatch({
      type: TOTAL_SALE_ORDER,
    });
  }

  const updateCommission = (commission) => {
    dispatch({
      type: UPDATE_COMMISSION,
      payload: commission,
    });
  }

  //Obtener la data para el select de CLIENTES
  const getCustomers = () => {
    let url = "customers-all";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_CUSTOMERS_SELECT,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const addCustomer = (customer) => {
    let url = "/customers";
    MethodPost(url, customer)
      .then((response) => {
        dispatch({
          type: ADD_CUSTOMER,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Agregar nueva orden
  const addOrder = (order) => {
    const data = {
      products_list: state.productsList,
      customer_id: order.customer_id.value,
      subtotal: state.subtotal,
      discount: state.discount,
      iva: state.iva,
      total: state.totalSale,
      discount_rate: state.discount_rate,
      commission: state.commission,
      notes: order.notes,
    };

    let url = "/orders";

    Swal.fire({
      title: "Guardando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        MethodPost(url, data)
          .then((response) => {
            dispatch({
              type: ADD_ORDER,
            });
            Swal.fire({
              title: "Correcto",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showConfirmButton: false,
            });
            Swal.close();
          })
          .catch((error) => {
            Swal.close();
            Swal.fire({
              title: "Algo salio mal :(",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: true,
            });
          });
      },
    });
  };


    //obtener detalle de la orden
    const getOrder = (order) => {
      let url = `orders/${order}`;
      MethodGet(url).then((response) => {
        dispatch({
          type: SHOW_ORDER,
          payload: response.data,
        });
      });
    };

  const uploadFiles = (data) => {
    let url = "/upload-files";
    MethodPost(url, data, {headers:{ 'Content-Type' : 'multipart/form-data'} })
      .then((response) => {
        dispatch({
          type: UPLOAD_FILES_ORDER,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  }


  //descargar la orden en formato pdf o Excel
  const downloadOrder = (id, filename, filetype) => {
    let url = `/download-order/${id}?type_file=${filetype}`;

    Swal.fire({
      title: "Descargando...",
      html: "Esto puede demorar algunos segundos.",
      allowEscapeKey: false,
      allowOutsideClick:false,
      didOpen: () => {
        Swal.showLoading();
        MethodGet(url, { responseType: "blob" })
          .then((response) => {
            fileDownload(response.data, filename);
            Swal.close();
          })
          .catch((error) => {
            console.log(error);
            Swal.close();
          });
      },
    });
  };

    //enviar orden por correo electronico
    const sendEmailOrder = (order) => {
      let url = `/email-order/${order}`;

      Swal.fire({
        title: "Enviando ...",
        html: "Esto puede demorar algunos segundos, no salgas de la ventana.",
        allowEscapeKey: false,
        allowOutsideClick:false,
        didOpen: () => {
          Swal.showLoading();
          MethodGet(url)
            .then((response) => {
              Swal.close();
              Swal.fire({
                title: "Correcto!",
                text: response.data.message,
                icon: "success",
                showConfirmButton: true,
              });
            })
            .catch((error) => {
              Swal.close();
              Swal.fire({
                title: "Algo salio mal :(",
                text: error.response.data.message,
                icon: "error",
                showConfirmButton: true,
              });
            });
        },
      });
    };

    //actualizar el status de una orden
    const updateStatus = (id, status) => {
      let url = `/update-status`;
      MethodPut(url, {order_id: id, status})
        .then((response) => {
          Swal.fire({
            title: 'Correcto!',
            text:  response.data.message,
            icon: 'success',
            timer: 3000,
            showConfirmButton: false
        });
          dispatch({
            type: UPDATE_STATUS_ORDER,
          });
        })
        .catch((error) => {
          Swal.fire({
            title: 'Stock Insuficiente',
            html: `<h6>No se pudo actualizar el Estado de la orden</h6> <div class="text-left"> ${error.response.data.error} </div>`,
            icon: 'warning',
          });
        });
    };

    //CERRAR MODAL DE STOCK INSUFICIENTE
    const closeModalOutStock = () => {
      dispatch({
        type: CLOSE_MODAL_OUT_STOCK,
      });
    }

    // Agregar un pago
    const addPayment = (data) => {
      let url = "/payments";
      MethodPost(url, data)
        .then((response) => {
          dispatch({
            type: ADD_PAYMENT,
          });
          Swal.fire({
            title: "Correcto!",
            text: response.data.message,
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };


    //obtener los datos de la orden a editar
    const getOrderEdit = (order) => {
      let url = `orders/${order}`;
      MethodGet(url).then((response) => {
        dispatch({
          type: EDIT_ORDER,
          payload: response.data,
        });
      });
    };

    //Eliminar productos de la orden
    const removeProducts = (data) => {
      dispatch({
        type: REMOVE_PRODUCTS_FROM_ORDER,
        payload: data,
      });
      dispatch({
        type: TOTAL_ORDER_EDIT,
      });
    }

    const addItemOrder = (data) => {
      dispatch({
        type: ADD_ITEM_TO_ORDER,
        payload: data,
      });
      dispatch({
        type: TOTAL_ORDER_EDIT,
      });
    };

    const updateProduct = (data) => {
      dispatch({
        type: UPDATE_PRODUCT_FROM_ORDER,
        payload: data,
      });
      dispatch({
        type: TOTAL_ORDER_EDIT,
      });
    };

    const resetProduct = () => {
      dispatch({
        type: RESET_PRODUCT_SELECTED,
      });
    }

    //Cancelar una orden
    const cancelOrder = (order) => {
      let url = `cancel-order/${order}`;
      MethodPut(url).then((response) => {
        dispatch({
          type: CANCEL_ORDER
        });
        Swal.fire({
          title: "Correcto!",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    };

  //función para guardar la edición de la venta
  const saveOrderEdition = () => {
    const data = {
      order_id: state.order.id,
      subtotal: state.subtotal.toFixed(2),
      shipping_costs: state.shipping_costs,
      discount: state.discount.toFixed(2),
      iva: state.iva.toFixed(2),
      total: state.totalSale.toFixed(2),
      discount_rate: state.discount_rate,
      commission: state.commission,
      added_products: state.addedItems,
      updated_products: state.updatedItems,
      removed_products: state.deletedItems,
    };

    let url = `/orders/${data.order_id}`;
    MethodPut(url, data)
      .then((response) => {
        Swal.fire({
          title: "Buen trabajo",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: SAVE_ORDER_EDITION,
        });
      })
      .catch((error) => {
        if (error.response.data.type === "out_stock") {

          Swal.fire({
            icon: "danger",
            title: "Stock Insuficiente",
            text: error.response.data.message,
            showConfirmButton: true,
            html:
            '<b>' + error.response.data.message  + '</b><br/><br/>' +
            `<table id="table" border=1 align="center">
              <thead>
                <tr>
                  <th>#</th>
                  <th>Material</th>
                  <th>Estado</th>
                </tr>
              </thead>
              <tbody id="listooutStock">
              </tbody>
            </table>`
          });

          let materiales = error.response.data.data;
          for (let index = 0; index < materiales.length; index++) {
            var element = '';
            element += '<tr>';
            element += '<td>' + (index +  1) + '</td>';
            element += '<td>' +  materiales[index].material.name + '</td>';
            element += '<td>';
            element += '<span class="badge badge-danger"> Insuficiente </span>';
            element += '</td>';
            element += '</tr>';

            $('#listooutStock').append(element);
          }

        } else {
          Swal.fire({
            title: "Algo salio mal :(",
            text: error.response.data.message,
            icon: "error",
            showConfirmButton: true,
          });

        }


      });
  };

  const updateDiscountRateEdit = (discount) => {
    dispatch({
      type: UPDATE_DISCOUNT_RATE,
      payload: discount,
    });

    dispatch({
      type: TOTAL_ORDER_EDIT,
    });
  }

    //GENERAR LA ORDEN EN FORMATO PDF
    const generateOrder = (details, order, filename) => {

      const data =  JSON.stringify(details)
      let url = `/generate-order/${order}?details=${data}`;
      Swal.fire({
        title: "Descargando...",
        html: "Esto puede demorar algunos segundos.",
        allowEscapeKey: false,
        allowOutsideClick:false,
        didOpen: () => {
          Swal.showLoading();
          MethodGet(url, { responseType: "blob" })
            .then((response) => {
              fileDownload(response.data, filename);
              Swal.close();
            })
            .catch((error) => {
              console.log(error);
              Swal.close();
            });
        },
      });
    };

     //Cancelar una orden
     const reorderItems = (data) => {
      let url = `/reorder-items?items=${data}`;
      MethodPut(url).then((response) => {
        dispatch({
          type: REORDER_ITEMS
        });
        Swal.fire({
          title: "Correcto!",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
    };

    //Funcion para agregar el numero de factura
    const setFacturaNumber = (data) => {
      let url = "/factura-order";
      Swal.fire({
        title: "Guardando...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          MethodPost(url, data)
            .then((response) => {
              dispatch({
                type: SET_INVOICE_NUMBER,
              });
              Swal.fire({
                title: "Correcto",
                text: response.data.message,
                icon: "success",
                timer: 5000,
                showConfirmButton: false,
              });
              Swal.close();
            })
            .catch((error) => {
                Swal.close();
              const errores = Object.keys(error.response.data.data).map(key => {
                return error.response.data.data[key];
              });
              Swal.fire({
                icon: 'error',
                title: error.response.data.message,
                text: errores.join('\n')
              });
            });
        },
      });
    };

    //Funcion para agregar fecha de entrega
    const setDeliveryDate = ({id, delivery_date}) => {
      let url = `/add-date-delivery/${id}`;
      Swal.fire({
        title: "Guardando...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          MethodPut(url, {'delivery_date': delivery_date})
          .then((response) => {
            dispatch({
              type: ADD_DATE_DELIVERY,
            });
            Swal.fire({
              title: "Correcto",
              text: response.data.message,
              icon: "success",
              timer: 3000,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.close();
            Swal.fire({
              title: "Algo salio mal :(",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: true,
            });
          });
        }
      });
    };

    //Funcion para actualizar la fecha de orden
    const updateOrderDate = ({id, new_date}) => {
      let url = `/update-order-date/${id}`;
      Swal.fire({
        title: "Actualizando...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          MethodPut(url, {'new_date': new_date})
          .then((response) => {
            dispatch({
              type: UPDATE_ORDER_DATE,
            });
            Swal.fire({
              title: "Correcto",
              text: response.data.message,
              icon: "success",
              timer: 3000,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.close();
            Swal.fire({
              title: "Algo salio mal :(",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: true,
            });
          });
        }
      });
    };

    //Funcion para agregar notas/comentarios a la orden
    const addNotesOrder = (data) => {
      let url = `/add-notes-order/${data.order_id}`;
      Swal.fire({
        title: "Actualizando...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          MethodPut(url,data)
          .then((response) => {
            dispatch({
              type: ADD_NOTES_TO_ORDER,
            });
            Swal.fire({
              title: "Correcto",
              text: response.data.message,
              icon: "success",
              timer: 3000,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.close();
            Swal.fire({
              title: "Algo salio mal :(",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: true,
            });
          });
        }
      });
    };

    //Funcion para agregar observaciones/comentarios a la orden
    const addObservationsOrder = (data) => {
      let url = `/add-observations-order/${data.order_id}`;
      Swal.fire({
        title: "Actualizando...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          MethodPut(url,data)
          .then((response) => {
            dispatch({
              type: ADD_OBSERVATIONS_TO_ORDER,
            });
            Swal.fire({
              title: "Correcto",
              text: response.data.message,
              icon: "success",
              timer: 3000,
              showConfirmButton: false,
            });
          })
          .catch((error) => {
            Swal.close();
            Swal.fire({
              title: "Algo salio mal :(",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: true,
            });
          });
        }
      });
    };

    // funcion para respaldar las ordenes de clientes
    const backupOrdersClient = (id, fechaInicial, fechaFinal) => {
      let url = `/backup/client/${id}?startDate=${fechaInicial}&endDate=${fechaFinal}`;
      Swal.fire({
        title: "Respaldando Informacíon...",
        html: "Esto puede demorar algunos segundos.",
        allowEscapeKey: false,
        allowOutsideClick:false,
        didOpen: () => {
          Swal.showLoading();
            MethodGet(url).then((response) => {
              dispatch({
                type: BACKUP_ORDERS_CLIENT,
                payload: response.data.datos,
              });
              Swal.close();
              Swal.fire({
                title: "Correcto!",
                text: response.data.message,
                icon: "success",
              });
            })
            .catch((error) => {
              console.log(error);
              Swal.close();
            });
        },
      });
    };

    // funcion para descargar las ordenes respadadas en un archivo zip
    const downloadBackupOrdersClient = (id, filename) => {
      let url = `/zip/client/${id}`;

      Swal.fire({
        title: "Descargando...",
        html: "Esto puede demorar algunos segundos.",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          MethodGet(url, { responseType: "blob" })
            .then((response) => {
              fileDownload(response.data, 'ordenes_' + filename + '.zip');
              Swal.close();
            })
            .catch((error) => {
              console.log(error);
              Swal.close();
            });
        },
      });
    };

  return (
    <OrdersContext.Provider
      value={{
        orders: state.orders,
        order: state.order,
        details: state.details,
        products: state.products,
        customers: state.customers,
        product: state.product,
        sizes: state.sizes,
        materialProduct: state.materialProduct,
        printing: state.printing,
        options: state.options,
        prices: state.prices,
        productsList: state.productsList,
        subtotal: state.subtotal,
        shipping_costs: state.shipping_costs,
        discount: state.discount,
        iva: state.iva,
        totalSale: state.totalSale,
        discount_rate: state.discount_rate,
        success: state.success,
        successOrder: state.successOrder,
        current_page: state.current_page,
        total: state.total,
        per_page: state.per_page,
        deletedItems: state.deletedItems,
        addedItems: state.addedItems,
        updatedItems: state.updatedItems,
        successEditing: state.successEditing,
        upCommission: state.upCommission,
        upDiscount: state.upDiscount,
        insufficient_material: state.insufficient_material,
        out_stock: state.out_stock,
        orders_backups: state.orders_backups,
        cantidad_pdfs: state.cantidad_pdfs,
        getOrders,
        addOrder,
        getProducts,
        getProduct,
        addProduct,
        calculateTotalSale,
        deleteProductSale,
        getCustomers,
        addCustomer,
        getOrder,
        downloadOrder,
        updateStatus,
        addPayment,
        getOrderEdit,
        removeProducts,
        addItemOrder,
        resetProduct,
        updateProduct,
        saveOrderEdition,
        sendEmailOrder,
        updateDiscountRate,
        updateCommission,
        updateDiscountRateEdit,
        generateOrder,
        uploadFiles,
        closeModalOutStock,
        cancelOrder,
        reorderItems,
        setFacturaNumber,
        backupOrdersClient,
        downloadBackupOrdersClient,
        setDeliveryDate,
        updateOrderDate,
        addNotesOrder,
        addObservationsOrder
      }}
    >
      {props.children}
    </OrdersContext.Provider>
  );
};

export default OrdersSatate;
