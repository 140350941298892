import React from 'react'
import $ from "jquery";

const ButtonMobileMenu = () => {

    const toggleSidebar = () => {
        $(".sidebar").toggleClass("open");
    };

  return (
    <>
      <button className="btn btn-primary btn-icon mobile_menu" 
      type="button"
      onClick={toggleSidebar}>
        <i className="zmdi zmdi-sort-amount-desc" />
      </button>
    </>
  );
}

export default ButtonMobileMenu