import { ADD_CUSTOMER, ADD_SHIPPING_ADDRESS, EDIT_CUSTOMER, EDIT_SHIPPING_ADDRESS, GET_CUSTOMERS, GET_ENTITIES, GET_MUNICIPALITIES, GET_OPTIONS_REFERENCES, REMOVE_CUSTOMER, REMOVE_SHIPPING_ADDRESS, SHOW_CUSTOMER, UPDATE_CUSTOMER, UPDATE_SHIPPING_ADDRESS } from '../../types';

export default function customersReducer(state, action) {
    switch (action.type) {
        case ADD_CUSTOMER:
            return{
                ...state,
                success: true,
            }

        case GET_CUSTOMERS:
            return{
                ...state,
                customers: action.payload,
                customer: {},
                address:  [],
                orders: [],
                success:false,
            }
        
        case EDIT_CUSTOMER:
            return {
                ...state,
                selectedCustomer: state.customers.find(customer => customer.id === action.payload),
            }
        
        case UPDATE_CUSTOMER:
            return{
                ...state,
                success: true,
                selectedCustomer: [],
            }

        case REMOVE_CUSTOMER:
            return {
                ...state,
                customers: state.customers.filter(
                (customer) => customer.id !== action.payload
                ),
            }
        
        case SHOW_CUSTOMER:
            return {
                ...state,
                success: false,
                customer: action.payload.data,
                address:  action.payload.data.address,
                orders: action.payload.data.orders
            }

        case ADD_SHIPPING_ADDRESS:
             return {
                 ...state,
                 success: true,
             }
    
        case EDIT_SHIPPING_ADDRESS: 
             return {
                ...state,
                selectedAddress: state.address.find(shippingAddress => shippingAddress.id === action.payload),
             }

        case UPDATE_SHIPPING_ADDRESS: 
             return {
                ...state,
                success: true,
                selectedAddress: [],
             }
        
        case REMOVE_SHIPPING_ADDRESS:
            return {
                ...state,
                address: state.address.filter(
                (shippingAddress) => shippingAddress.id !== action.payload
                ),
            }

        case GET_ENTITIES:
            return{
                ...state,
                entities: action.payload
            } 

        case GET_MUNICIPALITIES:
            return{
                ...state,
                municipalities: action.payload
            } 

        case GET_OPTIONS_REFERENCES:
            return{
                ...state,
                opcionesComoSeEnteraron: action.payload
            } 

        default:
            return state;
    }
}
