import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import Layout from "../../layouts/Layout";
import DesignContext from "../../contexts/design/designContext";
import FiltersDesing from "./FiltersDesing";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const OrdersList = () => {
  const {
    getOrders,
    orders,
    current_page,
    total,
    per_page,
    success
  } = useContext(DesignContext);

  const [filterOn, setfilterOn] = useState(false);
  const [data, setDataFilters] = useState(null);

  useEffect(() => {
    const PageNumber = 1;
    getOrders(PageNumber, data);
    // eslint-disable-next-line
  }, [success]);

  const handleChangePage = (PageNumber) => {
    getOrders(PageNumber, data);
  };

  const handleOpenFilters = () => setfilterOn(!filterOn);

  const onSubmit = async (e) => {
    e.preventDefault();
    const PageNumber = 1;
    if (data !== null) {
      getOrders(PageNumber, data);
    } else {
      return false;
    }

  }


  return (
    <Layout>
       <FiltersDesing
        filterOn={filterOn}
        handleOpenFilters={handleOpenFilters}
        data={data}
        setDataFilters={setDataFilters}
        onSubmit={onSubmit}
      />
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Ordenes</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Ordenes</li>
                  <li className="breadcrumb-item active">Lista</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
                <button
                    className="btn btn-info float-right"
                    onClick={handleOpenFilters}
                  >
                    <i className="zmdi zmdi-search" />
                  </button>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-md-12 col-sm-12 col-xs-12">
                <div className="card project_list">
                  <div className="table-responsive">
                    <table className="table table-hover c_table table-sm theme-color">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Emitida</th>
                          <th>Creada por</th>
                          <th>Cliente</th>
                          <th>Vencimiento</th>
                          <th>Orden</th>
                          <th>Status</th>
                          <th>Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!orders.length ? (
                          <tr>
                            <td align="center" colSpan={8}>
                              No se encuentran Ordenes Registradas
                            </td>
                          </tr>
                        ) : (
                          orders.map((order, index) => (
                            <tr key={index}>
                              <td>
                                <strong>{order.id}</strong>
                              </td>
                              <td>
                                <Moment format="DD/MM/YYYY">
                                  {order.created_at}
                                </Moment>
                              </td>
                              <td>
                                  {order.user ? order.user.name : '-'}
                              </td>
                              <td> {order.customer.full_name}</td>
                              <td>
                                <Moment format="DD/MM/YYYY">
                                  {order.expires}
                                </Moment>
                              </td>
                              <td>
                                  {order.type_of_sale === 1 ? (
                                    <div className="status local">
                                      {" "}
                                      <i className="zmdi zmdi-circle"></i> local{" "}
                                    </div>
                                  ) : order.type_of_sale === 2 ? (
                                    <div className="status ecommerce">
                                      {" "}
                                      <i className="zmdi zmdi-circle"></i>{" "}
                                      online{" "}
                                    </div>
                                  ) : null}
                                </td>
                              <td>
                                {order.status === 1 ? (
                                  <span className="badge badge-warning">
                                    Pendiente
                                  </span>
                                ) : order.status === 2 ? (
                                  <span className="badge badge-success">
                                    Aprobada
                                  </span>
                                ) : order.status === 3 ? (
                                  <span className="badge badge-primary">
                                    En Producción
                                  </span>
                                ) : order.status === 4 ? (
                                  <span className="badge badge-info">
                                    Pedido listo
                                  </span>
                                ) : order.status === 5 ? (
                                  <span className="badge badge-danger">
                                    Completada
                                  </span>
                                ) : null}
                              </td>
                              <td>
                                <Link to={`/order-desing/${order.id}`}>
                                  <button
                                    className="btn btn-default btn-sm"
                                  >
                                    <i className="zmdi zmdi-eye" />
                                  </button>
                                </Link>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                  {total ? (
                    <div className="card">
                      <div className="body">
                        <ul className="pagination pagination-primary justify-content-center">
                          <Pagination
                            activePage={current_page}
                            totalItemsCount={total}
                            itemsCountPerPage={per_page}
                            onChange={(pageNumber) =>
                              handleChangePage(pageNumber)
                            }
                            itemClass="page-item"
                            linkClass="page-link"
                          />
                        </ul>
                      </div>
                    </div>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default OrdersList;
