import { ADD_MATERIAL, EDIT_MATERIAL, GET_MATERIALS, GET_MEASUREMENTS_SELECT, REMOVE_MATERIAL, UPDATE_MATERIAL } from '../../types';

export default function materialsReducer(state, action) {
    switch (action.type) {
        case ADD_MATERIAL:
            return{
                ...state,
                success: true,
            }

        case GET_MATERIALS:
            return{
                ...state,
                selectedMaterial: [],
                materials: action.payload,
                success:false,
            }
        
        case EDIT_MATERIAL:
            return {
                ...state,
                selectedMaterial: action.payload,
            }
        
        case UPDATE_MATERIAL:
            return{
                ...state,
                success: true,
                selectedMaterial: [],
            }

        case REMOVE_MATERIAL:
            return {
                ...state,
                materials: state.materials.filter(
                (material) => material.id !== action.payload
                ),
            }

        case GET_MEASUREMENTS_SELECT:
            return{
                ...state,
                measurements: action.payload
            } 

        default:
            return state;
    }
}
