import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import DiscountsContext from "../../contexts/discounts/discountsContext";
import Select from "react-select";

const EditDiscount = ({ optionsProducts }) => {
  const { selectedDiscount, updateDiscount } = useContext(DiscountsContext);
  const { register, errors, handleSubmit, reset, control, setValue } =
    useForm();

  useEffect(() => {
    if (selectedDiscount.product) {
      setValue(
        "product",
        {
          value: selectedDiscount.product.id,
          label: selectedDiscount.product.name,
        },
        { shouldDirty: true }
      );
    }
    // eslint-disable-next-line
  }, [selectedDiscount]);

  const handleCloseModal = () => {
    window.$("#editDiscount").modal("hide");
  };

  const onSubmit = (data) => {
    data.id = selectedDiscount.id;
    data.product_id = data.product.value;
    updateDiscount(data);
    reset();
    handleCloseModal();
  };

  return (
    <>
      <div className="modal fade" id="editDiscount" tabIndex={-1} role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title" id="defaultModalLabel">
                Actualizar Descuento
              </h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-8">
                    <div className="form-group">
                      <label>Producto</label>
                      <Controller
                        name="product"
                        control={control}
                        placeholder="Selecciona una opción"
                        defaultValue
                        options={optionsProducts}
                        as={<Select />}
                        rules={{
                          required: "Selecciona un producto de la lista",
                        }}
                      />
                      {errors?.product ? (
                        <label className="error">
                          {errors?.product?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Descuento</label>
                      <div className="input-group masked-input">
                        <div className="input-group-prepend">
                          <span className="input-group-text">
                            <img
                              src="https://img.icons8.com/material-outlined/16/000000/percentage.png"
                              alt="porcent"
                            />
                          </span>
                        </div>
                        <input
                          type="text"
                          name="discount"
                          defaultValue={
                            selectedDiscount ? selectedDiscount.discount : ""
                          }
                          ref={register({
                            required: {
                              value: true,
                              message: "El descuento es requerido",
                            },
                            pattern: {
                              value: /^[0-9\b]+$/,
                              message: "El campo debe ser numerico",
                            },
                          })}
                          className={
                            errors.discount
                              ? "form-control is-invalid"
                              : "form-control "
                          }
                          placeholder="Descuento"
                        />
                      </div>

                      {errors?.discount ? (
                        <label className="error">
                          {errors?.discount?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Fecha inicio</label>
                      <input
                        name="start_date"
                        type="date"
                        defaultValue={
                          selectedDiscount ? selectedDiscount.start_date : ""
                        }
                        ref={register({
                          required: {
                            value: true,
                            message:
                              "La fecha de inicio del descuento es requerida",
                          },
                        })}
                        className={
                          errors.start_date
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.start_date ? (
                        <label className="error">
                          {errors?.start_date?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Fecha Expiración</label>
                      <input
                        name="end_date"
                        type="date"
                        defaultValue={
                          selectedDiscount ? selectedDiscount.end_date : ""
                        }
                        ref={register({
                          required: {
                            value: true,
                            message:
                              "La fecha de expiración del descuento es requerida",
                          },
                        })}
                        className={
                          errors.end_date
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.end_date ? (
                        <label className="error">
                          {errors?.end_date?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  ACTUALIZAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditDiscount;
