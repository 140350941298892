import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import OrdersContext from "../../contexts/orders/ordersContext";
import Pagination from "react-js-pagination";
import Moment from "react-moment";
import "moment/locale/es";
import Layout from "../../layouts/Layout";
import Swal from "sweetalert2";
import SearchFilter from "../../components/SearchFilter";
import ModalStock from "./ModalStock";
import { Fab, Action } from 'react-tiny-fab';
import 'react-tiny-fab/dist/styles.css';
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const OrdersList = () => {
  const {
    getOrders,
    orders,
    current_page,
    total,
    per_page,
    downloadOrder,
    updateStatus,
    addPayment,
    success,
    sendEmailOrder,
    cancelOrder,
    setFacturaNumber,
    setDeliveryDate,
    updateOrderDate,
    addObservationsOrder
  } = useContext(OrdersContext);

  const [filterOn, setfilterOn] = useState(false);
  const [data, setDataFilters] = useState(null);
  const [page, setPage] = useState(1);


  useEffect(() => {
    getOrders(page, data);
    // eslint-disable-next-line
  }, [success]);

  const handleChangePage = (PageNumber) => {
    setPage(PageNumber);
    getOrders(PageNumber, data);
  };

  const handleOpenFilters = () => setfilterOn(!filterOn);

  const onSubmit = (e) => {
    e.preventDefault();
    const PageNumber = 1;
    if (data !== null) {
      setPage(PageNumber);
      getOrders(PageNumber, data);
    } else {
      return false;
    }
  }


  const showConfirmDownload = (order) => {
    let filename;
    Swal.fire({
      title: "Descargar orden",
      input: "select",
      inputOptions: {
        1: "PDF",
        2: "EXCEL",
      },
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Descargar!",
      cancelButtonText: "No, cancelar!",
      inputPlaceholder: "Selecciona el tipo de archivo",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            if (value === "1") {
              filename = `orden${order.id}-${order.customer.full_name}.pdf`;
            } else if (value === "2") {
              filename = `orden${order.id}-${order.customer.full_name}.xlsx`;
            } else {
              filename = `orden${order.id}-${order.customer.full_name}.pdf`;
            }
            downloadOrder(order.id, filename, value);
            resolve();
          } else {
            resolve("Debes seleccionar una opción");
          }
        });
      },
    });
  };

  const handleChangeStatus = (order) => {
    Swal.fire({
      title: "Actualizar status de la orden",
      input: "select",
      inputOptions: {
        1: "PENDIENTE",
        2: "APROBADA",
        3: "EN PRODUCCIÓN",
        4: "ORDEN COMPLETADA",
        5: "ENTREGADA",
      },
      inputValue: Number(order.status + 1),
      inputAttributes: {
        disabled: true,
      },
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      inputPlaceholder: "Selecciona un status",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            updateStatus(order.id, value);
            resolve();
          } else {
            resolve("Debes seleccionar una opción");
          }
        });
      },
    });
  };

  const handleAddPayment = (order) => {
    let total_paid = order.payments.reduce(
      (sum, value) => sum + value.amount,
      0
    );
    let balance = parseFloat(order.total) - parseFloat(total_paid);

    Swal.fire({
      title: "Agregar pago",
      html: `
        <div class="row">
        <div class="col-12"> <p class="text-success"> Saldo restante  ${new Intl.NumberFormat(
          "es-MX",
          {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 2,
          }
        ).format(balance)} </p>  </div>
          <div class="col-4 input-group"> <input id="swal-input1" type="number" min="0.1" class="form-control" placeholder="monto $100"> </div>

          <div class="col-8 input-group">
          <select id="swal-input2" class="form-control">
          <option value="">Selecciona metodo de pago</option>
          <option value="Efectivo">EFECTIVO</option>
          <option value="Transferencia">TRANSFERENCIA</option>
            </select> </div>
        </div>

        `,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Guardar Pago",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      preConfirm: () => {
        let amount = document.getElementById("swal-input1").value;
        let payment_method = document.getElementById("swal-input2").value;

        if (amount !== "" && payment_method !== "") {
          if (Number(parseFloat(amount)) > balance.toFixed(2)) {
            Swal.showValidationMessage(
              "El monto no puede ser mayor al saldo restante."
            );
          } else {
            addPayment({
              order_id: order.id,
              amount: Number(amount),
              payment_method: payment_method,
            });
          }
        } else {
          Swal.showValidationMessage("Completa todos los campos"); // Show error when validation fails.
        }
      },
    });
  };

  const handleSendEmail = (order) => {
    Swal.fire({
      title: "Enviar por correo?",
      html: `Se enviara a ${order.customer.full_name} <br/> con correo: <b> ${order.customer.email}</b>`,
      icon: "question",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Enviar",
      cancelButtonText: "No, Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        sendEmailOrder(order.id);
      }
    });
  };

  const handleCancelOrder = (order) => {
    Swal.fire({
      title: "¿Se cancelará la orden?",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, cancelar!",
      cancelButtonText: "No",
    }).then((result) => {
      if (result.isConfirmed) {
        cancelOrder(order.id);
      }
    });
  };

  //agregar numero de factura
  const addFacturaNumber = (order) => {
    Swal.fire({
      title: `Orden: ${order.id}`,
      input: "text",
      inputValue: order.invoice_number,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      inputPlaceholder: "Número de Factura",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            setFacturaNumber({ order_id: order.id, invoice_number: value });
            resolve();
          } else {
            resolve("Ingresa el número de la Factura");
          }
        });
      },
    });
  };

  // agregar fecha de entrega
  const addDeliveryDate = (order) => {
    Swal.fire({
      title: 'Fecha de Entrega',
      text: `Orden: ${order.id}`,
      input: "datetime-local",
      inputValue: order.expires,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      inputPlaceholder: "Fecha de Entrega",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            setDeliveryDate({ id: order.id, delivery_date: value });
            resolve();
          } else {
              resolve("Ingresa la fecha de entrega");
          }
        });
      }
    });
  };

  // actualizar la fecha de la orden
  const addOrderDate = (order) => {
    Swal.fire({
      title: 'Fecha de Orden',
      text: `Orden: ${order.id}`,
      input: "datetime-local",
      inputValue: order.order_date,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      inputPlaceholder: "Fecha de orden",
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            updateOrderDate({ id: order.id, new_date: value });
            resolve();
          } else {
              resolve("Ingresa la nueva fecha");
          }
        });
      }
    });
  };


  const handleAddObservations = (order) => {
    Swal.fire({
      text: "Agregar Observaciones/Comentarios",
      input: "textarea",
      inputValue: order.notes,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Agregar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            addObservationsOrder({"order_id":order.id, "observations":value});
            resolve();
          } else {
            resolve("Agrega comentario / observaciones");
          }
        });
      },
    });
  };

  const handleShowObservations = (order) => {
    Swal.fire({
      text: order.observations
    });
  };

  return (
    <>
      <Layout>
        <SearchFilter
          filterOn={filterOn}
          handleOpenFilters={handleOpenFilters}
          data={data}
          setDataFilters={setDataFilters}
          onSubmit={onSubmit}
        />
        <section className="content">
          <div className="body_scroll">
            <div className="block-header">
              <div className="row">
                <div className="col-lg-7 col-md-6 col-sm-12">
                  <h2>Ordenes</h2>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <Link to="/">
                        <i className="zmdi zmdi-home" /> Home
                      </Link>
                    </li>
                    <li className="breadcrumb-item">Ordenes</li>
                    <li className="breadcrumb-item active">Lista</li>
                  </ul>
                  <ButtonMobileMenu/>
                  <Fab
                    icon={<i className="ti-more-alt" />}
                  >
                    <Link to="/order-add">
                        <Action
                            text="Nueva"
                          >
                                <i className="ti-plus" />
                        </Action>
                    </Link>

                    <Action
                      text="Buscar"
                      onClick={handleOpenFilters}
                    >
                       <i className="ti-search" />
                    </Action>
                  </Fab>
                </div>
                <div className="col-lg-5 col-md-6 col-sm-12">
                 <RightButtonToggle/>
                  <Link to="/order-add">
                    <button
                      className="btn btn-danger float-right"
                    >
                      <i className="zmdi zmdi-plus" /> Nueva
                    </button>
                  </Link>
                  <button
                    className="btn btn-info float-right"
                    onClick={handleOpenFilters}
                  >
                    <i className="zmdi zmdi-search" />
                  </button>
                </div>
              </div>
            </div>
            <div className="container-fluid">
              <div className="row clearfix">
                <div className="col-md-12 col-sm-12 col-xs-12">
                  <div className="card project_list">
                    <div
                      className="table-responsive"
                      id="table-responsive-cliente"
                    >
                      <table className="table table-hover table-sm c_table theme-color">
                        <thead>
                          <tr className="text-uppercase">
                            <th>#</th>
                            <th>Creada</th>
                            <th>Usuario</th>
                            <th>Cliente</th>
                            <th>Total</th>
                            <th>F. Entrega</th>
                            <th>Orden</th>
                            <th className="text-center">Factura</th>
                            <th>Estatus</th>
                            <th>Pago</th>
                            <th></th>
                            <th>Acciones</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!orders.length ? (
                            <tr>
                              <td align="center" colSpan={12}>
                                No se encuentran Ordenes Registradas
                              </td>
                            </tr>
                          ) : (
                            orders.map((order, index) => (
                              <tr key={index}>
                                <td>
                                  <Link
                                    to={`/order-detail/${order.id}`}
                                  >
                                    <strong>{order.id}</strong>
                                  </Link>
                                </td>
                                <td>
                                  <Moment format="DD/MM/YYYY hh:mm a">
                                    {order.order_date}
                                  </Moment>
                                </td>
                                <td>{order.user ? order.user.name : "-"}</td>
                                <td>
                                  <p>{order.customer.full_name}</p>
                                </td>
                                <td>
                                  {new Intl.NumberFormat("es-MX", {
                                    style: "currency",
                                    currency: "MXN",
                                    minimumFractionDigits: 2,
                                  }).format(order.total)}
                                </td>
                                <td>
                                  {
                                    order.expires ? (
                                      <Moment format="DD/MM/YYYY hh:mm a">
                                        {order.expires}
                                      </Moment>
                                      ) : (
                                        <button
                                        className="btn btn-neutral waves-effect waves-indigo"
                                        onClick={() => addDeliveryDate(order)}
                                        >
                                          ASIGNAR
                                        </button>
                                        )
                                  }
                                </td>
                                <td>
                                  {order.type_of_sale === 1 ? (
                                    <div className="status local">
                                      {" "}
                                      <i className="zmdi zmdi-circle"></i> local{" "}
                                    </div>
                                  ) : order.type_of_sale === 2 ? (
                                    <div className="status ecommerce">
                                      {" "}
                                      <i className="zmdi zmdi-circle"></i>{" "}
                                      online{" "}
                                    </div>
                                  ) : null}
                                </td>
                                <td className="text-center">
                                  {order.payment_status === 3 ? (
                                  order.invoice_number ? (
                                    order.invoice_number
                                  ) : (
                                    <button
                                      className="btn btn-neutral waves-effect waves-indigo"
                                      onClick={() => addFacturaNumber(order)}
                                    >
                                      FACTURA
                                    </button>
                                  )
                                ) : "--"}
                                </td>
                                <td>
                                  {order.status === 0 ? (
                                    <span className="badge badge-default">
                                      Rechazada
                                    </span>
                                  ) : order.status === 1 ? (
                                    <span className="badge badge-warning">
                                      Pendiente
                                    </span>
                                  ) : order.status === 2 ? (
                                    <span className="badge badge-success">
                                      Aprobada
                                    </span>
                                  ) : order.status === 3 ? (
                                    <span className="badge badge-primary">
                                      En Producción
                                    </span>
                                  ) : order.status === 4 ? (
                                    <span className="badge badge-info">
                                      Orden completada
                                    </span>
                                  ) : order.status === 5 ? (
                                    <span className="badge badge-danger">
                                      Entregada
                                    </span>
                                  ) : null}
                                </td>
                                <td>
                                  {order.status >= 2 ? (
                                    <>
                                      {order.payment_status === 1 ? (
                                        <span className="badge bg-deep-orange">
                                          Cuenta por cobrar
                                        </span>
                                      ) : order.payment_status === 2 ? (
                                        <span className="badge bg-amber">
                                          Pago Incompleto
                                        </span>
                                      ) : order.payment_status === 3 ? (
                                        <span className="badge bg-teal">
                                          Pagado
                                        </span>
                                      ) : null}
                                    </>
                                  ) : (
                                    <span className="badge bg-lime">
                                      No aplica
                                    </span>
                                  )}
                                </td>
                                <td>
                                {order.observations ?
                                  <span className="text-primary"
                                    onClick={() =>
                                      handleShowObservations(order)
                                    }
                                  >
                                    <i className="zmdi zmdi-comment-text" />
                                  </span>
                                  : null }
                                </td>
                                <td>
                                  <div className="btn-group">
                                    <button
                                      className="btn btn-outline-secondary btn-sm dropdown-toggle"
                                      type="button"
                                      data-toggle="dropdown"
                                      aria-haspopup="true"
                                      aria-expanded="false"
                                    >
                                      Acciones
                                    </button>
                                    <div className="dropdown-menu">
                                      <Link
                                        to={`/order-detail/${order.id}`}
                                        className="dropdown-item"
                                      >
                                        <i className="ti-eye" /> Ver detalles
                                      </Link>
                                      {order.status !== 0 ? (
                                        <>
                                          <button
                                            className="dropdown-item"
                                            onClick={() =>
                                              showConfirmDownload(order)
                                            }
                                          >
                                            <i className="ti-download" />{" "}
                                            Descargar
                                          </button>
                                          <button
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleSendEmail(order)
                                            }
                                          >
                                            <i className="ti-email" /> Enviar
                                          </button>
                                          {order.status !== 5 && (
                                            <>
                                              <Link
                                                to={`/order-edit/${order.id}`}
                                                className="dropdown-item"
                                              >
                                                <i className="ti-pencil" />{" "}
                                                Editar
                                              </Link>
                                              <button
                                                className="dropdown-item"
                                                onClick={() =>
                                                  handleChangeStatus(order)
                                                }
                                              >
                                                <i className="ti-reload" />{" "}
                                                Estatus
                                              </button>
                                            </>
                                          )}
                                          {order.payment_status !== 3 ? (
                                            <button
                                              className="dropdown-item"
                                              onClick={() =>
                                                handleAddPayment(order)
                                              }
                                            >
                                              <i className="ti-money" /> Agregar
                                              pago
                                            </button>
                                          ) : null}
                                            {order.payment_status === 3 ? (
                                            <button
                                              className="dropdown-item"
                                              onClick={() =>
                                                addFacturaNumber(order)
                                              }
                                            >
                                              <i className="ti-receipt" />  No. Factura
                                            </button>
                                              ) : null}
                                            {order.status !== 5 ? (
                                              <>
                                                <button
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    addDeliveryDate(order)
                                                  }
                                                >
                                                  <i className="ti-calendar" /> Fecha Entrega
                                                </button>
                                                <button
                                                  className="dropdown-item"
                                                  onClick={() =>
                                                    addOrderDate(order)
                                                  }
                                                >
                                                  <i className="ti-calendar" /> Fecha Orden
                                                </button>
                                              </>
                                            ) : null}
                                          {order.status === 1 ? (
                                            <button
                                              className="dropdown-item"
                                              onClick={() =>
                                                handleCancelOrder(order)
                                              }
                                            >
                                              <i className="ti-close" />{" "}
                                              Cancelar orden
                                            </button>
                                          ) : null}
                                          <button
                                            className="dropdown-item"
                                            onClick={() =>
                                              handleAddObservations(order)
                                            }
                                          >
                                            <i className="ti-comment" /> Comentario
                                          </button>
                                        </>
                                      ) : null}
                                    </div>
                                  </div>
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                    {total ? (
                      <div className="card">
                        <div className="body">
                          <ul className="pagination pagination-primary justify-content-center">
                            <Pagination
                              activePage={current_page}
                              totalItemsCount={total}
                              itemsCountPerPage={per_page}
                              onChange={(pageNumber) =>
                                handleChangePage(pageNumber)
                              }
                              itemClass="page-item"
                              linkClass="page-link"
                            />
                          </ul>
                        </div>
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <ModalStock />
      </Layout>
    </>
  );
};

export default OrdersList;
