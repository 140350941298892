import { ADD_PRINTING, EDIT_PRINTING, GET_PRINTINGS, REMOVE_PRINTING, UPDATE_PRINTING } from "../../types"

export default function printingsReducer(state, action) {
    switch (action.type) {
        case ADD_PRINTING:
            return{
                ...state,
                success: true,
            }

        case GET_PRINTINGS:
            return{
                ...state,
                printings: action.payload,
                success:false,
            }

        case EDIT_PRINTING:
            return {
                ...state,
                selectedPrinting: state.printings.find(printing => printing.id === action.payload),
            }

        case UPDATE_PRINTING:
            return{
                ...state,
                success: true,
                selectedPrinting: [],
            }

        case REMOVE_PRINTING:
            return {
                ...state,
                printings: state.printings.filter(
                (printing) => printing.id !== action.payload
                ),
            }

        default:
            return state;
    }
}
