import React, { useContext, useEffect } from "react";
import OrdersContext from "../../contexts/orders/ordersContext";
import FormProduct from "./add/FormProduct";
import OrderDetails from "./add/OrderDetails";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layouts/Layout";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";
import CustomersContext from "../../contexts/customers/customersContext";

const AddOrder = () => {

  const { getCustomers, success, successOrder } = useContext(OrdersContext);

  const { getComoSeEnteraron } = useContext(CustomersContext);

  const navigate = useNavigate();

  useEffect(() => {
    getCustomers();
    getComoSeEnteraron();
    // eslint-disable-next-line
  }, [success])

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de clientes
    if (successOrder) {
      navigate("/orders"); 
    }
    //eslint-disable-next-line
  }, [successOrder]);

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Orden Nueva</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                      <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item"><Link to="/orders"> Ordenes </Link></li>
                  <li className="breadcrumb-item active">Nueva</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
               <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12">
                <FormProduct />
              </div>

              <div className="col-lg-8 col-md-12">
                
                <OrderDetails />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AddOrder;
