import React, { useContext, useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { Link, useNavigate } from "react-router-dom";
import { Controller, useForm } from "react-hook-form";
import Select from "react-select";
import PurchasesContext from "../../contexts/purchases/purchasesContext";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";
import { v4 as uuidv4 } from "uuid";

const AddPurchase = () => {
  const { addPurchase, getProviders, getMaterials, materials, providers, success } = useContext(PurchasesContext);
  const { register, errors, handleSubmit, control, reset } = useForm();
  const [unidad_medida, setUnidadMedida] = useState("unidad");
  const [metros_cuadrados, setMetrosCuadrados] = useState(0);
  const [ancho, setAncho] = useState(0);
  const [largo, setLargo] = useState(0);
  const [qty, setQuantity] = useState(1);
  const [errorsForm, setErrorsForm] = useState({});

  const initialState = {
    order_date: '',
    provider_id: '',
    commentary: '',
    lista_material: [], // array de materiales agregados
    subtotal: 0, // subtotal inicial
    iva: 0, // iva inicial
    total: 0 // total inicial
  };

  const [state, setState] = useState(initialState);

  const validate = () => {
    const errores = {};
    if (!state.order_date) {
      errores.order_date = 'La fecha de compra es requerida';
    }

    if (!state.provider_id) {
      errores.provider_id = 'Selecciona un proveedor de la lista';
    }
    if (!state.lista_material.length) {
      errores.lista_material = 'Aun no has agregado materiales a la orden';
    }
    setErrorsForm(errores);
  };

  const navigate = useNavigate();
  useEffect(() => {
    getProviders();
    getMaterials();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (success) {
      navigate("/compras");
    }
    //eslint-disable-next-line
  }, [success]);


 useEffect(() => {
      let metros = ancho * largo || 0;
      let total_metros = metros * qty || 0;
      setMetrosCuadrados(Number(total_metros.toFixed(2)));
    //eslint-disable-next-line
  }, [ancho, largo, qty]);

  useEffect(() => {
      handleTotalesOrden();
     //eslint-disable-next-line
   }, [state.lista_material]);

  const optionsMeterials = materials.map((material) => {
    return { value: material.id, label: material.name };
  });

  const optionsProviders = providers.map((provider) => {
    return { value: provider.id, label: provider.nombre_comercial };
  });

   const handleChangeUnidad = (e) => {
    setUnidadMedida(e.target.value);
  };

  const handleTotalesOrden = () => {
    let subtotalOrden = state.lista_material.reduce((sum, value) => sum + value.importe,0);
    let ivaOrden = subtotalOrden * 0.16;
    let totalOrden = subtotalOrden + ivaOrden;
    setState({
        ...state,
         subtotal: Number(subtotalOrden.toFixed(2)),
         iva: Number(ivaOrden.toFixed(2)),
         total: Number(totalOrden.toFixed(2))
       });
  }

  const removeMaterial = (materialId) => {
    const materialList = state.lista_material.filter(
      (material) => material.id !== materialId
    );
    setState({
        ...state,
        lista_material: materialList,
    });
  }

  const handleSelectChange = (event) => {
   setState({ ...state, provider_id: event});
 };

  const handleStoreOrder = () => {
    validate();
    if (Object.keys(errors).length === 0) {
      addPurchase(state);
    }
  }

  const handlePush = (data) => {
    data.id = uuidv4();
    data.ancho = Number(ancho);
    data.largo = Number(largo);
    data.importe = Number(data.quantity * data.unit_price);
    setState({ ...state, lista_material: [...state.lista_material, data]});
    reset({});
    setAncho(0);
    setLargo(0);
    setMetrosCuadrados(0);
    setUnidadMedida('unidad');
    setQuantity(1);
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Agregar nueva compra </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/compras"> Compras </Link>
                  </li>
                  <li className="breadcrumb-item active">Nueva</li>
                </ul>
              <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
               <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
              <div className="row clearfix">
                <div className="col-lg-12">
                  <div className="card">
                    <div className="body">
                      <div className="header">
                        <h2>
                          <strong>Datos de la compra</strong>
                          <small>Completa la información necesaria.</small>
                        </h2>
                      </div>
                        <div className="row">
                        <div className="col-lg-4">
                            <label>Fecha de compra</label>
                            <div className="form-group">
                              <input
                                type="date"
                                name="order_date"
                                className="form-control"
                                onChange={(event) => setState({...state, order_date:event.target.value})}
                              />
                              {errorsForm.order_date && <div style={{ color: 'red' }}>{errorsForm.order_date}</div>}
                            </div>
                          </div>
                          <div className="col-lg-8">
                            <label>Proveedor</label>
                            <div className="form-group">
                              <Select className="react-select form-control p-0"
                                name="provider_id"
                                options={optionsProviders}
                                placeholder="Selecciona un proveedor"
                                value={state.provider_id}
                                onChange={(e) => handleSelectChange(e)}
                               />
                               {errorsForm.provider_id && <div style={{ color: 'red' }}>{errorsForm.provider_id}</div>}
                            </div>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
              </div>
              <form onSubmit={handleSubmit(handlePush)}>
              <div className="row clearfix">
                <div className="col-lg-5">
                  <div className="card">
                    <div className="body">
                      <div className="header">
                        <h2>
                          <strong>Lista de Materiales</strong>
                          <small>Agrega los materiales solicitados.</small>
                        </h2>
                      </div>
                        <div className="row">
                          <div className="col-lg-12">
                            <label>Material</label>
                            <div className="form-group">
                              <Controller
                                name="material_id"
                                control={control}
                                placeholder="Selecciona el material"
                                options={optionsMeterials}
                                defaultValue=""
                                as={
                                  <Select className="react-select form-control p-0" />
                                }
                                rules={{
                                  required: "Selecciona el material de la lista",
                                }}
                              />
                              {errors?.material_id ? (
                                <label className="error">
                                  {errors?.material_id?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <label>Unidad de compra</label>
                            <div className="form-group">
                              <select
                                name="opcion_unidad"
                                className="form-control"
                                onChange={(e) =>
                                  handleChangeUnidad(e)}
                                  ref={register({
                                    required: {
                                      value: true,
                                      message: "Selecciona la unidad de compra",
                                    }
                                  })}
                              >
                                <option value="unidad">Unidad (pza)</option>
                                <option value="metro2">Metro cuadrado (m2)</option>
                                <option value="rollo">Rollo</option>
                                <option value="placa">Placa</option>
                              </select>
                            </div>
                          </div>
                          {
                            unidad_medida === 'rollo' || unidad_medida === 'placa' ? (
                              <>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Ancho (m)</label>
                              <input
                                name="ancho"
                                type="text"
                                autoComplete="off"
                                value={ancho}
                                onChange={(e) =>
                                  setAncho(e.target.value)}
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "La cantidad es requerida",
                                  },
                                  min: {
                                    value: 0.1,
                                    message:
                                      "El campo cantidad debe ser mayor a 1",
                                  },
                                  pattern: {
                                    value: /^[0-9]+([.])?([0-9]+)?$/,
                                    message: "El campo debe ser numerico",
                                  },
                                })}
                                className={
                                  errors.ancho
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                              />
                              {errors?.ancho ? (
                                <label className="error">
                                  {errors?.ancho?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Largo (m)</label>
                              <input
                                name="largo"
                                type="text"
                                autoComplete="off"
                                value={largo}
                                onChange={(e) =>
                                  setLargo(e.target.value)}
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "La cantidad es requerida",
                                  },
                                  min: {
                                    value: 0.1,
                                    message:
                                      "El campo cantidad debe ser mayor a 1",
                                  },
                                  pattern: {
                                    value: /^[0-9]+([.])?([0-9]+)?$/,
                                    message: "El campo debe ser numerico",
                                  },
                                })}
                                className={
                                  errors.largo
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                              />
                              {errors?.largo ? (
                                <label className="error">
                                  {errors?.largo?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-4">
                            <div className="form-group">
                              <label>Metros (m2)</label>
                              <input
                                name="metros"
                                type="text"
                                readOnly
                                autoComplete="off"
                                value={metros_cuadrados}
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "La cantidad es requerida",
                                  },
                                  pattern: {
                                    value: /^[0-9]+([.])?([0-9]+)?$/,
                                    message: "El campo debe ser numerico",
                                  },
                                })}
                                className={
                                  errors.metros
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                              />
                              {errors?.metros ? (
                                <label className="error">
                                  {errors?.metros?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                              </>
                            ) : null
                          }

                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>Cantidad</label>
                              <input
                                name="quantity"
                                type="text"
                                autoComplete="off"
                                value={qty}
                                onChange={(e) =>
                                  setQuantity(e.target.value)}
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "La cantidad es requerida",
                                  },
                                  min: {
                                    value: 1,
                                    message:
                                      "El campo cantidad debe ser mayor a 1",
                                  },
                                  pattern: {
                                    value: /^[0-9]+([.])?([0-9]+)?$/,
                                    message: "El campo debe ser numerico",
                                  },
                                })}
                                className={
                                  errors.quantity
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                              />
                              {errors?.quantity ? (
                                <label className="error">
                                  {errors?.quantity?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-6">
                            <div className="form-group">
                              <label>Precio Unitario</label>
                              <input
                                name="unit_price"
                                type="text"
                                defaultValue=""
                                autoComplete="off"
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "El monto es requerido",
                                  },
                                  min: {
                                    value:  1,
                                    message: "El campo monto debe ser mayor a 1",
                                  },
                                  pattern: {
                                    value: /^[0-9]+([.])?([0-9]+)?$/,
                                    message: "El campo debe ser numerico",
                                  },
                                })}
                                className={
                                  errors.unit_price
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                              />
                              {errors?.unit_price ? (
                                <label className="error">
                                  {errors?.unit_price?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <label>Descripción</label>
                            <div className="form-group">
                              <textarea
                                name="description"
                                defaultValue=""
                                rows={2}
                                placeholder="Agrega aqui la descripción (opcional)"
                                ref={register({
                                  required: {
                                    value: false,
                                    message: "La descripción es requerida",
                                  },
                                })}
                                className={
                                  errors.description
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                              />
                              {errors?.description ? (
                                <label className="error">
                                  {errors?.description?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-lg-12">
                            <button type="submit" className="btn btn-warning btn-block">Agregar material</button>
                          </div>
                        </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-7">
                  <div className="card">
                    <div className="body">
                    {errorsForm.lista_material && <div style={{ color: 'red' }}>{errorsForm.lista_material}</div>}
                      <div className="table-responsive">
                        <table className="table table-hover c_table theme-color">
                          <thead>
                            <tr>
                              <th>Descripción</th>
                              <th>Cantidad</th>
                              <th className="hidden-sm-down">P.U.</th>
                              <th>Importe</th>
                              <th className="hidden-sm-down"></th>
                            </tr>
                          </thead>
                          <tbody>
                            {!state.lista_material.length ? (
                              <tr>
                                <td align="center" colSpan={6}>
                                  No hay materiales agregados a la orden
                                </td>
                              </tr>
                            ) : (
                              state.lista_material.map((material, index) => (
                                <tr key={index}>
                                  <td>
                                    <strong> {material.material_id.label} </strong>
                                    <br />
                                    {material.description ? (
                                      <small>{material.description}</small>
                                    ) : null}
                                  </td>

                                  <td className="text-center">{material.quantity}</td>
                                  <td className="hidden-sm-down">
                                    {new Intl.NumberFormat("es-MX", {
                                      style: "currency",
                                      currency: "MXN",
                                      minimumFractionDigits: 2,
                                    }).format(material.unit_price)}
                                  </td>
                                  <td>
                                    {new Intl.NumberFormat("es-MX", {
                                      style: "currency",
                                      currency: "MXN",
                                      minimumFractionDigits: 2,
                                    }).format(material.importe)}
                                  </td>
                                  <td className="hidden-sm-down">
                                    <button
                                      className="btn btn-danger btn-sm"
                                      onClick={() => removeMaterial(material.id)}
                                    >
                                      <i className="zmdi zmdi-delete"></i>
                                    </button>
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                      <div className="row">
                      <div className="col-md-7">
                        <label>Observaciones</label>
                        <div className="form-group">
                          <textarea
                            name="commentary"
                            defaultValue=""
                            rows={6}
                            placeholder="Agrega aqui una observación (opcional)"
                            className="form-control"
                            onChange={(event) => setState({...state, commentary:event.target.value})}
                          />
                        </div>
                      </div>
                           <div className="col-md-5 text-right">
                           <div className="pt-4 mb-3 wmin-md-400 ml-auto">
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <th className="text-left">Subtotal:</th>
                                <td className="text-right">
                                  {new Intl.NumberFormat("es-MX", {
                                    style: "currency",
                                    currency: "MXN",
                                    minimumFractionDigits: 2,
                                  }).format(state.subtotal)}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  IVA:{" "}
                                  <span className="font-weight-normal">(16%)</span>
                                </th>
                                <td className="text-right">
                                  {new Intl.NumberFormat("es-MX", {
                                    style: "currency",
                                    currency: "MXN",
                                    minimumFractionDigits: 2,
                                  }).format(state.iva)}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">Total:</th>
                                <td className="text-right text-primary">
                                  <h5 className="font-weight-semibold">
                                    {new Intl.NumberFormat("es-MX", {
                                      style: "currency",
                                      currency: "MXN",
                                      minimumFractionDigits: 2,
                                    }).format(state.total)}
                                  </h5>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                        <div className="text-right mt-3">
                          <button type="button"
                          onClick={() => handleStoreOrder()}
                          className="btn btn-primary btn-block">
                            Guardar Orden
                          </button>
                        </div>
                      </div>
                    </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AddPurchase;
