import { ADD_SLIDE, EDIT_SLIDE, GET_SLIDES, REMOVE_SLIDE, UPDATE_SLIDE } from "../../types";

export default function carouselReducer(state, action) {
  switch (action.type) {
    case ADD_SLIDE:
      return {
        ...state,
        success: true,
      };

    case GET_SLIDES:
      return {
        ...state,
        slides: action.payload.data,
        current_page: action.payload.current_page,
        per_page: action.payload.per_page,
        total: action.payload.total,
        success: false,
      };

    case EDIT_SLIDE:
      return {
        ...state,
        selectedSlide: state.slides.find(
          (slide) => slide.id === action.payload
        ),
      };

    case UPDATE_SLIDE:
      return {
        ...state,
        success: true,
        selectedSlide: [],
      };

    case REMOVE_SLIDE:
      return {
        ...state,
        slides: state.slides.filter((slide) => slide.id !== action.payload),
      };

    default:
      return state;
  }
}
