import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import Select2 from "react-select";
import ExpensesContext from "../../contexts/expenses/expensesContext";
import Layout from "../../layouts/Layout";
import { Link, useNavigate } from "react-router-dom";
import AddTipoGasto from "./AddTipoGasto";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const AddExpense = () => {
  const { addExpense, success, success2, tiposgastos, getTiposGastos } =
    useContext(ExpensesContext);
  const navigate = useNavigate();
  const { register, errors, handleSubmit, control, reset, setValue } =
    useForm();

  useEffect(() => {
    getTiposGastos();
    // eslint-disable-next-line
  }, [success2]);

  useEffect(() => {
    if (success) {
      navigate("/gastos");
    }
    //eslint-disable-next-line
  }, [success]);

  const optionsTiposGastos = tiposgastos.map((tipo) => {
    return { value: tipo.id, label: tipo.nombre };
  });

  const handleChangeTipo = (e) => {
    setValue("tipo_gasto_id", e, { shouldValidate: true });
  };

  const onSubmit = (data) => {
    data.tipo_gasto_id = data.tipo_gasto_id.value;
    addExpense(data);
    reset();
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Agregar Gastos</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/gastos"> Lista de gastos </Link>
                  </li>
                  <li className="breadcrumb-item active">Nueva</li>
                </ul>
                <ButtonMobileMenu />
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle />
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="header">
                      <h2>
                        <small>Completa la información necesaria.</small>
                      </h2>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-9">
                          <div className="form-group">
                            <label>Categoría</label>
                            <div className="input-group">
                              <Controller
                                name="tipo_gasto_id"
                                control={control}
                                defaultValue
                                render={({ field }) => (
                                  <div className="react-select form-control p-0">
                                    <Select2
                                      {...field}
                                      placeholder="Selecciona una categoría"
                                      options={optionsTiposGastos}
                                      onChange={(e) => handleChangeTipo(e)}
                                    />
                                  </div>
                                )}
                                rules={{
                                  required:
                                    "Selecciona un tipo de gasto para continuar",
                                }}
                              />
                              <div className="ml-2 input-group-append">
                                <span
                                  className="input-group-text"
                                  data-toggle="modal"
                                  data-target="#addTipoGasto"
                                >
                                  <i className="zmdi zmdi-plus" />
                                </span>
                              </div>
                            </div>
                            {errors?.tipo_gasto_id ? (
                              <label className="error">
                                {errors?.tipo_gasto_id?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <label>Fecha del gasto</label>
                          <div className="form-group">
                            <input
                              type="date"
                              name="fecha_gasto"
                              ref={register({
                                required: {
                                  value: true,
                                  message: "La fecha es obligatoria",
                                },
                              })}
                              className={
                                errors.fecha_gasto
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                            />
                            {errors?.fecha_gasto ? (
                              <label className="error">
                                {errors?.fecha_gasto?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>Comprobante</label>
                            <select
                              name="tipo_comprobante"
                              id="tipo_comprobante"
                              defaultValue=""
                              className={
                                errors.tipo_comprobante
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              ref={register({
                                required: "El tipo de comprobante es requerido",
                              })}
                            >
                              <option value="" disabled>
                                Selecciona un tipo
                              </option>
                              <option value="FACTURA">FACTURA</option>
                              <option value="NOTA DE REMISION">
                                NOTA DE REMISION
                              </option>
                              <option value="OTRO">OTRO</option>
                            </select>
                            {errors?.tipo_comprobante ? (
                              <label className="error">
                                {errors?.tipo_comprobante?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>Método de pago</label>
                            <select
                              name="tipo_pago"
                              id="tipo_pago"
                              defaultValue=""
                              className={
                                errors.tipo_pago
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                              ref={register({
                                required: "El tipo de pago es requerido.",
                              })}
                            >
                              <option value="" disabled>
                                Selecciona uno
                              </option>
                              <option value="EFECTIVO">EFECTIVO</option>
                              <option value="TRANSFERENCIA">
                                TRANSFERENCIA
                              </option>
                              <option value="TARJETA">TARJETA</option>
                              <option value="OTRO">OTRO</option>
                            </select>
                            {errors?.tipo_pago ? (
                              <label className="error">
                                {errors?.tipo_pago?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>Monto</label>
                            <input
                              name="amount"
                              ref={register({
                                required: {
                                  value: true,
                                  message: "El monto es requerido",
                                },
                                pattern: {
                                  value:
                                    /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                                  message:
                                    "El monto no puede ser un valor negativo",
                                },
                              })}
                              className={
                                errors.amount
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {errors?.amount ? (
                              <label className="error">
                                {errors?.amount?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>Folio fiscal</label>
                            <input
                              name="folio_fiscal"
                              ref={register({
                                required: {
                                  value: false,
                                  message: "El folio fiscal es requerido",
                                }
                              })}
                              className={
                                errors.folio_fiscal
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {errors?.folio_fiscal ? (
                              <label className="error">
                                {errors?.folio_fiscal?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Concepto</label>
                            <textarea
                              name="description"
                              rows={3}
                              ref={register({
                                required: {
                                  value: true,
                                  message: "El concepto del gasto es requerido",
                                },
                              })}
                              className={
                                errors.description
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                            />
                            {errors?.description ? (
                              <label className="error">
                                {errors?.description?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-danger btn-round waves-effect"
                          >
                            AGREGAR GASTO
                          </button>
                          <Link to="/gastos">
                            <button
                              type="button"
                              className="btn btn-secondary btn-round waves-effect"
                            >
                              CANCELAR
                            </button>
                          </Link>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddTipoGasto />
    </Layout>
  );
};

export default AddExpense;
