import { ADD_SIZE, EDIT_SIZE, GET_SIZES, REMOVE_SIZE, UPDATE_SIZE } from "../../types"

export default function sizesReducer(state, action) {
    switch (action.type) {
        case ADD_SIZE:
            return{
                ...state,
                success: true,
            }

        case GET_SIZES:
            return{
                ...state,
                sizes: action.payload,
                success:false,
            }

        case EDIT_SIZE:
            return {
                ...state,
                selectedSize: state.sizes.find(size => size.id === action.payload),
            }

        case UPDATE_SIZE:
            return{
                ...state,
                success: true,
                selectedSize: [],
            }

        case REMOVE_SIZE:
            return {
                ...state,
                sizes: state.sizes.filter(
                (size) => size.id !== action.payload
                ),
            }

        default:
            return state;
    }
}
