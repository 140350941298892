/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import OrdersContext from "../../contexts/orders/ordersContext";
import Layout from "../../layouts/Layout";
import Swal from "sweetalert2";
import { toast } from "react-toastify";
import EditItem from "./edit/EditItem";
import AddItem from "./edit/AddItem";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const OrderEdit = () => {

  const navigate = useNavigate();
  const { getOrderEdit, order, details, subtotal,
    shipping_costs,
    discount,
    iva,
    totalSale,
    discount_rate,
    getProducts,
    removeProducts,
    deletedItems,
    addedItems,
    updatedItems,
    saveOrderEdition,
    successEditing,
    updateDiscountRateEdit, 
    updateCommission,
    upDiscount,
    upCommission,
   } = useContext(OrdersContext);
  const { id } = useParams();

   //state de los producto seleccionados a eliminar
   const [selected, setSelected] = useState([]); 

   const [onedit, setOnEdit] = useState(false); 
   const [loading, setLoading] = useState(false);

   const [discounts, setDiscounts] = useState(false);
   const [commissions, setCommissions] = useState(false);
   const [offcanvasOn, setOffcanvasOn] = useState(false);
   const overClass = offcanvasOn ? "show" : "";

   useEffect(() => {
    //si success retorna verdadero la pagina redireccionara a la lista de ordenes
    if (successEditing) {
      navigate("/orders"); 
    }
    // eslint-disable-next-line
  }, [successEditing]);

     //funcion que selecciona el producto que se desea eliminar y lo agrega al array
  const handleClick = (event, product) => {
    const selectedIndex = selected.indexOf(product);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, product);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (product) => selected.indexOf(product) !== -1;

  const handleDeleteProducts = () => {
    if (selected.length) {
      removeProducts(selected); 
      setSelected([])
    } else {
      return false;
    }
  };

  const handleEditProduct = () => {
    if (selected.length) {
      if (selected.length > 1) {
        toast.info("Upss, Selecciona solo un elemento a editar a la vez", {
          theme: "colored",
        });
      } else {

        setOnEdit(true);

        setTimeout(() => {   
          window.$("#editModal").modal("show");
        }, 1000);
      }
    }
  };

  useEffect(() => {
    getOrderEdit(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, []);


  const handleOffcanvas = () => setOffcanvasOn(!offcanvasOn);


    //funcion para guardar y confirmar la edición de la venta
    const handleSaveSale = () => {
      Swal.fire({
        title: "Editar Orden",
        text: "Antes de confirmar, verifique que los cambios efectuados sean correctos",
        icon: "warning",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Confirmar",
        cancelButtonText: "Cancelar",
      }).then((result) => {
        if (result.isConfirmed) {
          if (!loading) {
            setLoading(true);
            saveOrderEdition();
            setTimeout(() => {
              setLoading(false);
            }, 3000);
          }
        }
      });
    };
  
    const handleShowDiscounts = () => {
      setDiscounts(!discounts);
      updateDiscountRateEdit(0)
    };
  
    const handleShowcommissions = () => {
      setCommissions(!commissions);
      updateCommission(0)
    };
    
    const handleChangeDiscount = (e) => {
      updateDiscountRateEdit(Number(e.target.value));
    }
  
    const handleChangeCommission = (e) => {
      updateCommission(Number(e.target.value))
    }

  return (
    <Layout>
      <div className={`bs-canvas-overlay bg-dark position-fixed w-100 h-100 ${overClass}`}></div>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Editar Orden</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/orders"> Ordenes </Link>
                  </li>
                  <li className="breadcrumb-item active">{`orden-${id}`}</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
               <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-md-12">
                <div className="d-flex">
                  <div className="mobile-left">
                    <a
                      className="btn btn-info btn-icon toggle-email-nav collapsed"
                      data-toggle="collapse"
                      href="#email-nav"
                      role="button"
                      aria-expanded="false"
                      aria-controls="email-nav"
                    >
                      <span className="btn-label">
                        <i className="zmdi zmdi-more" />
                      </span>
                    </a>
                  </div>
                  <div className="inbox left body" id="email-nav">
                    <div className="mail-side">
                      <h3 className="label text-primary">
                        DETALLES DE LA ORDEN
                      </h3>

                      <div className="about mb-2">
                        <div className="name">
                          {" "}
                          {order.customer ? order.customer.full_name : ""}
                        </div>
                        <div className="status offline">
                          {order.customer ? order.customer.email : ""} <br />
                          {order.customer ? order.customer.phone : ""}
                        </div>
                      </div>
                      <hr />
                      <ul className="nav">
                        <li className="d-flex justify-content-between">
                          <small>SUBTOTAL</small>
                          <span className="text-dark">
                            {new Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                              minimumFractionDigits: 2,
                            }).format(subtotal)}
                          </span>
                        </li>
                        {order && order.shipping_costs > 0 && (
                        <li className="d-flex justify-content-between">
                            <small>ENVÍO</small>
                          <span className="">
                            {new Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                              minimumFractionDigits: 2,
                            }).format(shipping_costs)}
                          </span>
                        </li>
                         )}
                        {order && order.discount > 0 && (
                          <li className="d-flex justify-content-between">
                            <small>DESCUENTO ( {new Intl.NumberFormat("es-MX", {
                                    style: "percent",
                                  }).format(discount_rate/100)})</small>
                            <span className="text-dark">
                              {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                                minimumFractionDigits: 2,
                              }).format(discount)}
                            </span>
                          </li>
                        )}
                        <li className="d-flex justify-content-between">
                          <small>IVA (16%)</small>
                          <span className="text-dark">
                            {new Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                              minimumFractionDigits: 2,
                            }).format(iva)}
                          </span>
                        </li>
                        <li className="d-flex justify-content-between">
                          <small>TOTAL</small>
                          <span className="text-dark">
                            {new Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                              minimumFractionDigits: 2,
                            }).format(totalSale)}
                          </span>
                        </li>
                      </ul>
                    </div>
                    <hr />
                    <div className="row">

                    <div className="col-md-12">
                    <div className="checkbox">
                      <input
                        id="checkbox1"
                        type="checkbox"
                        value={discounts}
                        checked={discounts ? true : false}
                        onChange={() => handleShowDiscounts()}
                      />
                      <label htmlFor="checkbox1" className="m-r-20">
                        <small>Descuentos</small>
                      </label>
                      <input
                        id="checkbox2"
                        type="checkbox"
                        value={commissions}
                        checked={commissions ? true : false}
                        onChange={() => handleShowcommissions()}
                      />
                      <label htmlFor="checkbox2"><small>Ayuntamientos</small></label>
                    </div>
                  </div>
                  {discounts && (
                    <div className="col-md-12">
                      <div className="form-group">
                      <div className="radio inlineblock m-r-20">
                          <input
                            type="radio"
                            name="discount"
                            id="discount-0"
                            className="with-gap"
                            value={0}
                            onClick={(e) => handleChangeDiscount(e)}
                          />
                          <label htmlFor="discount-0">sin descuento</label>
                        </div>
                        <div className="radio inlineblock m-r-20">
                          <input
                            type="radio"
                            name="discount"
                            id="discount-1"
                            className="with-gap"
                            value={5}
                            onClick={(e) => handleChangeDiscount(e)}
                          />
                          <label htmlFor="discount-1">5%</label>
                        </div>
                        <div className="radio inlineblock m-r-20">
                          <input
                            type="radio"
                            name="discount"
                            id="discount-2"
                            className="with-gap"
                            value={10}
                            onClick={(e) => handleChangeDiscount(e)}
                          />
                          <label htmlFor="discount-2">10%</label>
                        </div>
                        <div className="radio inlineblock m-r-20">
                          <input
                            type="radio"
                            name="discount"
                            id="discount-3"
                            className="with-gap"
                            value={15}
                            onClick={(e) => handleChangeDiscount(e)}
                          />
                          <label htmlFor="discount-3">15%</label>
                        </div>
                        <div className="radio inlineblock">
                          <input
                            type="radio"
                            name="discount"
                            id="discount-4"
                            className="with-gap"
                            value={20}
                            onClick={(e) => handleChangeDiscount(e)}
                          />
                          <label htmlFor="discount-4">20%</label>
                        </div>
                      </div>
                    </div>
                  )}

                  {commissions && (
                    <div className="col-md-12">
                      <div className="form-group">
                      <div className="radio inlineblock m-r-20">
                          <input
                            type="radio"
                            name="comission"
                            id="comission-0"
                            className="with-gap"
                            value={0}
                            onClick={(e) => handleChangeCommission(e)}
                          />
                          <label htmlFor="comission-0">sin descuento</label>
                        </div>
                        <div className="radio inlineblock m-r-20">
                          <input
                            type="radio"
                            name="comission"
                            id="comission-1"
                            className="with-gap"
                            value={5}
                            onClick={(e) => handleChangeCommission(e)}
                          />
                          <label htmlFor="comission-1">5%</label>
                        </div>
                        <div className="radio inlineblock m-r-20">
                          <input
                            type="radio"
                            name="comission"
                            id="comission-2"
                            className="with-gap"
                            value={10}
                            onClick={(e) => handleChangeCommission(e)}
                          />
                          <label htmlFor="comission-2">10%</label>
                        </div>
                        <div className="radio inlineblock m-r-20">
                          <input
                            type="radio"
                            name="comission"
                            id="comission-3"
                            className="with-gap"
                            value={15}
                            onClick={(e) => handleChangeCommission(e)}
                          />
                          <label htmlFor="comission-3">15%</label>
                        </div>
                      </div>
                    </div>
                  )}
                    </div>
                    
                    <div className="mail-compose mb-4">
                      {deletedItems.length > 0 ||
                      addedItems.length > 0 ||
                      updatedItems.length > 0  || upDiscount === true || upCommission === true ? (
                        <button
                          className="btn btn-danger btn-block"
                          onClick={handleSaveSale}
                        >
                          {loading ? (
                            <i className="zmdi zmdi-spinner" />
                          ) : (
                            <i className="zmdi zmdi-save" />
                          )}
                          {loading ? " Guardando ...." : " Guardar Edición"}
                        </button>
                      ) : null}
                    </div>
                  </div>
                  <div className="inbox right">
                    <div className="i_action d-flex justify-content-between align-items-center">
                      <div>
                        <div className="btn-group">
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => handleEditProduct()}
                          >
                            <i className="zmdi zmdi-edit" />
                          </button>
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            onClick={() => handleDeleteProducts()}
                          >
                            <i className="zmdi zmdi-delete" />
                          </button>
                        </div>
                        <div className="btn-group">
                          <button
                            className="btn btn-outline-secondary btn-sm"
                            onClick={handleOffcanvas}
                          >
                            <i className="zmdi zmdi-plus" /> {""}
                            Productos
                          </button>
                        </div>
                      </div>
                      <div className="pagination-email">
                        {/* <span>1-50/295</span>
                        <div className="btn-group ml-3">
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm"
                          >
                            <i className="zmdi zmdi-chevron-left" />
                          </button>
                          <button
                            type="button"
                            className="btn btn-outline-secondary btn-sm"
                          >
                            <i className="zmdi zmdi-chevron-right" />
                          </button>
                        </div> */}
                      </div>
                    </div>
                    <div className="table-responsive">
                      <table className="table c_table">
                        <thead>
                          <tr>
                            <th className="w50"></th>
                            <th className="hidden-sm-down">Descripción</th>
                            <th className="text-center">Cantidad</th>
                            <th className="hidden-sm-down text-center">Costo Unit</th>
                            <th className="text-center">Total</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!details.length ? (
                            <tr>
                              <td align="center" colSpan={6}>
                                No hay productos agregados a la orden
                              </td>
                            </tr>
                          ) : (
                            details.map((detail, index) => {
                              const isItemSelected = isSelected(detail);
                              return (
                                <tr
                                  key={index}
                                  className={
                                    isItemSelected ? "table-info" : null
                                  }
                                >
                                  <td className="chb">
                                    <div className="checkbox simple">
                                      <input
                                        id={`mc${detail.id}`}
                                        type="checkbox"
                                        onChange={(event) =>
                                          handleClick(event, detail)
                                        }
                                        checked={isItemSelected}
                                      />
                                      <label htmlFor={`mc${detail.id}`} />
                                    </div>
                                  </td>
                                  <td className="hidden-sm-down">
                                      <strong> {detail.product.name} </strong>
                                      <br />
                                      {detail.product &&
                                      detail.product.product_type !==
                                        "service" ? (
                                        <>
                                          {detail.product &&
                                          detail.product.product_type ===
                                            "byarea" ? (
                                            <small>
                                              Medidas: {detail.base} x{" "}
                                              {detail.altura} m
                                            </small>
                                          ) : (
                                            detail.product.status === 1 && (
                                              <small>
                                                Tamaño: {detail.options.size.name}
                                              </small>
                                            )
                                          )}
                                          {order.type_of_sale === 2 && (
                                          <>
                                            <br />
                                            <small>
                                              Material:{" "}
                                              {detail.options.material.name}
                                            </small>
                                            <br />
                                            <small>
                                              Impresión:{" "}
                                              {detail.options.printing.name}
                                            </small>
                                            <br />
                                            <small>
                                              Acabado:{" "}
                                              {detail.options.option.name}
                                            </small>
                                          </>
                                          )}
                                        </>
                                      ) : detail.options.measure.name ===
                                        "m2" ? (
                                        <>
                                          <small>
                                            precio por:{" "}
                                            {detail.options.measure.name}
                                          </small>
                                          <br />
                                          <small>
                                            Medidas: {detail.options.size.base}{" "}
                                            x {detail.options.size.altura} m
                                          </small>{" "}
                                        </>
                                      ) : (
                                        <small>
                                          precio por:{" "}
                                          {detail.options.measure.name}
                                        </small>
                                      )}

                                      <br />
                                      <small>{detail.description}</small>
                                    </td>
                                  <td className="text-center">{detail.quantity}</td>
                                  <td className="hidden-sm-down text-center">
                                    {new Intl.NumberFormat("es-MX", {
                                      style: "currency",
                                      currency: "MXN",
                                      minimumFractionDigits: 2,
                                    }).format(detail.unit_price)}
                                  </td>
                                  <td className="text-center">
                                    {new Intl.NumberFormat("es-MX", {
                                      style: "currency",
                                      currency: "MXN",
                                      minimumFractionDigits: 2,
                                    }).format(detail.amount)}
                                  </td>
                                </tr>
                              );
                            })
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {onedit ? (
        <EditItem
          selected={selected[0]}
          setSelected={setSelected}
          onedit={onedit}
          setOnEdit={setOnEdit}
        />
      ) : null}

      {!onedit ? <AddItem offcanvasOn={offcanvasOn}
        handleOffcanvas={handleOffcanvas}
         /> : null}
    </Layout>
  );
};

export default OrderEdit;
