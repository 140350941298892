import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import ProvidersContext from "../../../contexts/providers/providersContext";
import Select from "react-select";

const ModalAddContact = ({ id }) => {
  const { entities, municipalities, getMunicipalities, addProviderContact } =
    useContext(ProvidersContext);

  const { register, errors, handleSubmit, reset, control, setValue } =
    useForm();

  //funcion que se encarga de listar los estados en el select
  const optionsEntities = entities.map((entity) => {
    return { value: entity.id, label: entity.name };
  });

  //funcion que se encarga de listar los municipios en el select
  const optionsMunicipalities = municipalities.map((municipality) => {
    return { value: municipality.id, label: municipality.name };
  });

  //funcion que cambia los municipios del select
  const handleChangeEntity = (value) => {
    setValue("estado", value, { shouldValidate: true });
    setValue("municipio_id", "");
    getMunicipalities(value.value);
  };

  const handleCloseModal = () => {
    window.$("#addContact").modal("hide");
  };

  const onSubmit = (data) => {
    addProviderContact(id, data);
    reset();
    handleCloseModal();
  };

  return (
    <>
      <div className="modal fade" id="addContact" tabIndex={-1} role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header bg-cyan">
              <h4 className="title">Contacto Nuevo</h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Nombre del Contacto *</label>
                      <input
                        name="nombre_contacto"
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: true,
                            message:
                              "El campo Nombre del contacto es requerido",
                          },
                        })}
                        className={
                          errors.nombre_contacto
                            ? "form-control is-invalid"
                            : "form-control"
                        }
                        placeholder="Nombre del contacto "
                      />
                      {errors?.nombre_contacto ? (
                        <label className="error">
                          {errors?.nombre_contacto?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label>Teléfono *</label>
                      <input
                        name="telefono"
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: true,
                            message: "El campo Teléfono es requerido",
                          },
                        })}
                        className={
                          errors.telefono
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Teléfono "
                      />
                      {errors?.telefono ? (
                        <label className="error">
                          {errors?.telefono?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-7">
                    <div className="form-group">
                      <label>Correo *</label>
                      <input
                        name="correo"
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: true,
                            message: "El campo Correo es requerido",
                          },
                        })}
                        className={
                          errors.correo
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Correo"
                      />
                      {errors?.correo ? (
                        <label className="error">
                          {errors?.correo?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>WhatsApp</label>
                      <input
                        name="whatsapp"
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: false,
                            message: "El campo WhatsApp es requerido",
                          },
                        })}
                        className={
                          errors.whatsapp
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="WhatsApp "
                      />
                      {errors?.whatsapp ? (
                        <label className="error">
                          {errors?.whatsapp?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label>Teléfono 2</label>
                      <input
                        name="telefono2"
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: false,
                            message: "El campo Teléfono 2 es requerido",
                          },
                        })}
                        className={
                          errors.telefono2
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Teléfono 2 "
                      />
                      {errors?.telefono2 ? (
                        <label className="error">
                          {errors?.telefono2?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-5">
                    <div className="form-group">
                      <label>Correo 2</label>
                      <input
                        name="correo2"
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: false,
                            message: "El campo Correo 2 es requerido",
                          },
                        })}
                        className={
                          errors.correo2
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Teléfono 2 "
                      />
                      {errors?.correo2 ? (
                        <label className="error">
                          {errors?.correo2?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-9">
                    <div className="form-group">
                      <label>Dirección *</label>
                      <input
                        name="direccion"
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: true,
                            message: "El campo Dirección es requerido",
                          },
                        })}
                        className={
                          errors.direccion
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Dirección "
                      />
                      {errors?.direccion ? (
                        <label className="error">
                          {errors?.direccion?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-3">
                    <div className="form-group">
                      <label>C. Postal *</label>
                      <input
                        name="codigo_postal"
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: true,
                            message: "El campo Código Postal es requerido",
                          },
                        })}
                        className={
                          errors.codigo_postal
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="CP "
                      />
                      {errors?.codigo_postal ? (
                        <label className="error">
                          {errors?.codigo_postal?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Estado *</label>
                      <Controller
                        name="estado"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Selecciona una opción"
                            options={optionsEntities}
                            onChange={(e) => handleChangeEntity(e)}
                          />
                        )}
                        rules={{
                          required: "El Estado es un campo requerido ",
                        }}
                      />
                      {errors?.estado ? (
                        <label className="error">
                          {errors?.estado?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Municipio *</label>
                      <Controller
                        name="municipio_id"
                        control={control}
                        defaultValue=""
                        placeholder="Selecciona una opción"
                        options={optionsMunicipalities}
                        as={<Select />}
                        rules={{
                          required: "El Municipio es un campo Requerido",
                        }}
                      />
                      {errors?.municipio_id ? (
                        <label className="error">
                          {errors?.municipio_id?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  GUARDAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAddContact;
