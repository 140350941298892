/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext } from "react";
import { Link } from "react-router-dom";
import LogoPBK from "../assets/images/logo.png";
import Avatar from "../assets/images/avatar.jpg";
import $ from "jquery";
import AuthContext from "../contexts/auth/authContext";
import SidebarAdmin from "./SidebarAdmin";
import SidebarSales from "./SidebarSales";
import SidebarProduction from "./SidebarProduction";
import SidebarDesing from "./SidebarDesing";
const Leftsidebar = () => {
  const { user } = useContext(AuthContext);

  const handleLsToggleBtnClick = () => {
    $("body").toggleClass("ls-toggle-menu");
  };

 
  let component;

  if (user) {
    switch (user.user_role) {
      case 1:
        component = <SidebarAdmin user={user} />;
        break;
      case 2:
        component = <SidebarSales user={user} />;
        break;
      case 3:
        component = <SidebarProduction user={user} />;
        break;
      case 4:
        component = <SidebarDesing user={user} />;
        break;
      default:
        break;
    }
  }
  return (
    <>
      {/* Left Sidebar */}
      <aside id="leftsidebar" className="sidebar">
        <div className="navbar-brand">
          <button
            className="btn-menu ls-toggle-btn"
            type="button"
            onClick={handleLsToggleBtnClick}
          >
            <i className="zmdi zmdi-menu" />
          </button>
          <Link to="/">
            <img
              src={LogoPBK}
              width={120}
              alt="Pbk Impresos"
              className="mx-auto d-block"
            />

            {/* <span className="m-l-10">Aero</span> */}
          </Link>
        </div>
        <div className="menu">
          <ul className="list">
            <li>
              <div className="user-info">
                <Link className="image" to="/">
                  <img
                    src={
                      user && user.profile_picture
                        ? user.profile_picture
                        : Avatar
                    }
                    alt="User"
                  />
                </Link>
                <div className="detail">
                  <h4>{user && user.name ? user.name : null}</h4>
                  <small>
                    {user && user.user_role === 1
                      ? "Administrador"
                      : user && user.user_role === 2
                      ? "Vendedor"
                      : user && user.user_role === 3
                      ? "Poducción"
                      : user && user.user_role === 4
                      ? "Diseño"
                      : null}
                  </small>
                </div>
              </div>
            </li>
            {component}
          </ul>
        </div>
      </aside>
    </>
  );
};

export default Leftsidebar;
