/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import UsersContext from "../../contexts/users/usersContext";
import AddUser from "./AddUser";
import EditUser from "./EditUser";
import Swal from "sweetalert2";
import Avatar from '../../assets/images/avatar.jpg'
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";
import DataTable from "react-data-table-component";

const UsersList = () => {
  const listUsers = useContext(UsersContext);
  const {
    users,
    getUsers,
    success,
    editUser,
    removeUser,
  } = listUsers;

  useEffect(() => {
    getUsers();
    // eslint-disable-next-line
  }, [success]);

  const [filterText, setFilterText] = useState("");
  const filteredItems = users.filter(
    (item) =>
      item.name.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        backgroundColor: "#5cc5cd",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    }
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      width: "80px",
    },
    {
      name: "",
      cell: (row) =>
        row.profile_picture ? (
          <img
            src={row.profile_picture}
            className="rounded avatar"
            alt="image_profile"
          />
        ) : (
          <img src={Avatar} className="rounded avatar" alt="image_profile" />
        ),
      width: "80px",
    },
    {
      name: "Nombre",
      selector: (row) => (
        <strong className="single-user-name">{row.name}</strong>
      ),
      sortable: true,
      wrap:true,
      width: "250px",
    },
    {
      name: "Teléfono",
      selector: (row) => (row.phone ? row.phone : "No disponible"),
      sortable: true,
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
        wrap:true,
      width: "300px",
    },
    {
      name: "	Rol",
      selector: (row) =>
        row.user_role === 1 ? (
          <span className="badge bg-light-green">Administrador</span>
        ) : row.user_role === 2 ? (
          <span className="badge bg-red">Vendedor</span>
        ) : row.user_role === 3 ? (
          <span className="badge bg-amber">Poducción</span>
        ) : row.user_role === 4 ? (
          <span className="badge bg-indigo">Diseño</span>
        ) : null,
      sortable: true,
      sortFunction: (a, b) => a.activo - b.activo,
      width: "120px",
    },
    {
      name: "Acciones",
      selector: (row) => row.id,
      sortable: false,
      cell: (row) =>
        row.id !== 1 && (
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary btn-sm"
              data-toggle="modal"
              data-target="#editUser"
              onClick={() => editUser(row.id)}
            >
              <i className="zmdi zmdi-edit"></i>
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => showConfirmMessage(row.id)}
            >
              <i className="zmdi zmdi-delete"></i>
            </button>
          </div>
        ),
      width: "150px",
    },
  ];

  const showConfirmMessage = (User) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Un Usuario eliminado no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeUser(User);
      }
    });
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Usuarios </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Usuarios</li>
                  <li className="breadcrumb-item active">lista</li>
                </ul>
                <ButtonMobileMenu/>
                <div className="chat_window">
                  <button
                    className="list_btn btn btn-danger btn-round float-md-right"
                    data-toggle="modal"
                    data-target="#addUser"
                  >
                    <i className="ti-plus" />
                  </button>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
                <button
                  className="btn btn-danger float-right"
                  data-toggle="modal"
                  data-target="#addUser"
                >
                  <i className="zmdi zmdi-plus" /> Nuevo
                </button>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="card project_list">
              <div className="body">
                <div className="input-box">
                    <i className="ti-search" />
                    <input
                      type="text"
                      placeholder="Buscar aqui..."
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <button className="button" onClick={handleClear}>
                      X
                    </button>
                  </div>
                  <div className="table">
                    <DataTable
                      customStyles={tableCustomStyles}
                      columns={columns}
                      data={filteredItems}
                      noDataComponent="No se encontraron registros"
                      pagination
                    />
                  </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddUser />
      <EditUser />
    </Layout>
  );
};

export default UsersList;
