/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import InventoriesContext from "../../contexts/inventories/inventoriesContext";
import Layout from "../../layouts/Layout";
import EditInventory from "./EditInventory";
import AdjustStock from "./AdjustStock";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";
import DataTable from "react-data-table-component";

const InventoriesList = () => {
  const listInventories = useContext(InventoriesContext);
  const {
    inventories,
    getInventories,
    success,
    editInventory,
  } = listInventories;

  useEffect(() => {
    getInventories();
    // eslint-disable-next-line
  }, [success]);

  const [filterText, setFilterText] = useState("");
  const filteredItems = inventories.filter(
    (item) =>
      item.material?.name
        .toLowerCase()
        .includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        backgroundColor: "#5cc5cd",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
  };

  const columns = [

    {
      name: "Material",
      selector: (row) => row.material?.name,
      sortable: true,
      wrap: true,
      width: "300px",
    },
    {
      name: "Stock",
      selector: (row) =>
        <>
          {new Intl.NumberFormat("es-MX", {
            notation: "compact",
            maximumFractionDigits: 2,
          }).format(row.stock)}{" "}
          {row.material?.sale_unit?.acronym}{" "}
        </>,
      sortable: true,
      sortFunction: (a, b) => a.stock - b.stock,
    },
    {
      selector: (row) =>
      <div className="w150 progress">
        <div
          className={
            parseFloat(row.stock) >=
            parseFloat(row.optimal_quantity)
              ? "progress-bar bg-green"
              : parseFloat(row.stock) <
                  parseFloat(
                    row.optimal_quantity
                  ) &&
                parseFloat(row.stock) >
                  parseFloat(row.min_quantity)
              ? "progress-bar bg-light-green"
              : parseFloat(
                  row.stock
                ) <=
                  parseFloat(
                    row.min_quantity
                  ) &&
                parseFloat(
                  row.stock
                ) >= parseFloat(1)
              ? "progress-bar bg-amber"
              : parseFloat(row.stock) <
                parseFloat(1)
              ? "progress-bar bg-red"
              : null
          }
          role="progressbar"
          style={{
            width:
              (row.stock * 100) /
                row.optimal_quantity +
              "%",
          }}
        />
      </div>,
      sortable: true,
      width: "200px",
    },
    {
      name: "Estatus",
      selector: (row) =>
        <>
          {parseFloat(row.stock) >=
          parseFloat(row.optimal_quantity) ? (
            <span className="badge bg-green">
              Completa
            </span>
          ) : parseFloat(row.stock) <
              parseFloat(row.optimal_quantity) &&
            parseFloat(row.stock) >
              parseFloat(row.min_quantity) ? (
            <span className="badge bg-light-green">
              Disponible
            </span>
          ) : parseFloat(row.stock) <=
              parseFloat(row.min_quantity) &&
            parseFloat(row.stock) >=
              parseFloat(1) ? (
            <span className="badge bg-amber">
              Por agotarse
            </span>
          ) : parseFloat(row.stock) <
            parseFloat(1) ? (
            <span className="badge bg-red">Agotada</span>
          ) : null}
        </>,
      sortable: true,
      width: "120px",
    },

    {
      name: "Acciones",
      selector: (row) => row.id,
      sortable: false,
      cell: (row) => (
        <div className="d-flex justify-content-center">
        <button
          className="btn btn-primary btn-sm"
          data-toggle="modal"
          data-target="#editInventory"
          onClick={() => editInventory(row.id)}
        >
          <i className="zmdi zmdi-edit"></i>
        </button>
        <button
          className="btn bg-orange btn-sm"
          data-toggle="modal"
          data-target="#ajustarStock"
          onClick={() => editInventory(row.id)}
        >
          <i className="zmdi zmdi-wrench"></i>
        </button>
        {/* ejecutar funcion al mismo tiempo de dar click al Link */}
        <Link
          to={`/inventory-history/${row.id}`}
          className="btn btn-info btn-sm"
        >
          <i className="zmdi zmdi-time-restore"></i>
        </Link>
        </div>
      ),
      width: "200px",
    },
  ];


  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Inventario </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Inventario</li>
                  <li className="breadcrumb-item active">lista</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
               <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                <div className="body">
                  <div className="input-box">
                    <i className="ti-search" />
                    <input
                      type="text"
                      placeholder="Buscar aqui..."
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <button className="button" onClick={handleClear}>
                      X
                    </button>
                  </div>
                  <DataTable
                    customStyles={tableCustomStyles}
                    columns={columns}
                    data={filteredItems}
                    noDataComponent="No se encontraron registros"
                    pagination
                  />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <EditInventory />
      <AdjustStock />
    </Layout>
  );
};

export default InventoriesList;
