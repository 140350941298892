import React, { useContext } from "react";
import AuthContext from "../contexts/auth/authContext";
import OrdersList from "../pages/Production/OrdersList";
import OrdersDesing from "../pages/Desing/OrdersList";
import Dashboard from "./Dashboard";

const Home = () => {
  const { user } = useContext(AuthContext);
  let component;

  if (user) {
    switch (user.user_role) {
      case 1:
        component = <Dashboard />;
        break;
      case 2:
        component = <Dashboard />;
        break;
      case 3:
        component = <OrdersList />;
        break;
      case 4:
        component = <OrdersDesing />;
        break;
      default:
        break;
    }
  }

  return <> {component} </>;
};

export default Home;
