import React from 'react'

const NotFoundPage = () => {
  return (
<>
<section className="content">
        <div className="body_scroll">
      <div className="row">
        <div className="col-lg-12 col-sm-12">
          <div className="card">
            <img src="assets/images/404.svg" alt={404} />
          </div>
        </div>
      </div>
    </div>
  </section>
</>

  )
}

export default NotFoundPage