import {
  ADD_INVENTORY,
  ADJUST_INVENTORY,
  EDIT_INVENTORY,
  GET_HISTORICAL_STOCK,
  GET_INVENTORIES,
  GET_MATERIALS_SELECT,
  GET_MEASUREMENTS_SELECT,
  UPDATE_INVENTORY,
  UPDATE_STOCK,
} from "../../types";

export default function inventoriesReducer(state, action) {
  switch (action.type) {
    case GET_INVENTORIES:
      return {
        ...state,
        inventories: action.payload,
        success: false,
        selectedInventory: [],
      };

      case ADD_INVENTORY:
        return{
            ...state,
            success: true,
        }


    case EDIT_INVENTORY:
      return {
        ...state,
        selectedInventory: state.inventories.find(
          (inventory) => inventory.id === action.payload
        ),
      };

    case UPDATE_INVENTORY:
      return {
        ...state,
        success: true,
        selectedInventory: [],
      };

    case UPDATE_STOCK:
      return {
        ...state,
        success: true,
        selectedInventory: [],
      };

    case ADJUST_INVENTORY:
      return {
        ...state,
        success: true,
        selectedInventory: [],
      };

    case GET_HISTORICAL_STOCK:
      return {
        ...state,
        selectedInventory: action.payload.inventory,
        historical: action.payload.data,
      };

    case GET_MEASUREMENTS_SELECT:
      return {
        ...state,
        measurements: action.payload,
      };

    case GET_MATERIALS_SELECT:
      return{
          ...state,
          materials: action.payload
      }

    default:
      return state;
  }
}
