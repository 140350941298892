import React, { useContext, useEffect } from "react";
import Moment from "react-moment";
import { Link, useParams } from "react-router-dom";
import InventoriesContext from "../../contexts/inventories/inventoriesContext";
import Layout from "../../layouts/Layout";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";
import DataTable from "react-data-table-component";

const StockHistory = () => {
  const {
    selectedInventory,
    historical,
    getHistoricalStock
  } = useContext(InventoriesContext);

  const { id } = useParams();

  useEffect(() => {
    getHistoricalStock(id);
    // eslint-disable-next-line
  }, []);

  const columns = [
    {
      name: "Fecha",
      selector: (row) =>
      <Moment format="DD/MM/YYYY HH:mm">
        {row.created_at}
      </Moment>,
      sortable: true,
      sortFunction: (a, b) => a.created_at.localeCompare(b.created_at),
    },
    {
      name: "Operación",
      selector: (row) => row.operation,
      sortable: true,
    },
    {
      name: "Cantidad",
      selector: (row) =>
        <>
          {new Intl.NumberFormat("es-MX", {
            notation: "compact",
            maximumFractionDigits: 2,
          }).format(row.quantity)}{" "}
          {row.inventory?.material?.sale_unit?.acronym}{" "}
        </>,
      sortable: true,
      sortFunction: (a, b) => a.quantity - b.quantity,
    },
    {
      name: "Stock final",
      selector: (row) =>
        <>
          {new Intl.NumberFormat("es-MX", {
            notation: "compact",
            maximumFractionDigits: 2,
          }).format(row.final_stock)}{" "}
          {row.inventory?.material?.sale_unit?.acronym}{" "}
        </>,
      sortable: true,
      sortFunction: (a, b) => a.final_stock - b.final_stock,
    },
    {
      name: "Observaciones",
      selector: (row) =>
      <div>
        <p> {row.observations} </p>
        {row.detail ?
          <>
        <small><strong>Cliente:</strong> {row.detail?.order?.customer?.full_name}</small>
        </>
       : null}
      </div>,
      sortable: true,
      wrap: true
    },
  ];

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Historial de inventario</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/inventories"> Inventario </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Historial de inventario
                  </li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <h5>
                      <strong>Inventario: </strong>{" "}
                      {selectedInventory?.material?.name}
                    </h5>
                    <div className="row">
                      <div className="col-md-3 col-sm-3 text-left">
                        <p className="mb-0">
                          <strong>En Existencia: </strong>{" "}
                          {selectedInventory?.stock}{" "}
                          {selectedInventory?.material?.sale_unit?.acronym}
                        </p>
                      </div>
                      <div className="col-md-3 col-sm-3 text-left">
                        <p className="mb-0">
                          <strong>Estatus: </strong>
                          {parseFloat(selectedInventory?.stock) >=
                          parseFloat(selectedInventory?.optimal_quantity) ? (
                            <span className="badge bg-green">Completa</span>
                          ) : parseFloat(selectedInventory?.stock) <
                              parseFloat(selectedInventory?.optimal_quantity) &&
                            parseFloat(selectedInventory?.stock) >
                              parseFloat(selectedInventory?.min_quantity) ? (
                            <span className="badge bg-light-green">
                              Disponible
                            </span>
                          ) : parseFloat(selectedInventory?.stock) <=
                              parseFloat(selectedInventory?.min_quantity) &&
                            parseFloat(selectedInventory?.stock) >=
                              parseFloat(1) ? (
                            <span className="badge bg-amber">Por agotarse</span>
                          ) : parseFloat(selectedInventory?.stock) <
                            parseFloat(1) ? (
                            <span className="badge bg-red">Agotada</span>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <DataTable
                        columns={columns}
                        data={historical}
                        noDataComponent="No se encontraron registros"
                        pagination
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default StockHistory;
