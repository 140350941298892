/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import CouponsContext from "../../contexts/coupons/couponsContext";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";
import AddCoupon from "./AddCoupon";
import EditCoupon from "./EditCoupon";
import Moment from "react-moment";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";
import DataTable from "react-data-table-component";

const CouponsList = () => {
  const listCoupons = useContext(CouponsContext);
  const {
    coupons,
    getCoupons,
    success,
    editCoupon,
    removeCoupon,
  } = listCoupons;

  useEffect(() => {
    getCoupons();
    // eslint-disable-next-line
  }, [success]);

  const [filterText, setFilterText] = useState("");
  const filteredItems = coupons.filter(
    (item) =>
      item.code
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
        item.description
          .toLowerCase()
          .includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        backgroundColor: "#5cc5cd",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
  };

  const columns = [
    {
      name: "Código",
      selector: (row) => <div>
        <strong> {row.code} </strong>
        <br />
        <small>{row.description}</small>
      </div>,
      sortable: true,
      wrap:true
    },
    {
      name: "Descuento",
      selector: (row) => row.type === 1
        ? new Intl.NumberFormat("es-MX", {
          style: "currency",
            currency: "MXN",
            minimumFractionDigits: 2,
          }).format(row.discount)
          : new Intl.NumberFormat("es-MX", {
            style: "percent",
          }).format(row.discount / 100),
      sortable: true,
        width: "150px",
    },
    {
      name: "Uso/limite",
      selector: (row) => <>{row.total_used} / {row.usage_limit}</>,
      sortable: true,
      width: "130px",
    },
    {
      name: "Inicio",
      selector: (row) => <Moment format="DD/MM/YYYY">
        {row.from}
      </Moment>,
      sortable: true,
      sortFunction: (a, b) => a.from.localeCompare(b.from),
      width: "120px",
    },
    {
      name: "Expira",
      selector: (row) =>   <Moment format="DD/MM/YYYY">
          {row.expires}
        </Moment>,
      sortable: true,
      sortFunction: (a, b) => a.expires.localeCompare(b.expires),
      width: "120px",
    },
    {
      name: "Estatus",
      selector: (row) => row.expires >= row.today ? (
        <span className="badge badge-warning">
          Activo
        </span>
      ) : (
        <span className="badge badge-danger">
          Expirado
        </span>
      ),
      sortable: true,
      sortFunction: (a, b) => a.expires.localeCompare(b.expires),
      width: "120px",
    },
    {
      name: "Acciones",
      selector: (row) => row.id,
      sortable: false,
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <button
            className="btn btn-primary btn-sm"
            data-toggle="modal"
            data-target="#editCoupon"
            onClick={() => editCoupon(row.id)}
          >
            <i className="zmdi zmdi-edit"></i>
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => showConfirmMessage(row.id)}
          >
            <i className="zmdi zmdi-delete"></i>
          </button>
        </div>
      ),
      width: "120px",
    },
  ];

  const showConfirmMessage = (coupon) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Un cupón no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeCoupon(coupon);
      }
    });
  };




  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Cupones </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Cupones</li>
                  <li className="breadcrumb-item active">lista</li>
                </ul>
                <ButtonMobileMenu/>
                <div className="chat_window">
                  <button
                    className="list_btn btn btn-danger btn-round float-md-right"
                    data-toggle="modal"
                    data-target="#addCoupon"
                  >
                    <i className="ti-plus" />
                  </button>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
                <button
                  className="btn btn-danger float-right"
                  data-toggle="modal"
                  data-target="#addCoupon"
                >
                  <i className="zmdi zmdi-plus" /> Nuevo
                </button>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="input-box">
                      <i className="ti-search" />
                      <input
                        type="text"
                        placeholder="Buscar aqui..."
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                      <button className="button" onClick={handleClear}>
                        X
                      </button>
                    </div>
                    <DataTable
                      customStyles={tableCustomStyles}
                      columns={columns}
                      data={filteredItems}
                      noDataComponent="No se encontraron registros"
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddCoupon />
      <EditCoupon />
    </Layout>
  );
};

export default CouponsList;
