import { ADD_COUPON, EDIT_COUPON, GET_COUPONS, REMOVE_COUPON, UPDATE_COUPON } from "../../types"

export default function couponsReducer(state, action) {
    switch (action.type) {
        case ADD_COUPON:
            return{
                ...state,
                success: true,
            }

        case GET_COUPONS:
            return{
                ...state,
                coupons: action.payload,
                success:false,
                selectedCoupon: [],
            }

        case EDIT_COUPON:
            return {
                ...state,
                selectedCoupon: state.coupons.find(coupon => coupon.id === action.payload),
            }

        case UPDATE_COUPON:
            return{
                ...state,
                success: true,
                selectedCoupon: [],
            }

        case REMOVE_COUPON:
            return {
                ...state,
                coupons: state.coupons.filter(
                (coupon) => coupon.id !== action.payload
                ),
            }

        default:
            return state;
    }
}
