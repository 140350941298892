/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import ProvidersContext from "../../contexts/providers/providersContext";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";
import DataTable from "react-data-table-component";
import RightButtonToggle from "../../components/RightButtonToggle";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";

const ProvidersList = () => {
  const listProviders = useContext(ProvidersContext);
  const { providers, getProviders, success, removeProvider, updateStatus } =
    listProviders;

  const [filterText, setFilterText] = useState("");
  const filteredItems = providers.filter(
    (item) =>
      item.nombre_comercial.toLowerCase().includes(filterText.toLowerCase()) ||
      item.razon_social.toLowerCase().includes(filterText.toLowerCase()) ||
      item.etiquetas
        .toString()
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
      item.giro.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  useEffect(() => {
    getProviders();
    // eslint-disable-next-line
  }, [success]);

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        backgroundColor: "#5cc5cd",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
  };

  const columns = [
    {
      name: "Proveedor",
      selector: (row) => row.nombre_comercial,
      sortable: true,
      wrap: true,
      width: "300px"
    },
    {
      name: "Razón Social",
      selector: (row) => row.razon_social,
      sortable: true,
      wrap: true,
      width: "400px"
    },
    {
      name: "Giro",
      selector: (row) => row.giro,
      sortable: true,
      wrap: true
    },
    {
      name: "Etiquetas",
      selector: (row) => row.etiquetas.join(", "),
      sortable: true,
      wrap: true
    },
    {
      name: "Estatus",
      // si activo es true colocar en una etiqueta badge activo si es falso colocar Inactivo
      selector: (row) =>
        row.activo ? (
          <span className="badge bg-light-green">Activo</span>
        ) : (
          <span className="badge bg-red">Inactivo</span>
        ),
      sortable: true,
      sortFunction: (a, b) => a.activo - b.activo,
      width: "120px",
    },
    // botones de acciones
    {
      name: "Acciones",
      selector: (row) => row.id,
      sortable: false,
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <Link to={`/proveedor/${row.id}/detail`}>
            <button className="btn bg-amber btn-sm">
              <i className="zmdi zmdi-eye"></i>
            </button>
          </Link>
          <button
            className="btn bg-light-green btn-sm"
            onClick={() => confirmChangeStatus(row.id)}
          >
            <i className="zmdi zmdi-spinner"></i>
          </button>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => showConfirmMessage(row.id)}
          >
            <i className="zmdi zmdi-delete"></i>
          </button>
        </div>
      ),
      width: "150px",
    },
  ];

  const showConfirmMessage = (provider) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Un Proveedor no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeProvider(provider);
      }
    });
  };

  // funcion para confirmar el cambio de estatus
  const confirmChangeStatus = (provider) => {
    Swal.fire({
      title: "Actualizar Estatus",
      text: "El estatus del proveedor se cambiara",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, confirmar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        updateStatus(provider);
      }
    });
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Proveedores </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Proveedores</li>
                  <li className="breadcrumb-item active">lista</li>
                </ul>
                <ButtonMobileMenu />
                <div className="chat_window">
                  <Link to={`/proveedor/nuevo`}>
                    <button className="list_btn btn btn-danger btn-round float-md-right">
                      <i className="ti-plus" />
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle />
                <Link to={`/proveedor/nuevo`}>
                  <button className="btn btn-danger float-right">
                    <i className="zmdi zmdi-plus" /> Nuevo
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="card">
              <div className="body">
                <div className="input-box">
                  <i className="ti-search" />
                  <input
                    type="text"
                    placeholder="Buscar aqui..."
                    value={filterText}
                    onChange={(e) => setFilterText(e.target.value)}
                  />
                  <button className="button" onClick={handleClear}>
                    X
                  </button>
                </div>
                <DataTable
                  customStyles={tableCustomStyles}
                  columns={columns}
                  data={filteredItems}
                  noDataComponent="No se encontraron registros"
                  pagination
                />
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProvidersList;
