import React, { useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import PurchasesContext from "../../../contexts/purchases/purchasesContext";

const EditMaterial = ({selectedPayment, setSelectedPayment}) => {
  const { updatePayment } = useContext(PurchasesContext);
  const { register, errors, handleSubmit, reset, control, setValue } = useForm();

  useEffect(() => {
    if (selectedPayment && selectedPayment.payment_method) {
      setValue("payment_method", selectedPayment.payment_method, { shouldDirty: true });
    }
    // eslint-disable-next-line
  }, [selectedPayment]);

  const handleCloseModal = () => {
    setSelectedPayment({})
    window.$("#editPaymentModal").modal("hide");
  };

  const onSubmit = async (data) => {
    let id = selectedPayment.id;
    updatePayment(id, data);
    reset({});
    handleCloseModal();
  };

  return (
    <>
      <div
        className="modal fade"
        id="editPaymentModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title" id="smallModalLabel">
                Actualizar Pago
              </h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                <div className="col-lg-6">
                    <label>Fecha de pago </label>
                    <div className="form-group">
                      <input
                        type="date"
                        name="payment_date"
                        defaultValue={selectedPayment && selectedPayment.payment_date ? selectedPayment.payment_date : ""}
                        ref={register({
                          required: {
                            value: true,
                            message: "La fecha de pago es requerida",
                          },
                        })}
                        className={
                          errors.payment_date
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.payment_date ? (
                        <label className="error">
                          {errors?.payment_date?.message}
                        </label>
                      ) : null}
                    </div>
                </div>
                <div className="col-lg-6">
                    <div className="form-group">
                      <label>Método de pago </label>
                      <Controller
                        name="payment_method"
                        control={control}
                        defaultValue
                        placeholder="Selecciona una opción"
                        as={<select className="form-control">
                          <option value="">Selecciona uno</option>
                          <option value="EFECTIVO">EFECTIVO</option>
                          <option value="TRANSFERENCIA">TRANSFERENCIA</option>
                          <option value="TARJETA">TARJETA</option>
                          <option value="OTRO">OTRO</option>
                        </select>}
                        rules={{
                          required: {
                            value: true,
                            message: "El método de pago es requerido",
                          },
                        }}
                      />

                      {errors?.payment_method ? (
                        <label className="error">
                          {errors?.payment_method?.message}
                        </label>
                      ) : null}
                    </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Monto</label>
                    <input
                      name="amount"
                      type="text"
                      autoComplete="off"
                      defaultValue={selectedPayment && selectedPayment.amount ? selectedPayment.amount : ""}
                      ref={register({
                        required: {
                          value: true,
                          message: "El monto es requerido",
                        },
                        min: {
                          value: 1,
                          message:
                            "El monto debe ser mayor a 1",
                        },
                        pattern: {
                          value: /^[0-9]+([.])?([0-9]+)?$/,
                          message: "El campo deben ser solo números",
                        },
                      })}
                      className={
                        errors.amount
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                    />
                    {errors?.amount ? (
                      <label className="error">
                        {errors?.amount?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Folio Fiscal</label>
                    <input
                      name="invoice_folio"
                      type="text"
                      autoComplete="off"
                      defaultValue={selectedPayment && selectedPayment.invoice_folio ? selectedPayment.invoice_folio : ""}
                      ref={register({
                        required: {
                          value: false,
                          message: "El folio es requerido",
                        }
                      })}
                      className={
                        errors.invoice_folio
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                    />
                    {errors?.invoice_folio ? (
                      <label className="error">
                        {errors?.invoice_folio?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Observaciones</label>
                    <textarea
                      name="notes"
                      defaultValue={selectedPayment && selectedPayment.notes ? selectedPayment.notes : ""}
                      rows={2}
                      placeholder="Agrega aqui alguna observación (opcional)"
                      ref={register({
                        required: {
                          value: false,
                          message: "Las observaciones son requeridas",
                        },
                      })}
                      className={
                        errors.notes
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                    />
                    {errors?.notes ? (
                      <label className="error">
                        {errors?.notes?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  Actualizar pago
                </button>
                <button
                  type="button"
                  className="btn btn-default waves-effect"
                  onClick={handleCloseModal}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMaterial;
