import React, { useContext, useEffect } from "react";
import "react-dropzone-uploader/dist/styles.css";
import Dropzone from "react-dropzone-uploader";
import DesignContext from "../../contexts/design/designContext";
import Moment from "react-moment";

const UploadFiles = ({ selectedDetail, setSelectedDetail }) => {
const { uploadFilesPrinting, getFilesPrinting, archivos, donwloadFile, removeFile } = useContext(DesignContext);

const handleSubmit = (files, allFiles) => {
    const archivos = files.map(f => f.file);
    const formData = new FormData();  
        for (let index = 0; index < archivos.length; index++) {
        formData.append('files[]', archivos[index]);
        }
        formData.append('detail_id', selectedDetail.id);
    
        uploadFilesPrinting(formData)
    allFiles.forEach(f => f.remove())
    handleCloseModal();
}

  useEffect(() => {
    if (selectedDetail.id) {   
      getFilesPrinting(selectedDetail.id); 
    }
  // eslint-disable-next-line
  }, [selectedDetail.id]);


  const handleCloseModal = () => {
    setSelectedDetail({});
    window.$("#uploadFile").modal("hide");
  };

  return (
    <div
      className="modal fade"
      id="uploadFile"
      data-backdrop="static"
      tabIndex={-1}
      role="dialog"
    >
      <div className="modal-dialog modal-dialog-centered modal-lg" role="document">
        <div className="modal-content bg-ligth">
          <div className="modal-body">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <Dropzone
                  onSubmit={handleSubmit}
                  inputContent="Arrastre sus archivos o haga clic para examinar"
                  inputWithFilesContent="+ agregar"
                  submitButtonContent="Guardar archivos"
                  multiple={true}
                />
              </div>
              <div className="col-xl-12 col-lg-12 col-md-12">
                <div className="table-responsive">
                  <table className="table table-hover mb-0 c_table ">
                    <thead>
                      <tr>
                        <th>Archivo</th>
                        <th>Última modificación</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {!archivos.length ? (
                          <tr>
                            <td align="center" colSpan={3}>
                             
                              <div className="alert bg-lime" role="alert">
                                  <div className="container">
                                      <div className="alert-icon">
                                          <i className="zmdi zmdi-alert-circle-o"></i>
                                      </div>
                                      <strong>No se encuentran archivos</strong>
                                  </div>
                              </div>
                            </td>
                          </tr>
                        ) : (
                          archivos.map((archivo) => (
                            <tr key={archivo.id}>
                              <td>
                                <strong className="mb-0">{archivo.file_name}</strong>
                              </td>
                              <td>
                                <span className="date">
                                  <Moment format="DD-MM-YYYY, h:mm a">
                                    {archivo.created_at}
                                  </Moment>
                                </span>
                              </td>
                              <td>
                                  <button className="btn btn-primary btn-icon btn-icon-mini btn-round"
                                    onClick={() => donwloadFile(archivo)}
                                  >
                                      <i className="ti-download"></i>
                                  </button>
                                  <button className="btn btn-danger btn-icon btn-icon-mini btn-round"
                                    onClick={() => removeFile(archivo.id)}
                                  >
                                      <i className="zmdi zmdi-delete"></i>
                                  </button>
                              </td>
                            </tr>
                          ))
                        )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="button"
              className="btn btn-danger waves-effect"
              onClick={handleCloseModal}
            >
              CERRAR
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadFiles;
