/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import { Link, useParams } from "react-router-dom";
import OrdersContext from "../../contexts/orders/ordersContext";
import Layout from "../../layouts/Layout";
import Swal from "sweetalert2";
import UploadFiles from "../Desing/UploadFiles";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const estilos = {
  concept: {
    maxWidth: "250px",
  },
};

const OrderDetails = () => {
  const { order, details, generateOrder, success, getOrder, reorderItems, addNotesOrder } =
    useContext(OrdersContext);

  // state details
  const [detailsState, setDetailsState] = useState([]);
  const { id } = useParams();

  useEffect(() => {
      getOrder(id);
    // eslint-disable-next-line
  }, [success]);

  useEffect(() => {

    if (details && details.length) {
      setDetailsState([...details]);
    }
    // eslint-disable-next-line
  }, [details]);

  let subtotalPayments =
    order && order.payments
      ? order.payments.reduce((sum, value) => sum + value.amount, 0)
      : 0;

  //state de los producto seleccionados a eliminar
  const [selected, setSelected] = useState([]);
  const [selectedDetail, setSelectedDetail] = useState({});
  //funcion que selecciona el producto que se desea eliminar y lo agrega al array
  const handleClick = (event, product) => {
    const selectedIndex = selected.indexOf(product);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, product);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (product) => selected.indexOf(product) !== -1;

  const handleSelectedDetail = (detail) => {
    setSelectedDetail(detail);
  };

  const handleOnDragEnd = (result) => {
    if (!result.destination) return;

    const items = Array.from(detailsState);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setDetailsState(items);
  };

  //reorderItems
  const handleReorderItems = () => {
    //crear array nuevo
    const detailsOrdered = detailsState.map((item, index) => ({id:item.id, position: index + 1}));

    Swal.fire({
      title: "Reordenar partidas de la orden",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        reorderItems(
          JSON.stringify(detailsOrdered)
        );
      }
    });
  }


  const handleAddNotes = (order) => {
    Swal.fire({
      title: "Agregar Notas/Comentarios",
      input: "textarea",
      inputValue: order.notes,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            addNotesOrder({"order_id":order.id, "notes":value});
            resolve();
          } else {
            resolve("Debes agregar alguna nota / comentario");
          }
        });
      },
    });
  };



  //funcion para enviar la informacion para generar la orden
  const handleGenerateOrder = () => {
    if (!selected.length) return;
    let filename;
    Swal.fire({
      title: "Generar Orden",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        filename = `orden-${order.id}.pdf`;
        generateOrder(selected, order.id, filename);
      }
    });
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
                <div className="col-lg-7 col-md-6 col-sm-12">
                  <h2>Detalles de la Orden</h2>
                  <ul className="breadcrumb">
                    <li className="breadcrumb-item">
                      <i className="zmdi zmdi-home" /> Home
                    </li>
                    <li className="breadcrumb-item active">
                      <Link to="/orders"> Ordenes </Link>
                    </li>
                    <li className="breadcrumb-item active">Detalles</li>
                  </ul>
                  <ButtonMobileMenu/>
                </div>
                <div className="col-lg-5 col-md-6 col-sm-12">
                 <RightButtonToggle/>
                </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                   <div className="body">
                      <h5>
                        <strong>Orden ID: </strong> #{order.id}
                      </h5>
                      <div className="row">
                        <div className="col-md-6 col-sm-6">
                          <address>
                            <strong>
                              {order.customer ? order.customer.full_name : ""}
                            </strong>
                            <br />
                            {order.customer ? order.customer.email : ""} <br />
                            <abbr title="Phone">Tel:</abbr>{" "}
                            {order.customer ? order.customer.phone : ""}
                            {order && order.shipping_ddress ? (
                              <>
                                <h6 className="mt-3">Dirección de Envío</h6>
                                <div>
                                  {order.shipping_ddress.street} #
                                  {order.shipping_ddress.number_out},{" "}
                                  {order.shipping_ddress.number_int
                                    ? "interior " +
                                      order.shipping_ddress.number_int +
                                      ","
                                    : ""}{" "}
                                  <br />
                                  {order.shipping_ddress.location},{" "}
                                  {order.shipping_ddress.municipality.name},{" "}
                                  <br />
                                  {
                                    order.shipping_ddress.municipality.state.name
                                  }{" "}
                                  {order.shipping_ddress.postal_code}
                                </div>
                              </>
                            ) : null}
                          </address>

                          <div className="btn-group">
                              <button
                                className="btn btn-outline-secondary btn-sm me-2"
                                onClick={() => handleGenerateOrder()}
                              >
                                <i className="zmdi zmdi-print" /> {""}
                                Generar orden
                              </button>
                          </div>
                          <div className="btn-group">
                              <button
                                className="btn btn-outline-secondary btn-sm"
                                onClick={() => handleReorderItems()}
                              >
                                <i className="zmdi zmdi-swap-vertical" /> {""}
                                Ordenar items
                              </button>
                          </div>
                        </div>
                        <div className="col-md-6 col-sm-6 text-right">
                          <p className="mb-0">
                            <strong>Status: </strong>
                            {order.status === 0 ? (
                              <span className="badge badge-default">
                                Rechazada
                              </span>
                            ) : order.status === 1 ? (
                              <span className="badge badge-warning">
                                Pendiente
                              </span>
                            ) : order.status === 2 ? (
                              <span className="badge badge-success">
                                Aprobada
                              </span>
                            ) : order.status === 3 ? (
                              <span className="badge badge-primary">
                                En Producción
                              </span>
                            ) : order.status === 4 ? (
                              <span className="badge badge-info">
                                Orden completada
                              </span>
                            ) : order.status === 5 ? (
                              <span className="badge badge-danger">
                                Entregada
                              </span>
                            ) : null}
                          </p>
                          <p className="mb-0">
                            <strong>Creada: </strong>{" "}
                            <Moment format="DD/MM/YYYY">
                              {order.order_date}
                            </Moment>
                          </p>
                          <p className="mb-0">
                            <strong>Expira: </strong>{" "}
                            <Moment format="DD/MM/YYYY">{order.expires}</Moment>
                          </p>
                          <p className="mb-0">
                            <strong>Envío: </strong>
                            {order.shipping_type === 1 ? (
                              <span>Gratis 3 a 4 días hábiles</span>
                            ) : order.shipping_type === 2 ? (
                              <span>Recoger en tienda</span>
                            ) : order.shipping_type === 3 ? (
                              <span>Estandar 2 a 3 días hábiles</span>
                            ) : order.shipping_type === 4 ? (
                              <span>Espress 2 a 3 días hábiles</span>
                            ) : order.shipping_type === 5 ? (
                              <span>Mismo dia</span>
                            ) : null}
                          </p>
                        </div>
                      </div>
                   </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table c_table theme-color">
                          <thead>
                            <tr>
                              <th></th>
                              <th>Descripción</th>
                              <th className="hidden-sm-down">Archivo</th>
                              <th>Cantidad</th>
                              <th className="hidden-sm-down">Costo Unit</th>
                              <th>Total</th>
                            </tr>
                          </thead>
                          <DragDropContext onDragEnd={handleOnDragEnd}>
                            <Droppable droppableId="table-body">
                            {(provided) => (
                              <tbody {...provided.droppableProps} ref={provided.innerRef}>
                                {!detailsState.length ? (
                                  <tr>
                                    <td align="center" colSpan={6}>
                                      No hay productos agregados a la orden
                                    </td>
                                  </tr>
                                ) : (
                                  detailsState.map((detail, index) => {
                                    const isItemSelected = isSelected(detail.id);
                                    return (
                                      <Draggable key={detail.id}  draggableId={`detail-${detail.id}`} index={index}>
                                          {(provided) => (
                                            <tr
                                              key={index}
                                              className={
                                                isItemSelected ? "table-info" : null
                                              }
                                              ref={provided.innerRef} {...provided.draggableProps} {...provided.dragHandleProps}
                                            >
                                              <td className="chb">
                                                <div className="checkbox simple">
                                                  <input
                                                    id={`mc${detail.id}`}
                                                    type="checkbox"
                                                    onChange={(event) =>
                                                      handleClick(event, detail.id)
                                                    }
                                                    checked={isItemSelected}
                                                  />
                                                  <label htmlFor={`mc${detail.id}`} />
                                                </div>
                                              </td>
                                              <td style={estilos.concept}>
                                                <strong> {detail.product.name} </strong>
                                                <br />
                                                {detail.product &&
                                                detail.product.product_type !==
                                                  "service" ? (
                                                  <>
                                                    {detail.product &&
                                                    detail.product.product_type ===
                                                      "byarea" ? (
                                                      <small>
                                                        Medidas: {detail.base} x{" "}
                                                        {detail.altura} m
                                                      </small>
                                                    ) : (
                                                      detail.product.status === 1 && (
                                                        <small>
                                                          Tamaño:{" "}
                                                          {detail.options.size.name}
                                                        </small>
                                                      )
                                                    )}
                                                    {order.type_of_sale === 2 && (
                                                      <>
                                                        <br />
                                                        <small>
                                                          Material:{" "}
                                                          {detail.options.material.name}
                                                        </small>
                                                        <br />
                                                        <small>
                                                          Impresión:{" "}
                                                          {detail.options.printing.name}
                                                        </small>
                                                        <br />
                                                        <small>
                                                          Acabado:{" "}
                                                          {detail.options.option.name}
                                                        </small>
                                                      </>
                                                    )}
                                                  </>
                                                ) : detail.options.measure.name ===
                                                  "m2" ? (
                                                  <>
                                                    <small>
                                                      precio por:{" "}
                                                      {detail.options.measure.name}
                                                    </small>
                                                    <br />
                                                    <small>
                                                      Medidas: {detail.options.size.base}{" "}
                                                      x {detail.options.size.altura} m
                                                    </small>{" "}
                                                  </>
                                                ) : (
                                                  <small>
                                                    precio por:{" "}
                                                    {detail.options.measure.name}
                                                  </small>
                                                )}
                                                <br />
                                                <small>{detail.description}</small>
                                              </td>
                                              <td>
                                                {detail.product.product_type !==
                                                "service" ? (
                                                  <button
                                                    className="btn btn-primary btn-simple btn-sm"
                                                    data-toggle="modal"
                                                    data-target="#uploadFile"
                                                    onClick={() =>
                                                      handleSelectedDetail(detail)
                                                    }
                                                  >
                                                    {" "}
                                                    <i className="zmdi zmdi-attachment-alt"></i>{" "}
                                                    Ver archivos{" "}
                                                  </button>
                                                ) : (
                                                  <span> - </span>
                                                )}
                                              </td>
                                              <td>
                                                <strong>{detail.quantity}</strong>
                                              </td>
                                              <td className="hidden-sm-down">
                                                {new Intl.NumberFormat("es-MX", {
                                                  style: "currency",
                                                  currency: "MXN",
                                                  minimumFractionDigits: 2,
                                                }).format(detail.unit_price)}
                                              </td>
                                              <td>
                                                {new Intl.NumberFormat("es-MX", {
                                                  style: "currency",
                                                  currency: "MXN",
                                                  minimumFractionDigits: 2,
                                                }).format(detail.amount)}
                                              </td>
                                            </tr>
                                          )}
                                      </Draggable>
                                    );
                                  })
                                )}
                                 {provided.placeholder}
                              </tbody>
                               )}
                            </Droppable>
                          </DragDropContext>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-8 mb-4 order-2 order-md-12">
                      <div className="body">
                        <div className="header">
                        <ul className="header-dropdown">
                            <li className="remove">
                                <a role="button" className="btn-outline-secondary btn-sm" onClick={() => handleAddNotes(order)}><i className="zmdi zmdi-edit"></i></a>
                            </li>
                        </ul>
                        </div>
                        <div className="form-group">
                          <label>Comentarios / Notas</label>
                          <textarea
                            name="notes"
                            defaultValue={order ? order.notes : ""}
                            readOnly
                            rows={4}
                            placeholder="Agrega aqui una observación (opcional)"
                            className="form-control"
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 mb-4 order-1 order-md-12">
                      <div className="body">
                      <div className="table-responsive">
                        <table className="table">
                          <tbody>
                            <tr>
                              <th className="text-left">Subtotal:</th>
                              <td className="text-right">
                                {new Intl.NumberFormat("es-MX", {
                                  style: "currency",
                                  currency: "MXN",
                                  minimumFractionDigits: 2,
                                }).format(order.subtotal)}
                              </td>
                            </tr>
                            {order && order.shipping_costs > 0 && (
                              <tr>
                                <th className="text-left">Envío:</th>
                                <td className="text-right">
                                  {new Intl.NumberFormat("es-MX", {
                                    style: "currency",
                                    currency: "MXN",
                                    minimumFractionDigits: 2,
                                  }).format(order.shipping_costs)}
                                </td>
                              </tr>
                            )}
                            {order && order.discount > 0 && (
                              <tr>
                                <th className="text-left">Descuento:</th>
                                <td className="text-right">
                                  {new Intl.NumberFormat("es-MX", {
                                    style: "currency",
                                    currency: "MXN",
                                    minimumFractionDigits: 2,
                                  }).format(order.discount)}
                                </td>
                              </tr>
                            )}
                            <tr>
                              <th className="text-left">
                                IVA:{" "}
                                <span className="font-weight-normal">
                                  (16%)
                                </span>
                              </th>
                              <td className="text-right">
                                {new Intl.NumberFormat("es-MX", {
                                  style: "currency",
                                  currency: "MXN",
                                  minimumFractionDigits: 2,
                                }).format(order.iva)}
                              </td>
                            </tr>
                            <tr>
                              <th className="text-left">Total:</th>
                              <td className="text-right text-primary">
                                <h5 className="font-weight-semibold">
                                  {new Intl.NumberFormat("es-MX", {
                                    style: "currency",
                                    currency: "MXN",
                                    minimumFractionDigits: 2,
                                  }).format(order.total)}
                                </h5>
                              </td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      </div>
                    </div>
                    <div className="col-md-8 order-3 order-md-12">
                      <div className="body">
                      <div className="header">
                          <h2><strong>Pagos</strong> Realizados</h2>
                          <div className="header-dropdown">
                              Total pagado <strong>
                              {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                                minimumFractionDigits: 2,
                              }).format(subtotalPayments)}</strong>
                          </div>
                      </div>
                        <ul className="comment-reply list-unstyled">
                          {order?.payments?.map((payment, index) => {
                            return (
                              <div key={`payment-${index}`}>
                                <li>
                                  <div className="text-box">
                                    <div className="row text-small">
                                      <div className="col-lg-4 mb-2">
                                        <span className="text-muted">
                                          Fecha
                                        </span>
                                        <br />
                                        <Moment format="DD/MM/YYYY">
                                          {payment.created_at}
                                        </Moment>
                                      </div>
                                      <div className="col-lg-4 mb-2">
                                        <span className="text-muted">Metodo Pago</span>
                                        <br />
                                        {payment.payment_method}
                                      </div>
                                      <div className="col-lg-4 mb-2">
                                        <span className="text-muted">
                                          Monto
                                        </span>
                                        <br />
                                        {new Intl.NumberFormat("es-MX", {
                                          style: "currency",
                                          currency: "MXN",
                                          minimumFractionDigits: 2,
                                        }).format(payment.amount)}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <hr />
                              </div>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UploadFiles
        selectedDetail={selectedDetail}
        setSelectedDetail={setSelectedDetail}
      />
    </Layout>
  );
};

export default OrderDetails;
