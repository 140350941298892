import React from "react";
import $ from "jquery";

const RightButtonToggle = () => {

    const handleRightToggleBtnClick = () => {
        $("body").toggleClass("right_icon_toggle");
      };

  return (
    <>
      <button
        className="btn btn-primary float-right right_icon_toggle_btn"
        type="button"
        onClick={handleRightToggleBtnClick}
      >
        <i className="zmdi zmdi-arrow-right" />
      </button>
    </>
  );
};

export default RightButtonToggle;
