import React, { useReducer } from "react";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import expensesReducer from "./expensesReducer";
import { ADD_EXPENSE, ADD_TYPE_EXPENSE, EDIT_EXPENSE, GET_EXPENSES, GET_TYPES_EXPENSES, REMOVE_EXPENSE, UPDATE_EXPENSE } from "../../types";
import ExpensesContext from "./expensesContext";


const ExpensesState = (props) => {
 // estado inicial
 const initialSate = {
    expenses: [],
    selectedExpense: [],
    tiposgastos: [],
    success: false,
    success2: false,
  };

  const [state, dispatch] = useReducer( expensesReducer, initialSate);

  //obtener los gastos
  const getExpenses = () => {
    let url = `expenses`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_EXPENSES,
        payload: response.data.data,
      });
    });
  };

  const addExpense = (expense) => {
    let url = "/expenses";
    MethodPost(url, expense)
      .then((response) => {
        dispatch({
          type: ADD_EXPENSE,
        });
        Swal.fire({
          title: 'Buen Trabajo',
          text:  response.data.message,
          icon: 'success',
          timer: 3000,
          showConfirmButton: false
      });
      })
      .catch((error) => {
        const errors = Object.values(error.response.data.data).flat();
        Swal.fire({
          icon: 'error',
          title: error.response.data.message,
          text: errors.join('\n')
        });
      });
  };

  //SELECCIONAR GASTO A EDITAR
  const editExpense = (expense) => {
    let url = `expenses/${expense}/edit`;
    MethodGet(url).then((response) => {
      dispatch({
        type: EDIT_EXPENSE,
        payload: response.data,
      });
    });
  };

  //Actualizar un gasto
  const updateExpense = (data) => {
    let url = `expenses/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        Swal.fire({
          title: 'Buen Trabajo',
          text:  response.data.message,
          icon: 'success',
          timer: 3000,
          showConfirmButton: false
      });
        dispatch({
          type: UPDATE_EXPENSE,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar un gasto
  const removeExpense = (id) => {
    let url = `expenses/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: 'Eliminado',
        text:  response.data.message,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false
    });
      dispatch({
        type: REMOVE_EXPENSE,
        payload: id,
      });
    });
  };

  //OBTENER LA DATA PARA EL SELECT TIPOS DE GASTOS
  const getTiposGastos = () => {
    let url = "tipo-gastos";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_TYPES_EXPENSES,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //AGREGAR TIPO DE GASTO
  const addTypeExpense = (data) => {
    let url = "/tipo-gastos";
    MethodPost(url, data)
      .then((response) => {
        dispatch({
          type: ADD_TYPE_EXPENSE,
        });
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <ExpensesContext.Provider
      value={{
        expenses: state.expenses,
        success: state.success,
        success2: state.success2,
        selectedExpense: state.selectedExpense,
        tiposgastos: state.tiposgastos,
        getExpenses,
        addExpense,
        editExpense,
        removeExpense,
        updateExpense,
        getTiposGastos,
        addTypeExpense
      }}
    >
      {props.children}
    </ExpensesContext.Provider>
  );
};

export default ExpensesState;
