import React from 'react'

const FiltersDesing = ({ filterOn, handleOpenFilters, setDataFilters, data, onSubmit }) => {

    const filtersClass = filterOn ? "right-sidebar open" : "right-sidebar";
  
    const handleChange = (e) =>{
      setDataFilters({
        ...data,
        [e.target.name] : e.target.value
       });
     }

  return (
      <>
      {/* Search Filters */}
      <aside id="rightsidebar" className={filtersClass}>
        <ul className="nav nav-tabs sm">
          <li className="nav-item">
            <p className="text-white text-center">FILTROS DE BUSQUEDA</p>
          </li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane active" id="filters">
            <div className="slim_scroll">
              <div className="card">
                <form onSubmit={onSubmit}>
                  <h6>ID ORDEN</h6>
                  <div className="form-group">
                    <input
                      type="text"
                      name="order_id"
                      placeholder="123"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <h6>CLIENTE</h6>
                  <div className="form-group">
                    <input
                      type="text"
                      name="customer"
                      placeholder="Nombre del cliente"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <h6>FECHA EMISION</h6>
                  <div className="form-group">
                    <input
                      type="date"
                      name="created_at"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <h6>Status Orden</h6>
                  <div className="form-group">
                    <select
                      name="order_status"
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option value="">Todos</option>
                      <option value="1">Pendiente</option>
                      <option value="2">Aprobada</option>
                      <option value="3">En Producción</option>
                      <option value="4">Pedido Listo</option>  
                      <option value="5">Completada</option>                      
                    </select>
                  </div>
        
                  <div className="form-group">
                    <button
                      className="btn btn-default "
                      type="button"
                      onClick={handleOpenFilters}
                    >
                      Cerrar
                    </button>
                    <button className="btn btn-primary " type="submit">
                      Aplicar filtros
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  )
}

export default FiltersDesing