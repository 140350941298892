import { ADD_FAMILY, EDIT_FAMILY, GET_FAMILIES, REMOVE_FAMILY, UPDATE_FAMILY } from '../../types';

export default function familiesReducer(state, action) {
    switch (action.type) {
        case ADD_FAMILY:
            return{
                ...state,
                success: true,
            }

        case GET_FAMILIES:
            return{
                ...state,
                families: action.payload,
                success:false,
            }

        case EDIT_FAMILY:
            return {
                ...state,
                selectedFamily: state.families.find(family => family.id === action.payload),
            }

        case UPDATE_FAMILY:
            return{
                ...state,
                success: true,
                selectedFamily: [],
            }

        case REMOVE_FAMILY:
            return {
                ...state,
                families: state.families.filter(
                (family) => family.id !== action.payload
                ),
            }

        default:
            return state;
    }
}
