import React, {useContext, useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import CustomerContext from "../../contexts/customers/customersContext";

const EditCustomer = ({cliente}) => {

  const { updateCustomer, opcionesComoSeEnteraron } = useContext(CustomerContext);

  const { register, errors, handleSubmit, reset } = useForm();
  const {
    id,
    full_name,
    phone,
    email,
    letterhead,
    opcion_como_se_enteraron_id
  } = cliente;
  
  const [selected, setSelected] = useState(1);
  const [selectedComoSeEnteraron, setComoSeEnteraron] = useState("");

  //funcion que se encarga de listar las opciones de como se enteraron
  const optionsReferences = opcionesComoSeEnteraron.map((referencia, index) => (
    <option key={index} value={referencia.id}>
      {referencia.opcion}
    </option>
  ));


  useEffect(() => {
    setSelected(letterhead);
    setComoSeEnteraron(opcion_como_se_enteraron_id);
    // eslint-disable-next-line
  }, [cliente]);

  const handleCloseModal = () => {
    window.$('#editCustomer').modal('hide');
  }

  const handleChange = (e) => {
    setSelected(Number(e.target.value));
  };

  const handleChangeReference = (e) => {
    setComoSeEnteraron(Number(e.target.value));
  };


  const onSubmit = (data) => {
    data.id = id;
    updateCustomer(data);
    reset();
    handleCloseModal();
  }

  return (
    <>
      <div className="modal fade" id="editCustomer" tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title">Actualizar Ciente</h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Nombre</label>
                      <input
                        name="full_name"
                        defaultValue={full_name}
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: true,
                            message: "El Nombre del cliente es requerido",
                          }
                        })}
                        className={
                          errors.full_name
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Nombre *"
                      />
                      {errors.full_name && (
                        <label className="error">
                          El nombre del cliente es requerido
                        </label>
                      )}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Teléfono</label>
                      <input
                        name="phone"
                        defaultValue={phone}
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: true,
                            message: "El Teléfono es requerido",
                          },
                          maxLength: {
                            value: 10,
                            message: "Maximo 10 digitos",
                          },
                          minLength: {
                            value: 10,
                            message: "Minimo 10 digitos",
                          },
                          pattern: {
                            value: /^[0-9\b]+$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.phone
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Teléfono *"
                      />
                      {errors?.phone ? (
                        <label className="error">
                          {errors?.phone?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Email</label>
                      <input
                        name="email"
                        defaultValue={email}
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: true,
                            message: "El Email es requerido",
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message:
                              "Dirección de correo electrónico no válida",
                          },
                        })}
                        className={
                          errors.email
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Email *"
                      />
                      {errors?.email ? (
                        <label className="error">
                          {errors?.email?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                        <label>¿Cómo se enteraron de nosotros?</label>
                    <div className="form-group">
                        <select
                        name="opcion_como_se_enteraron_id"
                        value={selectedComoSeEnteraron}
                        onChange={(e) => handleChangeReference(e)} 
                        ref={register({ required: { 
                          value: true,
                          message: "Este campo es requerido",
                          }, })}
                          className={
                            errors.opcion_como_se_enteraron_id
                            ? "form-control is-invalid"
                            : "form-control "
                            }
                            >
                              <option value="">Selecciona una opción</option>
                              {optionsReferences}
                            </select>
                      {errors?.opcion_como_se_enteraron_id ? (
                        <label className="error">
                          {errors?.opcion_como_se_enteraron_id?.message}
                        </label>
                      ) : null}
                    </div>
                    </div>
                  <div className="col-lg-12">
                  <label>Hoja membretada</label>
                  <div className="form-group">
                    <select name="letterhead"  
                      value={selected} 
                      onChange={(e) => handleChange(e)} 
                      ref={register({
                        required: {
                          value: true,
                          message: "Este campo es requerido",
                        },
                      })}
                      className="form-control">
                      <option value="1">PBK</option>
                      <option value="2">CAMCA</option>
                    </select>
                    {errors?.letterhead ? (
                      <label className="error">Selecciona el tipo de hoja membretada</label>
                    ) : null}
                  </div>
            </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  ACTUALIZAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditCustomer;
