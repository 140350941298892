import React, { useContext, useEffect } from "react";
import { Navigate } from 'react-router-dom';
import AuthContext from "../contexts/auth/authContext";


const PrivateRoute = ({ children }) => {
  const {authenticatedUser, authenticated, cargando } = useContext(AuthContext);

  useEffect(() => {
    authenticatedUser();
    // eslint-disable-next-line
}, []);

return !authenticated && !cargando ? <Navigate to="/login" /> : children ;
};

export default PrivateRoute;
