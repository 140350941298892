import React, { useContext } from 'react'
import { useForm } from 'react-hook-form';
import ExpensesContext from '../../contexts/expenses/expensesContext';

const AddTipoGasto = () => {

    const { addTypeExpense } = useContext(ExpensesContext)
    const { register, errors, handleSubmit, reset } = useForm();

    const handleCloseModal = () => {
      window.$('#addTipoGasto').modal('hide');
    }
    
    const onSubmit = (data) => {
      addTypeExpense(data);
      reset();
      handleCloseModal();
    }

  return (
    <>
    <div className="modal fade" id="addTipoGasto" tabIndex={-1} role="dialog">
   <div className="modal-dialog" role="document">
     <div className="modal-content">
       <div className="modal-header">
         <h4 className="title" id="defaultModalLabel">
           Nueva categoría (gastos)
         </h4>
       </div>
       <form onSubmit={handleSubmit(onSubmit)}>
         <div className="modal-body">
           <div className="row">
             <div className="col-lg-12">
               <div className="form-group">
                   <label>Categoría</label>
                 <input
                   name="nombre"
                   ref={register({
                     required: {
                       value: true,
                       message: "El nombre del gasto es requerido",
                     },
                   })}
                   className={
                     errors.nombre
                       ? "form-control is-invalid"
                       : "form-control "
                   }
                 />
                 {errors?.nombre ? (
                   <label className="error">{errors?.nombre?.message}</label>
                 ) : null}
               </div>
             </div>
           </div>
         </div>
         <div className="modal-footer">
           <button
             type="submit"
             className="btn btn-danger btn-round waves-effect"
           >
             GUARDAR
           </button>
           <button
             type="button"
             className="btn btn-default btn-round waves-effect"
             onClick={handleCloseModal}
           >
             CANCELAR
           </button>
         </div>
       </form>
     </div>
   </div>
 </div>
</>
  )
}

export default AddTipoGasto