import { ADD_DISCOUNT, EDIT_DISCOUNT, GET_DISCOUNTS, GET_PRODUCTS_SELECT, REMOVE_DISCOUNT, SHOW_ERRORS_API, UPDATE_DISCOUNT } from "../../types"


export default function discountsReducer(state, action) {
    switch (action.type) {
        case ADD_DISCOUNT:
            return{
                ...state,
                success: true,
            }

        case GET_DISCOUNTS:
            return{
                ...state,
                discounts: action.payload.data,
                current_page: action.payload.current_page,
                per_page: action.payload.per_page,
                total: action.payload.total,
                success:false,
                selectedDiscount: [],
                apierrors: [],
            }
        
        case EDIT_DISCOUNT:
            return {
                ...state,
                selectedDiscount: state.discounts.find(discount => discount.id === action.payload),
            }
        
        case UPDATE_DISCOUNT:
            return{
                ...state,
                success: true,
                selectedDiscount: [],
            }

        case REMOVE_DISCOUNT:
            return {
                ...state,
                discounts: state.discounts.filter(
                (discount) => discount.id !== action.payload
                ),
            }

        case GET_PRODUCTS_SELECT:
            return {
                ...state,
                products: action.payload,
            };
            
        case SHOW_ERRORS_API:
            return{
                ...state,
                apierrors: action.payload
            }

        default:
            return state;
    }
}
