import React, { useContext, useEffect, useMemo, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import Layout from "../../layouts/Layout";
import Select from "react-select";
import OrdersContext from "../../contexts/orders/ordersContext";
import moment from "moment";
import DataTable from "react-data-table-component";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const RespaldarOrdenes = () => {
  const {
    getCustomers,
    customers,
    backupOrdersClient,
    orders_backups,
    cantidad_pdfs,
    downloadBackupOrdersClient,
  } = useContext(OrdersContext);

  const { register, control, errors, handleSubmit, setValue } = useForm();
  // state para cliente
  const [clientSelected, setClientSelected] = useState(null);

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line
  }, []);

  const optionsCustomers = customers.map((customer) => {
    return { value: customer.id, label: customer.full_name };
  });

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
    },
    {
      name: "Fecha",
      selector: (row) => moment(row.created_at).format("DD/MM/YYYY"),
      sortable: true,
    },
    {
      name: "Cliente",
      selector: (row) => row.customer.full_name,
      sortable: true,
      width: "400px",
    },
    {
      name: "Total",
      selector: (row) =>
        new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
          minimumFractionDigits: 2,
        }).format(row.total),
      sortable: true,
    },
    {
      name: "Estatus",
      cell: (row) => (
        <>
          {row.status === 0 ? (
            <span className="badge badge-default">Rechazada</span>
          ) : row.status === 1 ? (
            <span className="badge badge-warning">Pendiente</span>
          ) : row.status === 2 ? (
            <span className="badge badge-success">Aprobada</span>
          ) : row.status === 3 ? (
            <span className="badge badge-primary">En Producción</span>
          ) : row.status === 4 ? (
            <span className="badge badge-info">Pedido listo</span>
          ) : row.status === 5 ? (
            <span className="badge badge-danger">Completada</span>
          ) : null}
        </>
      ),
      sortable: true,
    },
  ];
  const dataTableRespaldos = useMemo(() => orders_backups, [orders_backups]);

  const handleChangeCustomer = (e) => {
    setValue("cliente", e, { shouldValidate: true });
    setClientSelected(e);
  };

  // funcion para descargar archivo zip
  const downloadZip = () => {
    downloadBackupOrdersClient(clientSelected.value, clientSelected.label);
  };

  const onSubmit = (data) => {
    backupOrdersClient(data.cliente?.value, data.fechaInicio, data.fechaFinal);
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Respaldar Información </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item active">Respaldar</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="header">
                      <h2>
                        <small className="text-primary">
                          Selecciona al cliente que desees respaldar su
                          información.
                        </small>
                      </h2>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-6 form-group">
                          <Controller
                            name="cliente"
                            control={control}
                            defaultValue=""
                            render={({ field }) => (
                              <div className="react-select form-control p-0">
                                <Select
                                  {...field}
                                  placeholder="Selecciona un cliente"
                                  options={optionsCustomers}
                                  onChange={(e) => handleChangeCustomer(e)}
                                />
                              </div>
                            )}
                            rules={{
                              required:
                                "Selecciona un cliente antes de continuar",
                            }}
                          />
                          {errors?.cliente ? (
                            <label className="error">
                              {errors?.cliente?.message}
                            </label>
                          ) : null}
                        </div>
                        <div className="col-lg-2 form-group">
                          <input
                            type="date"
                            id="fechaInicio"
                            name="fechaInicio"
                            ref={register({
                              required: {
                                value: true,
                                message: "La fecha de inicio es requerida",
                              },
                            })}
                            className={
                              errors.fechaInicio
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {errors?.fechaInicio ? (
                            <label className="error">
                              {errors?.fechaInicio?.message}
                            </label>
                          ) : null}
                        </div>
                        <div className="col-lg-2 form-group">
                          <input
                            type="date"
                            id="fechaFinal"
                            name="fechaFinal"
                            ref={register({
                              required: {
                                value: true,
                                message: "La fecha de inicio es requerida",
                              },
                            })}
                            className={
                              errors.fechaFinal
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {errors?.fechaFinal ? (
                            <label className="error">
                              {errors?.fechaFinal?.message}
                            </label>
                          ) : null}
                        </div>
                        <div className="col-lg-2 form-group">
                          <button
                            type="submit"
                            className="btn btn-secondary btn-block waves-effect"
                          >
                            RESPALDAR ORDENES
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              {orders_backups.length ? (
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <div className="card">
                    <div className="header">
                      <h2>
                        SE RESPALDARON <strong>{cantidad_pdfs}</strong> ORDENES
                      </h2>
                      <button
                        className="btn btn-success float-right"
                        onClick={() => downloadZip()}
                      >
                        <i className="zmdi zmdi-download"></i> archivo zip
                      </button>
                    </div>
                    <div className="table-responsive">
                      <DataTable
                        columns={columns}
                        data={dataTableRespaldos}
                        noDataComponent="NO HAY MOVIMIENTOS REGISTRADOS"
                        pagination
                      />
                    </div>
                  </div>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default RespaldarOrdenes;
