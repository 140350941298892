import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import SizesContext from "../../contexts/sizes/sizesContext";

const EditSize = () => {
  const { selectedSize, updateSize } = useContext(SizesContext);

  const { register, errors, handleSubmit, reset } = useForm();

  const handleCloseModal = () => {
    window.$("#editSize").modal("hide");
  };

  const onSubmit = (data) => {
    data.id = selectedSize.id;
    updateSize(data);
    reset();
    handleCloseModal();
  };

  return (
    <div className="modal fade" id="editSize" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="largeModalLabel">
              Actualizar Tamaño
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Nombre</label>
                    <input
                      name="name"
                      type="text"
                      ref={register({
                        required: {
                          value: true,
                          message: "El campo Nombre es requerido",
                        },
                      })}
                      className={
                        errors.name
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="Nombre del tamaño *"
                      defaultValue={selectedSize ? selectedSize.name : ""}
                    />
                    {errors?.name ? (
                      <label className="error">{errors?.name?.message}</label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Base (cm)</label>
                    <input
                      name="base"
                      type="text"
                      ref={register({
                        required: {
                          value: true,
                          message: "El campo Base es requerido",
                        },
                        pattern: {
                          value:
                            /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                          message:
                            "El campo Altura no puede ser un valor negativo",
                        },
                      })}
                      className={
                        errors.base
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="Base *"
                      defaultValue={selectedSize ? selectedSize.base : ""}
                    />
                    {errors?.base ? (
                      <label className="error">{errors?.base?.message}</label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Altura (cm)</label>
                    <input
                      name="altura"
                      type="text"
                      ref={register({
                        required: {
                          value: true,
                          message: "El campo Altura es requerido",
                        },
                        pattern: {
                          value:
                            /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                          message:
                            "El campo Altura no puede ser un valor negativo",
                        },
                      })}
                      className={
                        errors.altura
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="Altura *"
                      defaultValue={selectedSize ? selectedSize.altura : ""}
                    />
                    {errors?.altura ? (
                      <label className="error">{errors?.altura?.message}</label>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-danger btn-round waves-effect"
              >
                ACTUALIZAR
              </button>
              <button
                type="button"
                className="btn btn-default btn-round waves-effect"
                onClick={handleCloseModal}
              >
                CANCELAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default EditSize;
