import React, { useContext } from 'react'
import { useForm } from "react-hook-form";
import MeasurementsContext from "../../contexts/measurements/measurementsContext";

const AddMeasurement = () => {

    const { addMeasurement } = useContext(MeasurementsContext);

  const { register, errors, handleSubmit, reset } = useForm();

  const handleCloseModal = () => {
    window.$('#addMeasurement').modal('hide');
  }

  const onSubmit = (data) => {
    addMeasurement(data);
    reset();
    handleCloseModal();
  }
  return (
    <>
    <div className="modal fade" id="addMeasurement" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="defaultModalLabel">
              Agregar Unidad de Medida
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                      <label>Nombre de la unidad</label>
                    <input
                      name="name"
                      ref={register({
                        required: {
                          value: true,
                          message: "El nombre de la unidad es requerido",
                        },
                      })}
                      className={
                        errors.name
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="nombre *"
                    />
                    {errors?.name ? (
                      <label className="error">{errors?.name?.message}</label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Abreviatura</label>
                    <input
                      name="acronym"
                      ref={register({
                        required: {
                          value: true,
                          message: "La abreviatura es requerida",
                        }
                      })}
                      className={
                        errors.acronym
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="abreviatura *"
                    />
                    {errors?.acronym ? (
                      <label className="error">
                        {errors?.acronym?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                      <label>Descripción</label>
                    <input
                      name="description"
                      ref={register({ required: {
                          value: true,
                          message: "La descripción es requerida",
                          }
                        })}
                      className={
                        errors.description
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="descripción *"
                    />
                    {errors?.description ? (
                      <label className="error">
                        {errors?.description?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-danger btn-round waves-effect"
              >
                GUARDAR
              </button>
              <button
                type="button"
                className="btn btn-default btn-round waves-effect"
                onClick={handleCloseModal}
              >
                CANCELAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  </>
  )
}

export default AddMeasurement