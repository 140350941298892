/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState, useRef } from "react";
import Layout from "../../layouts/Layout";
import { Link, useParams, useNavigate } from "react-router-dom";
import PurchasesContext from "../../contexts/purchases/purchasesContext";
import RightButtonToggle from "../../components/RightButtonToggle";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import AddMaterial from "./edit/AddMaterial";
import EditItem from "./edit/EditMaterial";

const EditPurchase = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const providerRef = useRef();
  const {
    getEditPurchase,
    purchase,
    purchase_order_details,
    subtotal_edit,
    iva_edit,
    total_edit,
    getProviders,
    providers,
    getMaterials,
    removeMaterialToOrder,
    savePurchaseOrderEdition,
    success
  } = useContext(PurchasesContext);

  const { register, errors, handleSubmit, control, setValue } = useForm();
  const [offcanvasOn, setOffcanvasOn] = useState(false);
  const overClass = offcanvasOn ? "show" : "";
  const [selectedMaterial, setSelectedMaterial] = useState({});

  useEffect(() => {
    getEditPurchase(id);
    getProviders();
    getMaterials();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (purchase) {
      setValue("provider_id", { value: purchase.provider?.id, label: purchase.provider?.nombre_comercial }, { shouldValidate: true });
    }
    // eslint-disable-next-line
  }, [purchase]);

  useEffect(() => {
   //si success retorna verdadero redireccionara al listado de ordenes de compra
   if (success) {
     navigate("/compras");
   }
   // eslint-disable-next-line
 }, [success]);


  const optionsProviders = providers.map((provider) => {
    return { value: provider.id, label: provider.nombre_comercial };
  });

  const handleSelectChange = (event) => {
    setValue("provider_id", event, { shouldValidate: true });
  };

  const handleOffcanvas = () => setOffcanvasOn(!offcanvasOn);

  const handleEditMaterial = (material) => {
    setSelectedMaterial(material);

    setTimeout(() => {
      window.$("#editMaterialModal").modal("show");
    }, 1000);
  }

  const handleDeleteMaterial = (material) => {
      removeMaterialToOrder(material);
  };

  const onSubmit = async (data) => {
    data.purchase_order_id = id;
    savePurchaseOrderEdition(data)
  };

  return (
    <Layout>
    <div className={`bs-canvas-overlay bg-dark position-fixed w-100 h-100 ${overClass}`}></div>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Editar Compra</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/compras">Compras</Link>
                  </li>
                  <li className="breadcrumb-item active">Detalles</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="body">
                      <h5>
                        <strong>Compra </strong> #{id}
                      </h5>
                      <div className="row">
                        <div className="col-md-8 col-sm-8">
                            <label>Proveedor: </label>
                              <div className="form-group">
                                <Controller
                                  name="provider_id"
                                  control={control}
                                  defaultValue
                                  render={({ onChange, value, ref }) => (
                                    <Select
                                      ref={providerRef}
                                      value={value}
                                      placeholder="Selecciona una opción"
                                      options={optionsProviders}
                                      onChange={(e) => handleSelectChange(e)}
                                    />
                                  )}
                                  rules={{ required: "Selecciona un proveedor de la lista" }}
                                />
                                {errors?.provider_id ? (
                                  <label className="error">
                                    {errors?.provider_id?.message}
                                  </label>
                                ) : null}
                              </div>
                        </div>
                        <div className="col-md-4 col-sm-4 text-left">
                            <label>Fecha de compra: </label>
                            <div className="form-group">
                              <input
                                type="date"
                                name="order_date"
                                defaultValue={purchase?.order_date}
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "La fecha de compra es requerida",
                                  },
                                })}
                                className={
                                  errors.order_date
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                              />
                              {errors?.order_date ? (
                                <label className="error">
                                  {errors?.order_date?.message}
                                </label>
                              ) : null}
                            </div>
                        </div>
                        <div className="col-12">
                          <label>Observaciones</label>
                          <div className="form-group">
                            <textarea
                              name="commentary"
                              placeholder="Agrega aqui una observación (opcional)"
                              defaultValue={purchase?.commentary}
                              ref={register({
                                required: {
                                  value: false,
                                },
                              })}
                              className={
                                errors.commentary
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                            />
                            {errors?.commentary ? (
                              <label className="error">
                                {errors?.commentary?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                      <div className="text-right mt-2">
                        <div className="btn-group">
                          <button
                            type="button"
                            className="btn bg-light-green"
                            onClick={handleOffcanvas}
                          >
                            <i className="zmdi zmdi-plus" /> {""}
                            Agregar Materiales
                          </button>

                        </div>
                      </div>
                        <div className="table-responsive">
                          <table className="table c_table theme-color">
                            <thead>
                              <tr>
                                <th>Descripción</th>
                                <th className="text-center">Cantidad</th>
                                <th className="text-center">Unidad</th>
                                <th>Precio Unit</th>
                                <th>Importe</th>
                                <th></th>
                              </tr>
                            </thead>
                            <tbody>
                            {!purchase_order_details?.length ? (
                              <tr>
                                <td align="center" colSpan={6}>
                                  No hay productos agregados a la orden
                                </td>
                              </tr>
                            ) : (
                              purchase_order_details?.map((detail, index) => {
                                return (

                                    <tr
                                      key={index}
                                    >
                                      <td>
                                        <strong> {detail.material.name} </strong>
                                        <br />
                                        <small>{detail.description}</small>
                                      </td>
                                      <td className="text-center">
                                        <strong>{detail.quantity}</strong>
                                      </td>
                                      <td className="text-center">
                                        {detail.unidad_compra}
                                      </td>
                                      <td className="hidden-sm-down">
                                        {new Intl.NumberFormat("es-MX", {
                                          style: "currency",
                                          currency: "MXN",
                                          minimumFractionDigits: 2,
                                        }).format(detail.unit_price)}
                                      </td>
                                      <td>
                                        {new Intl.NumberFormat("es-MX", {
                                          style: "currency",
                                          currency: "MXN",
                                          minimumFractionDigits: 2,
                                        }).format(detail.subtotal)}
                                      </td>
                                      <td>
                                        <div className="btn-group">
                                          <span
                                            className="btn btn-outline-primary btn-sm"
                                            onClick={() => handleEditMaterial(detail)}
                                          >
                                            <i className="zmdi zmdi-edit" />
                                          </span>
                                          <span
                                            className="btn btn-outline-danger btn-sm"
                                            onClick={() => handleDeleteMaterial(detail)}
                                          >
                                            <i className="zmdi zmdi-delete" />
                                          </span>
                                        </div>
                                      </td>
                                    </tr>
                                );
                              })
                            )}
                            </tbody>
                          </table>
                        </div>
                      </div>
                      <div className="col-md-8">
                      </div>
                      <div className="col-md-4">
                        <div className="body">
                          <div className="table-responsive">
                            <table className="table">
                              <tbody>
                                <tr>
                                  <th className="text-left">Subtotal:</th>
                                  <td className="text-right">
                                    {new Intl.NumberFormat("es-MX", {
                                      style: "currency",
                                      currency: "MXN",
                                      minimumFractionDigits: 2,
                                    }).format(subtotal_edit)}
                                  </td>
                                </tr>
                                <tr>
                                  <th className="text-left">
                                    IVA:
                                    <span className="font-weight-normal">
                                      (16%)
                                    </span>
                                  </th>
                                  <td className="text-right">
                                    {new Intl.NumberFormat("es-MX", {
                                      style: "currency",
                                      currency: "MXN",
                                      minimumFractionDigits: 2,
                                    }).format(iva_edit)}
                                  </td>
                                </tr>
                                <tr>
                                  <th className="text-left">Total:</th>
                                  <td className="text-right text-primary">
                                    <h5 className="font-weight-semibold">
                                      {new Intl.NumberFormat("es-MX", {
                                        style: "currency",
                                        currency: "MXN",
                                        minimumFractionDigits: 2,
                                      }).format(total_edit)}
                                    </h5>
                                  </td>
                                </tr>
                              </tbody>
                            </table>
                          </div>
                          <div className="text-right mt-3">
                            <button type="submit"
                            className="btn btn-primary btn-block">
                              Guardar Orden
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddMaterial
        offcanvasOn={offcanvasOn}
        handleOffcanvas={handleOffcanvas}
      />
      <EditItem
        selectedMaterial={selectedMaterial}
        setSelectedMaterial={setSelectedMaterial}
      />
    </Layout>
  );
};

export default EditPurchase;
