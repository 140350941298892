import React, { useContext } from 'react'
import 'react-dropzone-uploader/dist/styles.css'
import Dropzone from 'react-dropzone-uploader'
import { useParams } from "react-router-dom";
import ProductsContext from '../../../contexts/products/productsContext';

const UploadImages = () => {

  const { addImagesProduct } = useContext(ProductsContext);

  const { id } = useParams();

 // receives array of files that are done uploading when submit button is clicked
 const handleSubmit = (files, allFiles) => {
   const images = files.map(f => f.file);
   const formData = new FormData();  
    for (let index = 0; index < images.length; index++) {
      formData.append('images[]', images[index]);
    }
    formData.append('product_id', id);

    addImagesProduct(formData)
   allFiles.forEach(f => f.remove())
   handleCloseModal();
 }

 const handleCloseModal = () => {
  window.$('#uploadImages').modal('hide');
}

  return (
    <div className="modal fade" id="uploadImages" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="largeModalLabel">
              Imagenes de Producto
            </h4>
          </div>
          <div className="modal-body">
            <div className="row">
              <div className="col-xl-12 col-lg-12 col-md-12">
                <Dropzone
                  onSubmit={handleSubmit}
                  inputContent="Selecciona las imagenes del producto"
                  inputWithFilesContent= 'agregar'
                  submitButtonContent='Guardar Imagenes'
                  accept="image/*"
                  maxFiles={4}
                />
              </div>
            </div>
            
          </div>
          <div className="modal-footer">
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CERRAR
                </button>
              </div>
        </div>
      </div>
    </div>
  )
}

export default UploadImages