import React, { useReducer } from "react";
import printingsReducer from "./printingsReducer";
import PrintingsContext from "./printingsContext";
import { ADD_PRINTING, EDIT_PRINTING, GET_PRINTINGS, REMOVE_PRINTING, UPDATE_PRINTING } from "../../types";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const PrintingsSatate = (props) => {
  // estado inicial
  const initialSate = {
    printings: [],
    selectedPrinting: [],
    success: false,
  };

  const [state, dispatch] = useReducer(printingsReducer, initialSate);

  //obtener los tipos de impresión
  const getPrintings = () => {
    let url = `printings`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_PRINTINGS,
        payload: response.data.data,
      });
    });
  };

  const addPrinting = (printing) => {
    let url = "/printings";
    MethodPost(url, printing)
      .then((response) => {
        dispatch({
          type: ADD_PRINTING,
        });
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR TIPO DE IMPRESIÓN A EDITAR
  const editPrinting = (printing) => {
    dispatch({
      type: EDIT_PRINTING,
      payload: printing,
    });
  };

  //Actualizar un impresión
  const updatePrinting = (data) => {
    let url = `printings/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        dispatch({
          type: UPDATE_PRINTING,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar tipo de impresión
  const removePrinting = (id) => {
    let url = `printings/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: 'Eliminado',
        text:  response.data.message,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false
    });
      dispatch({
        type: REMOVE_PRINTING,
        payload: id,
      });
    });
  };

  return (
    <PrintingsContext.Provider
      value={{
        printings: state.printings,
        success: state.success,
        selectedPrinting: state.selectedPrinting,
        getPrintings,
        addPrinting,
        editPrinting,
        removePrinting,
        updatePrinting,
      }}
    >
      {props.children}
    </PrintingsContext.Provider>
  );
};

export default PrintingsSatate;
