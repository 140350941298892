import React, { useReducer } from "react";
import MethodGet, { MethodDelete, MethodPost } from "../../services/api";
import designReducer from "./designReducer";
import DesignContext from "./designContext";
import { ADD_FILES_PRINTING, GET_FILES_PRINTING, GET_ORDERS, REMOVE_FILE_PRINTING, SHOW_ORDER } from "../../types";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import fileDownload from "js-file-download";

const DesignSatate = (props) => {
  // estado inicial
  const initialSate = {
    orders: [],
    order: [],
    details: [],
    success: false,
    current_page: null,
    total: null,
    per_page: null,
    archivos: []
  };

  const [state, dispatch] = useReducer(designReducer, initialSate);
  
  //obtener todas las ordenes
  const getOrders = (page = 1, filters = '') => {
    let url = `orders?page=${page}&order_id=${
      filters && filters.order_id ? filters.order_id : ""
    }&customer=${
      filters && filters.customer ? filters.customer : ""
    }&created_at=${filters && filters.created_at ? filters.created_at : ""}
    &order_status=${
      filters && filters.order_status ? filters.order_status : ""
    }`;
    MethodGet(url, filters).then((response) => {
      dispatch({
        type: GET_ORDERS,
        payload: response.data.data,
      });
    });
  };


  //obtener detalle de la orden
  const getOrder = (order) => {
    let url = `orders/${order}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: SHOW_ORDER,
        payload: response.data,
      });
    });
  };

  // cargar archivos para impresion
  const uploadFilesPrinting = (data) => {
  let url = "/filesPrinting";
  MethodPost(url, data, {headers:{ 'Content-Type' : 'multipart/form-data'} })
    .then((response) => {
      dispatch({
        type: ADD_FILES_PRINTING,
      });
        toast.success(response.data.message, { theme: "colored" });
    })
    .catch((error) => {
      console.log(error);
    });
  };  

  //obtener los archivos de impresion
  const getFilesPrinting = (detail) => {
    let url = `get-files?detail_id=${detail}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_FILES_PRINTING,
        payload: response.data.data,
      });
    });
  };


    //descargar archivo de impresion
    const donwloadFile = (file) => {
      let url = `/filesPrinting/${file.id}`;
  
      Swal.fire({
        title: "Descargando...",
        html: "Esto puede demorar algunos segundos.",
        allowEscapeKey: false,
        allowOutsideClick:false,
        didOpen: () => {
          Swal.showLoading();
          MethodGet(url, { responseType: "blob" })
            .then((response) => {
              fileDownload(response.data, file.file_name);
              Swal.close();
            })
            .catch((error) => {
              console.log(error);
              Swal.close();
            });
        },
      });
    };


    //Eliminar archivo
    const removeFile = (id) => {
      let url = `filesPrinting/${id}`;
      MethodDelete(url).then((response) => {
        Swal.fire({
          title: 'Eliminado',
          text:  response.data.message,
          icon: 'success',
          timer: 3000,
          showConfirmButton: false
      });
        dispatch({
          type: REMOVE_FILE_PRINTING,
          payload: id,
        });
      });
    };


  return (
    <DesignContext.Provider
      value={{
        orders: state.orders,
        order: state.order,
        details: state.details,
        success: state.success,
        successOrder: state.successOrder,
        current_page: state.current_page,
        total: state.total,
        per_page: state.per_page,
        archivos: state.archivos,
        getOrders,
        getOrder,
        uploadFilesPrinting,
        getFilesPrinting,
        donwloadFile,
        removeFile
      }}
    >
      {props.children}
    </DesignContext.Provider>
  );
};

export default DesignSatate;
