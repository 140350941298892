import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ProductsContext from "../../../contexts/products/productsContext";

const AddMaterial = () => {
  const { materials, addMaterialProduct, materialProduct } = useContext(ProductsContext);
  const { errors, handleSubmit, reset, control } = useForm();

  const { id } = useParams();

  const optionsMaterial = materials.map((opmaterial) => {
    return { value: opmaterial.id, label: opmaterial.name };
  });

  const handleCloseModal = () => {
    window.$("#addMaterial").modal("hide");
  };

  const onSubmit = (data) => {
    data.product_id = id;
    addMaterialProduct(data);
    reset({});
    handleCloseModal();
  };
  return (
    <div className="modal fade" id="addMaterial" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="largeModalLabel">
              Agregar Material
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Material</label>
                    <Controller
                      name="material_id"
                      control={control}
                      placeholder="Selecciona una opción"
                      defaultValue=""
                      options={optionsMaterial}
                      isOptionDisabled={(option) =>
                        materialProduct.find(
                          (element) => element.material_id === option.value
                        )
                      }
                      as={<Select />}
                      rules={{
                        required: "Selecciona un material de la lista",
                      }}
                    />
                    {errors?.material_id ? (
                      <label className="error">
                        {errors?.material_id?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-danger btn-round waves-effect"
              >
                AGREGAR
              </button>
              <button
                type="button"
                className="btn btn-default btn-round waves-effect"
                onClick={handleCloseModal}
              >
                CANCELAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddMaterial;
