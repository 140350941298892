import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import ProductsContext from "../../../contexts/products/productsContext";

const ProductPrice = () => {
  const {
    product,
    sizesProduct,
    materialProduct,
    printingProduct,
    addPriceProduct,
  } = useContext(ProductsContext);
  const { register, errors, handleSubmit, reset, control } = useForm();

  const { id } = useParams();

  const optionsSize = sizesProduct.map((opsize) => {
    return { value: opsize.size.id, label: opsize.size.name };
  });

  const optionsMaterial = materialProduct.map((opmaterial) => {
    return { value: opmaterial.material.id, label: opmaterial.material.name };
  });

  const optionsPrinting = printingProduct.map((print) => {
    return { value: print.printing.id, label: print.printing.name };
  });


  const handleCloseModal = () => {
    window.$("#productPrice").modal("hide");
  };

  const onSubmit = (data) => {
    data.product_id = id;
    addPriceProduct(data);
    reset({});
    handleCloseModal();
  };
  return (
    <div className="modal fade" id="productPrice" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="largeModalLabel">
              Agregar Precio
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Tamaño</label>
                    <Controller
                      name="size_id"
                      control={control}
                      placeholder="Selecciona una opción"
                      defaultValue=""
                      options={optionsSize}
                      as={<Select />}
                      rules={{
                        required: "Selecciona un tamaño de la lista",
                      }}
                    />
                    {errors?.size_id ? (
                      <label className="error">
                        {errors?.size_id?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Material</label>
                    <Controller
                      name="material_id"
                      control={control}
                      placeholder="Selecciona una opción"
                      defaultValue=""
                      options={optionsMaterial}
                      as={<Select />}
                      rules={{
                        required: "Selecciona un material de la lista",
                      }}
                    />
                    {errors?.material_id ? (
                      <label className="error">
                        {errors?.material_id?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Impresión</label>
                    <Controller
                      name="printing_id"
                      control={control}
                      placeholder="Selecciona una opción"
                      defaultValue=""
                      options={optionsPrinting}
                      as={<Select />}
                      rules={{
                        required: "Selecciona un tipo de impresión de la lista",
                      }}
                    />
                     {errors?.printing_id ? (
                      <label className="error">
                        {errors?.printing_id?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                {product && product.product_type === "offset" ? (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Cantidad</label>
                      <select
                      className={
                        errors.quantity
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      name="quantity"
                      ref={register({
                        required: "La cantidad es requerida.",
                      })}
                    >
                       {product && product.units.length ?
                       product.units.map((unit, index) => {
                            return <option key={index} value={unit}>{unit}</option>;
                        }) : null
                        }
                    </select>
                      {errors?.quantity ? (
                        <label className="error">
                          {errors?.quantity?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                ) : (
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Cantidad</label>
                      <input
                        name="quantity"
                        type="number"
                        min={1}
                        defaultValue={1}
                        readOnly
                        ref={register({
                          required: {
                            value: true,
                            message: "El campo Cantidad es requerido",
                          },
                          minLength: {
                            value: 1,
                            message: "El campo Cantidad debe ser minimo 1",
                          },
                        })}
                        className={
                          errors.quantity
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Cantidad *"
                      />
                      {errors?.quantity ? (
                        <label className="error">
                          {errors?.quantity?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                )}
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Precio</label>
                    <input
                      name="price"
                      type="text"
                      ref={register({
                        required: {
                          value: true,
                          message: "El campo precio es requerido",
                        },
                        pattern: {
                          value:
                            /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                          message:
                            "El campo precio no puede ser un valor negativo",
                        },
                      })}
                      className={
                        errors.price
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="Precio *"
                    />
                    {errors?.price ? (
                      <label className="error">{errors?.price?.message}</label>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-danger btn-round waves-effect"
              >
                AGREGAR
              </button>
              <button
                type="button"
                className="btn btn-default btn-round waves-effect"
                onClick={handleCloseModal}
              >
                CANCELAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default ProductPrice;
