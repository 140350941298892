import React from "react";

const Header = () => {
  return (
    <>
      <div>
        {/* Overlay For Sidebars */}
        <div className="overlay" />
        {/* Main Search */}
        <div id="search">
          <button
            id="close"
            type="button"
            className="close btn btn-primary btn-icon btn-icon-mini btn-round"
          >
            x
          </button>
          <form>
            <input type="search" defaultValue placeholder="Search..." />
            <button type="submit" className="btn btn-primary">
              Search
            </button>
          </form>
        </div>
      </div>
    </>
  );
};

export default Header;
