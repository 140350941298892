/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";
import PurchasesContext from "../../contexts/purchases/purchasesContext";
import Moment from "react-moment";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";
import DataTable from "react-data-table-component";

const PurchasesList = () => {
  const listPurchases = useContext(PurchasesContext);
  const {
    purchases,
    getPurchases,
    success,
    removePurchase,
    addPayment,
    updateStatus
  } = listPurchases;

  useEffect(() => {
    getPurchases();
    // eslint-disable-next-line
  }, [success]);

  const [filterText, setFilterText] = useState("");
  const filteredItems = purchases.filter(
    (item) =>
      item.provider?.nombre_comercial.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        backgroundColor: "#5cc5cd",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      width: "80px",
    },
    {
      name: "Fecha",
      selector: (row) =>
      <Moment format="DD/MM/YYYY">
        {row.order_date}
      </Moment>,
      sortable: true,
      sortFunction: (a, b) => a.order_date.localeCompare(b.order_date),
      width: "120px",
    },
    {
      name: "Proveedor",
      selector: (row) => row.provider?.nombre_comercial,
      sortable: true,
    },
    {
      name: "Importe",
      selector: (row) =>
        new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
          minimumFractionDigits: 2,
        }).format(row.total),
      sortable: true,
      width: "150px",
    },
    {
      name: "Pago",
      // si activo es true colocar en una etiqueta badge activo si es falso colocar Inactivo
      selector: (row) =>
      row.order_status !== "cancelada" ? (
        row.paid_status === "pendiente" ? (
          <span className="badge bg-orange">Pendiente</span>
        ) : row.paid_status === "pago incompleto" ? (
          <span className="badge bg-amber">Pago incompleto</span>
        ): row.paid_status === "pagado" ? (
          <span className="badge bg-green">Pagado</span>
        ) : null) : '--',
      sortable: true,
      sortFunction: (a, b) => a.activo - b.activo,
      width: "130px",
    },
    {
      name: "Estatus",
      // si activo es true colocar en una etiqueta badge activo si es falso colocar Inactivo
      selector: (row) =>
        row.order_status === "solicitada" ? (
          <span className="badge bg-light-green">Solicitada</span>
        ) : row.order_status === "en proceso" ? (
          <span className="badge bg-teal">En proceso</span>
        ): row.order_status === "completada" ? (
          <span className="badge bg-light-blue">Completada</span>
        ) : row.order_status === "cancelada" ? (
          <span className="badge bg-red">Cancelada</span>
        ): null,
      sortable: true,
      sortFunction: (a, b) => a.activo - b.activo,
      width: "120px",
    },
    // botones de acciones
    {
      name: "Acciones",
      selector: (row) => row.id,
      sortable: false,
      cell: (row) => (
        row.order_status !== "cancelada" ? (
        <div className="btn-group">
          <button
            className="btn btn-outline-secondary btn-sm dropdown-toggle"
            type="button"
            data-toggle="dropdown"
            aria-haspopup="true"
            aria-expanded="false"
          >
            Acciones
          </button>
          <div className="dropdown-menu">
            <Link
              to={`/compras/detail/${row.id}`}
              className="dropdown-item"
            >
              <i className="ti-eye" /> Ver detalles
            </Link>
            {row.paid_status === "pendiente" && row.order_status === "solicitada"  ? (
              <Link
                to={`/compras/edit/${row.id}`}
                className="dropdown-item"
              >
                <i className="ti-pencil" />{" "}
                Editar
              </Link>
            ) : null}
            <button
              className="dropdown-item"
              onClick={() =>
                handleChangeStatus(row.id)
              }
            >
              <i className="ti-reload" />{" "}
              Estatus
            </button>
            {row.paid_status !== "pagado" ? (
              <button
                className="dropdown-item"
                onClick={() =>
                  handleAddPayment(row)
                }
              >
                <i className="ti-money" /> Agregar
                pago
              </button>
            ) : null}
            {row.paid_status === "pendiente" || row.estatus === "solicitada"  ? (
              <button
                className="dropdown-item"
                onClick={() =>
                  showConfirmMessage(row.id)
                }
              >
                <i className="zmdi zmdi-delete"></i> Eliminar orden
              </button>
            ) : null}

          </div>
        </div>
        ) : null
      ),
      width: "150px",
    },
  ];


  const handleAddPayment = (purchase) => {
    let balance = parseFloat(purchase.total) - parseFloat(purchase.total_payments);
    Swal.fire({
      title: "Agregar pago",
      html: `
        <div class="row text-left">
          <div class="col-12">
            <p class="font-weight-bold text-center"> Saldo restante  ${new Intl.NumberFormat("es-MX",
                {
                  style: "currency",
                  currency: "MXN",
                  minimumFractionDigits: 2,
                }
              ).format(balance)}
            </p>
          </div>
          <div class="col-6 form-group">
            <label>Fecha de pago</label>
            <input id="swal-input1" type="date" class="form-control">
          </div>
          <div class="col-6 form-group">
            <label>Monto</label>
            <input id="swal-input2" type="number" min="0.1" class="form-control" placeholder="$100">
          </div>
          <div class="col-6 form-group">
            <label>Método de pago</label>
            <select id="swal-input3" class="form-control">
              <option value="">Selecciona uno</option>
              <option value="EFECTIVO">EFECTIVO</option>
              <option value="TRANSFERENCIA">TRANSFERENCIA</option>
              <option value="TARJETA">TARJETA</option>
              <option value="OTRO">OTRO</option>
            </select>
          </div>
          <div class="col-6 form-group">
            <label>Folio Fiscal</label>
            <input id="swal-input4" type="text" class="form-control" placeholder="Folio factura">
          </div>
          <div class="col-12 form-group">
            <label>Observaciones</label>
            <textarea class="form-control" id="swal-input5" placeholder="Agrega alguna observación (opcional)"></textarea>
          </div>
        </div>
        `,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Guardar Pago",
      cancelButtonText: "Cancelar",
      showCancelButton: true,
      preConfirm: () => {
        let payment_date = document.getElementById("swal-input1").value;
        let amount = document.getElementById("swal-input2").value;
        let payment_method = document.getElementById("swal-input3").value;
        let invoice_folio = document.getElementById("swal-input4").value;
        let notes = document.getElementById("swal-input5").value;

        if (payment_date !== "" && amount !== "" && payment_method !== "") {
          if (Number(parseFloat(amount)) > balance.toFixed(2)) {
            Swal.showValidationMessage(
              "El monto no puede ser mayor al saldo restante."
            );
          } else {
            addPayment({
              purchase_order_id: purchase.id,
              payment_date: payment_date,
              amount: Number(amount),
              payment_method: payment_method,
              invoice_folio: invoice_folio,
              notes: notes,
            });
          }
        } else {
          Swal.showValidationMessage("Completa todos los campos"); // Show error when validation fails.
        }
      },
    });
  };

  const handleChangeStatus = (purchase) => {
    Swal.fire({
      title: "Actualizar Estatus",
      input: "select",
      inputOptions: {
        'en proceso': "En Proceso",
        'completada': "Completada",
        'cancelada': "Cancelada",
      },
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Actualizar",
      cancelButtonText: "Cancelar",
      inputPlaceholder: "Selecciona un status",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            updateStatus(purchase, {status: value});
            resolve();
          } else {
            resolve("Debes seleccionar una opción");
          }
        });
      },
    });
  };


  const showConfirmMessage = (purchase) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: `Eliminar la orden de compra.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        removePurchase(purchase);
      }
    });
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Compras de Material </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Compras</li>
                  <li className="breadcrumb-item active">lista</li>
                </ul>
                <ButtonMobileMenu/>
                <div className="chat_window">
                  <Link to="/compra-add">
                    <button
                        className="list_btn btn btn-danger btn-round float-md-right"
                    >
                        <i className="ti-plus" />
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
                <Link to="/compra-add">
                    <button
                    className="btn btn-danger float-right"
                    >
                    <i className="zmdi zmdi-plus" /> Nueva compra
                    </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                <div className="body">
                  <div className="input-box">
                    <i className="ti-search" />
                    <input
                      type="text"
                      placeholder="Buscar aqui..."
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <button className="button" onClick={handleClear}>
                      X
                    </button>
                  </div>
                  <DataTable
                    customStyles={tableCustomStyles}
                    columns={columns}
                    data={filteredItems}
                    noDataComponent="No se encontraron registros"
                    pagination
                  />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default PurchasesList;
