/* eslint-disable jsx-a11y/anchor-is-valid */
import React from 'react'
import { Link } from "react-router-dom";

const SidebarProduction = () => {
  return (
    <>
        <Link to="/">
          <li className="active open">
            <i className="ti-package"></i>
            <span>Ordenes</span>
          </li>
        </Link>
    </>
  )
}

export default SidebarProduction