import React, { useContext, useState } from 'react'
import { useForm } from "react-hook-form";
import UsersContext from "../../contexts/users/usersContext";
import Avatar from '../../assets/images/avatar.jpg'

const AddUser = () => {

  const { addUser } = useContext(UsersContext);

  const { register, errors, handleSubmit, reset } = useForm();

  const handleCloseModal = () => {
    window.$('#addUser').modal('hide');
  }

  const [photo, setPhoto] = useState({
    urlPhoto: Avatar,
    image: ''
  });

   //funcion para guardar la imagen y obtener la vista previa
   const handleCangePhoto = (e) => {
    setPhoto({ ...photo, urlPhoto: URL.createObjectURL(e.target.files[0]), 
                image: e.target.files[0]
            });
  }

  const onSubmit = (data) => {
    data.profile_picture = photo.image;
    addUser(data);
    reset();
    setPhoto({  urlPhoto: Avatar, image: ''});
    handleCloseModal();
  }

  return (
    <>
      <div className="modal fade" id="addUser" tabIndex={-1} role="dialog">
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title" id="defaultModalLabel">
                Agregar Usuario
              </h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-6">
                    <div className="form-group">
                        <label>Nombre</label>
                      <input
                        name="name"
                        ref={register({
                          required: {
                            value: true,
                            message: "El nombre de usuario es requerido",
                          },
                        })}
                        className={
                          errors.name
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Nombre *"
                      />
                      {errors?.name ? (
                        <label className="error">{errors?.name?.message}</label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Teléfono</label>
                      <input
                        name="phone"
                        ref={register({
                          required: {
                            value: false,
                            message: "El Teléfono es requerido",
                          },
                          maxLength: {
                            value: 10,
                            message: "Maximo 10 digitos",
                          },
                          minLength: {
                            value: 10,
                            message: "Minimo 10 digitos",
                          },
                          pattern: {
                            value: /^[0-9\b]+$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.phone
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Teléfono (opcional)"
                      />
                      {errors?.phone ? (
                        <label className="error">
                          {errors?.phone?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                        <label>Email</label>
                      <input
                        name="email"
                        ref={register({ required: {
                            value: true,
                            message: "El Email es requerido",
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Dirección de correo electrónico no válida",
                          }, })}
                        className={
                          errors.email
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Email *"
                      />
                      {errors?.email ? (
                        <label className="error">
                          {errors?.email?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                        <label>Contraseña</label>
                      <input
                        name="password"
                        ref={register({ required: {
                            value: true,
                            message: "La contraseña es requerida",
                          },
                          minLength: {
                            value: 6,
                            message: 'Minimo 6 caracteres'
                        },
                        })}
                        className={
                          errors.password
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Contraseña *"
                      />
                      {errors?.password ? (
                        <label className="error">
                          {errors?.password?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                        <label>Imagen de Perfil</label>
                      <input
                        name="image"
                        accept="image/png, image/jpg, image/jpeg" 
                        onChange={handleCangePhoto}
                        type="file"
                        className="form-control"
                      />
                    </div>
                    <img src={photo.urlPhoto} alt="User_profile" className='rounded-circle img-raised' width={200} />
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Rol de usuario</label>
                     <select name="user_role"
                     ref={register({ required: {
                      value: true,
                      message: "El rol de usuario es requerido",
                    },
                  })}
                  className={
                    errors.user_role
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                     >
                        <option value="">Selecciona una opción</option>
                        <option value={1}>Administrador(a)</option>
                        <option value={2}>Vendedor(a)</option>
                        <option value={3}>Producción</option>
                        <option value={4}>Diseño</option>
                     </select>
                      {errors?.user_role ? (
                        <label className="error">
                          {errors?.user_role?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  GUARDAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  )
}

export default AddUser