import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";
import AddSlide from "./AddSlide";
import EditSlide from "./EditSlide";
import DefaultImage from "../../assets/images/default_image.png";
import CarouselContext from "../../contexts/carousel/carouselContext";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const Slides = () => {
  const listCarousel = useContext(CarouselContext);
  const {
    slides,
    getSlides,
    success,
    current_page,
    total,
    per_page,
    editSlide,
    removeSlide,
  } = listCarousel;

  useEffect(() => {
    getSlides();
    // eslint-disable-next-line
  }, [success]);

  const mainslides = slides.filter((slide) => slide.id >= 3);

  const rigthlides = slides.filter((slide) => slide.id <= 2);

  const handleChangePage = (PageNumber) => {
    getSlides(PageNumber);
  };

  const showConfirmMessage = (slide) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Un slide eliminado no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeSlide(slide);
      }
    });
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Carrousel </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Carrusel</li>
                  <li className="breadcrumb-item active">Slides</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
               <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container">
            <div className="row">
              <div className="col-12 pb-5">
                <section className="row">
                  <div className="col-12 col-md-8 mb-4">
                    <div
                      id="demo3"
                      className="carousel slide"
                      data-ride="carousel"
                    >
                      {/* Indicators */}
                      <ul className="carousel-indicators">
                        {mainslides.length ? (
                          mainslides.map((mainslide, index) => (
                            <li
                              key={mainslide.id}
                              data-target="#demo3"
                              data-slide-to={index}
                            />
                          ))
                        ) : (
                          <li data-target="#demo3" data-slide-to={0} />
                        )}
                      </ul>
                      {/* Wrapper for slides */}
                      <div className="carousel-inner">
                        {mainslides.length ? (
                          mainslides.map((mainslide, index) => (
                            <div
                              className={
                                index === 0
                                  ? "carousel-item active"
                                  : "carousel-item"
                              }
                              key={index}
                            >
                              <img
                                src={mainslide.image_url}
                                className="img-fluid"
                                alt="img"
                              />
                            </div>
                          ))
                        ) : (
                          <div className="carousel-item active">
                            <img
                              src={DefaultImage}
                              className="img-fluid"
                              alt="img"
                            />
                          </div>
                        )}
                      </div>
                      {/* Controls */}
                      <a
                        className="carousel-control-prev"
                        href="#demo3"
                        data-slide="prev"
                      >
                        <span className="carousel-control-prev-icon" />
                      </a>
                      <a
                        className="carousel-control-next"
                        href="#demo3"
                        data-slide="next"
                      >
                        <span className="carousel-control-next-icon" />
                      </a>
                    </div>
                    <div className="row clearfix">
                      <div className="col-sm-12">
                        <div className="card">
                          <div className="header">
                            <h2>
                              Lista de <strong>Slides</strong>
                            </h2>
                            <ul className="header-dropdown">
                              <li>
                                <button
                                  className="btn btn-primary btn-sm"
                                  data-toggle="modal"
                                  data-target="#addSlide"
                                >
                                  + agregar
                                </button>
                              </li>
                            </ul>
                          </div>
                          <div className="table-responsive social_media_table">
                            <table className="table table-hover c_table">
                              <thead>
                                <tr>
                                  <th>Media</th>
                                  <th>Descripción</th>
                                  <th>URL de destino</th>
                                  <th>Acciones</th>
                                </tr>
                              </thead>
                              <tbody>
                                {!mainslides.length ? (
                                  <tr>
                                    <td align="center" colSpan={4}>
                                      No se encontraron slides
                                    </td>
                                  </tr>
                                ) : (
                                  mainslides.map((mainslide) => (
                                    <tr key={mainslide.id}>
                                      <td className="w70">
                                        <img
                                          className="w70"
                                          src={mainslide.image_url}
                                          alt="img"
                                        />
                                      </td>
                                      <td>
                                        <span className="text-muted">
                                          {mainslide.description}
                                        </span>
                                      </td>
                                      <td>
                                        <small className="text-muted">
                                          https://www.pbkimpresos.com/
                                          {mainslide.destination_url}
                                        </small>
                                      </td>
                                      <td>
                                        <button
                                          className="btn btn-primary btn-sm"
                                          data-toggle="modal"
                                          data-target="#editSlide"
                                          onClick={() =>
                                            editSlide(mainslide.id)
                                          }
                                        >
                                          <i className="zmdi zmdi-edit"></i>
                                        </button>
                                        <button
                                          className="btn btn-danger btn-sm"
                                          onClick={() =>
                                            showConfirmMessage(mainslide.id)
                                          }
                                        >
                                          <i className="zmdi zmdi-delete"></i>
                                        </button>
                                      </td>
                                    </tr>
                                  ))
                                )}
                              </tbody>
                            </table>
                          </div>
                        </div>
                        {total ? (
                          <div className="pagination pagination-danger justify-content-center m-b-0">
                            <Pagination
                              activePage={current_page}
                              totalItemsCount={total}
                              itemsCountPerPage={per_page}
                              onChange={(pageNumber) =>
                                handleChangePage(pageNumber)
                              }
                              itemClass="page-item"
                              linkClass="page-link"
                            />
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-md-4">
                    <div className="row">
                      {rigthlides.length
                        ? rigthlides.map((rigthlide) => (
                            <div
                              className="col-12 file_manager"
                              key={rigthlide.id}
                            >
                              <div className="card">
                                <div className="file">
                                  <div className="hover">
                                    <button
                                      type="button"
                                      className="btn btn-icon btn-icon-mini btn-round btn-danger"
                                      data-toggle="modal"
                                      data-target="#editSlide"
                                      onClick={() => editSlide(rigthlide.id)}
                                    >
                                      <i className="zmdi zmdi-edit" />
                                    </button>
                                  </div>
                                  <div className="image">
                                    <img
                                      src={rigthlide.image_url}
                                      alt="img"
                                      height={210}
                                      width="100%"
                                    />
                                  </div>
                                </div>
                              </div>
                            </div>
                          ))
                        : null}
                    </div>
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddSlide />
      <EditSlide />
    </Layout>
  );
};

export default Slides;
