import React, { useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import MaterialsContext from "../../../contexts/materials/materialsContext";

const AddMaterial = () => {
  const { addMaterial, measurements, getMeasurements, } = useContext(MaterialsContext);
  const { register, errors, handleSubmit, reset, control } = useForm();

  useEffect(() => {
    getMeasurements();
    // eslint-disable-next-line
  }, []);

  //funcion que se encarga de listar las unidades de medida en el select
  const optionsMeasurements = measurements.map((measurement) => {
    return {
      value: measurement.id,
      label: `${measurement.name} (${measurement.acronym}) `,
    };
  });


  const handleCloseModal = () => {
    window.$("#addMaterial").modal("hide");
  };

  const onSubmit = (data) => {
    addMaterial({ ...data,
      sale_unit : parseInt(data.sale_unit.value),
     });
    reset();
  };
  return (
    <div className="modal fade" id="addMaterial" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="largeModalLabel">
              Agregar Material
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Nombre del Material</label>
                    <input
                      name="name"
                      autoComplete="off"
                      ref={register({
                        required: {
                          value: true,
                          message:
                            "El nombre del Material es requerido",
                        },
                      })}
                      className={
                        errors.name
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                    />
                    {errors?.name ? (
                      <label className="error">
                        {errors?.name?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Descripción (opcional)</label>
                    <input
                      name="description"
                      autoComplete="off"
                      ref={register({
                        required: {
                          value: false,
                          message:
                          "La descripción es opcional.",

                        },
                      })}
                      className={
                        errors.description
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                    />
                    {errors?.description ? (
                      <label className="error">
                        {errors?.description?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Unidad de venta</label>
                    <Controller
                      name="sale_unit"
                      control={control}
                      placeholder="Selecciona una unidad"
                      defaultValue=""
                      options={optionsMeasurements}
                      as={<Select />}
                      rules={{
                        required: "La unidad de medida es un campo requerido",
                      }}
                    />
                    {errors?.sale_unit ? (
                      <label className="error">
                        {errors?.sale_unit?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Control de inventario</label>
                    <div className="custom-control custom-switch">
                      <input
                        name="is_inventory"
                        type="checkbox"
                        className="custom-control-input"
                        id="customSwitch2"
                        ref={register()}
                      />
                      <label
                        className="custom-control-label"
                        htmlFor="customSwitch2"
                      >
                      </label>
                    </div>
                    {errors?.is_inventory ? (
                      <label className="error">
                        {errors?.is_inventory?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-danger btn-round waves-effect"
              >
                AGREGAR
              </button>
              <button
                type="button"
                className="btn btn-default btn-round waves-effect"
                onClick={handleCloseModal}
              >
                CANCELAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default AddMaterial;
