/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import FamiliesContext from '../../contexts/families/familiesContext';
import AddFamily from './AddFamily';
import Swal from 'sweetalert2'
import { EditFamily } from './EditFamily';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/Layout';
import ButtonMobileMenu from '../../components/ButtonMobileMenu';
import RightButtonToggle from '../../components/RightButtonToggle';
import DataTable from "react-data-table-component";

const FamiliesList = () => {

  const listFamilies = useContext(FamiliesContext);
  const { families, getFamilies, success, editFamily, removeFamily } = listFamilies;

  useEffect(() => {
    getFamilies();
    // eslint-disable-next-line
  }, [success])

    const [filterText, setFilterText] = useState("");
    const filteredItems = families.filter(
      (item) =>
        item.name
          .toLowerCase()
          .includes(filterText.toLowerCase())
    );

    const handleClear = () => {
      if (filterText) {
        setFilterText("");
      }
    };

    const tableCustomStyles = {
      headCells: {
        style: {
          fontSize: "12px",
          backgroundColor: "#5cc5cd",
          color: "#fff",
          fontWeight: "bold",
          textTransform: "uppercase",
        },
      },
    };

    const columns = [
      {
        name: "#",
        selector: (row) => row.id,
        sortable: true,
        width: "80px",
      },
      {
        name: "Familia",
        selector: (row) => row.name,
        sortable: true,
        wrap: true,
        width: "350px",
      },
      {
        name: "Estatus",
        selector: (row) => row.published === 1 ? (
          <span className="badge bg-teal">Online</span>
        ) : row.published === 2 ? (
          <span className="badge bg-orange">Local</span>
        ) : null,
        sortable: true,
        sortFunction: (a, b) => a.published - b.published,
      },
      {
        name: "Acciones",
        selector: (row) => row.id,
        sortable: false,
        cell: (row) => (
          <div className="d-flex justify-content-center">
            <button
              className="btn btn-primary btn-sm"
              data-toggle="modal"
              data-target="#editFamily"
              onClick={() => editFamily(row.id)}
            >
              <i className="zmdi zmdi-edit"></i>
            </button>
            <button
              className="btn btn-danger btn-sm"
              onClick={() => showConfirmMessage(row.id)}
            >
              <i className="zmdi zmdi-delete"></i>
            </button>
          </div>
        ),
        width: "150px",
      },
    ];

  const showConfirmMessage = (family) => {
    Swal.fire({
      title: '¿Estas seguro?',
      text: "Una Familia no se puede Recuperar",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      cancelButtonText: 'No, cancelar!',
    }).then((result) => {
      if (result.isConfirmed) {
        removeFamily(family)
      }
    })
  }



  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Familias</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Familias</li>
                  <li className="breadcrumb-item active">lista</li>
                </ul>
                <ButtonMobileMenu/>
                <div className="chat_window">
                  <button
                    className="list_btn btn btn-danger btn-round float-md-right"
                    data-toggle="modal"
                    data-target="#addFamily"
                  >
                    <i className="ti-plus" />
                  </button>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
               <RightButtonToggle/>
                <button
                  className="btn btn-danger float-right"
                  data-toggle="modal"
                  data-target="#addFamily"
                >
                  <i className="zmdi zmdi-plus" /> Nueva
                </button>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="input-box">
                      <i className="ti-search" />
                      <input
                        type="text"
                        placeholder="Buscar aqui..."
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                      <button className="button" onClick={handleClear}>
                        X
                      </button>
                    </div>
                    <DataTable
                      customStyles={tableCustomStyles}
                      columns={columns}
                      data={filteredItems}
                      noDataComponent="No se encontraron registros"
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddFamily />
      <EditFamily />
    </Layout>
  );
};

export default FamiliesList;
