import React from "react";

const checkboxStatusOrder = [
    {
      value: 1,
      name: "Pendiente",
    },
    {
        value: 2,
      name: "Aprobada",
    },
    {
        value: 3,
      name: "En Producción",
    },
    {
        value: 4,
      name: "Pedido Listo",
    },
    {
        value: 5,
      name: "Completada",
    },
  ];

  const checkboxStatusPago = [
    {
      value: 1,
      name: "Pago Pendiente",
    },
    {
        value: 2,
      name: "Pago Incompleto",
    },
    {
        value: 3,
      name: "Pagado",
    }
  ];

const SearchFilter = ({ filterOn, handleOpenFilters, setDataFilters, data, onSubmit }) => {
  const filtersClass = filterOn ? "right-sidebar open" : "right-sidebar";

  const handleChange = (e) =>{
    setDataFilters({
      ...data,
      [e.target.name] : e.target.value
     });
   }

  return (
    <>
      {/* Search Filters */}
      <aside id="rightsidebar" className={filtersClass}>
        <ul className="nav nav-tabs sm">
          <li className="nav-item">
            <p className="text-white text-center">FILTROS DE BUSQUEDA</p>
          </li>
        </ul>
        <div className="tab-content">
          <div className="tab-pane active" id="filters">
            <div className="slim_scroll">
              <div className="card">
                <form onSubmit={onSubmit}>
                  <h6>ID ORDEN</h6>
                  <div className="form-group">
                    <input
                      type="text"
                      name="order_id"
                      placeholder="123"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <h6>CLIENTE</h6>
                  <div className="form-group">
                    <input
                      type="text"
                      name="customer"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <h6>FECHA EMISION</h6>
                  <div className="form-group">
                    <input
                      type="date"
                      name="created_at"
                      className="form-control"
                      onChange={handleChange}
                    />
                  </div>
                  <h6>Status Orden</h6>
                  <div className="form-group">
                    <select
                      name="order_status"
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option value="">Todos</option>

                      {checkboxStatusOrder.map((statusOrder) => (
                        <option
                          key={statusOrder.value}
                          value={statusOrder.value}
                        >
                          {" "}
                          {statusOrder.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  <h6>Status Pago</h6>
                  <div className="form-group">
                    <select
                      name="payment_status"
                      className="form-control"
                      onChange={handleChange}
                    >
                      <option value="">Todos</option>

                      {checkboxStatusPago.map((statusPago) => (
                        <option key={statusPago.value} value={statusPago.value}>
                          {" "}
                          {statusPago.name}
                        </option>
                      ))}
                    </select>
                  </div>
                  {/* <h6>Status Orden</h6>
                <ul className="setting-list list-unstyled">
                  {checkboxStatusOrder.map((statusOrder) => (
                    <li key={statusOrder.value}>
                      <div className="checkbox">
                        <input
                          id={`statusOrder${statusOrder.value}`}
                          type="checkbox"
                          name="status_order"
                          value={statusOrder.value}
                          onChange={handleChange}
                        />
                        <label htmlFor={`statusOrder${statusOrder.value}`}>
                          {statusOrder.name}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul>
                <h6>Status Pago</h6>
                <ul className="setting-list list-unstyled">
                {checkboxStatusPago.map((statusPago) => (
                    <li key={statusPago.value}>
                      <div className="checkbox">
                        <input
                          id={`statusPago${statusPago.value}`}
                          type="checkbox"
                          value={statusPago.value}
                        />
                        <label htmlFor={`statusPago${statusPago.value}`}>
                          {statusPago.name}
                        </label>
                      </div>
                    </li>
                  ))}
                </ul> */}
                  <div className="form-group">
                    <button
                      className="btn btn-default "
                      type="button"
                      onClick={handleOpenFilters}
                    >
                      Cerrar
                    </button>
                    <button className="btn btn-primary " type="submit">
                      Aplicar filtros
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </aside>
    </>
  );
};

export default SearchFilter;
