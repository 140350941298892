import React from "react";
import Header from "./Header";
import Leftsidebar from "./Leftsidebar";
import Rightsidebar from "./Rightsidebar";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import IconPBK from "../assets/images/icon.png"
import PwaInstallPopupIOS from 'react-pwa-install-ios';

const Layout = ({ children }) => {

  return (
    <>
      <Header />
      <Leftsidebar />
      <Rightsidebar />
      <ToastContainer />
      <>{children}</>
      <PwaInstallPopupIOS
        delay={3}
        lang="es"
        appIcon={IconPBK}
        appName="PBK Ecommerce"
      ></PwaInstallPopupIOS>
    </>
  );
};

export default Layout;
