import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import CustomersContext from "../../contexts/customers/customersContext";
import Swal from "sweetalert2";
import Layout from "../../layouts/Layout";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";
import Moment from "react-moment";
import DataTable from "react-data-table-component";

const CustomersList = () => {
  const { customers, getCustomers, removeCustomer, success } =
    useContext(CustomersContext);

  useEffect(() => {
    getCustomers();
    // eslint-disable-next-line
  }, [success]);

  const [filterText, setFilterText] = useState("");
  const filteredItems = customers.filter(
    (item) =>
      item.full_name.toLowerCase().includes(filterText.toLowerCase()) ||
      item.email.toLowerCase().includes(filterText.toLowerCase()) ||
      item.phone.toLowerCase().includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        backgroundColor: "#5cc5cd",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      width: "80px",
    },
    {
      name: "Cliente",
      selector: (row) => row.full_name,
      sortable: true,
      width: "400px",
      wrap: true
    },
    {
      name: "Teléfono",
      selector: (row) => row.phone,
      sortable: true,
      width: "150px",
    },
    {
      name: "Email",
      selector: (row) => row.email,
      sortable: true,
      width: "300px",
      wrap: true
    },
    {
      name: "Referencia",
      selector: (row) => row.como_se_enteraron.opcion,
      sortable: true,
    },
    {
      name: "Registro",
      selector: (row) =>
        row.customer_type === 1 ? (
          <div className="status local">
            <i className="zmdi zmdi-circle"></i> local
          </div>
        ) : row.customer_type === 2 ? (
          <div className="status ecommerce">
            <i className="zmdi zmdi-circle"></i>
            online
          </div>
        ) : null,
      sortable: true,
      sortFunction: (a, b) => a.customer_type - b.customer_type,
      width: "120px",
    },
    {
      name: "Creado",
      selector: (row) => <Moment format="DD/MM/YYYY">{row.created_at}</Moment>,
      sortFunction: (a, b) => a.created_at.localeCompare(b.created_at),
      sortable: true,
      width: "120px",
    },
    {
      name: "Acciones",
      selector: (row) => row.id,
      sortable: false,
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <Link to={`/customers/detail/${row.id}`}>
            <button className="btn bg-amber btn-sm">
              <i className="zmdi zmdi-eye"></i>
            </button>
          </Link>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => showConfirmMessage(row.id)}
          >
            <i className="zmdi zmdi-delete"></i>
          </button>
        </div>
      ),
      width: "120px",
    },
  ];

  const showConfirmMessage = (customer) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Un Cliente no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeCustomer(customer);
      }
    });
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Clientes </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Clientes</li>
                  <li className="breadcrumb-item active">lista</li>
                </ul>
                <ButtonMobileMenu />
                <div className="chat_window">
                  <Link to="/customers/add">
                    <button className="list_btn btn btn-danger btn-round float-md-right">
                      <i className="ti-plus" />
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle />
                <Link
                  to="/customers/add"
                  className="btn btn-danger float-right"
                >
                  <i className="zmdi zmdi-plus" /> Nuevo
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="input-box">
                      <i className="ti-search" />
                      <input
                        type="text"
                        placeholder="Buscar aqui..."
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                      <button className="button" onClick={handleClear}>
                        X
                      </button>
                    </div>
                    <DataTable
                      customStyles={tableCustomStyles}
                      columns={columns}
                      data={filteredItems}
                      noDataComponent="No se encontraron registros"
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default CustomersList;
