/** familias */
export const GET_FAMILIES = 'GET_FAMILIES'
export const ADD_FAMILY = 'ADD_FAMILY'
export const EDIT_FAMILY = 'EDIT_FAMILY'
export const UPDATE_FAMILY = 'UPDATE_FAMILY'
export const REMOVE_FAMILY = 'REMOVE_FAMILY'

/** materiales */
export const GET_MATERIALS = 'GET_MATERIALS'
export const ADD_MATERIAL = 'ADD_MATERIAL'
export const EDIT_MATERIAL = 'EDIT_MATERIAL'
export const UPDATE_MATERIAL = 'UPDATE_MATERIAL'
export const REMOVE_MATERIAL = 'REMOVE_MATERIAL'

/** proveedores */
export const GET_PROVIDERS = 'GET_PROVIDERS'
export const SHOW_PROVIDER = 'SHOW_PROVIDER'
export const ADD_PROVIDER = 'ADD_PROVIDER'
export const EDIT_PROVIDER = 'EDIT_PROVIDER'
export const UPDATE_PROVIDER = 'UPDATE_PROVIDER'
export const REMOVE_PROVIDER = 'REMOVE_PROVIDER'
export const UPDATE_PROVIDER_STATUS = 'UPDATE_PROVIDER_STATUS'

export const ADD_PROVIDER_CONTACT = 'ADD_PROVIDER_CONTACT'
export const REMOVE_PROVIDER_CONTACT = 'REMOVE_PROVIDER_CONTACT'
export const UPDATE_PROVIDER_CONTACT = 'UPDATE_PROVIDER_CONTACT'

export const ADD_PROVIDER_BANK_ACCOUNT = 'ADD_PROVIDER_BANK_ACCOUNT'
export const REMOVE_PROVIDER_BANK_ACCOUNT = 'REMOVE_PROVIDER_BANK_ACCOUNT'
export const UPDATE_PROVIDER_BANK_ACCOUNT = 'UPDATE_PROVIDER_BANK_ACCOUNT'

/** clientes */
export const GET_CUSTOMERS = 'GET_CUSTOMERS'
export const ADD_CUSTOMER = 'ADD_CUSTOMER'
export const EDIT_CUSTOMER = 'EDIT_CUSTOMER'
export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER'
export const REMOVE_CUSTOMER = 'REMOVE_CUSTOMER'
export const SHOW_CUSTOMER = 'SHOW_CUSTOMER'

/** Dirección de envió */
export const ADD_SHIPPING_ADDRESS = 'ADD_SHIPPING_ADDRESS'
export const EDIT_SHIPPING_ADDRESS = 'EDIT_SHIPPING_ADDRESS'
export const UPDATE_SHIPPING_ADDRESS = 'UPDATE_SHIPPING_ADDRESS'
export const REMOVE_SHIPPING_ADDRESS = 'REMOVE_SHIPPING_ADDRESS'

/** Data Select */
export const GET_ENTITIES = 'GET_ENTITIES'
export const GET_MUNICIPALITIES = 'GET_MUNICIPALITIES'
export const GET_FAMILIES_SELECT = 'GET_FAMILIES_SELECT'
export const GET_MEASUREMENTS_SELECT = 'GET_MEASUREMENTS_SELECT'
export const GET_MATERIALS_SELECT = 'GET_MATERIALS_SELECT'
export const GET_PRODUCTS_SELECT = 'GET_PRODUCTS_SELECT'
export const GET_CUSTOMERS_SELECT = 'GET_CUSTOMERS_SELECT'
export const GET_SIZES_SELECT = 'GET_SIZES_SELECT'
export const GET_PRINTINGS_SELECT = 'GET_PRINTINGS_SELECT'
export const GET_OPTIONS_SELECT = 'GET_OPTIONS_SELECT'
export const GET_PROVIDERS_SELECT = 'GET_PROVIDERS_SELECT'
export const GET_MATERIALS_WITH_INVENTORY = 'GET_MATERIALS_WITH_INVENTORY'
export const GET_TYPES_EXPENSES = "GET_TYPES_EXPENSES"
export const GET_OPTIONS_REFERENCES = "GET_OPTIONS_REFERENCES"


/** Unidades de medida */
export const GET_MEASUREMENTS = 'GET_MEASUREMENTS'
export const ADD_MEASUREMENT = 'ADD_MEASUREMENT'
export const EDIT_MEASUREMENT = 'EDIT_MEASUREMENT'
export const UPDATE_MEASUREMENT = 'UPDATE_MEASUREMENT'
export const REMOVE_MEASUREMENT = 'REMOVE_MEASUREMENT'

/** Usuarios */
export const GET_USERS = 'GET_USERS'
export const ADD_USER = 'ADD_USER'
export const EDIT_USER = 'EDIT_USER'
export const UPDATE_USER = 'UPDATE_USER'
export const REMOVE_USER = 'REMOVE_USER'

/** Productos */
export const GET_PRODUCTS = 'GET_PRODUCTS'
export const ADD_PRODUCT = 'ADD_PRODUCT'
export const EDIT_PRODUCT = 'EDIT_PRODUCT'
export const UPDATE_PRODUCT = 'UPDATE_PRODUCT'
export const REMOVE_PRODUCT = 'REMOVE_PRODUCT'
export const SHOW_PRODUCT = 'SHOW_PRODUCT'
export const DELETE_DATA_FROM_LOCALSTORAGE = 'DELETE_DATA_FROM_LOCALSTORAGE'

/** tamaños */
export const GET_SIZES = 'GET_SIZES'
export const ADD_SIZE = 'ADD_SIZE'
export const EDIT_SIZE = 'EDIT_SIZE'
export const UPDATE_SIZE = 'UPDATE_SIZE'
export const REMOVE_SIZE = 'REMOVE_SIZE'

/** impresion */
export const GET_PRINTINGS = 'GET_PRINTINGS'
export const ADD_PRINTING = 'ADD_PRINTING'
export const EDIT_PRINTING = 'EDIT_PRINTING'
export const UPDATE_PRINTING = 'UPDATE_PRINTING'
export const REMOVE_PRINTING = 'REMOVE_PRINTING'

/** acabados */
export const GET_OPTIONS = 'GET_OPTIONS'
export const ADD_OPTION = 'ADD_OPTION'
export const EDIT_OPTION = 'EDIT_OPTION'
export const UPDATE_OPTION = 'UPDATE_OPTION'
export const REMOVE_OPTION = 'REMOVE_OPTION'


/** Detalles del Producto */
export const ADD_PRICE_PRODUCT = 'ADD_PRICE_PRODUCT'
export const ADD_SIZE_PRODUCT = 'ADD_SIZE_PRODUCT'
export const ADD_MATERIAL_PRODUCT = 'ADD_MATERIAL_PRODUCT'
export const ADD_PRINTING_PRODUCT = 'ADD_PRINTING_PRODUCT'
export const ADD_OPTION_PRODUCT = 'ADD_OPTION_PRODUCT'
export const ADD_IMAGES_PRODUCT = 'ADD_IMAGES_PRODUCT'

export const DELETE_PRICE_PRODUCT = 'DELETE_PRICE_PRODUCT'
export const DELETE_SIZE_PRODUCT = 'DELETE_SIZE_PRODUCT'
export const DELETE_MATERIAL_PRODUCT = 'DELETE_MATERIAL_PRODUCT'
export const DELETE_PRINTING_PRODUCT = 'DELETE_PRINTING_PRODUCT'
export const DELETE_OPTION_PRODUCT = 'DELETE_OPTION_PRODUCT'
export const EDIT_OPTION_PRODUCT = 'EDIT_OPTION_PRODUCT'
export const UPDATE_OPTION_PRODUCT = 'UPDATE_OPTION_PRODUCT'
export const DELETE_IMAGE_PRODUCT = 'DELETE_IMAGE_PRODUCT'

export const EDIT_PRICE_PRODUCT = 'EDIT_PRICE_PRODUCT'
export const UPDATE_PRICE_PRODUCT = 'UPDATE_PRICE_PRODUCT'

/** Cupones de descuento */
export const GET_COUPONS = 'GET_COUPONS'
export const ADD_COUPON = 'ADD_COUPON'
export const EDIT_COUPON = 'EDIT_COUPON'
export const UPDATE_COUPON = 'UPDATE_COUPON'
export const REMOVE_COUPON = 'REMOVE_COUPON'

/** Ordenes */
export const GET_ORDERS = 'GET_ORDERS'
export const ADD_ORDER = 'ADD_ORDER'
export const EDIT_ORDER = 'EDIT_ORDER'
export const UPDATE_ORDER = 'UPDATE_ORDER'
export const REMOVE_ORDER = 'REMOVE_ORDER'
export const SHOW_ORDER = 'SHOW_ORDER'
export const UPLOAD_FILES_ORDER = 'UPLOAD_FILES_ORDER'
export const SET_INVOICE_NUMBER = 'SET_INVOICE_NUMBER'
export const ADD_DATE_DELIVERY = 'ADD_DATE_DELIVERY'
export const UPDATE_ORDER_DATE = 'UPDATE_ORDER_DATE'

export const BACKUP_ORDERS_CLIENT = 'BACKUP_ORDERS_CLIENT'


export const UPDATE_STATUS_ORDER = 'UPDATE_STATUS_ORDER'
export const ADD_PAYMENT = 'ADD_PAYMENT'

export const GET_PRODUCT = 'GET_PRODUCT'
export const ADD_PRODUCT_TO_CART = 'ADD_PRODUCT_TO_CART'
export const TOTAL_SALE_ORDER = 'TOTAL_SALE_ORDER'
export const DELETE_PRODUCT_FROM_SALE = 'DELETE_PRODUCT_FROM_SALE'
export const UPDATE_DISCOUNT_RATE = 'UPDATE_DISCOUNT_RATE'
export const UPDATE_COMMISSION = 'UPDATE_COMMISSION'

export const INSUFFICIENT_MATERIAL_IN_STOCK = 'INSUFFICIENT_MATERIAL_IN_STOCK'
export const CLOSE_MODAL_OUT_STOCK = 'CLOSE_MODAL_OUT_STOCK'
export const CANCEL_ORDER = 'CANCEL_ORDER'
export const REORDER_ITEMS = 'REORDER_ITEMS'

/** Ordenes Producción */
export const GET_ORDERS_PRODUCTION = 'GET_ORDERS_PRODUCTION'
export const EDIT_ORDER_PRODUCTION = 'EDIT_ORDER_PRODUCTION'
export const UPDATE_STATUS_ORDER_PRODUCTION = 'UPDATE_STATUS_ORDER_PRODUCTION'
export const SHOW_ORDER_PRODUCTION = 'SHOW_ORDER_PRODUCTION'

/** Editar orden */
export const REMOVE_PRODUCTS_FROM_ORDER = 'REMOVE_PRODUCTS_FROM_ORDER'
export const TOTAL_ORDER_EDIT = 'TOTAL_ORDER_EDIT'
export const ADD_ITEM_TO_ORDER = 'ADD_ITEM_TO_ORDER'
export const UPDATE_PRODUCT_FROM_ORDER = 'UPDATE_PRODUCT_FROM_ORDER'
export const RESET_PRODUCT_SELECTED = 'RESET_PRODUCT_SELECTED'
export const SAVE_ORDER_EDITION = 'SAVE_ORDER_EDITION'

export const GENERATE_ORDER_PDF = 'GENERATE_ORDER_PDF'
export const ADD_FILES_PRINTING = 'ADD_FILES_PRINTING'
export const GET_FILES_PRINTING = 'GET_FILES_PRINTING'
export const REMOVE_FILE_PRINTING = 'REMOVE_FILE_PRINTING'

export const ADD_NOTES_TO_ORDER = 'ADD_NOTES_TO_ORDER'
export const ADD_OBSERVATIONS_TO_ORDER = 'ADD_OBSERVATIONS_TO_ORDER'

/** Login */
export const SUCCESS_LOGIN = 'SUCCESS_LOGIN'
export const FAILED_LOGIN = 'FAILED_LOGIN'
export const LOGOUT = 'LOGOUT'
export const AUTHENTICATED_USER = 'AUTHENTICATED_USER'

/** Carrusel - Slides */
export const GET_SLIDES = 'GET_SLIDES'
export const ADD_SLIDE = 'ADD_SLIDE'
export const EDIT_SLIDE = 'EDIT_SLIDE'
export const UPDATE_SLIDE = 'UPDATE_SLIDE'
export const REMOVE_SLIDE = 'REMOVE_SLIDE'

/** Descuentos */
export const GET_DISCOUNTS = 'GET_DISCOUNTS'
export const ADD_DISCOUNT = 'ADD_DISCOUNT'
export const EDIT_DISCOUNT= 'EDIT_DISCOUNT'
export const UPDATE_DISCOUNT = 'UPDATE_DISCOUNT'
export const REMOVE_DISCOUNT = 'REMOVE_DISCOUNT'

/** Inventarios */
export const GET_INVENTORIES = 'GET_INVENTORIES'
export const ADD_INVENTORY = 'ADD_INVENTORY'
export const EDIT_INVENTORY= 'EDIT_INVENTORY'
export const UPDATE_INVENTORY = 'UPDATE_INVENTORY'
export const UPDATE_STOCK = 'UPDATE_STOCK'
export const GET_HISTORICAL_STOCK = 'GET_HISTORICAL_STOCK'
export const ADJUST_INVENTORY = 'ADJUST_INVENTORY'

/** Gastos */
export const GET_EXPENSES = 'GET_EXPENSES'
export const ADD_EXPENSE = 'ADD_EXPENSE'
export const EDIT_EXPENSE= 'EDIT_EXPENSE'
export const UPDATE_EXPENSE = 'UPDATE_EXPENSE'
export const REMOVE_EXPENSE = 'REMOVE_EXPENSE'
export const ADD_TYPE_EXPENSE = 'ADD_TYPE_EXPENSE'

/** Ordenes de compra */
export const GET_PURCHASES = 'GET_PURCHASES'
export const ADD_PURCHASE = 'ADD_PURCHASE'
export const SHOW_PURCHASE = 'SHOW_PURCHASE'
export const UPDATE_STATUS_PURCHASE = 'UPDATE_STATUS_PURCHASE'
export const REMOVE_PURCHASE = 'REMOVE_PURCHASE'
export const STORE_PAYMENT_PURCHASE = 'STORE_PAYMENT_PURCHASE'
export const EDIT_PURCHASE = 'EDIT_PURCHASE'
export const UPDATE_PURCHASE = 'UPDATE_PURCHASE'
export const SUPPLY_MATERIALS_TO_WHAREHOUSE = 'SUPPLY_MATERIALS_TO_WHAREHOUSE'

/** Editar orden de compra */
export const ADD_MATERIAL_FROM_PURCHASE_ORDER = 'ADD_MATERIAL_FROM_PURCHASE_ORDER'
export const UPDATE_MATERIAL_FROM_PURCHASE_ORDER = 'UPDATE_MATERIAL_FROM_PURCHASE_ORDER'
export const REMOVE_MATERIAL_FROM_PURCHASE_ORDER = 'REMOVE_MATERIAL_FROM_PURCHASE_ORDER'
export const CALCULATE_PURCHASE_ORDER_TOTALS = 'CALCULATE_PURCHASE_ORDER_TOTALS'
export const SAVE_PURCHASE_ORDER_EDITION = 'SAVE_PURCHASE_ORDER_EDITION'

/** Pagos orden de compra */
export const UPDATE_PAYMENT_PURCHASE = 'UPDATE_PAYMENT_PURCHASE'
export const DELETE_PAYMENT_PURCHASE = 'DELETE_PAYMENT_PURCHASE'

/** Dashboard */
export const GET_DATA_DASHBOARD = 'GET_DATA_DASHBOARD'
export const GET_DATA_GENERAL_REPORT = 'GET_DATA_GENERAL_REPORT'
export const EXPORT_REPORT_CAJA = 'EXPORT_REPORT_CAJA'

/** Errores de la api */
export const SHOW_ERRORS_API = 'SHOW_ERRORS_API'
