import React from "react";

const SearchBar = ({ searchTerm, handleChangeSearch, handleSearch, placeholder}) => {
  return (
    <>
      <form className="form-search float-right">
        <input
          type="search"
          name="search"
          placeholder={placeholder}
          value={searchTerm}
          onChange={handleChangeSearch}
        />
        <i className="zmdi zmdi-search" />
        <button type="button" onClick={(e) => handleSearch(e)}>
          {" "}
          Buscar
        </button>
      </form>
    </>
  );
};

export default SearchBar;
