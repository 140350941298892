import React, { useContext } from "react";
import { Controller, useForm } from "react-hook-form";
import ProvidersContext from "../../../contexts/providers/providersContext";
import Select from "react-select";

const ModalAddAccount = ({ id }) => {
  const { addProviderBankAccount } = useContext(ProvidersContext);

  const { register, errors, handleSubmit, reset, control } = useForm();

  // Lista de los nombres de bancos que operan en mexico
  const bancoOptions = [
    { value: "abc-capital", label: "ABC Capital" },
    { value: "actinver", label: "Actinver" },
    { value: "afirme", label: "Afirme" },
    { value: "american-express", label: "American Express" },
    { value: "autofin", label: "Autofin" },
    { value: "banca-mifel", label: "Banca Mifel" },
    { value: "banco-ahorro-famsa", label: "Banco Ahorro Famsa" },
    { value: "banco-azteca", label: "Banco Azteca" },
    { value: "banco-bancrea", label: "Banco Bancrea" },
    { value: "banco-base", label: "Banco Base" },
    { value: "banco-credit-suisse", label: "Banco Credit Suisse" },
    { value: "banco-del-banjio", label: "Banco del Banjío" },
    { value: "bancoppel", label: "Bancoppel" },
    { value: "bank-of-america", label: "Bank of America" },
    { value: "bank-of-tokyo-mitsubishi", label: "Bank of Tokyo-Mitsubishi" },
    { value: "banorte-ixe", label: "Banorte / IXE" },
    { value: "banregio", label: "Banregio" },
    { value: "bansi", label: "Bansi" },
    { value: "barclays", label: "Barclays" },
    { value: "bbva-bancomer", label: "BBVA Bancomer" },
    { value: "ci-banco", label: "CI Banco" },
    { value: "citibanamex", label: "Citibanamex" },
    { value: "compartamos", label: "Compartamos" },
    { value: "consubanco", label: "Consubanco" },
    { value: "deutsche-bank", label: "Deutsche Bank" },
    { value: "donde-banco", label: "Dondé Banco" },
    { value: "hsbc-mexico", label: "HSBC México" },
    { value: "icbc", label: "ICBC" },
    { value: "inbursa", label: "Inbursa" },
    { value: "inmobiliario-mexicano", label: "Inmobiliario Mexicano" },
    { value: "inter-banco", label: "Inter Banco" },
    { value: "interacciones", label: "Interacciones" },
    { value: "investa-bank", label: "Investa Bank" },
    { value: "invex", label: "Invex" },
    { value: "jp-morgan", label: "JP Morgan" },
    { value: "mizuho", label: "Mizuho" },
    { value: "monex", label: "Monex" },
    { value: "multiva", label: "Multiva" },
    { value: "s3", label: "S3" },
    { value: "sabadell", label: "Sabadell" },
    { value: "santander", label: "Santander" },
    { value: "scotiabank", label: "Scotiabank" },
    { value: "shinhan", label: "Shinhan" },
    { value: "ubs", label: "UBS" },
    { value: "ve-por-mas", label: "Ve por Más" },
    { value: "volkswagen-bank", label: "Volkswagen Bank" },
  ];

  const handleCloseModal = () => {
    window.$("#addAccount").modal("hide");
  };

  const onSubmit = (data) => {
    addProviderBankAccount(id, data);
    reset();
    handleCloseModal();
  };

  return (
    <>
      <div className="modal fade" id="addAccount" tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header bg-cyan">
              <h4 className="title">Cuenta Nueva</h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Banco *</label>
                      <Controller
                        name="banco"
                        control={control}
                        defaultValue=""
                        placeholder="Selecciona una opción"
                        options={bancoOptions}
                        as={<Select />}
                        rules={{
                          required: {
                            value: true,
                            message: "El campo Banco es requerido",
                          },
                        }}
                      />
                      {errors?.banco ? (
                        <label className="error">
                          {errors?.banco?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>No. Cuenta *</label>
                      <input
                        name="numero_cuenta"
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: true,
                            message: "El campo No. Cuenta es requerido",
                          },
                        })}
                        className={
                          errors.numero_cuenta
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="No. Cuenta"
                      />
                      {errors?.numero_cuenta ? (
                        <label className="error">
                          {errors?.numero_cuenta?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>CLABE *</label>
                      <input
                        name="clabe"
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: false,
                            message: "El campo CLABE es requerido",
                          },
                        })}
                        className={
                          errors.clabe
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="CLABE"
                      />
                      {errors?.clabe ? (
                        <label className="error">
                          {errors?.clabe?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Titular *</label>
                      <input
                        name="nombre_titular"
                        autoComplete="off"
                        ref={register({
                          required: {
                            value: true,
                            message: "El campo Titular es requerido",
                          },
                        })}
                        className={
                          errors.nombre_titular
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Nombre del titular"
                      />
                      {errors?.nombre_titular ? (
                        <label className="error">
                          {errors?.nombre_titular?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  GUARDAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalAddAccount;
