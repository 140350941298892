import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import PurchasesContext from "../../../contexts/purchases/purchasesContext";

const EditMaterial = ({selectedMaterial, setSelectedMaterial}) => {
  const { updateMaterialToOrder } = useContext(PurchasesContext);
  const { register, errors, handleSubmit, reset } = useForm();

  const [unidad_medida, setUnidadMedida] = useState('');
  const [metros_cuadrados, setMetrosCuadrados] = useState(0);
  const [ancho, setAncho] = useState(0);
  const [largo, setLargo] = useState(0);
  const [qty, setQuantity] = useState(1);
  const [unit_price, setUnitPrice] = useState(0);

  useEffect(() => {
     let metros = ancho * largo || 0;
     let total_metros = metros * qty || 0;
     setMetrosCuadrados(Number(total_metros.toFixed(2)));
     //eslint-disable-next-line
   }, [ancho, largo, qty]);

   useEffect(() => {
      if (selectedMaterial) {
        setUnidadMedida(selectedMaterial && selectedMaterial.unidad_compra ? selectedMaterial.unidad_compra : '');
        setAncho(selectedMaterial && selectedMaterial.ancho ? selectedMaterial.ancho : 0);
        setLargo(selectedMaterial && selectedMaterial.largo ? selectedMaterial.largo : 0);
        setQuantity(selectedMaterial && selectedMaterial.quantity ? selectedMaterial.quantity : 1);
        setUnitPrice(selectedMaterial && selectedMaterial.unit_price ? selectedMaterial.unit_price : 0);
      }
      //eslint-disable-next-line
    }, [selectedMaterial]);

   const handleChangeUnidad = (e) => {
    setUnidadMedida(e.target.value);
   };

  const handleCloseModal = () => {
    setSelectedMaterial({})
    window.$("#editMaterialModal").modal("hide");
  };

  const onSubmit = async (data) => {
    data.id = selectedMaterial.id;
    data.ancho = Number(ancho);
    data.largo = Number(largo);
    data.subtotal = Number(data.quantity * data.unit_price);
    data.material = selectedMaterial.material;
    data.material_id = selectedMaterial.material?.id;
    data.isrecent = selectedMaterial.isrecent;
    updateMaterialToOrder(data);
    reset({});
    setAncho(0);
    setLargo(0);
    setMetrosCuadrados(0);
    setUnidadMedida('unidad');
    setQuantity(1);
    handleCloseModal();
  };

  return (
    <>
      <div
        className="modal fade"
        id="editMaterialModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title" id="smallModalLabel">
                Actualizar Material
              </h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <label>Material</label>
                    <div className="form-group">
                      <select
                        name="material_id"
                        ref={register({
                          required: {
                            value: true,
                            message: "El material es requerido",
                          },
                        })}
                        className={
                          errors.material_id
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        disabled
                      >
                        <option
                          value={
                            selectedMaterial && selectedMaterial.material?.id
                              ? selectedMaterial.material.id
                              : ""
                          }

                        >
                          {selectedMaterial && selectedMaterial.material?.name
                            ? selectedMaterial.material.name
                            : ""}
                        </option>
                      </select>
                      {errors?.material_id ? (
                        <label className="error">
                          {errors?.material_id?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <label>Unidad de compra</label>
                    <div className="form-group">
                      <select
                        name="unidad_compra"
                        className="form-control"
                        value={unidad_medida}
                        onChange={(e) =>
                          handleChangeUnidad(e)}
                          ref={register({
                            required: {
                              value: true,
                              message: "Selecciona la unidad de compra",
                            }
                          })}
                      >
                        <option value="unidad">Unidad (pza)</option>
                        <option value="metro2">Metro cuadrado (m2)</option>
                        <option value="rollo">Rollo</option>
                        <option value="placa">Placa</option>
                      </select>
                    </div>
                  </div>
                  {
                    unidad_medida === 'rollo' || unidad_medida === 'placa' ? (
                      <>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label><small>Ancho (m)</small></label>
                      <input
                        name="ancho"
                        type="text"
                        autoComplete="off"
                        value={ancho}
                        onChange={(e) =>
                          setAncho(e.target.value)}
                        ref={register({
                          required: {
                            value: true,
                            message: "La cantidad es requerida",
                          },
                          min: {
                            value: 0.1,
                            message:
                              "El campo cantidad debe ser mayor a 1",
                          },
                          pattern: {
                            value: /^[0-9]+([.])?([0-9]+)?$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.ancho
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.ancho ? (
                        <label className="error">
                          {errors?.ancho?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label><small>Largo (m)</small></label>
                      <input
                        name="largo"
                        type="text"
                        autoComplete="off"
                        value={largo}
                        onChange={(e) =>
                          setLargo(e.target.value)}
                        ref={register({
                          required: {
                            value: true,
                            message: "La cantidad es requerida",
                          },
                          min: {
                            value: 0.1,
                            message:
                              "El campo cantidad debe ser mayor a 1",
                          },
                          pattern: {
                            value: /^[0-9]+([.])?([0-9]+)?$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.largo
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.largo ? (
                        <label className="error">
                          {errors?.largo?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-4">
                    <div className="form-group">
                      <label><small>Metros (m2)</small></label>
                      <input
                        name="metros"
                        type="text"
                        readOnly
                        autoComplete="off"
                        value={metros_cuadrados}
                        ref={register({
                          required: {
                            value: true,
                            message: "La cantidad es requerida",
                          },
                          pattern: {
                            value: /^[0-9]+([.])?([0-9]+)?$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.metros
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.metros ? (
                        <label className="error">
                          {errors?.metros?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                      </>
                    ) : null
                  }

                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Cantidad</label>
                      <input
                        name="quantity"
                        type="text"
                        autoComplete="off"
                        value={qty}
                        onChange={(e) =>
                          setQuantity(e.target.value)}
                        ref={register({
                          required: {
                            value: true,
                            message: "La cantidad es requerida",
                          },
                          min: {
                            value: 1,
                            message:
                              "El campo cantidad debe ser mayor a 1",
                          },
                          pattern: {
                            value: /^[0-9]+([.])?([0-9]+)?$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.quantity
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.quantity ? (
                        <label className="error">
                          {errors?.quantity?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Precio Unitario</label>
                      <input
                        name="unit_price"
                        type="text"
                        value={unit_price}
                        autoComplete="off"
                        onChange={(e) =>
                          setUnitPrice(e.target.value)}
                        ref={register({
                          required: {
                            value: true,
                            message: "El monto es requerido",
                          },
                          min: {
                            value:  1,
                            message: "El campo monto debe ser mayor a 1",
                          },
                          pattern: {
                            value: /^[0-9]+([.])?([0-9]+)?$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.unit_price
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.unit_price ? (
                        <label className="error">
                          {errors?.unit_price?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <label>Descripción</label>
                    <div className="form-group">
                      <textarea
                        name="description"
                        defaultValue={selectedMaterial && selectedMaterial.description ? selectedMaterial.description : ""}
                        rows={2}
                        placeholder="Agrega aqui la descripción (opcional)"
                        ref={register({
                          required: {
                            value: false,
                            message: "La descripción es requerida",
                          },
                        })}
                        className={
                          errors.description
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.description ? (
                        <label className="error">
                          {errors?.description?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  Actualizar material
                </button>
                <button
                  type="button"
                  className="btn btn-default waves-effect"
                  onClick={handleCloseModal}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditMaterial;
