import { v4 as uuidv4 } from "uuid";
import {
  ADD_CUSTOMER,
  ADD_DATE_DELIVERY,
  ADD_ITEM_TO_ORDER,
  ADD_ORDER,
  ADD_PAYMENT,
  ADD_PRODUCT_TO_CART,
  BACKUP_ORDERS_CLIENT,
  CANCEL_ORDER,
  CLOSE_MODAL_OUT_STOCK,
  DELETE_PRODUCT_FROM_SALE,
  EDIT_ORDER,
  GET_CUSTOMERS_SELECT,
  GET_ORDERS,
  GET_PRODUCT,
  GET_PRODUCTS_SELECT,
  INSUFFICIENT_MATERIAL_IN_STOCK,
  REMOVE_ORDER,
  REMOVE_PRODUCTS_FROM_ORDER,
  REORDER_ITEMS,
  RESET_PRODUCT_SELECTED,
  SAVE_ORDER_EDITION,
  SET_INVOICE_NUMBER,
  SHOW_ORDER,
  TOTAL_ORDER_EDIT,
  TOTAL_SALE_ORDER,
  UPDATE_COMMISSION,
  UPDATE_DISCOUNT_RATE,
  UPDATE_ORDER,
  UPDATE_PRODUCT_FROM_ORDER,
  UPDATE_STATUS_ORDER,
  UPLOAD_FILES_ORDER,
  UPDATE_ORDER_DATE,
  ADD_NOTES_TO_ORDER,
  ADD_OBSERVATIONS_TO_ORDER
} from "../../types";

export default function ordersReducer(state, action) {
  switch (action.type) {
    case ADD_ORDER:
      return {
        ...state,
        successOrder: true,
      };

    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
        current_page: action.payload.current_page,
        per_page: action.payload.per_page,
        total: action.payload.total,
        successOrder: false,
        success: false,
        order: [],
        details: [],
        products: [],
        customers: [],
        product: null,
        sizes: [],
        materialProduct: [],
        printing: [],
        options: [],
        prices: [],
        productsList: [],
        subtotal: 0,
        shipping_costs: 0,
        discount: 0,
        iva: 0,
        totalSale: 0,
        discount_rate: 0,
        deletedItems: [],
        addedItems: [],
        updatedItems: [],
        successEditing: false,
        commission: 0,
        upDiscount: false,
        upCommission: false,
        orders_backups: [],
        cantidad_pdfs:0,

      };

    case EDIT_ORDER:
      return {
        ...state,
        success: false,
        order: action.payload.order,
        details: action.payload.details,
        subtotal: action.payload.order.subtotal,
        shipping_costs: action.payload.order.shipping_costs,
        discount: action.payload.order.discount,
        iva: action.payload.order.iva,
        totalSale: action.payload.order.total,
        discount_rate: action.payload.order.discount_rate,
        commission: action.payload.order.commission,
      };

    case UPDATE_ORDER:
      return {
        ...state,
        success: true,
      };

    case REMOVE_ORDER:
      return {
        ...state,
      };

    case GET_PRODUCTS_SELECT:
      return {
        ...state,
        products: action.payload,
      };

    case GET_PRODUCT:
      return {
        ...state,
        success: false,
        product: action.payload.product,
        sizes: action.payload.sizes,
        materialProduct: action.payload.materialProduct,
        printing: action.payload.printing,
        options: action.payload.options,
        prices: action.payload.prices,
      };

    case ADD_PRODUCT_TO_CART:
      let dataProduct = action.payload;
      let productType = action.payload.product.product_type;
      dataProduct.id = uuidv4();
      switch (productType) {
        case "simple":
          dataProduct.options = {
            size: { id: dataProduct.size.value, name: dataProduct.size.label },
            material: {
              id: dataProduct.material.value,
              name: dataProduct.material.label,
            },
            printing: {
              id: dataProduct.printing.value,
              name: dataProduct.printing.label,
            },
            option: {
              id: dataProduct.option.value,
              name: dataProduct.option.label,
            },
          };
          break;

        case "byarea":
          dataProduct.options = {
            size: "",
            material: {
              id: dataProduct.material.value,
              name: dataProduct.material.label,
            },
            printing: {
              id: dataProduct.printing.value,
              name: dataProduct.printing.label,
            },
            option: {
              id: dataProduct.option.value,
              name: dataProduct.option.label,
            },
          };
          break;

        case "offset":
          dataProduct.options = {
            size: { id: dataProduct.size.value, name: dataProduct.size.label },
            material: {
              id: dataProduct.material.value,
              name: dataProduct.material.label,
            },
            printing: {
              id: dataProduct.printing.value,
              name: dataProduct.printing.label,
            },
            option: {
              id: dataProduct.option.value,
              name: dataProduct.option.label,
            },
          };
          break;

        case "service":
          if (dataProduct.measure === "m2") {
              dataProduct.options = {
                size: { base: dataProduct.base, altura: dataProduct.altura },
                measure: { name: dataProduct.measure },
              };
          }else{
            dataProduct.options = {
              measure: { name: dataProduct.measure },
            };
          }
          break;

        default:
          break;
      }
      return {
        ...state,
        productsList: [...state.productsList, dataProduct],
      };

    case DELETE_PRODUCT_FROM_SALE:
      const productsList = state.productsList.filter(
        (product) => product.id !== action.payload
      );
      return {
        ...state,
        productsList: productsList,
      };

    case TOTAL_SALE_ORDER:
      let subtotal = state.productsList.reduce(
        (sum, value) => sum + value.final_price,
        0
      );
      let discount = (subtotal * state.discount_rate) / 100;
      let subtotal_final = subtotal - discount;
      let iva = subtotal_final * 0.16;
      let total = subtotal_final + iva;

      return {
        ...state,
        subtotal: subtotal,
        discount: discount,
        iva: iva,
        totalSale: total,
      };

    case UPDATE_DISCOUNT_RATE:
      return {
        ...state,
        discount_rate: action.payload,
        upDiscount: true,
      };
    case UPDATE_COMMISSION:
      return {
        ...state,
        commission: action.payload,
        upCommission: true,
      };

    case GET_CUSTOMERS_SELECT:
      return {
        ...state,
        customers: action.payload,
      };

    case ADD_CUSTOMER:
      return {
        ...state,
        success: true,
      };

    case SHOW_ORDER:
      return {
        ...state,
        success: false,
        order: action.payload.order,
        details: action.payload.details,
      };

    case UPLOAD_FILES_ORDER:
      return {
        ...state,
        success: true,
      }

    case UPDATE_STATUS_ORDER:
      return {
        ...state,
        success: true,
      };

    case INSUFFICIENT_MATERIAL_IN_STOCK:
      return {
        ...state,
        insufficient_material: action.payload,
        out_stock: true
      }

    case CLOSE_MODAL_OUT_STOCK:
      return {
        ...state,
        insufficient_material: [],
        out_stock: false
      }

    case ADD_PAYMENT:
      return {
        ...state,
        success: true,
      };

    case REMOVE_PRODUCTS_FROM_ORDER:
      let data = action.payload;
      let newDetails = state.details;
      let deletedItems = state.deletedItems;
      let updateProducts = state.updatedItems;
      let addedProducts = state.addedItems;

      for (let index = 0; index < data.length; index++) {
        newDetails = newDetails.filter(
          (detail) => detail.id !== data[index].id
        );
      }

      //remover prductos agregados o editados durante la edicion de la venta
      for (let index = 0; index < data.length; index++) {
        //productos agregados
        let foundElement1 = addedProducts.find(
          (element) => element.id === data[index].id
        );

        //productos modificados
        let foundElement2 = updateProducts.find(
          (element) => element.id === data[index].id
        );

        if (foundElement1 || foundElement2) {
          if (foundElement1) {
            addedProducts = addedProducts.filter(
              (detail) => detail.id !== foundElement1.id
            );
          }

          if (foundElement2) {
            if (!foundElement2.isrecent) {
              deletedItems = deletedItems.concat(foundElement2.id);
            }
            updateProducts = updateProducts.filter(
              (detail) => detail.id !== foundElement2.id
            );
          }
        } else {
          deletedItems = deletedItems.concat(data[index].id);
        }
      }

      return {
        ...state,
        details: newDetails,
        addedItems: addedProducts,
        updatedItems: updateProducts,
        deletedItems: deletedItems,
      };

    case TOTAL_ORDER_EDIT:
      let subtotalEdit = state.details.reduce(
        (sum, value) => sum + value.amount,
        0
      );

      let discountEdit = ((subtotalEdit + state.shipping_costs ) * state.discount_rate) / 100;
      let subtotal_final_edit = (subtotalEdit + state.shipping_costs ) - discountEdit;
      let ivaEdit = subtotal_final_edit * 0.16;
      let totalEdit = subtotal_final_edit + ivaEdit;

      return {
        ...state,
        subtotal: subtotalEdit,
        discount: discountEdit,
        iva: ivaEdit,
        totalSale: totalEdit,
      };

    case ADD_ITEM_TO_ORDER:
      let dataItem = action.payload;
      let itemType = action.payload.product.product_type;
      dataItem.id = uuidv4();
      switch (itemType) {
        case "simple":
          dataItem.options = {
            size: { id: dataItem.size.value, name: dataItem.size.label },
            material: {
              id: dataItem.material.value,
              name: dataItem.material.label,
            },
            printing: {
              id: dataItem.printing.value,
              name: dataItem.printing.label,
            },
            option: {
              id: dataItem.option.value,
              name: dataItem.option.label,
            },
          };
          break;

        case "byarea":
          dataItem.options = {
            size: "",
            material: {
              id: dataItem.material.value,
              name: dataItem.material.label,
            },
            printing: {
              id: dataItem.printing.value,
              name: dataItem.printing.label,
            },
            option: {
              id: dataItem.option.value,
              name: dataItem.option.label,
            },
          };
          break;

        case "offset":
          dataItem.options = {
            size: { id: dataItem.size.value, name: dataItem.size.label },
            material: {
              id: dataItem.material.value,
              name: dataItem.material.label,
            },
            printing: {
              id: dataItem.printing.value,
              name: dataItem.printing.label,
            },
            option: {
              id: dataItem.option.value,
              name: dataItem.option.label,
            },
          };
          break;

        case "service":
          if (dataItem.measure === "m2") {
              dataItem.options = {
                size: { base: dataItem.base, altura: dataItem.altura },
                measure: { name: dataItem.measure },
              };
          }else{
            dataItem.options = {
              measure: { name: dataItem.measure },
            };
          }
          break;

        default:
          break;
      }
      return {
        ...state,
        details: [...state.details, dataItem],
        addedItems: [...state.addedItems, dataItem],
      };

    case RESET_PRODUCT_SELECTED:
      return {
        ...state,
        product: null,
        sizes: [],
        materialProduct: [],
        printing: [],
        options: [],
        prices: [],
      };

    case UPDATE_PRODUCT_FROM_ORDER:
      let currentItem = action.payload;
      let addedItems = state.addedItems;
      let updatedItems = state.updatedItems;

      if (currentItem.isrecent) {
        addedItems = addedItems.map((item) =>
          item.id === currentItem.id ? currentItem : item
        );
      } else {
        let element = updatedItems.find(
          (product) => product.id === currentItem.id
        );

        if (element) {
          updatedItems = updatedItems.map((item) =>
            item.id === currentItem.id ? currentItem : item
          );
        } else {
          updatedItems = updatedItems.concat(currentItem);
        }
      }

      return {
        ...state,
        details: state.details.map((detail) =>
          detail.id === currentItem.id ? currentItem : detail
        ),
        addedItems: addedItems,
        updatedItems: updatedItems,
      };

    case SAVE_ORDER_EDITION:
      return {
        ...state,
        successEditing: true,
      };

      case CANCEL_ORDER:
        return {
          ...state,
          success: true,
        };

        case REORDER_ITEMS:
        return {
          ...state,
          success: true,
        };

        case SET_INVOICE_NUMBER:
          return {
            ...state,
            success: true,
          };

        case ADD_DATE_DELIVERY:
          return {
            ...state,
            success: true,
          };

        case UPDATE_ORDER_DATE:
          return {
            ...state,
            success: true,
          };

        case ADD_NOTES_TO_ORDER:
          return {
            ...state,
            success: true,
          };

        case ADD_OBSERVATIONS_TO_ORDER:
          return {
            ...state,
            success: true,
          };

        case BACKUP_ORDERS_CLIENT:
          return {
            ...state,
            success: true,
            orders_backups: action.payload.ordenes,
            cantidad_pdfs: action.payload.cantidad_pdfs,
          };

    default:
      return state;
  }

}
