import React, { useContext, useEffect, useState } from "react";
import Moment from "react-moment";
import { Link, useParams } from "react-router-dom";
import DesignContext from "../../contexts/design/designContext";
import Layout from "../../layouts/Layout";
import UploadFiles from "./UploadFiles";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const estilos = {
  concept: {
    maxWidth: "250px",
  }
};

const OrderDetails = () => {
  const { id } = useParams();
  const { order, details, getOrder } = useContext(DesignContext);
  const [selectedDetail, setSelectedDetail] = useState({});

  useEffect(() => {
      getOrder(id); 
    // eslint-disable-next-line
  }, []);

  const handleSelectedDetail = (detail) => {
    setSelectedDetail(detail)
  }

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Orden</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item active">
                    <Link to="/orders-desing"> Ordenes </Link>
                  </li>
                  <li className="breadcrumb-item active">Detalles</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <h5>
                      <strong>Orden ID: </strong> #{order.id}
                    </h5>
                    <div className="row">
                      <div className="col-md-6 col-sm-6">
                        <address>
                          <strong>
                            {order.customer ? order.customer.full_name : ""}
                          </strong>
                          <br />
                          {order.customer ? order.customer.email : ""} <br />
                          <abbr title="Phone">Tel:</abbr>{" "}
                          {order.customer ? order.customer.phone : ""}
                        </address>
                      </div>
                      <div className="col-md-6 col-sm-6 text-right">
                        <p className="mb-0">
                          <strong>Expira: </strong>
                          <Moment format="DD/MM/YYYY">{order.expires}</Moment>
                        </p>
                        <p className="mb-0">
                          <strong>Estado de la orden: </strong>{" "}
                          {order.status === 1 ? (
                            <span className="badge badge-warning">
                              Pendiente
                            </span>
                          ) : order.status === 2 ? (
                            <span className="badge badge-success">
                              Aprobada
                            </span>
                          ) : order.status === 3 ? (
                            <span className="badge badge-primary">
                              En Producción
                            </span>
                          ) : order.status === 4 ? (
                            <span className="badge badge-info">
                              Pedido listo
                            </span>
                          ) : order.status === 5 ? (
                            <span className="badge badge-danger">
                              Completada
                            </span>
                          ) : null}
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table c_table theme-color">
                          <thead>
                            <tr>
                              <th>Descripción</th>
                              <th>Archivo</th>
                              <th className="text-center">Cantidad</th>
                            </tr>
                          </thead>
                          <tbody>
                            {!details.length ? (
                              <tr>
                                <td align="center" colSpan={6}>
                                  No hay productos agregados a la orden
                                </td>
                              </tr>
                            ) : (
                              details.map((detail, index) => (
                                <tr key={index}>
                                  <td style={estilos.concept}>
                                    <strong> {detail.product.name} </strong>
                                    <br />
                                    {detail.product &&
                                    detail.product.product_type !==
                                      "service" ? (
                                      <>
                                        {detail.product &&
                                        detail.product.product_type ===
                                          "byarea" ? (
                                          <small>
                                            Medidas: {detail.base} x{" "}
                                            {detail.altura} m
                                          </small>
                                        ) : (
                                          detail.product.status === 1 && (
                                          <small>
                                            Tamaño: {detail.options.size.name}
                                          </small>
                                          )
                                        )}
                                     {
                                      order.type_of_sale === 2 && (
                                        <>
                                          <br />
                                          <small>
                                            Material:{" "}
                                            {detail.options.material.name}
                                          </small>
                                          <br />
                                          <small>
                                            Impresión:{" "}
                                            {detail.options.printing.name}
                                          </small>
                                          <br />
                                          <small>
                                            Acabado: {detail.options.option.name}
                                          </small>
                                        </>
                                      )
                                     }   
                                      </>
                                    ) : detail.options.measure.name === "m2" ? (
                                      <>
                                        <small>
                                          precio por:{" "}
                                          {detail.options.measure.name}
                                        </small>
                                        <br />
                                        <small>
                                          Medidas: {detail.options.size.base} x{" "}
                                          {detail.options.size.altura} m
                                        </small>{" "}
                                      </>
                                    ) : (
                                      <small>
                                        precio por:{" "}
                                        {detail.options.measure.name}
                                      </small>
                                    )}

                                    <br />
                                      <small>{detail.description}</small>
                                  </td>
                                  <td>
                                      {detail.product.product_type !==
                                      "service" ? (
                                            <button
                                              className="btn btn-primary btn-simple btn-sm"
                                              data-toggle="modal"
                                              data-target="#uploadFile"
                                              onClick={() => handleSelectedDetail(detail)}
                                            >
                                              {" "}
                                              <i className="zmdi zmdi-attachment-alt"></i>{" "}
                                              Ver archivos{" "}
                                            </button>
                                      ) : (
                                        <span> - </span>
                                      )}
                                    </td>
                                    <td className="text-center">
                                    {detail.quantity}{" "}
                                  </td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <UploadFiles 
        selectedDetail={selectedDetail}
        setSelectedDetail={setSelectedDetail}
      />
    </Layout>
  );
};

export default OrderDetails;
