import React from "react";
import AuthState from "./contexts/auth/authState";
import AppRouter from "./routes/AppRouter";

const App = () => {

  return (
    <AuthState>
        <AppRouter />
    </AuthState>
  );
};

export default App;
