import React, {useContext, useEffect, useState} from "react";
import { useForm } from "react-hook-form";
import FamiliesContext from "../../contexts/families/familiesContext";

export const EditFamily = () => {
    const { selectedFamily, updateFamily } = useContext(FamiliesContext);

    const [url_category, setUrlCategory] = useState('');
    const [selected, setSelected] = useState(null)

    const { register, errors, handleSubmit, reset } = useForm();

    useEffect(() => {
      if (selectedFamily.published) {
          setSelected(selectedFamily.published)
      } 
      // eslint-disable-next-line
    }, [selectedFamily])


    const handleCloseModal = () => {
        window.$('#editFamily').modal('hide');
    }

    const handleChangeUrlName = (e) => {

        let srt = e.target.value;
    
        let srt_spaces = srt.replace(/\s+/g, '-');
        let str_normalice = srt_spaces.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
        let str_final = str_normalice.toLowerCase();
        
        setUrlCategory(str_final)
      };

      const handleChange = (e) => {
        setSelected(Number(e.target.value));
      };
    
    const onSubmit = (data) => {
        data.id = selectedFamily.id;
        updateFamily(data);
        reset()
        setUrlCategory('')
        handleCloseModal();
    }

  return (
    <>
      <div className="modal fade" id="editFamily" tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title" id="defaultModalLabel">
                Actualizar Familia
              </h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        name="name"
                        ref={register({
                          required: {
                            value: true,
                            message: "El nombre de la Familia es requerido",
                          },
                        })}
                        onChange={(e) => handleChangeUrlName(e)}
                        defaultValue={selectedFamily ? selectedFamily.name : ""}
                        className={
                          errors.name
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Nombre *"
                      />
                      {errors.name && (
                        <label className="error">
                          El nombre de la Familia es requerido
                        </label>
                      )}
                    </div>
                    <div className="form-group">
                      <input
                        name="url_name"
                        ref={register({
                          required: {
                            value: true,
                            message: "La url de la familia es requerida",
                          },
                        })}
                        className={
                          errors.url_name
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Url *"
                        readOnly
                        defaultValue={
                          url_category ? url_category : selectedFamily.url_name
                        }
                      />
                      {errors?.url_name ? (
                        <label className="error">
                          {errors?.url_name?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Estatus</label>
                    <div className="form-group">
                      <div className="radio inlineblock m-r-20">
                        <input
                          type="radio"
                          name="published"
                          id="published2"
                          className="with-gap"
                          defaultValue={1}
                          ref={register({
                            required: true,
                          })}
                          checked={selected === 1}
                          onChange={handleChange}
                        />
                        <label htmlFor="published2">Online</label>
                      </div>
                      <div className="radio inlineblock">
                        <input
                          type="radio"
                          name="published"
                          id="draft2"
                          className="with-gap"
                          defaultValue={2}
                          ref={register({
                            required: true,
                          })}
                          checked={selected === 2}
                          onChange={handleChange}
                        />
                        <label htmlFor="draft2">Local</label>
                      </div>{" "}
                      <br />
                      {errors?.published ? (
                        <label className="error">Selecciona el estatus</label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  ACTUALIZAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
}
