import { ADD_PURCHASE, GET_MATERIALS_WITH_INVENTORY, GET_PROVIDERS_SELECT, GET_PURCHASES, SHOW_PURCHASE, REMOVE_PURCHASE, STORE_PAYMENT_PURCHASE, EDIT_PURCHASE, ADD_MATERIAL_FROM_PURCHASE_ORDER, CALCULATE_PURCHASE_ORDER_TOTALS, UPDATE_MATERIAL_FROM_PURCHASE_ORDER, REMOVE_MATERIAL_FROM_PURCHASE_ORDER, SAVE_PURCHASE_ORDER_EDITION, DELETE_PAYMENT_PURCHASE, UPDATE_PAYMENT_PURCHASE, UPDATE_STATUS_PURCHASE, SUPPLY_MATERIALS_TO_WHAREHOUSE } from "../../types";

export default function purchasesReducer(state, action) {
    switch (action.type) {
        case ADD_PURCHASE:
            return{
                ...state,
                success: true,
            }

        case GET_PURCHASES:
            return{
                ...state,
                purchases: action.payload,
                success:false,
                providers: [],
                materials: [],
                purchase: {},
                purchase_order_details: [],
                added_material_list: [],
                updated_material_list: [],
                deleted_material_list: [],
                subtotal_edit: 0,
                iva_edit: 0,
                total_edit: 0,
            }

        case SHOW_PURCHASE:
            return{
                ...state,
                success:false,
                purchase: action.payload,
            }

        case EDIT_PURCHASE:
            return{
                ...state,
                purchase: action.payload,
                purchase_order_details: action.payload.purchase_order_details,
                subtotal_edit: action.payload.subtotal,
                iva_edit: action.payload.iva,
                total_edit: action.payload.total,
                success:false,
            }

        case STORE_PAYMENT_PURCHASE:
            return {
              ...state,
              success: true,
            };

        case GET_PROVIDERS_SELECT:
            return{
                ...state,
                providers: action.payload
            }

        case GET_MATERIALS_WITH_INVENTORY:
            return{
                ...state,
                materials: action.payload
            }

        case REMOVE_PURCHASE:
            return {
                ...state,
                purchases: state.purchases.filter(
                (purchase) => purchase.id !== action.payload
                ),
            }

        case ADD_MATERIAL_FROM_PURCHASE_ORDER:
          let dataItem = action.payload;
          return {
            ...state,
            purchase_order_details: [...state.purchase_order_details, dataItem],
            added_material_list: [...state.added_material_list, dataItem],
          };


        case UPDATE_MATERIAL_FROM_PURCHASE_ORDER:
          let currentItem = action.payload;
          let addedItems = state.added_material_list;
          let updatedItems = state.updated_material_list;

          if (currentItem.isrecent === true) {
              addedItems = addedItems.map((material) =>
                material.id === currentItem.id ? currentItem : material
              );
          } else {
              let element = updatedItems.find(
                (material) => material.id === currentItem.id
              );

              if (element) {
                updatedItems = updatedItems.map((material) =>
                  material.id === currentItem.id ? currentItem : material
                );
              } else {
                updatedItems = updatedItems.concat(currentItem);
              }
          }
          return {
            ...state,
            purchase_order_details: state.purchase_order_details.map((detail) =>
              detail.id === currentItem.id ? currentItem : detail
            ),
            added_material_list: addedItems,
            updated_material_list: updatedItems,
          };

          case REMOVE_MATERIAL_FROM_PURCHASE_ORDER:
            let removeMaterial = action.payload;
            let order_details = state.purchase_order_details;
            let deletedMaterials = state.deleted_material_list
            let updateMaterials = state.updated_material_list;
            let addedMaterials = state.added_material_list;

            order_details = order_details.filter(
              (detail) => detail.id !== removeMaterial.id
            );

            //remover materiales agregados o editados durante la edicion de la venta

              //productos agregados
              let foundElement1 = addedMaterials.find(
                (element) => element.id === removeMaterial.id
              );

              //productos modificados
              let foundElement2 = updateMaterials.find(
                (element) => element.id === removeMaterial.id
              );

              if (foundElement1 || foundElement2) {
                if (foundElement1) {
                  addedMaterials = addedMaterials.filter(
                    (detail) => detail.id !== foundElement1.id
                  );
                }

                if (foundElement2) {
                  if (!foundElement2.isrecent) {
                    deletedMaterials = deletedMaterials.concat(foundElement2.id);
                  }
                  updateMaterials = updateMaterials.filter(
                    (detail) => detail.id !== foundElement2.id
                  );
                }
              } else {
                deletedMaterials = deletedMaterials.concat(removeMaterial.id);
              }

            return {
              ...state,
              purchase_order_details: order_details,
              added_material_list: addedMaterials,
              updated_material_list: updateMaterials,
              deleted_material_list: deletedMaterials,
            };

        case CALCULATE_PURCHASE_ORDER_TOTALS:
         const newSubtotal = state.purchase_order_details.reduce((sum, value) => sum + value.subtotal,0);
         const newIva = newSubtotal * 0.16;
         const newTotal = newSubtotal + newIva;

          return {
            ...state,
            subtotal_edit:Number(newSubtotal),
            iva_edit: Number(newIva),
            total_edit: Number(newTotal)
          };

        case SAVE_PURCHASE_ORDER_EDITION:
          return {
            ...state,
            success: true,
          };

        case UPDATE_STATUS_PURCHASE:
          return {
            ...state,
            success: true,
          };

        case DELETE_PAYMENT_PURCHASE:
            return {
              ...state,
              success: true,
            };
        case UPDATE_PAYMENT_PURCHASE:
          return {
            ...state,
            success: true,
          };

        case SUPPLY_MATERIALS_TO_WHAREHOUSE:
          return {
            ...state,
            success: true,
          };
        default:
          return state;
    }
}
