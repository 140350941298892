import React, { useReducer } from "react";
import PurchasesContext from "./purchasesContext";
import purchasesReducer from "./purchasesReducer";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import Swal from "sweetalert2";
import { ADD_PURCHASE, GET_MATERIALS_WITH_INVENTORY, GET_PROVIDERS_SELECT, GET_PURCHASES, SHOW_PURCHASE, REMOVE_PURCHASE, STORE_PAYMENT_PURCHASE, EDIT_PURCHASE, ADD_MATERIAL_FROM_PURCHASE_ORDER, CALCULATE_PURCHASE_ORDER_TOTALS, REMOVE_MATERIAL_FROM_PURCHASE_ORDER, UPDATE_MATERIAL_FROM_PURCHASE_ORDER, SAVE_PURCHASE_ORDER_EDITION, DELETE_PAYMENT_PURCHASE, UPDATE_PAYMENT_PURCHASE, UPDATE_STATUS_PURCHASE, SUPPLY_MATERIALS_TO_WHAREHOUSE } from "../../types";


const PurchasesState = (props) => {
 // estado inicial
 const initialSate = {
    purchases: [],
    providers: [],
    materials: [],
    purchase: {},
    purchase_order_details: [],
    added_material_list: [],
    deleted_material_list: [],
    updated_material_list: [],
    subtotal_edit: 0,
    iva_edit: 0,
    total_edit: 0,
    success: false,
  };

  const [state, dispatch] = useReducer(purchasesReducer, initialSate);

  //obtener las ordenes de compra
  const getPurchases = () => {
    let url = `purchases`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_PURCHASES,
        payload: response.data.data,
      });
    });
  };

  //obtener data para select proveedores
  const getProviders = () => {
    let url = "providers-list";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_PROVIDERS_SELECT,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

   //obtener data para select materiales inventario
   const getMaterials = () => {
    let url = "materials-all";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_MATERIALS_WITH_INVENTORY,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //agregar una orden de compra
  const addPurchase = (purchase) => {
    let url = "/purchases";
    MethodPost(url, purchase)
      .then((response) => {
        dispatch({
          type: ADD_PURCHASE,
        });
        Swal.fire({
            title: "Buen trabajo",
            text: response.data.message,
            icon: "success",
            timer: 3000,
            showConfirmButton: false,
          });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // obtener los datos de la compra
  const getPurchase = (id) => {
    let url = `purchases/${id}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: SHOW_PURCHASE,
        payload: response.data,
      });
    });
  };

  // obtener los datos de la compra a editar
  const getEditPurchase = (id) => {
    let url = `purchases/${id}/edit`;
    MethodGet(url).then((response) => {
      dispatch({
        type: EDIT_PURCHASE,
        payload: response.data,
      });
    });
  };

  //Agregar pago a la orden de compra
  const addPayment = (payment) => {
    let url = "/payment-provider";
    MethodPost(url, payment)
      .then((response) => {
        dispatch({
          type: STORE_PAYMENT_PURCHASE,
        });
        Swal.fire({
          title: "Correcto!",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };


  //Eliminar orden de compra
  const removePurchase = (id) => {
    let url = `purchases/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: 'Eliminado',
        text:  response.data.message,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false
    });
      dispatch({
        type: REMOVE_PURCHASE,
        payload: id,
      });
    });
  };

  //funcion para agregar nuvos material a la orden
  const addMaterialToOrder = (data) => {
    dispatch({
      type: ADD_MATERIAL_FROM_PURCHASE_ORDER,
      payload: data,
    });
    dispatch({
      type: CALCULATE_PURCHASE_ORDER_TOTALS,
    });
  };

  const updateMaterialToOrder = (data) => {
    dispatch({
      type: UPDATE_MATERIAL_FROM_PURCHASE_ORDER,
      payload: data,
    });
    dispatch({
      type: CALCULATE_PURCHASE_ORDER_TOTALS,
    });
  };

  //Eliminar material de la orden de compra
  const removeMaterialToOrder = (material) => {
    dispatch({
      type: REMOVE_MATERIAL_FROM_PURCHASE_ORDER,
      payload: material,
    });
    dispatch({
      type: CALCULATE_PURCHASE_ORDER_TOTALS,
    });
  }

  //funcion para guardar la edicion de la orden de compra
  const savePurchaseOrderEdition = (data) => {
    const orderData = {
      purchase_order_id: data.purchase_order_id,
      provider_id: data.provider_id.value,
      order_date: data.order_date,
      commentary: data.commentary,
      subtotal: Number(state.subtotal_edit.toFixed(2)),
      iva: Number(state.iva_edit.toFixed(2)),
      total: Number(state.total_edit.toFixed(2)),
      added_material_list: state.added_material_list,
      updated_material_list: state.updated_material_list,
      deleted_material_list: state.deleted_material_list,
    };

    let url = `/purchases/${data.purchase_order_id}`;
    Swal.fire({
      title: "Guardando...",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        MethodPut(url, orderData)
          .then((response) => {
            Swal.fire({
              title: "Correcto",
              text: response.data.message,
              icon: "success",
              timer: 5000,
              showConfirmButton: false,
            });
            Swal.close();
            dispatch({
              type: SAVE_PURCHASE_ORDER_EDITION,
            });
          })
          .catch((error) => {
            Swal.close();
            Swal.fire({
              title: "Algo salio mal :(",
              text: error.response.data.message,
              icon: "error",
              showConfirmButton: true,
            });
          });
        },
      });
    };

    //Actualizar estado de la orden de compra
    const updateStatus = (id, data) => {
      let url = `update-status-purchase/${id}`;
      MethodPut(url, data).then((response) => {
        Swal.fire({
          title: "Correcto",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_STATUS_PURCHASE,
        });
      }).catch((error) => {
        Swal.fire({
          title: "Algo salio mal :(",
          text: error.response.data.message,
          icon: "error",
          showConfirmButton: true,
        });
      });
    };

    //Actualizar pago de la orden de compra
    const updatePayment = (id, data) => {
      let url = `update-payment-purchase/${id}`;
      MethodPut(url, data).then((response) => {
        Swal.fire({
          title: "Correcto",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type: UPDATE_PAYMENT_PURCHASE,
        });
      }).catch((error) => {
        Swal.fire({
          title: "Algo salio mal :(",
          text: error.response.data.message,
          icon: "error",
          showConfirmButton: true,
        });
      });
    };

    //Eliminar pago de la orden de compra
    const deletePayment = (id) => {
      let url = `delete-payment-purchase/${id}`;
      MethodDelete(url).then((response) => {
        Swal.fire({
          title: 'Eliminado',
          text:  response.data.message,
          icon: 'success',
          timer: 3000,
          showConfirmButton: false
      });
        dispatch({
          type: DELETE_PAYMENT_PURCHASE,
          payload: id,
        });
      });
    };

    //funcion para ingresar los materiales a almacén y actualizar el inventario
    const supplyMaterialToWarehouse = (data) => {
      let url = `/supply-materials-to-wharehouse`;
      Swal.fire({
        title: "Actualizando...",
        allowEscapeKey: false,
        allowOutsideClick: false,
        didOpen: () => {
          Swal.showLoading();
          MethodPost(url, data)
            .then((response) => {
              Swal.fire({
                title: "Correcto",
                text: response.data.message,
                icon: "success",
                timer: 5000,
                showConfirmButton: false,
              });
              Swal.close();
              dispatch({
                type: SUPPLY_MATERIALS_TO_WHAREHOUSE,
              });
            })
            .catch((error) => {
              Swal.close();
              Swal.fire({
                title: "Algo salio mal :(",
                text: error.response.data.message,
                icon: "error",
                showConfirmButton: true,
              });
            });
          },
        });
      };


  return (
    <PurchasesContext.Provider
      value={{
        purchases: state.purchases,
        providers: state.providers,
        materials: state.materials,
        success: state.success,
        purchase: state.purchase,
        purchase_order_details: state.purchase_order_details,
        added_material_list: state.added_material_list,
        updated_material_list: state.updated_material_list,
        deleted_material_list: state.deleted_material_list,
        subtotal_edit: state.subtotal_edit,
        iva_edit: state.iva_edit,
        total_edit: state.total_edit,
        getPurchases,
        getProviders,
        getMaterials,
        addPurchase,
        getPurchase,
        removePurchase,
        addPayment,
        getEditPurchase,
        addMaterialToOrder,
        updateMaterialToOrder,
        removeMaterialToOrder,
        savePurchaseOrderEdition,
        deletePayment,
        updatePayment,
        updateStatus,
        supplyMaterialToWarehouse
      }}
    >
      {props.children}
    </PurchasesContext.Provider>
  );
};

export default PurchasesState;
