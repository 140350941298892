import { ADD_PROVIDER, ADD_PROVIDER_BANK_ACCOUNT, ADD_PROVIDER_CONTACT, EDIT_PROVIDER, GET_ENTITIES, GET_MUNICIPALITIES, GET_PROVIDERS, REMOVE_PROVIDER, REMOVE_PROVIDER_BANK_ACCOUNT, REMOVE_PROVIDER_CONTACT, SHOW_PROVIDER, UPDATE_PROVIDER, UPDATE_PROVIDER_BANK_ACCOUNT, UPDATE_PROVIDER_CONTACT } from '../../types';

export default function providersReducer(state, action) {
    switch (action.type) {
        case ADD_PROVIDER:
            return{
                ...state,
                success: true,
            }
        case GET_PROVIDERS:
            return{
                ...state,
                selectedProvider: {},
                providers: action.payload.data,
                success:false,
            }
        case SHOW_PROVIDER:
            return{
                ...state,
                selectedProvider: action.payload.data,
                success:false,
            }
        
        case EDIT_PROVIDER:
            return {
                ...state,
                selectedProvider: state.providers.find(provider => provider.id === action.payload),
            }
        
        case UPDATE_PROVIDER:
            return{
                ...state,
                success: true,
                selectedProvider: [],
            }

        case REMOVE_PROVIDER:
            return {
                ...state,
                providers: state.providers.filter(
                (provider) => provider.id !== action.payload
                ),
            }
        case GET_ENTITIES:
            return{
                ...state,
                entities: action.payload
            } 

        case GET_MUNICIPALITIES:
            return{
                ...state,
                municipalities: action.payload
            } 

        case ADD_PROVIDER_CONTACT:
            return{
                ...state,
                success: true,
            }

        case UPDATE_PROVIDER_CONTACT:
            return{
                ...state,
                success: true,
                }

        case REMOVE_PROVIDER_CONTACT:
            return{
                ...state,
                success: true,
                }

        case ADD_PROVIDER_BANK_ACCOUNT:
            return{
                ...state,
                success: true,
            }

        case UPDATE_PROVIDER_BANK_ACCOUNT:
            return{
                ...state,
                success: true,
                }

        case REMOVE_PROVIDER_BANK_ACCOUNT:
            return{
                ...state,
                success: true,
                }

        default:
            return state;
    }
}
