import { GET_ORDERS_PRODUCTION, SHOW_ORDER_PRODUCTION, UPDATE_STATUS_ORDER_PRODUCTION } from "../../types";


export default function productionReducer(state, action) {
  switch (action.type) {

    case GET_ORDERS_PRODUCTION:
      return {
        ...state,
        orders: action.payload.data,
        current_page: action.payload.current_page,
        per_page: action.payload.per_page,
        total: action.payload.total,
        success: false,
        order: [],
        details: [],
      };
    
    case SHOW_ORDER_PRODUCTION:
      return {
          ...state,
          success: false,
          order: action.payload.order,
          details: action.payload.details,
          
      }

    case UPDATE_STATUS_ORDER_PRODUCTION:
      return {
        ...state,
        success: true,
      };

    default:
      return state;
  }
}
