import React, { useContext } from "react";
import OrdersContext from "../../../contexts/orders/ordersContext";

const TableProducts = () => {
  const { productsList, deleteProductSale } = useContext(OrdersContext);
  return (
    <>
      <div className="table-responsive">
        <table className="table table-hover c_table theme-color">
          <thead>
            <tr>
              <th>Descripción</th>
              <th>Cantidad</th>
              <th className="hidden-sm-down">P.U.</th>
              <th>Total</th>
              <th className="hidden-sm-down"></th>
            </tr>
          </thead>
          <tbody>
            {!productsList.length ? (
              <tr>
                <td align="center" colSpan={6}>
                  No hay productos agregados a la orden
                </td>
              </tr>
            ) : (
              productsList.map((product, index) => (
                <tr key={index}>
                  <td>
                    <strong> {product.product_id.label} </strong>
                    {product && product.product.product_type !== "service" ? (
                      <>
                        {product &&
                        product.product.product_type === "byarea" ? (
                          <>
                            <br />
                            <small>
                              Medidas: {product.base} x {product.altura} m
                            </small>{" "}
                          </>
                        ) : (
                          product.product.status === 1 && (
                            <>
                              <br />
                              <small>Tamaño: {product.size.label}</small>
                            </>
                          )
                        )}
                      </>
                    ) : product.measure === "m2" ? (
                      <>
                        <small>precio por: {product.measure}</small>
                        <br />
                        <small>
                          Medidas: {product.base} x {product.altura} m
                        </small>{" "}
                      </>
                    ) : (
                      <small>precio por: {product.measure}</small>
                    )}

                    <br />
                    {product.description ? (
                      <small>{product.description}</small>
                    ) : null}
                  </td>

                  <td>{product.quantity}</td>
                  <td className="hidden-sm-down">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 2,
                    }).format(product.price)}
                  </td>
                  <td>
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 2,
                    }).format(product.final_price)}
                  </td>
                  <td className="hidden-sm-down">
                    <button
                      className="btn btn-danger btn-sm"
                      onClick={() => deleteProductSale(product.id)}
                    >
                      <i className="zmdi zmdi-delete"></i>
                    </button>
                  </td>
                </tr>
              ))
            )}
          </tbody>
        </table>
      </div>
    </>
  );
};

export default TableProducts;
