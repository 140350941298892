/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { Link, useParams } from "react-router-dom";
import PurchasesContext from "../../contexts/purchases/purchasesContext";
import RightButtonToggle from "../../components/RightButtonToggle";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import Moment from "react-moment";
import Swal from "sweetalert2";
import EditPayment from "./payments/EditPayment";

const DetailPurchase = () => {
  const { id } = useParams();

  const {
    getPurchase,
    purchase,
    success,
    deletePayment,
    supplyMaterialToWarehouse
  } = useContext(PurchasesContext);
  // desestructurar purchase
  const {
    provider,
    purchase_order_details,
    purchase_order_payments,
  } = purchase;

  const [selectedPayment, setSelectedPayment] = useState({});

  //state de los materiales a ingresar a almacen
  const [selected, setSelected] = useState([]);

  //funcion que selecciona el material y lo agrega al array
  const handleClick = (event, material) => {
    const selectedIndex = selected.indexOf(material);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, material);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const isSelected = (material) => selected.indexOf(material) !== -1;

  const showConfirmMessage = (payment) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: `Eliminar pago de la orden.`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        deletePayment(payment);
      }
    });
  };

  useEffect(() => {
    getPurchase(id);
    // eslint-disable-next-line
  }, [success]);


  const handleEditPayment = (payment) => {
    setSelectedPayment(payment);

    setTimeout(() => {
      window.$("#editPaymentModal").modal("show");
    }, 1000);
  }

  //funcion ingresar los materiales al almacen
  const handleMaterialStock = () => {
    if (!selected.length) return;
    Swal.fire({
      title: "Ingresar Material",
      text: 'Se ingresara el material seleccionado a almacen',
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Confirmar",
      cancelButtonText: "Cancelar",
    }).then((result) => {
      if (result.isConfirmed) {
        supplyMaterialToWarehouse({'purchase_order_items':selected});
        setSelected([]);
      }
    });
  };


  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Compra</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/compras">Compras</Link>
                  </li>
                  <li className="breadcrumb-item active">Detalles</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <h5>
                      <strong>Compra </strong> #{id}
                    </h5>
                    <div className="row">
                      <div className="col-md-9 col-sm-9">
                        <p className="mb-0">
                          <strong>Proveedor: </strong>
                            <br/>
                            {provider?.nombre_comercial}
                        </p>
                        <p className="mb-0">
                          <strong>Estatus Pago: </strong>
                          <br/>
                            {purchase?.order_status !== "cancelada" ? (
                              purchase?.paid_status === "pendiente" ? (
                                <span className="badge bg-orange">PENDIENTE</span>
                              ) : purchase?.paid_status === "pago incompleto" ? (
                                <span className="badge bg-amber">PAGO INCOMPLETO</span>
                              ): purchase?.paid_status === "pagado" ? (
                                <span className="badge bg-green">PAGADO</span>
                              ) : null) : '--'}
                        </p>
                      </div>
                      <div className="col-md-3 col-sm-3 text-left">
                        <p className="mb-0">
                          <strong>Fecha de compra: </strong>
                          <br/>
                          <Moment format="DD/MMMM/YYYY">
                            {purchase.order_date}
                          </Moment>
                        </p>
                        <p className="mb-0">
                          <strong>Estatus compra: </strong>
                            <br/>
                          {purchase?.order_status === "solicitada" ? (
                            <span className="badge bg-light-green">SOLICITADA</span>
                          ) : purchase?.order_status === "en proceso" ? (
                            <span className="badge bg-teal">EN PROCESO</span>
                          ): purchase?.order_status === "completada" ? (
                            <span className="badge bg-light-blue">COMPLETADA</span>
                          ) : purchase?.order_status === "cancelada" ? (
                            <span className="badge bg-red">CANCELADA</span>
                          ): null}
                        </p>
                      </div>
                      <div className="col-12">
                      <p className="mb-0">
                        <strong>Observaciones: </strong>
                          <br/>
                          {purchase?.commentary}
                      </p>
                      </div>
                      <div className="col-12">
                        <div className="btn-group">
                            <button
                              className="btn btn-outline-secondary btn-sm"
                                onClick={() => handleMaterialStock()}
                            >
                              <i className="zmdi zmdi-home" /> {""}
                                Ingresar Material
                            </button>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="row">
                    <div className="col-md-12">
                      <div className="table-responsive">
                        <table className="table c_table theme-color">
                          <thead>
                            <tr>
                              <th></th>
                              <th>#</th>
                              <th>Descripción</th>
                              <th className="text-center">Cantidad</th>
                              <th className="text-center">Unidad</th>
                              <th>Precio Unit</th>
                              <th>Importe</th>
                              <th>Estado</th>
                              <th>Fecha Ingreso</th>
                            </tr>
                          </thead>
                          <tbody>
                          {!purchase_order_details?.length ? (
                            <tr>
                              <td align="center" colSpan={9}>
                                No hay productos agregados a la orden
                              </td>
                            </tr>
                          ) : (
                            purchase_order_details?.map((detail, index) => {
                              const isItemSelected = isSelected(detail.id);
                              return (

                                  <tr
                                    key={index}
                                    className={
                                      isItemSelected ? "table-success" : null
                                    }
                                  >
                                  <td className="chb">
                                  {detail.status !== "SURTIDO" ?
                                    <div className="checkbox simple">
                                      <input
                                        id={`mc${detail.id}`}
                                        type="checkbox"
                                        onChange={(event) =>
                                          handleClick(event, detail.id)
                                        }
                                        checked={isItemSelected}
                                      />
                                      <label htmlFor={`mc${detail.id}`} />
                                    </div>
                                    : null }
                                  </td>
                                  <td>
                                    {index + 1}
                                  </td>
                                    <td>
                                      <strong> {detail.material.name} </strong>
                                      <br />
                                      <small>{detail.description}</small>
                                    </td>
                                    <td className="text-center">
                                      <strong>{detail.quantity}</strong>
                                    </td>
                                    <td className="text-center">
                                      {detail.unidad_compra}
                                    </td>
                                    <td className="hidden-sm-down">
                                      {new Intl.NumberFormat("es-MX", {
                                        style: "currency",
                                        currency: "MXN",
                                        minimumFractionDigits: 2,
                                      }).format(detail.unit_price)}
                                    </td>
                                    <td>
                                      {new Intl.NumberFormat("es-MX", {
                                        style: "currency",
                                        currency: "MXN",
                                        minimumFractionDigits: 2,
                                      }).format(detail.subtotal)}
                                    </td>
                                    <td>
                                      {detail.status === "NO SURTIDO" ?
                                        <span className="badge bg-amber">NO SURTIDO</span>
                                      :
                                        <span className="badge bg-light-green">SURTIDO</span>
                                      }
                                    </td>
                                    <td>
                                      {detail.status === "SURTIDO" ?
                                        <Moment format="DD/MM/YYYY">
                                          {detail.entry_date}
                                        </Moment>
                                      : null
                                      }
                                    </td>
                                  </tr>
                              );
                            })
                          )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                    <div className="col-md-8 order-2 order-md-12">
                      <div className="body">
                      <div className="header">
                          <h2><strong>Pagos</strong> Realizados</h2>
                          <div className="header-dropdown">
                              Total pagado <strong>
                              {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                                minimumFractionDigits: 2,
                              }).format(purchase?.total_payments)}</strong>
                          </div>
                      </div>
                        <ul className="comment-reply list-unstyled">
                          {purchase_order_payments?.map((payment, index) => {
                            return (
                              <div key={`payment-${index}`}>
                                <li>
                                  <div className="text-box">
                                    <div className="replybutton">
                                      <div className="btn-group">
                                        <span
                                          className="btn btn-outline-primary btn-sm"
                                          onClick={() => handleEditPayment(payment)}
                                        >
                                          <i className="zmdi zmdi-edit" />
                                        </span>
                                        <span
                                          className="btn btn-outline-danger btn-sm"
                                          onClick={() => showConfirmMessage(payment.id)}
                                        >
                                          <i className="zmdi zmdi-delete" />
                                        </span>
                                      </div>
                                    </div>
                                    <div className="row text-small">
                                      <div className="col-lg-3 mb-2">
                                        <span className="text-muted">
                                          Fecha
                                        </span>
                                        <br />
                                        <Moment format="DD/MM/YYYY">
                                          {payment.payment_date}
                                        </Moment>
                                      </div>
                                      <div className="col-lg-4 mb-2">
                                        <span className="text-muted">Metodo Pago</span>
                                        <br />
                                        {payment.payment_method}
                                      </div>
                                      <div className="col-lg-2 mb-2">
                                        <span className="text-muted">
                                          Monto
                                        </span>
                                        <br />
                                        {new Intl.NumberFormat("es-MX", {
                                          style: "currency",
                                          currency: "MXN",
                                          minimumFractionDigits: 2,
                                        }).format(payment.amount)}
                                      </div>
                                      <div className="col-lg-3 mb-2">
                                        <span className="text-muted">Folio Fiscal</span>
                                        <br />
                                        {payment.invoice_folio}
                                      </div>
                                      <div className="col-lg-12 mb-2">
                                        <span className="text-muted">Observaciones</span>
                                        <br />
                                        {payment.notes}
                                      </div>
                                    </div>
                                  </div>
                                </li>
                                <hr />
                              </div>
                            );
                          })}
                        </ul>
                      </div>
                    </div>
                    <div className="col-md-4 mb-4 order-1 order-md-12">
                      <div className="body">
                        <div className="table-responsive">
                          <table className="table">
                            <tbody>
                              <tr>
                                <th className="text-left">Subtotal:</th>
                                <td className="text-right">
                                  {new Intl.NumberFormat("es-MX", {
                                    style: "currency",
                                    currency: "MXN",
                                    minimumFractionDigits: 2,
                                  }).format(purchase?.subtotal)}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">
                                  IVA:
                                  <span className="font-weight-normal">
                                    (16%)
                                  </span>
                                </th>
                                <td className="text-right">
                                  {new Intl.NumberFormat("es-MX", {
                                    style: "currency",
                                    currency: "MXN",
                                    minimumFractionDigits: 2,
                                  }).format(purchase?.iva)}
                                </td>
                              </tr>
                              <tr>
                                <th className="text-left">Total:</th>
                                <td className="text-right text-primary">
                                  <h5 className="font-weight-semibold">
                                    {new Intl.NumberFormat("es-MX", {
                                      style: "currency",
                                      currency: "MXN",
                                      minimumFractionDigits: 2,
                                    }).format(purchase?.total)}
                                  </h5>
                                </td>
                              </tr>
                            </tbody>
                          </table>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <EditPayment
        selectedPayment={selectedPayment}
        setSelectedPayment={setSelectedPayment}
      />
    </Layout>
  );
};

export default DetailPurchase;
