import { ADD_IMAGES_PRODUCT, ADD_MATERIAL_PRODUCT, ADD_OPTION_PRODUCT, ADD_PRICE_PRODUCT, ADD_PRINTING_PRODUCT, ADD_PRODUCT, ADD_SIZE_PRODUCT, DELETE_DATA_FROM_LOCALSTORAGE, DELETE_IMAGE_PRODUCT, DELETE_MATERIAL_PRODUCT, DELETE_OPTION_PRODUCT, DELETE_PRICE_PRODUCT, DELETE_PRINTING_PRODUCT, DELETE_SIZE_PRODUCT, EDIT_OPTION_PRODUCT, EDIT_PRICE_PRODUCT, EDIT_PRODUCT, GET_FAMILIES_SELECT, GET_MATERIALS_SELECT, GET_MEASUREMENTS_SELECT, GET_OPTIONS_SELECT, GET_PRINTINGS_SELECT, GET_PRODUCTS, GET_SIZES_SELECT, REMOVE_PRODUCT, SHOW_PRODUCT, UPDATE_OPTION_PRODUCT, UPDATE_PRICE_PRODUCT, UPDATE_PRODUCT } from "../../types"


export default function productsReducer(state, action) {
    switch (action.type) {
        case ADD_PRODUCT:
            return{
                ...state,
                success: true,
            }

        case GET_PRODUCTS:
            return{
                ...state,
                products: action.payload,
                success:false,
                families: [],
                measurements: [],
                materials: [],
                selectedProduct: [],
                product: [],
                sizes: [],
                printing: [],
                printings: [],
                options: [],
                prices: [],
                materialProduct: [],
                sizesProduct: [],
                printingProduct: [],
                optionsProduct: [],
                imagesProduct: [],
                selectedPrice: [],
                selectedOption: []
            }

        case EDIT_PRODUCT:
            return {
                ...state,
                selectedProduct: state.products.find(product => product.id === action.payload),
            }

        case UPDATE_PRODUCT:
            return{
                ...state,
                success: true,
                selectedProduct: [],
            }

        case REMOVE_PRODUCT:
            return {
                ...state,
                products: state.products.filter(
                (product) => product.id !== action.payload
                ),
            }

        case SHOW_PRODUCT:
            return {
                ...state,
                success: false,
                product: action.payload.product,
                sizesProduct: action.payload.sizes,
                materialProduct: action.payload.materialProduct,
                printingProduct: action.payload.printing,
                optionsProduct: action.payload.options,
                prices: action.payload.prices,
                imagesProduct: action.payload.imagesProduct

            }

        case GET_FAMILIES_SELECT:
            return{
                ...state,
                families: action.payload
            }

        case GET_MEASUREMENTS_SELECT:
            return{
                ...state,
                measurements: action.payload
            }

        case GET_MATERIALS_SELECT:
            return{
                ...state,
                materials: action.payload
            }

        case GET_SIZES_SELECT:
            return{
                ...state,
                sizes: action.payload
            }

        case GET_PRINTINGS_SELECT:
            return{
                ...state,
                printings: action.payload
            }

        case GET_OPTIONS_SELECT:
            return{
                ...state,
                options: action.payload
            }

        case DELETE_DATA_FROM_LOCALSTORAGE:
            //Eliminar la data de localstorage
            localStorage.removeItem("_product");
            localStorage.removeItem("_sizes");
            localStorage.removeItem("_material");
            localStorage.removeItem("_printing");
            localStorage.removeItem("_options");
            return {
                ...state,
            }

        case ADD_PRICE_PRODUCT:
            return{
                ...state,
                success: true,
            }

        case ADD_SIZE_PRODUCT:
            return{
                ...state,
                success: true,
            }

        case ADD_MATERIAL_PRODUCT:
            return{
                ...state,
                success: true,
            }

        case ADD_PRINTING_PRODUCT:
            return{
                ...state,
                success: true,
            }

        case ADD_OPTION_PRODUCT:
            return{
                ...state,
                success: true,
            }

        case ADD_IMAGES_PRODUCT:
            return{
                ...state,
                success: true,
            }

        case DELETE_PRICE_PRODUCT:
            return {
                ...state,
                prices: state.prices.filter(
                (price) => price.id !== action.payload
                ),
            }

        case DELETE_SIZE_PRODUCT:
            return {
                ...state,
                sizesProduct: state.sizesProduct.filter(
                (size) => size.id !== action.payload
                ),
            }

        case DELETE_MATERIAL_PRODUCT:
            return {
                ...state,
                materialProduct: state.materialProduct.filter(
                (material) => material.id !== action.payload
                ),
            }

        case DELETE_PRINTING_PRODUCT:
            return {
                ...state,
                printingProduct: state.printingProduct.filter(
                (print) => print.id !== action.payload
                ),
            }

        case DELETE_OPTION_PRODUCT:
            return {
                ...state,
                optionsProduct: state.optionsProduct.filter(
                (option) => option.id !== action.payload
                ),
            }

        case DELETE_IMAGE_PRODUCT:
            return {
                ...state,
                imagesProduct: state.imagesProduct.filter(
                (image) => image.id !== action.payload
                ),
            }

        case EDIT_PRICE_PRODUCT:
            return {
                ...state,
                selectedPrice: state.prices.find(price => price.id === action.payload),
            }

        case UPDATE_PRICE_PRODUCT:
            return{
                ...state,
                success: true,
                selectedPrice: [],
            }

        case EDIT_OPTION_PRODUCT:
            return {
                ...state,
                selectedOption: state.optionsProduct.find(option => option.id === action.payload),
            }

        case UPDATE_OPTION_PRODUCT:
            return{
                ...state,
                success: true,
                selectedOption: [],
            }

        default:
            return state;
    }
}
