import React, { useReducer } from "react";
import MethodGet, { MethodDelete, MethodPost } from "../../services/api";
import $ from "jquery";
import "bootstrap-notify";
import Swal from "sweetalert2";
import CarouselContext from "./carouselContext";
import carouselReducer from "./carouselReducer";
import { ADD_SLIDE, EDIT_SLIDE, GET_SLIDES, REMOVE_SLIDE, UPDATE_SLIDE } from "../../types";

const CarouselState = (props) => {
  // estado inicial
  const initialSate = {
    slides: [],
    selectedSlide: [],
    success: false,
    current_page: null,
    total: null,
    per_page: null,
  };

  const [state, dispatch] = useReducer(carouselReducer, initialSate);

  //obtener los slides
  const getSlides = (page = 1) => {
    let url = `slides?page=${page}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_SLIDES,
        payload: response.data.data,
      });
    });
  };

  const addSlide = (slide) => {
    const formData = new FormData();
    formData.append("description", slide.description);
    formData.append("destination_url", slide.destination_url);
    formData.append("image_url", slide.image_url);

    let url = "/slides";
    MethodPost(url, formData)
      .then((response) => {
        dispatch({
          type: ADD_SLIDE,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR SLIDE A EDITAR
  const editSlide = (slide) => {
    dispatch({
      type: EDIT_SLIDE,
      payload: slide,
    });
  };

  //Actualizar slide
  const updateSlide = (data) => {
    const formData = new FormData();
    if (data.image_url !== "") {
      formData.append("image_url", data.image_url);
    }
    formData.append("description", data.description);
    formData.append("destination_url", data.destination_url);
    formData.append("_method", "PATCH");

    let url = `slides/${data.id}`;
    MethodPost(url, formData)
      .then((response) => {
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
        dispatch({
          type: UPDATE_SLIDE,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar slide
  const removeSlide = (id) => {
    let url = `slides/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: "Eliminado",
        text: response.data.message,
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
      });
      dispatch({
        type: REMOVE_SLIDE,
        payload: id,
      });
    });
  };

  return (
    <CarouselContext.Provider
      value={{
        slides: state.slides,
        success: state.success,
        current_page: state.current_page,
        total: state.total,
        per_page: state.per_page,
        selectedSlide: state.selectedSlide,
        getSlides,
        addSlide,
        editSlide,
        removeSlide,
        updateSlide,
      }}
    >
      {props.children}
    </CarouselContext.Provider>
  );
};

export default CarouselState;
