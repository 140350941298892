import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import FamiliesContext from "../../contexts/families/familiesContext";

const AddFamily = () => {
  const { addFamily } = useContext(FamiliesContext);

  const { register, errors, handleSubmit, reset } = useForm();
  const [url_category, setUrlCategory] = useState('');

  const handleCloseModal = () => {
    window.$("#addFamily").modal("hide");
  };

  const handleChangeUrlName = (e) => {

    let srt = e.target.value;

    let srt_spaces = srt.replace(/\s+/g, '-');
    let str_normalice = srt_spaces.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    let str_final = str_normalice.toLowerCase();

    setUrlCategory(str_final)
  };


  const onSubmit = (data) => {
    addFamily(data);
    reset();
    setUrlCategory('')
    handleCloseModal();
  };

  return (
    <>
      <div className="modal fade" id="addFamily" tabIndex={-1} role="dialog">
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title" id="defaultModalLabel">
                Agregar Familia
              </h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <input
                        name="name"
                        ref={register({
                          required: {
                            value: true,
                            message: "El nombre de la Familia es requerido",
                          },
                        })}
                        className={
                          errors.name
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Nombre *"
                        onChange={(e) => handleChangeUrlName(e)}
                      />
                      {errors?.name ? (
                        <label className="error">{errors?.name?.message}</label>
                      ) : null}
                    </div>
                    <div className="form-group">
                      <input
                        name="url_name"
                        ref={register({
                          required: {
                            value: true,
                            message: "La url de la familia es requerida",
                          },
                        })}
                        className={
                          errors.url_name
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Url *"
                        readOnly
                        defaultValue={url_category}
                      />
                      {errors?.url_name ? (
                        <label className="error">
                          {errors?.url_name?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <label>Estatus</label>
                    <div className="form-group">
                      <div className="radio inlineblock m-r-20">
                        <input
                          type="radio"
                          name="published"
                          id="published3"
                          className="with-gap"
                          defaultValue={1}
                          ref={register({
                            required: true,
                          })}
                        />
                        <label htmlFor="published3">Online</label>
                      </div>
                      <div className="radio inlineblock">
                        <input
                          type="radio"
                          name="published"
                          id="draft3"
                          className="with-gap"
                          defaultValue={2}
                          ref={register({
                            required: true,
                          })}
                        />
                        <label htmlFor="draft3">Local</label>
                      </div>{" "}
                      <br />
                      {errors?.published ? (
                        <label className="error">Selecciona el estatus</label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  GUARDAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddFamily;
