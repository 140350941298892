import React, { useReducer } from "react";
import MethodGet, { MethodPost, MethodPut } from "../../services/api";
import inventoriesReducer from "./inventoriesReducer";
import InventoriesContext from "./inventoriesContext";
import {
  ADD_INVENTORY,
  ADJUST_INVENTORY,
  EDIT_INVENTORY,
  GET_HISTORICAL_STOCK,
  GET_INVENTORIES,
  GET_MATERIALS_SELECT,
  GET_MEASUREMENTS_SELECT,
  UPDATE_INVENTORY,
  UPDATE_STOCK,
} from "../../types";
import { toast } from "react-toastify";

const InventoriesState = (props) => {
  // estado inicial
  const initialSate = {
    inventories: [],
    selectedInventory: [],
    measurements: [],
    materials: [],
    historical: [],
    success: false,
  };

  const [state, dispatch] = useReducer(inventoriesReducer, initialSate);

  //obtener la lista de inventarios
  const getInventories = () => {
    let url = `inventories`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_INVENTORIES,
        payload: response.data.data,
      });
    });
  };

  //SELECCIONAR INVENTARIO A EDITAR
  const editInventory = (inventory) => {
    dispatch({
      type: EDIT_INVENTORY,
      payload: inventory,
    });
  };

  //Actualizar un inventario
  const updateInventory = (data) => {
    let url = `inventories/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        dispatch({
          type: UPDATE_INVENTORY,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Actualizar la cantodad de stock de material
  const updateStock = (data) => {
    let url = `update-stock/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        dispatch({
          type: UPDATE_STOCK,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Ajustar la cantidad de inventario
  const ajustarStock = (data) => {
    let url = `ajustar-stock/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        dispatch({
          type: ADJUST_INVENTORY,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };


  //Obtener el historial del material
  const getHistoricalStock = (inventory, page = 1) => {
    let url = `hisinventory/${inventory}?page=${page}`;
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_HISTORICAL_STOCK,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Obtener la data para el select de unidades
  const getMeasurements = () => {
    let url = "measurements-all";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_MEASUREMENTS_SELECT,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

      //Obtener la data para el select de materiales
      const getMaterials = () => {
        let url = "materials-all";
        MethodGet(url)
          .then((response) => {
            dispatch({
              type: GET_MATERIALS_SELECT,
              payload: response.data.data,
            });
          })
          .catch((error) => {
            console.log(error);
          });
      };

      const addInventory = (data) => {
        let url = "/inventories";
        MethodPost(url, data)
          .then((response) => {
            dispatch({
              type: ADD_INVENTORY,
            });
            toast.success(response.data.message, { theme: "colored" });
          })
          .catch((error) => {
            toast.info(error.response.data.message, { theme: "colored" });
          });
      };

  return (
    <InventoriesContext.Provider
      value={{
        inventories: state.inventories,
        selectedInventory: state.selectedInventory,
        measurements: state.measurements,
        materials: state.materials,
        historical: state.historical,
        success: state.success,
        getInventories,
        editInventory,
        updateInventory,
        getMeasurements,
        updateStock,
        getHistoricalStock,
        getMaterials,
        addInventory,
        ajustarStock
      }}
    >
      {props.children}
    </InventoriesContext.Provider>
  );
};

export default InventoriesState;
