import React, { useContext } from "react";
import { useForm, Controller } from "react-hook-form";
import { useParams } from "react-router-dom";
import Select from "react-select";
import CustomersContext from "../../../contexts/customers/customersContext";

const AddShippingAddress = () => {
  const {
    entities,
    municipalities,
    getMunicipalities,
    addShippingAddess
  } = useContext(CustomersContext);

  const { register, errors, handleSubmit, setValue, control, reset} = useForm();

  const { id } = useParams();

  //funcion que se encarga de listar los estados en el select
  const optionsEntities = entities.map((entity) => {
    return { value: entity.id, label: entity.name };
  });

  //funcion que se encarga de listar los municipios en el select
  const optionsMunicipalities = municipalities.map((municipality) => {
    return { value: municipality.id, label: municipality.name };
  });

  //funcion que cambia los municipios del select
  const handleChangeEntity = (value) => {
    setValue("entity", value, { shouldValidate: true });
    setValue("municipality_id", "");
    getMunicipalities(value.value);
  };

  const handleCloseModal = () => {
    window.$("#addShippingAddess").modal("hide");
  };

  const onSubmit = (data) => {
    data.customer_id = id;
    addShippingAddess(data);
    reset();
    handleCloseModal();
  };

  return (
    <>
      <div
        className="modal fade"
        id="addShippingAddess"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-lg" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title">Agregar Nueva Dirección</h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row clearfix">
                  <div className="col-sm-4">
                    <label htmlFor="email_address">Calle</label>
                    <div className="form-group">
                      <input
                        name="street"
                        type="text"
                        placeholder="Ej. Av. Insurgentes"
                        ref={register({
                          required: {
                            value: true,
                            message: "El nombre de la calle es requerida",
                          },
                        })}
                        className={
                          errors.street
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.street ? (
                        <label className="error">
                          {errors?.street?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="email_address">No. Ext</label>
                    <div className="form-group">
                      <input
                        name="number_out"
                        type="text"
                        placeholder="240"
                        ref={register({
                          required: {
                            value: true,
                            message: "El No. Exterior es requerido",
                          },
                          pattern: {
                            value: /^[0-9\b]+$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.number_out
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.number_out ? (
                        <label className="error">
                          {errors?.number_out?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="email_address">No. Int</label>
                    <div className="form-group">
                      <input
                        name="number_int"
                        type="text"
                        placeholder="12"
                        ref={register({
                          required: {
                            value: false,
                            message: "El No. Interior es requerido",
                          },
                          pattern: {
                            value: /^[0-9\b]+$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.number_int
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.number_int ? (
                        <label className="error">
                          {errors?.number_int?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-4">
                    <label htmlFor="email_address">Colonia</label>
                    <div className="form-group">
                      <input
                        name="location"
                        type="text"
                        placeholder="San Francisco"
                        ref={register({
                          required: {
                            value: true,
                            message: "La colonia es requerida",
                          },
                        })}
                        className={
                          errors.location
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.location ? (
                        <label className="error">
                          {errors?.location?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
                <div className="row clearfix">
                  <div className="col-sm-5">
                    <label htmlFor="email_address">Estado</label>
                    <div className="form-group">
                      <Controller
                        name="entity"
                        control={control}
                        defaultValue=""
                        render={({ field }) => (
                          <Select
                            {...field}
                            placeholder="Selecciona una opción"
                            options={optionsEntities}
                            onChange={(e) => handleChangeEntity(e)}
                          />
                        )}
                        rules={{ required: "El Estado es un campo requerido " }}
                      />
                      {errors?.entity ? (
                        <label className="error">
                          {errors?.entity?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-5">
                    <label htmlFor="email_address">Municipio</label>
                    <div className="form-group">
                      <Controller
                          name="municipality_id"
                          control={control}
                          defaultValue=""
                          placeholder="Selecciona una opción"
                          options={optionsMunicipalities}
                          as={<Select/>}
                          rules={{ required: "El Municipio es un campo Requerido" }}
                      />
                      {errors?.municipality_id ? (
                        <label className="error">
                          {errors?.municipality_id?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-2">
                    <label htmlFor="email_address">C.P.</label>
                    <div className="form-group">
                      <input
                        name="postal_code"
                        type="text"
                        placeholder="54200"
                        ref={register({
                          required: {
                            value: true,
                            message: "El Código Postal es requerido",
                          },
                          maxLength: {
                            value: 5,
                            message: "Maximo 5 caracteres",
                          },
                          minLength: {
                            value: 1,
                            message: "Minimo 0 caracteres",
                          },
                          pattern: {
                            value: /^[0-9\b]+$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.postal_code
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                      />
                      {errors?.postal_code ? (
                        <label className="error">
                          {errors?.postal_code?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
                <label htmlFor="password">Referencias</label>
                <div className="form-group">
                  <textarea
                    name="reference"
                    rows={3}
                    placeholder="Ej. Se encuentra a lado de un oxxo..."
                    ref={register({
                      required: {
                        value: false,
                        message: "La Referencia es Requerida",
                      },
                      maxLength: {
                        value: 150,
                        message: "Maximo 150 caracteres",
                      },
                    })}
                    className={
                      errors.reference
                        ? "form-control is-invalid no-resize"
                        : "form-control no-resize"
                    }
                  />
                  {errors?.reference ? (
                    <label className="error">
                      {errors?.reference?.message}
                    </label>
                  ) : null}
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  GUARDAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default AddShippingAddress;
