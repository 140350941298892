import React, {useContext, useState, useEffect} from "react";
import { useForm, Controller } from "react-hook-form";
import PurchasesContext from "../../../contexts/purchases/purchasesContext";
import Select from "react-select";
import { v4 as uuidv4 } from "uuid";

const AddMaterial = ({offcanvasOn, handleOffcanvas}) => {
  const { materials, addMaterialToOrder} = useContext(PurchasesContext);
  const { register, errors, handleSubmit, control, reset } = useForm();
  const openClass = offcanvasOn ? "mr-0" : "";
  const [unidad_medida, setUnidadMedida] = useState("unidad");
  const [metros_cuadrados, setMetrosCuadrados] = useState(0);
  const [ancho, setAncho] = useState(0);
  const [largo, setLargo] = useState(0);
  const [qty, setQuantity] = useState(1);

  useEffect(() => {
       let metros = ancho * largo || 0;
       let total_metros = metros * qty || 0;
       setMetrosCuadrados(Number(total_metros.toFixed(2)));
     //eslint-disable-next-line
   }, [ancho, largo, qty]);

   const optionsMeterials = materials.map((material) => {
     return { value: material.id, label: material.name };
   });

   const handleChangeUnidad = (e) => {
    setUnidadMedida(e.target.value);
   };

  const handleCloseOffcanvas = () => {
    handleOffcanvas();
  };

  const onSubmit = async (data) => {
    data.id = uuidv4();
    data.ancho = Number(ancho);
    data.largo = Number(largo);
    data.subtotal = Number(data.quantity * data.unit_price);
    data.material = { id: data.material_id.value, name: data.material_id.label }
    data.isrecent = true;
    addMaterialToOrder(data);
    reset({});
    setAncho(0);
    setLargo(0);
    setMetrosCuadrados(0);
    setUnidadMedida('unidad');
    setQuantity(1);
    handleOffcanvas();
  };

  return (
    <>
      <div
        id="addMaterial"
        className={`bs-canvas bs-canvas-right position-fixed bg-light h-100 ${openClass}`}
      >
        <header className="bs-canvas-header p-3 bg-light-green overflow-auto">
          <button
            type="button"
            className="bs-canvas-close float-left close"
            onClick={handleCloseOffcanvas}
          >
            <span aria-hidden="true" className="text-light">
              ×
            </span>
          </button>
          <h5 className="text-center mb-0 mt-0">
            Agregar Material
          </h5>
        </header>
        <div className="bs-canvas-content px-3 py-2">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="row">
            <div className="col-lg-12">
              <label>Material</label>
              <div className="form-group">
                <Controller
                  name="material_id"
                  control={control}
                  placeholder="Selecciona el material"
                  options={optionsMeterials}
                  defaultValue=""
                  as={
                    <Select className="react-select form-control p-0" />
                  }
                  rules={{
                    required: "Selecciona el material de la lista",
                  }}
                />
                {errors?.material_id ? (
                  <label className="error">
                    {errors?.material_id?.message}
                  </label>
                ) : null}
              </div>
            </div>
            <div className="col-lg-12">
              <label>Unidad de compra</label>
              <div className="form-group">
                <select
                  name="unidad_compra"
                  className="form-control"
                  onChange={(e) =>
                    handleChangeUnidad(e)}
                    ref={register({
                      required: {
                        value: true,
                        message: "Selecciona la unidad de compra",
                      }
                    })}
                >
                  <option value="unidad">Unidad (pza)</option>
                  <option value="metro2">Metro cuadrado (m2)</option>
                  <option value="rollo">Rollo</option>
                  <option value="placa">Placa</option>
                </select>
              </div>
            </div>
            {
              unidad_medida === 'rollo' || unidad_medida === 'placa' ? (
                <>
            <div className="col-lg-4">
              <div className="form-group">
                <label><small>Ancho (m)</small></label>
                <input
                  name="ancho"
                  type="text"
                  autoComplete="off"
                  value={ancho}
                  onChange={(e) =>
                    setAncho(e.target.value)}
                  ref={register({
                    required: {
                      value: true,
                      message: "La cantidad es requerida",
                    },
                    min: {
                      value: 0.1,
                      message:
                        "El campo cantidad debe ser mayor a 1",
                    },
                    pattern: {
                      value: /^[0-9]+([.])?([0-9]+)?$/,
                      message: "El campo debe ser numerico",
                    },
                  })}
                  className={
                    errors.ancho
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors?.ancho ? (
                  <label className="error">
                    {errors?.ancho?.message}
                  </label>
                ) : null}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label><small>Largo (m)</small></label>
                <input
                  name="largo"
                  type="text"
                  autoComplete="off"
                  value={largo}
                  onChange={(e) =>
                    setLargo(e.target.value)}
                  ref={register({
                    required: {
                      value: true,
                      message: "La cantidad es requerida",
                    },
                    min: {
                      value: 0.1,
                      message:
                        "El campo cantidad debe ser mayor a 1",
                    },
                    pattern: {
                      value: /^[0-9]+([.])?([0-9]+)?$/,
                      message: "El campo debe ser numerico",
                    },
                  })}
                  className={
                    errors.largo
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors?.largo ? (
                  <label className="error">
                    {errors?.largo?.message}
                  </label>
                ) : null}
              </div>
            </div>
            <div className="col-lg-4">
              <div className="form-group">
                <label><small>Metros (m2)</small></label>
                <input
                  name="metros"
                  type="text"
                  readOnly
                  autoComplete="off"
                  value={metros_cuadrados}
                  ref={register({
                    required: {
                      value: true,
                      message: "La cantidad es requerida",
                    },
                    pattern: {
                      value: /^[0-9]+([.])?([0-9]+)?$/,
                      message: "El campo debe ser numerico",
                    },
                  })}
                  className={
                    errors.metros
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors?.metros ? (
                  <label className="error">
                    {errors?.metros?.message}
                  </label>
                ) : null}
              </div>
            </div>
                </>
              ) : null
            }

            <div className="col-lg-6">
              <div className="form-group">
                <label>Cantidad</label>
                <input
                  name="quantity"
                  type="text"
                  autoComplete="off"
                  value={qty}
                  onChange={(e) =>
                    setQuantity(e.target.value)}
                  ref={register({
                    required: {
                      value: true,
                      message: "La cantidad es requerida",
                    },
                    min: {
                      value: 1,
                      message:
                        "El campo cantidad debe ser mayor a 1",
                    },
                    pattern: {
                      value: /^[0-9]+([.])?([0-9]+)?$/,
                      message: "El campo debe ser numerico",
                    },
                  })}
                  className={
                    errors.quantity
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors?.quantity ? (
                  <label className="error">
                    {errors?.quantity?.message}
                  </label>
                ) : null}
              </div>
            </div>
            <div className="col-lg-6">
              <div className="form-group">
                <label>Precio Unitario</label>
                <input
                  name="unit_price"
                  type="text"
                  defaultValue=""
                  autoComplete="off"
                  ref={register({
                    required: {
                      value: true,
                      message: "El monto es requerido",
                    },
                    min: {
                      value:  1,
                      message: "El campo monto debe ser mayor a 1",
                    },
                    pattern: {
                      value: /^[0-9]+([.])?([0-9]+)?$/,
                      message: "El campo debe ser numerico",
                    },
                  })}
                  className={
                    errors.unit_price
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors?.unit_price ? (
                  <label className="error">
                    {errors?.unit_price?.message}
                  </label>
                ) : null}
              </div>
            </div>
            <div className="col-lg-12">
              <label>Descripción</label>
              <div className="form-group">
                <textarea
                  name="description"
                  defaultValue=""
                  rows={2}
                  placeholder="Agrega aqui la descripción (opcional)"
                  ref={register({
                    required: {
                      value: false,
                      message: "La descripción es requerida",
                    },
                  })}
                  className={
                    errors.description
                      ? "form-control is-invalid"
                      : "form-control "
                  }
                />
                {errors?.description ? (
                  <label className="error">
                    {errors?.description?.message}
                  </label>
                ) : null}
              </div>
            </div>
            <div className="col-lg-12">
              <button type="submit" className="btn bg-black btn-block">Agregar material</button>
            </div>
          </div>
        </form>
        </div>
      </div>
    </>
  );
};

export default AddMaterial;
