import React, { useReducer } from "react";
import { ADD_FAMILY, EDIT_FAMILY, GET_FAMILIES, REMOVE_FAMILY, UPDATE_FAMILY } from "../../types/index";
import FamiliesContext from "./familiesContext";
import familiesReducer from "./familiesReducer";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import $ from "jquery";
import "bootstrap-notify";
import Swal from "sweetalert2";

const FamiliesSatate = (props) => {
  // estado inicial
  const initialSate = {
    families: [],
    selectedFamily: [],
    success: false,
  };

  const [state, dispatch] = useReducer(familiesReducer, initialSate);

  //obtener las familias
  const getFamilies = () => {
    let url = `families`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_FAMILIES,
        payload: response.data.data,
      });
    });
  };

  const addFamily = (family) => {
    let url = "/families";
    MethodPost(url, family)
      .then((response) => {
        dispatch({
          type: ADD_FAMILY,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR FAMILIA A EDITAR
  const editFamily = (family) => {
    dispatch({
      type: EDIT_FAMILY,
      payload: family,
    });
  };

  //Actualizar una FAMILIA
  const updateFamily = (data) => {
    let url = `families/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
        dispatch({
          type: UPDATE_FAMILY,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar FAMILIA
  const removeFamily = (id) => {
    let url = `families/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: 'Eliminado',
        text:  response.data.message,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false
    });
      dispatch({
        type: REMOVE_FAMILY,
        payload: id,
      });
    });
  };

  return (
    <FamiliesContext.Provider
      value={{
        families: state.families,
        success: state.success,
        selectedFamily: state.selectedFamily,
        getFamilies,
        addFamily,
        editFamily,
        removeFamily,
        updateFamily,
      }}
    >
      {props.children}
    </FamiliesContext.Provider>
  );
};

export default FamiliesSatate;
