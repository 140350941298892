/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useMemo, useState } from "react";
import { Link } from "react-router-dom";
import DashboardContext from "../contexts/dashboard/dashboardContext";
import Layout from "./Layout";
import Moment from "react-moment";
import moment from "moment";
import DataTable from "react-data-table-component";
import ButtonMobileMenu from "../components/ButtonMobileMenu";
import RightButtonToggle from "../components/RightButtonToggle";
import Swal from "sweetalert2";

const Dashboard = () => {
  const {
    getDashboard,
    local_customers,
    online_customers,
    bestSellers,
    recent_orders,
    total_orders_local,
    total_orders_online,
    today_income,
    today_expense,
    today_utility,
    getGeneralReport,
    expense,
    income,
    utility,
    total_ingresos_efectivo,
    total_ingresos_transferencia,
    dataset,
    movimientos,
    exportReportCaja,
  } = useContext(DashboardContext);

  //state fecha inicio y fecha fin
  const [fechaInicial, setFechaInicial] = useState("");
  const [fechaFinal, setFechaFinal] = useState("");
  const [loading, setLoading] = useState(false);

  const [errorFechaFinal, setErrorFechaFinal] = useState(false);

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "11px",
        fontWeight: "700",
        textTransform: "uppercase",
      },
    },

    cells: {
      style: {
        fontSize: "11px",
      },
    },
  };

  const columns = [
    {
      name: "Fecha",
      selector: (row) => moment(row.created_at).format("DD/MM/YYYY"),
      sortable: true,
    },
    {
      name: "Concepto",
      selector: (row) => row.concepto,
      sortable: true,
      width: "300px",
      wrap: true,
    },
    {
      name: "Descripcion",
      selector: (row) => row.descripcion,
      sortable: true,
      width: "350px",
      wrap: true
    },
    {
      name: "Monto",
      selector: (row) =>
        new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
          minimumFractionDigits: 2,
        }).format(row.monto),
      sortable: true,
    },
    {
      name: "Metodo Pago",
      selector: (row) => row.metodo_pago,
      sortable: true,
      width: "150px",
      style: {
        "text-transform": 'uppercase',
      }
    },
    {
      name: "Movimiento",
      selector: (row) => row.tipo,
      sortable: true,
      width: "130px",
      style: {
        "text-transform": 'uppercase',
      }
    }
  ];
  const dataTableMovimientos = useMemo(() => movimientos, [movimientos]);

  //llamado a la función que obtiene los datos del dashboard al cargar el componente.
  useEffect(() => {
    const fecha = moment();
    let fechaInicial = fecha.startOf("month").format("YYYY-MM-DD");
    let fechaFinal = fecha.endOf("month").format("YYYY-MM-DD");
    setFechaInicial(fechaInicial);
    setFechaFinal(fechaFinal);

    getDashboard();
    getGeneralReport(fechaInicial, fechaFinal);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    window.c3.generate({
      bindto: "#chart-donut", // id of chart wrapper
      data: {
        columns: dataset,
        type: "donut", // default type of chart
      },
      axis: {},
      legend: {
        show: true, //hide legend
      },
      padding: {
        bottom: 0,
        top: 0,
      },
    });
  }, [dataset]);

  const handleChangeFechaInicial = (event) => {
    let newDate = moment(event.target.value).format("YYYY-MM-DD");
    setFechaInicial(newDate);
  };


  const handleChangeFechaFinal = (event) => {
    if (!moment(event.target.value).isValid()) return;
    let newDate = moment(event.target.value).format("YYYY-MM-DD");
    setFechaFinal(newDate);
    setErrorFechaFinal(false);
    if (moment(newDate).isBefore(fechaInicial)) {
      setErrorFechaFinal(true);
      return;
    }
  };

  //realizar la consulta de ingresos y gastos
  const handleSubmitReportG = (e) => {
    e.preventDefault();
    setLoading(true);
    getGeneralReport(fechaInicial, fechaFinal);
      setTimeout(() => {
      setLoading(false);
      }, 2000); // Mostrar loading durante 2 segundos
  };

  //funcion para exportar PDF el reporte
  function downloadPDF() {

    Swal.fire({
      title: "Descargar reporte",
      input: "select",
      inputOptions: {
        "pdf": "PDF",
        "xlsx": "EXCEL",
      },
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Descargar!",
      cancelButtonText: "cancelar!",
      inputPlaceholder: "Selecciona el tipo de archivo",
      showCancelButton: true,
      inputValidator: (value) => {
        return new Promise((resolve) => {
          if (value) {
            exportReportCaja(fechaInicial, fechaFinal, value);
            resolve();
          } else {
            resolve("Debes seleccionar una opción");
          }
        });
      },
    });


  }

  return (
    <Layout>
      <section className="content">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12">
              <h2>E-commerce Dashboard</h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <Link to="/">
                    <i className="zmdi zmdi-home" /> PBK Impresos
                  </Link>
                </li>
                <li className="breadcrumb-item">E-commerce</li>
                <li className="breadcrumb-item active">Dashboard</li>
              </ul>
              <ButtonMobileMenu/>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12">
             <RightButtonToggle/>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-3 col-md-6 col-sm-12 col-12 text-center">
              <div className="card">
                <div className="body">
                  <div className="w_icon indigo">
                    <i className="zmdi zmdi-accounts-outline" />
                  </div>
                  <p className="mt-3">Clientes</p>
                  <div className="d-flex bd-highlight text-center mt-4">
                    <div className="flex-fill bd-highlight">
                      <small className="text-muted">Local</small>
                      <h5 className="mb-0">{local_customers}</h5>
                    </div>
                    <div className="flex-fill bd-highlight">
                      <small className="text-muted">Online</small>
                      <h5 className="mb-0">{online_customers}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-3 col-md-6 col-sm-12 col-12 text-center">
              <div className="card">
                <div className="body">
                  <div className="w_icon pink">
                    <i className="ti-shopping-cart-full" />
                  </div>
                  <p className="mt-3">Total Ordenes</p>
                  <div className="d-flex bd-highlight text-center mt-4">
                    <div className="flex-fill bd-highlight">
                      <small className="text-muted">Local</small>
                      <h5 className="mb-0">{total_orders_local}</h5>
                    </div>
                    <div className="flex-fill bd-highlight">
                      <small className="text-muted">Online</small>
                      <h5 className="mb-0">{total_orders_online}</h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-lg-6 col-md-12 col-sm-12 col-12 text-center">
              <div className="card">
                <div className="body">
                  <div className="w_icon green">
                    <i className="ti-stats-up" />
                  </div>
                  <p className="mt-3">Corte Diario</p>
                  <div className="d-flex bd-highlight text-center mt-4">
                    <div className="flex-fill bd-highlight">
                      <small className="text-muted">Ingresos</small>
                      <h5 className="mb-0">
                        {new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                          minimumFractionDigits: 2,
                        }).format(today_income)}
                      </h5>
                    </div>
                    <div className="flex-fill bd-highlight">
                      <small className="text-muted">Gastos</small>
                      <h5 className="mb-0">
                        {new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                          minimumFractionDigits: 2,
                        }).format(today_expense)}
                      </h5>
                    </div>
                    <div className="flex-fill bd-highlight">
                      <small className="text-muted">Utilidad</small>
                      <h5 className="mb-0">
                        {new Intl.NumberFormat("es-MX", {
                          style: "currency",
                          currency: "MXN",
                          minimumFractionDigits: 2,
                        }).format(today_utility)}
                      </h5>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-lg-12 col-md-12">
              <div className="card product-report">
                <div className="header">
                  <h2>
                    <strong>REPORTE</strong> GENERAL
                  </h2>
                  <ul className="header-dropdown">
                    <li className="dropdown">
                      {" "}
                      <a
                        href="#"
                        className="dropdown-toggle"
                        data-toggle="dropdown"
                        role="button"
                        aria-haspopup="true"
                        aria-expanded="false"
                      >
                        {" "}
                        <i className="zmdi zmdi-download" />{" "}
                      </a>
                      <ul className="dropdown-menu dropdown-menu-right">
                        <li>
                          <a onClick={() => downloadPDF()}>Descargar Reporte</a>
                        </li>
                      </ul>
                    </li>
                  </ul>
                </div>
                <div className="body">
                  <form>
                    <div className="row clearfix">
                      <div className="col-lg-3 form-group">
                        <input
                          type="date"
                          id="fechaInicio"
                          name="fechaInicio"
                          className="form-control"
                          value={fechaInicial}
                          onChange={(e) => {
                            handleChangeFechaInicial(e);
                          }}
                        />
                      </div>
                      <div className="col-lg-3 form-group">
                        <input
                          type="date"
                          id="fechaFinal"
                          name="fechaFinal"
                          className="form-control"
                          value={fechaFinal}
                          onChange={(e) => {
                            handleChangeFechaFinal(e);
                          }}
                        />
                        {errorFechaFinal ? (
                          <small className="text-danger">
                            La fecha final no es valida
                          </small>
                        ) : null}
                      </div>
                      <div className="col-lg-2 form-group">
                        <button
                          type="submit"
                          className="btn btn-warning btn-block waves-effect"
                          onClick={(e) => {
                            handleSubmitReportG(e);
                          }}
                        >
                          CONSULTAR
                        </button>
                      </div>
                    </div>
                  </form>
{
  loading ? <div className="text-center"><div className="loader"></div></div>
  : <>
  <div className="row clearfix">
    <div className="col-lg-4 col-md-4 col-sm-12">
      <div className="icon xl-blue m-b-15">
        <i className="zmdi ti-stats-up" />
      </div>
      <div className="col-in">
        <small className="text-muted mt-0">Ingresos</small>
        <h4 className="mt-0">
          {" "}
          {new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 2,
          }).format(income)}
        </h4>
      </div>
    </div>
    <div className="col-lg-4 col-md-4 col-sm-12">
      <div className="icon xl-amber m-b-15">
        <i className="zmdi ti-stats-down" />
      </div>
      <div className="col-in">
        <small className="text-muted mt-0">Gastos</small>
        <h4 className="mt-0">
          {" "}
          {new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 2,
          }).format(expense)}
        </h4>
      </div>
    </div>
    <div className="col-lg-4 col-md-4 col-sm-12">
      <div className="icon xl-purple m-b-15">
        <i className="zmdi ti-credit-card" />
      </div>
      <div className="col-in">
        <small className="text-muted mt-0">
          Utilidad total
        </small>
        <h4 className="mt-0">
          {" "}
          {new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 2,
          }).format(utility)}
        </h4>
      </div>
    </div>
  </div>
  <a
    className="collapsed"
    role="button"
    data-toggle="collapse"
    href="#collapseDetallesReport"
    aria-expanded="false"
    aria-controls="collapseDetallesReport"
  >
    Ver detalles
  </a>

  <div className="collapse" id="collapseDetallesReport">
    <div className="row clearfix">
      <div className="col-lg-12 col-md-12 col-sm-12">
        <div className="header">
          <h5>Detalle de Movimientos Realizados</h5>
          <div>
          <div className="pw_meta">
                <span>{new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 2,
          }).format(total_ingresos_efectivo)}</span>
                <small className="text-muted"> IMPORTE INGRESOS EFECTIVO</small>
            </div>
            <div className="pw_meta">
                <span>{new Intl.NumberFormat("es-MX", {
            style: "currency",
            currency: "MXN",
            minimumFractionDigits: 2,
          }).format(total_ingresos_transferencia)}</span>
                <small className="text-muted"> IMPORTE INGRESOS TRANSFERENCIA</small>
            </div>
          </div>
        </div>

        <div className="table-responsive">
          <DataTable
            customStyles={tableCustomStyles}
            columns={columns}
            data={dataTableMovimientos}
            noDataComponent="NO HAY MOVIMIENTOS REGISTRADOS"
            pagination
          />
        </div>
      </div>
    </div>
  </div>
  </>
}

                </div>
              </div>
            </div>
            <div className="col-md-12">
              <div className="card">
                <div className="header">
                  <h2>
                    <strong>PRODUCTOS</strong> MÁS VENDIDOS
                  </h2>
                  <ul className="header-dropdown">
                    <li className="remove">
                      <a role="button" className="boxs-close">
                        <i className="zmdi zmdi-close" />
                      </a>
                    </li>
                  </ul>
                </div>
                <div className="body">
                  <div className="row clearfix">
                    <div className="col-lg-8 col-md-12">
                      <div id="chart-donut" style={{ height: "17rem" }} />
                    </div>
                    <div className="col-lg-4 col-md-12">
                      <div className="table-responsive">
                        <table className="table table-hover c_table mb-0">
                          <tbody>
                            {!bestSellers.length ? (
                              <tr>
                                <td align="center" colSpan={2}>
                                  No se encontraron registros
                                </td>
                              </tr>
                            ) : (
                              bestSellers.map((item, index) => (
                                <tr key={item.id}>
                                  <td>{index + 1}</td>
                                  <td>{item.name}</td>
                                </tr>
                              ))
                            )}
                          </tbody>
                        </table>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>

          <div className="row clearfix">
            <div className="col-sm-12 col-md-12 col-lg-12">
              <div className="card">
                <div className="header">
                  <h2>
                    <strong>ORDENES</strong> RECIENTES
                  </h2>
                </div>
                <div className="table-responsive">
                  <table className="table table-hover table-sm c_table">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Creada</th>
                        <th>Usuario</th>
                        <th>Cliente</th>
                        <th>Total</th>
                        <th>F. Entrega</th>
                        <th>Orden</th>
                        <th>Estatus</th>
                        <th>Pago</th>
                      </tr>
                    </thead>
                    <tbody>
                      {!recent_orders.length ? (
                        <tr>
                          <td align="center" colSpan={9}>
                            No se encuentran Ordenes Registradas
                          </td>
                        </tr>
                      ) : (
                        recent_orders.map((order, index) => (
                          <tr key={index}>
                            <td>
                              <Link
                                to={`/order-detail/${order.id}`}
                              >
                                <strong>{order.id}</strong>
                              </Link>
                            </td>
                            <td>
                              <Moment format="DD/MM/YYYY hh:mm a">
                                {order.order_date}
                              </Moment>
                            </td>
                            <td>{order.user ? order.user.name : "-"}</td>
                            <td>
                              <p>{order.customer.full_name}</p>
                            </td>
                            <td>
                              {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                                minimumFractionDigits: 2,
                              }).format(order.total)}
                            </td>
                            <td>
                              {
                                order.expires ? (
                                  <Moment format="DD/MM/YYYY hh:mm a">
                                    {order.expires}
                                  </Moment>
                                ) : "--"
                              }
                            </td>
                            <td>
                              {order.type_of_sale === 1 ? (
                                <div className="status local">
                                  {" "}
                                  <i className="zmdi zmdi-circle"></i> local{" "}
                                </div>
                              ) : order.type_of_sale === 2 ? (
                                <div className="status ecommerce">
                                  {" "}
                                  <i className="zmdi zmdi-circle"></i>{" "}
                                  online{" "}
                                </div>
                              ) : null}
                            </td>
                            <td>
                              {order.status === 0 ? (
                                <span className="badge badge-default">
                                  Rechazada
                                </span>
                              ) : order.status === 1 ? (
                                <span className="badge badge-warning">
                                  Pendiente
                                </span>
                              ) : order.status === 2 ? (
                                <span className="badge badge-success">
                                  Aprobada
                                </span>
                              ) : order.status === 3 ? (
                                <span className="badge badge-primary">
                                  En Producción
                                </span>
                              ) : order.status === 4 ? (
                                <span className="badge badge-info">
                                  Orden completada
                                </span>
                              ) : order.status === 5 ? (
                                <span className="badge badge-danger">
                                  Entregada
                                </span>
                              ) : null}
                            </td>
                            <td>
                              {order.status >= 2 ? (
                                <>
                                  {order.payment_status === 1 ? (
                                    <span className="badge bg-deep-orange">
                                      Cuenta por cobrar
                                    </span>
                                  ) : order.payment_status === 2 ? (
                                    <span className="badge bg-amber">
                                      Pago Incompleto
                                    </span>
                                  ) : order.payment_status === 3 ? (
                                    <span className="badge bg-teal">
                                      Pagado
                                    </span>
                                  ) : null}
                                </>
                              ) : (
                                <span className="badge bg-lime">
                                  No aplica
                                </span>
                              )}
                            </td>
                          </tr>
                        ))
                      )}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default Dashboard;
