import React, { useReducer } from "react";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import $ from "jquery";
import "bootstrap-notify";
import Swal from "sweetalert2";
import DiscountsContext from "./discountsContext";
import discountsReducer from "./discountsReducer";
import { ADD_DISCOUNT, EDIT_DISCOUNT, GET_DISCOUNTS, GET_PRODUCTS_SELECT, REMOVE_DISCOUNT, UPDATE_DISCOUNT } from "../../types";
import { toast } from "react-toastify";


const DiscountsState = (props) => {
 // estado inicial
 const initialSate = {
    discounts: [],
    products: [],
    selectedDiscount: [],
    apierrors: [],
    success: false,
    current_page: null,
    total: null,
    per_page: null,
  };

  const [state, dispatch] = useReducer( discountsReducer, initialSate);

  //obtener los descuentos
  const getDiscounts = (page = 1, search = '') => {
    let url = `discounts?page=${page}&search=${search}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_DISCOUNTS,
        payload: response.data.data,
      });
    });
  };

    //Obtener la data para el select de productos
    const getProducts = () => {
      let url = "products-all";
      MethodGet(url)
        .then((response) => {
          dispatch({
            type: GET_PRODUCTS_SELECT,
            payload: response.data.data,
          });
        })
        .catch((error) => {
          console.log(error);
        });
    };

  const addDiscount = (discount) => {
    let url = "/discounts";
    MethodPost(url, discount)
      .then((response) => {
        dispatch({
          type: ADD_DISCOUNT,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        toast.error(error.response.data.message, { theme: "colored" });
      });
  };

  //SELECCIONAR DESCUENTO A EDITAR
  const editDiscount = (discount) => {
    dispatch({
      type: EDIT_DISCOUNT,
      payload: discount,
    });
  };

  //Actualizar un descuento
  const updateDiscount = (data) => {
    let url = `discounts/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
        dispatch({
          type: UPDATE_DISCOUNT,
        });
      })
      .catch((error) => {
        console.log(error)
      });
  };

  //Eliminar descuento
  const removeDiscount = (id) => {
    let url = `discounts/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: 'Eliminado',
        text:  response.data.message,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false
    });
      dispatch({
        type: REMOVE_DISCOUNT,
        payload: id,
      });
    });
  };

  return (
    <DiscountsContext.Provider
      value={{
        discounts: state.discounts,
        products: state.products,
        apierrors: state.apierrors,
        success: state.success,
        current_page: state.current_page,
        total: state.total,
        per_page: state.per_page,
        selectedDiscount: state.selectedDiscount,
        getDiscounts,
        addDiscount,
        editDiscount,
        removeDiscount,
        updateDiscount,
        getProducts
      }}
    >
      {props.children}
    </DiscountsContext.Provider>
  );
};

export default DiscountsState;
