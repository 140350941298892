/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useState} from "react";
import { Link } from "react-router-dom";
import AuthContext from "../contexts/auth/authContext";

const Rightsidebar = () => {
  const { logout, user } = useContext(AuthContext);

  const [navbarOpen, setNavbarOpen] = useState(false);

  const toggleNavbar = () => {
    setNavbarOpen(!navbarOpen);
  };

  // const token = localStorage.getItem("token");
  // const user = JSON.parse(localStorage.getItem("user"));
  // const client = require('pusher-js'); 
  // const apiUrl = 'http://127.0.0.1:8000';
  // const options = {
  //   broadcaster: 'pusher',
  //   key: process.env.REACT_APP_PUSHER_APP_KEY,
  //   cluster: process.env.REACT_APP_PUSHER_APP_CLUSTER,
  //   forceTLS: true,
  //   //authEndpoint is your apiUrl + /broadcasting/auth
  //   authEndpoint: apiUrl + '/broadcasting/auth' , 
  //   // As I'm using JWT tokens, I need to manually set up the headers.
  //   auth: {
  //     headers: {
  //       Authorization: `Bearer ${token}`,
  //       Accept: 'application/json',
  //     },
  //     client: client
  //   },
  // };
  
  // const echo = new Echo(options);
  // echo.private(`App.Models.User.${user.id}`).notification((data) => {
  //     console.log(data);
  // });



  return (
    <>
      {/* Right Icon menu Sidebar */}
      <div className="navbar-right">
        <ul className={`navbar-nav ${navbarOpen ? 'open' : ''}`}>
          <button type="submit" className="btn btn-primary rv-btn-toggle" onClick={toggleNavbar}>
            <i className="zmdi zmdi-settings" />
          </button>

          {user && (user.user_role === 1 || user.user_role === 2 )  ? (
            <li className="dropdown">
              <a
                href="#"
                className="dropdown-toggle"
                title="App"
                data-toggle="dropdown"
                role="button"
              >
                <i className="zmdi zmdi-apps" />
              </a>
              <ul className="dropdown-menu slideUp2">
                <li className="header">Accesos rapidos</li>
                <li className="body">
                  <ul className="menu app_sortcut list-unstyled">
                    <li>
                      <Link to="/orders">
                        <div className="icon-circle mb-2 bg-blue">
                          <i className="zmdi zmdi-assignment-check" />
                        </div>
                        <p className="mb-0">Ordenes</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/customers">
                        <div className="icon-circle mb-2 bg-amber">
                          <i className="zmdi zmdi-accounts" />
                        </div>
                        <p className="mb-0">Clientes</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/products">
                        <div className="icon-circle mb-2 bg-green">
                          <i className="zmdi zmdi-shopping-cart" />
                        </div>
                        <p className="mb-0">Productos</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/families">
                        <div className="icon-circle mb-2 bg-purple">
                          <i className="zmdi zmdi-label" />
                        </div>
                        <p className="mb-0">Familias</p>
                      </Link>
                    </li>
                    <li>
                      <Link to="/inventories">
                        <div className="icon-circle mb-2 bg-red">
                          <i className="zmdi zmdi-assignment" />
                        </div>
                        <p className="mb-0">Inventario</p>
                      </Link>
                    </li>
                    {user.user_role === 1 && (
                      <li>
                        <Link to="/users">
                          <div className="icon-circle mb-2 bg-grey">
                            <i className="zmdi zmdi-account" />
                          </div>
                          <p className="mb-0">Usuarios</p>
                        </Link>
                      </li>
                    )}
                  </ul>
                </li>
              </ul>
            </li>
          ) : null}

         {/* <li className="dropdown">
            <a
              href="#"
              className="dropdown-toggle"
              title="Notifications"
              data-toggle="dropdown"
              role="button"
            >
              <i className="zmdi zmdi-notifications" />
              <div className="notify">
                <span className="heartbit" />
                <span className="point" />
              </div>
            </a>
            <ul className="dropdown-menu slideUp2">
              <li className="header">Notificaciones</li>
              <li className="body">
                 <ul className="menu list-unstyled">
                  <li>
                    <a href="#">
                      <div className="icon-circle bg-blue">
                        <i className="zmdi zmdi-account" />
                      </div>
                      <div className="menu-info">
                        <h4>8 New Members joined</h4>
                        <p>
                          <i className="zmdi zmdi-time" /> 14 mins ago{" "}
                        </p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="icon-circle bg-amber">
                        <i className="zmdi zmdi-shopping-cart" />
                      </div>
                      <div className="menu-info">
                        <h4>4 Sales made</h4>
                        <p>
                          <i className="zmdi zmdi-time" /> 22 mins ago{" "}
                        </p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="icon-circle bg-red">
                        <i className="zmdi zmdi-delete" />
                      </div>
                      <div className="menu-info">
                        <h4>
                          <b>Nancy Doe</b> Deleted account
                        </h4>
                        <p>
                          <i className="zmdi zmdi-time" /> 3 hours ago{" "}
                        </p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="icon-circle bg-green">
                        <i className="zmdi zmdi-edit" />
                      </div>
                      <div className="menu-info">
                        <h4>
                          <b>Nancy</b> Changed name
                        </h4>
                        <p>
                          <i className="zmdi zmdi-time" /> 2 hours ago{" "}
                        </p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="icon-circle bg-grey">
                        <i className="zmdi zmdi-comment-text" />
                      </div>
                      <div className="menu-info">
                        <h4>
                          <b>John</b> Commented your post
                        </h4>
                        <p>
                          <i className="zmdi zmdi-time" /> 4 hours ago{" "}
                        </p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="icon-circle bg-purple">
                        <i className="zmdi zmdi-refresh" />
                      </div>
                      <div className="menu-info">
                        <h4>
                          <b>John</b> Updated status
                        </h4>
                        <p>
                          <i className="zmdi zmdi-time" /> 3 hours ago{" "}
                        </p>
                      </div>
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <div className="icon-circle bg-light-blue">
                        <i className="zmdi zmdi-settings" />
                      </div>
                      <div className="menu-info">
                        <h4>Settings Updated</h4>
                        <p>
                          <i className="zmdi zmdi-time" /> Yesterday{" "}
                        </p>
                      </div>
                    </a>
                  </li>
                </ul> 
              </li>
              <li className="footer text-center">
                {" "}
                <a href="#">ver todas las notificaciones </a>{" "}
              </li>
            </ul>
          </li> */}

          <li>
            <a
              className="mega-menu"
              title="Cerrar Sesión"
              onClick={() => logout()}
            >
              <i className="zmdi zmdi-power" />
            </a>
          </li>
        </ul>
      </div>
    </>
  );
};

// function useNotificationChannel(authUserId, onChange) {
//   const channels = usePrivateChannels(authUserId);
//   useEffect(() => {
//     if (channels) {
//       channels.listen("UpdateCreatedEvent", onChange);
//       return () => {
//         channels.stopListening("UpdateCreatedEvent");
//       };
//     }
//   }, [channels, onChange]);
// }

// const Notifications = ({ authUserId, token }) => {
//   const [notifications, setNotifications] = useState([]);
//   const handleNotificationsEvent = useCallback(
//     (notification) => {
//       setNotifications([...notifications, notification.update]);
//     },
//     [notifications]
//   );

  // useEffect(() => {
  //   const getNotifications = async () => {
  //     try {
  //       const result = await axios.request({
  //         baseURL: "http://localhost:8000",
  //         headers: {
  //           Accept: "application/json",
  //           Authorization: `Bearer ${token}`,
  //         },
  //         method: "get",
  //         url: "/updates",
  //         params: {},
  //       });
  //       console.log("resultado de notifications", result);
  //       if (result.status === 200) {
  //         setNotifications(result.data);
  //       }
  //     } catch (error) {
  //       console.log(error);
  //     }
  //   };
  //   getNotifications();
  // }, [token]);

//   useNotificationChannel(authUserId, handleNotificationsEvent);

//   return (
//     <ol>
//       {notifications.map((item) => (
//         <li key={item?.id}>
//           <p>{item?.text}</p>
//           <p>{item?.user?.name}</p>
//         </li>
//       ))}
//     </ol>
//   );
// };

export default Rightsidebar;
