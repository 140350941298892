import React, { useReducer } from "react";
import optionsReducer from "./optionsReducer";
import OptionsContext from "./optionsContext";
import { ADD_OPTION, EDIT_OPTION, GET_OPTIONS, REMOVE_OPTION, UPDATE_OPTION } from "../../types";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const OptionsSatate = (props) => {
  // estado inicial
  const initialSate = {
    options: [],
    selectedOption: [],
    success: false,
  };

  const [state, dispatch] = useReducer(optionsReducer, initialSate);

  //obtener la lista de acabados
  const getOptions = () => {
    let url = `options`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_OPTIONS,
        payload: response.data.data,
      });
    });
  };

  const addOption = (option) => {
    let url = "/options";
    MethodPost(url, option)
      .then((response) => {
        dispatch({
          type: ADD_OPTION,
        });
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR TIPO DE ACABADO A EDITAR
  const editOption = (option) => {
    dispatch({
      type: EDIT_OPTION,
      payload: option,
    });
  };

  //Actualizar un acabado
  const updateOption = (data) => {
    let url = `options/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        dispatch({
          type: UPDATE_OPTION,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar acabado
  const removeOption = (id) => {
    let url = `options/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: 'Eliminado',
        text:  response.data.message,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false
    });
      dispatch({
        type: REMOVE_OPTION,
        payload: id,
      });
    });
  };

  return (
    <OptionsContext.Provider
      value={{
        options: state.options,
        success: state.success,
        selectedOption: state.selectedOption,
        getOptions,
        addOption,
        editOption,
        removeOption,
        updateOption,
      }}
    >
      {props.children}
    </OptionsContext.Provider>
  );
};

export default OptionsSatate;
