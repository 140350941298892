import { ADD_MEASUREMENT, EDIT_MEASUREMENT, GET_MEASUREMENTS, REMOVE_MEASUREMENT, UPDATE_MEASUREMENT } from "../../types"


export default function MeasurementsReducer(state, action) {
    switch (action.type) {
        case ADD_MEASUREMENT:
            return{
                ...state,
                success: true,
            }

        case GET_MEASUREMENTS:
            return{
                ...state,
                measurements: action.payload,
                success:false,
            }

        case EDIT_MEASUREMENT:
            return {
                ...state,
                selectedMeasurement: state.measurements.find(measurement => measurement.id === action.payload),
            }

        case UPDATE_MEASUREMENT:
            return{
                ...state,
                success: true,
                selectedMeasurement: [],
            }

        case REMOVE_MEASUREMENT:
            return {
                ...state,
                measurements: state.measurements.filter(
                (measurement) => measurement.id !== action.payload
                ),
            }

        default:
            return state;
    }
}
