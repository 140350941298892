import React, { useContext, useEffect, useState } from "react";
import ProductsContext from "../../contexts/products/productsContext";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import DefaultImage from "../../assets/images/default_image.png";
import Layout from "../../layouts/Layout";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";
import DataTable from "react-data-table-component";

const ProductsList = () => {
  const listProducts = useContext(ProductsContext);
  const {
    products,
    getProducts,
    removeProduct,
    success,
  } = listProducts;

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, [success]);


  const [filterText, setFilterText] = useState("");
  const filteredItems = products.filter(
    (item) =>
      item.name
        .toLowerCase()
        .includes(filterText.toLowerCase()) ||
        item.family?.name
          .toLowerCase()
          .includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        backgroundColor: "#5cc5cd",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
  };

  const columns = [
    {
      name: "Imagen",
      selector: (row) =>
      <img
        src={
          row.imageProduct
            ? row.imageProduct
            : DefaultImage
        }
        width={50}
        alt="Product img"
      />,
      width: "120px"
    },
    {
      name: "Producto",
      selector: (row) => row.name,
      sortable: true,
      width: "450px",
      wrap: true
    },
    {
      name: "Familia",
      selector: (row) => row.family?.name,
      sortable: true,
      width: "300px",
      wrap: true
    },
    {
      name: "Precio",
      selector: (row) =>
        new Intl.NumberFormat("es-MX", {
          style: "currency",
          currency: "MXN",
          minimumFractionDigits: 2,
        }).format(row.price),
      sortable: true,
      width: "150px",
    },
    {
      name: "Estatus",
      selector: (row) => (
        row.status === 1 ? (
            <span className="badge bg-teal">Online</span>
          ) : row.status === 2 ? (
            <span className="badge bg-amber">Local</span>
          ) : null
      ),
      sortFunction: (a, b) => a.status - b.status,
      sortable: true,
    },
    {
      name: "Acciones",
      selector: (row) => row.id,
      sortable: false,
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <Link to={`/product-detail/${row.id}`} className="btn btn-primary btn-sm">
              <i className="zmdi zmdi-eye" />

          </Link>
          <button
            className="btn btn-danger btn-sm"
            onClick={() => showConfirmMessage(row.id)}
          >
            <i className="zmdi zmdi-delete"></i>
          </button>
        </div>
      ),
      width: "120px",
    },
  ];

  const showConfirmMessage = (product) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Un Producto eliminado no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeProduct(product);
      }
    });
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Productos </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Productos</li>
                  <li className="breadcrumb-item active">Lista</li>
                </ul>
                <ButtonMobileMenu/>
                <div className="chat_window">
                  <Link to="/product-add">
                    <button className="list_btn btn btn-danger btn-round float-md-right">
                      <i className="ti-plus" />
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
                <Link to="/product-add">
                  <button className="btn btn-danger float-right">
                    <i className="zmdi zmdi-plus" /> Nuevo
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                <div className="body">
                  <div className="input-box">
                    <i className="ti-search" />
                    <input
                      type="text"
                      placeholder="Buscar aqui..."
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <button className="button" onClick={handleClear}>
                      X
                    </button>
                  </div>
                  <DataTable
                    customStyles={tableCustomStyles}
                    columns={columns}
                    data={filteredItems}
                    noDataComponent="No se encontraron registros"
                    pagination
                  />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default ProductsList;
