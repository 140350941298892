import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import Layout from "../../layouts/Layout";
import { useForm, Controller } from "react-hook-form";
import ProductsContext from "../../contexts/products/productsContext";
import Select from "react-select";
import Swal from "sweetalert2";
import AddFamily from "../Families/AddFamily";
import FamiliesContext from "../../contexts/families/familiesContext";
import AddMaterial from "./add/AddMaterial";
import MaterialsContext from "../../contexts/materials/materialsContext";
import AddSize from "../Sizes/AddSize";
import AddPrinting from "../Printings/AddPrinting";
import AddOption from "../Options/AddOption";
import SizesContext from "../../contexts/sizes/sizesContext";
import PrintingsContext from "../../contexts/printings/printingsContext";
import OptionsContext from "../../contexts/options/optionsContext";
import { TagsInput } from "react-tag-input-component";
import { toast } from "react-toastify";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const AddProduct = () => {
  const navigate = useNavigate();

  const {
    getFamilies,
    getMaterials,
    families,
    materials,
    addProduct,
    getSizes,
    getPrintings,
    getOptions,
    sizes,
    options,
    printings,
    success,
  } = useContext(ProductsContext);
  const familyContext = useContext(FamiliesContext);
  const materialContext = useContext(MaterialsContext);
  const sizeContext = useContext(SizesContext);
  const printingContext = useContext(PrintingsContext);
  const optionContext = useContext(OptionsContext);

  const { register, errors, handleSubmit, control } = useForm();

  const [url_product, setUrlProduct] = useState("");
  const [status, setStatus] = useState("2");
  const [product_type, setProductType] = useState("simple");
  const [units, setUnits] = useState([]);

  //funcion que se encarga de listar las familias en el select
  const optionsFamilies = families.map((family) => {
    return { value: family.id, label: family.name };
  });

  //funcion que se encarga de listar las unidades de medida en el select
  const optionsMaterial = materials.map((opmaterial) => {
    return { value: opmaterial.id, label: opmaterial.name };
  });

  //funcion que se encarga de listar los tamaños en el select
  const optionsSizes = sizes.map((size) => {
    return { value: size.id, label: size.name };
  });

  //funcion que se encarga de listar los tipos de impresión en el select
  const optionsPrinting = printings.map((printing) => {
    return { value: printing.id, label: printing.name };
  });

  //funcion que se encarga de listar los tipos de impresión en el select
  const optionsAcabados = options.map((option) => {
    return { value: option.id, label: option.name };
  });

  useEffect(() => {
    getFamilies();
    getMaterials();
    getSizes();
    getPrintings();
    getOptions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getFamilies();
    // eslint-disable-next-line
  }, [familyContext.success]);

  useEffect(() => {
    getMaterials();
    // eslint-disable-next-line
  }, [materialContext.success]);

  useEffect(() => {
    getSizes();
    // eslint-disable-next-line
  }, [sizeContext.success]);

  useEffect(() => {
    getPrintings();
    // eslint-disable-next-line
  }, [printingContext.success]);

  useEffect(() => {
    getOptions();
    // eslint-disable-next-line
  }, [optionContext.success]);

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de productos
    if (success) {
      navigate("/products");
    }
    //eslint-disable-next-line
  }, [success]);

  const handleChangeUrlName = (e) => {
    let srt = e.target.value;

    let srt_spaces = srt.replace(/\s+/g, "-");
    let str_normalice = srt_spaces
      .normalize("NFD")
      .replace(/[\u0300-\u036f]/g, "");
    let str_final = str_normalice.toLowerCase();

    setUrlProduct(str_final);
  };

  const handleStatus = (e) => {
    setStatus(e.target.value);
  };

  const handleProductType = (e) => {
    setProductType(e.target.value);
  };

  const onSubmit = (data) => {
    if (status === "1" && product_type === "offset" && !units.length) {
      toast.error("Agrega las unidades de venta del producto", {
        theme: "colored",
      });
      return false;
    }
    if (product_type === "offset") {
      data.units = units;
    }

    Swal.fire({
      title: "El producto se guardará?",
      text: "Da clic en confirmar si los datos son correctos!",
      icon: "info",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Confirmar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        addProduct(data);
      }
    });
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Agregar Producto</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/products"> Productos </Link>
                  </li>
                  <li className="breadcrumb-item active">Nuevo</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
               <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="row clearfix">
                <div className="col-lg-4">
                  <div className="card">
                    <div className="header">
                      <h2>
                        <strong>Datos del producto</strong>
                        <small>
                          Completa la información necesaria del producto.
                        </small>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="card">
                    <div className="body">
                      <div className="row">
                        <div className="col-lg-6">
                          <label>Nombre</label>
                          <div className="form-group">
                            <input
                              type="text"
                              name="name"
                              defaultValue=""
                              placeholder="Nombre del producto"
                              ref={register({
                                required: {
                                  value: true,
                                  message:
                                    "El Nombre del producto es requerido",
                                },
                              })}
                              className={
                                errors.name
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              onChange={(e) => handleChangeUrlName(e)}
                            />
                            {errors?.name ? (
                              <label className="error">
                                {errors?.name?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label>Slug</label>
                          <div className="form-group">
                            <input
                              type="text"
                              name="slug"
                              defaultValue={url_product}
                              placeholder="Url del producto"
                              readOnly
                              ref={register({
                                required: {
                                  value: true,
                                  message: "La url del producto es requerida",
                                },
                              })}
                              className={
                                errors.slug
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                            />
                            {errors?.slug ? (
                              <label className="error">
                                {errors?.slug?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <label>Descripción</label>
                          <div className="form-group">
                            <textarea
                              name="description"
                              defaultValue=""
                              rows={3}
                              placeholder="Descripción del producto"
                              ref={register({
                                required: {
                                  value: true,
                                  message:
                                    "La descripción del producto es requerida",
                                },
                              })}
                              className={
                                errors.description
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                            />
                            {errors?.description ? (
                              <label className="error">
                                {errors?.description?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <label>Familia</label>
                          <div className="form-group">
                            <div className="input-group">
                              <Controller
                                name="family_id"
                                control={control}
                                placeholder="Selecciona una familia"
                                defaultValue=""
                                options={optionsFamilies}
                                as={
                                  <Select className="react-select form-control p-0" />
                                }
                                rules={{
                                  required: "La familia es un campo Requerido",
                                }}
                              />

                              <div className="input-group-append">
                                <span
                                  className="input-group-text"
                                  data-toggle="modal"
                                  data-target="#addFamily"
                                >
                                  <i className="zmdi zmdi-plus-circle" />
                                </span>
                              </div>
                            </div>
                            {errors?.family_id ? (
                              <label className="error">
                                {errors?.family_id?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <label>Estatus</label>
                          <div className="form-group">
                            <div className="radio inlineblock m-r-20">
                              <input
                                type="radio"
                                name="status"
                                id="published"
                                className="with-gap"
                                value={1}
                                onChange={(e) => handleStatus(e)}
                                checked={status === "1"}
                                ref={register({
                                  required: true,
                                })}
                              />
                              <label htmlFor="published">Online</label>
                            </div>
                            <div className="radio inlineblock">
                              <input
                                type="radio"
                                name="status"
                                id="draft"
                                className="with-gap"
                                value={2}
                                onChange={(e) => handleStatus(e)}
                                checked={status === "2"}
                                ref={register({
                                  required: true,
                                })}
                              />
                              <label htmlFor="draft">Local</label>
                            </div>{" "}
                            <br />
                            {errors?.status ? (
                              <label className="error">
                                Selecciona el estatus del producto
                              </label>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              <div className="row clearfix">
                <div className="col-lg-4">
                  <div className="card">
                    <div className="header">
                      <h2>
                        <strong>Tipo de producto</strong>
                        <small>
                          Seleccione de la lista el tipo de producto
                        </small>
                      </h2>
                    </div>
                  </div>
                </div>
                <div className="col-lg-8">
                  <div className="card">
                    <div className="body">
                      <div className="row">
                        <div className="col-lg-12">
                          <label>Venta por</label>
                          <div className="row">
                            <div className="col-lg-3">
                              <div className="radio inlineblock">
                                <input
                                  name="product_type"
                                  id="simple"
                                  type="radio"
                                  ref={register}
                                  className="with-gap"
                                  value="simple"
                                  onChange={(e) => handleProductType(e)}
                                  checked={product_type === "simple"}
                                />
                                <label htmlFor="simple">
                                  Unidad <br />
                                  <small className="text-muted">
                                    (venta por unidad)
                                  </small>
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="radio inlineblock">
                                <input
                                  name="product_type"
                                  id="byarea"
                                  type="radio"
                                  ref={register}
                                  className="with-gap"
                                  value="byarea"
                                  onChange={(e) => handleProductType(e)}
                                  checked={product_type === "byarea"}
                                />
                                <label htmlFor="byarea">
                                  Metro Cuadrado <br />
                                  <small className="text-muted">
                                    ("base x altura" )
                                  </small>
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="radio inlineblock">
                                <input
                                  name="product_type"
                                  id="offset"
                                  type="radio"
                                  ref={register}
                                  className="with-gap"
                                  value="offset"
                                  onChange={(e) => handleProductType(e)}
                                  checked={product_type === "offset"}
                                />
                                <label htmlFor="offset">
                                  Unidades <br />
                                  <small className="text-muted">
                                    (e.j. 100, 500, 1000 uds )
                                  </small>
                                </label>
                              </div>
                            </div>
                            <div className="col-lg-3">
                              <div className="radio inlineblock">
                                <input
                                  name="product_type"
                                  id="service"
                                  type="radio"
                                  ref={register}
                                  className="with-gap"
                                  value="service"
                                  onChange={(e) => handleProductType(e)}
                                  checked={product_type === "service"}
                                />
                                <label htmlFor="service">
                                  Servicio <br />
                                  <small className="text-muted">
                                    (e.j. instalación, diseño )
                                  </small>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <hr />
              {status === "1" ? (
                <>
                  {product_type !== "service" && (
                    <>
                      {product_type === "simple" ||
                      product_type === "offset" ? (
                        <>
                          <div className="row clearfix">
                            <div className="col-lg-4">
                              <div className="card">
                                <div className="header">
                                  <h2>
                                    <strong>Tamaños</strong>
                                    <small>
                                      Agrega los tamaños del producto.
                                    </small>
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <div className="card">
                                <div className="body">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <label>Tamaños</label>
                                      <div className="form-group">
                                        <div className="input-group">
                                          <Controller
                                            name="sizes"
                                            control={control}
                                            placeholder="Selecciona los tamaños"
                                            defaultValue=""
                                            options={optionsSizes}
                                            isMulti
                                            closeMenuOnSelect={false}
                                            as={
                                              <Select className="react-select form-control p-0" />
                                            }
                                            rules={{
                                              required:
                                                "Selecciona el o los tamaños de la lista",
                                            }}
                                          />
                                          <div className="input-group-append">
                                            <span
                                              className="input-group-text"
                                              data-toggle="modal"
                                              data-target="#addSize"
                                            >
                                              <i className="zmdi zmdi-plus-circle" />
                                            </span>
                                          </div>
                                        </div>
                                        {errors?.size ? (
                                          <label className="error">
                                            {errors?.size?.message}
                                          </label>
                                        ) : null}
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      ) : null}
                      <div className="row clearfix">
                        <div className="col-lg-4">
                          <div className="card">
                            <div className="header">
                              <h2>
                                <strong>Material</strong>
                                <small>
                                  Selecciona los materiales para el producto.
                                </small>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="card">
                            <div className="body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label>Material</label>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <Controller
                                        name="material"
                                        control={control}
                                        placeholder="Selecciona los materiales"
                                        defaultValue=""
                                        options={optionsMaterial}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        as={
                                          <Select className="react-select form-control p-0" />
                                        }
                                        rules={{
                                          required:
                                            "Selecciona el o los materiales de la lista",
                                        }}
                                      />
                                      <div className="input-group-append">
                                        <span
                                          className="input-group-text"
                                          data-toggle="modal"
                                          data-target="#addMaterial"
                                        >
                                          <i className="zmdi zmdi-plus-circle" />
                                        </span>
                                      </div>
                                    </div>
                                    {errors?.material ? (
                                      <label className="error">
                                        {errors?.material?.message}
                                      </label>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row clearfix">
                        <div className="col-lg-4">
                          <div className="card">
                            <div className="header">
                              <h2>
                                <strong>Impresión</strong>
                                <small>
                                  Agrega los tipos de impresión del producto.
                                </small>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="card">
                            <div className="body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label>Impresión</label>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <Controller
                                        name="printing"
                                        control={control}
                                        placeholder="Selecciona tipo de impresión"
                                        defaultValue=""
                                        options={optionsPrinting}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        as={
                                          <Select className="react-select form-control p-0" />
                                        }
                                        rules={{
                                          required:
                                            "Selecciona el o los tipos de impresión de la lista",
                                        }}
                                      />
                                      <div className="input-group-append">
                                        <span
                                          className="input-group-text"
                                          data-toggle="modal"
                                          data-target="#addPrinting"
                                        >
                                          <i className="zmdi zmdi-plus-circle" />
                                        </span>
                                      </div>
                                    </div>
                                    {errors?.printing ? (
                                      <label className="error">
                                        {errors?.printing?.message}
                                      </label>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      <div className="row clearfix">
                        <div className="col-lg-4">
                          <div className="card">
                            <div className="header">
                              <h2>
                                <strong>Acabados</strong>
                                <small>Agrega los acabados del producto.</small>
                              </h2>
                            </div>
                          </div>
                        </div>
                        <div className="col-lg-8">
                          <div className="card">
                            <div className="body">
                              <div className="row">
                                <div className="col-lg-12">
                                  <label>Acabados</label>
                                  <div className="form-group">
                                    <div className="input-group">
                                      <Controller
                                        name="options"
                                        control={control}
                                        placeholder="Selecciona los acabados del producto"
                                        defaultValue=""
                                        options={optionsAcabados}
                                        isMulti
                                        closeMenuOnSelect={false}
                                        as={
                                          <Select className="react-select form-control p-0" />
                                        }
                                        rules={{
                                          required:
                                            "Selecciona el o los acabados de la lista",
                                        }}
                                      />
                                      <div className="input-group-append">
                                        <span
                                          className="input-group-text"
                                          data-toggle="modal"
                                          data-target="#addOption"
                                        >
                                          <i className="zmdi zmdi-plus-circle" />
                                        </span>
                                      </div>
                                    </div>
                                    {errors?.option ? (
                                      <label className="error">
                                        {errors?.option?.message}
                                      </label>
                                    ) : null}
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <hr />
                      {product_type === "offset" ? (
                        <>
                          <div className="row clearfix">
                            <div className="col-lg-4">
                              <div className="card">
                                <div className="header">
                                  <h2>
                                    <strong>Unidades</strong>
                                    <small>
                                      Agrega las unidades de venta del producto{" "}
                                      <br /> e.j. 100, 500, 1000 unidades
                                    </small>
                                  </h2>
                                </div>
                              </div>
                            </div>
                            <div className="col-lg-8">
                              <div className="card">
                                <div className="body">
                                  <div className="row">
                                    <div className="col-lg-12">
                                      <label>Unidades</label>
                                      <TagsInput
                                        value={units}
                                        onChange={setUnits}
                                        name="units"
                                        placeHolder="presiona enter para agregar"
                                      />
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <hr />
                        </>
                      ) : null}
                    </>
                  )}
                  {product_type === "service" || product_type === "byarea" ? (
                    <div className="row clearfix">
                      <div className="col-lg-4">
                        <div className="card">
                          <div className="header">
                            <h2>
                              <strong>Precio</strong>
                              <small>
                                Precio por metro cuadrado o servicio.
                              </small>
                            </h2>
                          </div>
                        </div>
                      </div>
                      <div className="col-lg-8">
                        <div className="card">
                          <div className="body">
                            <div className="row">
                              <div className="col-lg-12">
                                <label>Precio</label>
                                <div className="form-group">
                                  <input
                                    type="text"
                                    name="price"
                                    placeholder="100"
                                    ref={register({
                                      required: {
                                        value: true,
                                        message:
                                          "El Precio del servicio es requerido",
                                      },
                                    })}
                                    className={
                                      errors.price
                                        ? "form-control is-invalid"
                                        : "form-control "
                                    }
                                  />
                                  {errors?.price ? (
                                    <label className="error">
                                      {errors?.price?.message}
                                    </label>
                                  ) : null}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              ) : (
                <>
                  <div className="row clearfix">
                    <div className="col-lg-4">
                      <div className="card">
                        <div className="header">
                          <h2>
                            <strong>Precio</strong>
                            <small>Precio por producto o servicio.</small>
                          </h2>
                        </div>
                      </div>
                    </div>
                    <div className="col-lg-8">
                      <div className="card">
                        <div className="body">
                          <div className="row">
                            <div className="col-lg-12">
                              <label>Precio</label>
                              <div className="form-group">
                                <input
                                  type="text"
                                  name="price"
                                  placeholder="100"
                                  ref={register({
                                    required: {
                                      value: true,
                                      message:
                                        "El Precio del servicio es requerido",
                                    },
                                  })}
                                  className={
                                    errors.price
                                      ? "form-control is-invalid"
                                      : "form-control "
                                  }
                                />
                                {errors?.price ? (
                                  <label className="error">
                                    {errors?.price?.message}
                                  </label>
                                ) : null}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </>
              )}
              <div className="row clearfix justify-content-end">
                <div className="col-lg-4">
                  <button className="btn btn-primary btn-block">
                    Guardar producto
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </section>
      <AddFamily />
      <AddMaterial />
      <AddSize />
      <AddPrinting />
      <AddOption />
    </Layout>
  );
};

export default AddProduct;
