/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect } from "react";
import { Link } from "react-router-dom";
import CustomersContext from "../../contexts/customers/customersContext";
import AddShippingAddress from "./address/AddShippingAddress";
import { useParams } from "react-router-dom";
import EditShippingAddress from "./address/EditShippingAddress";
import Swal from "sweetalert2";
import Layout from "../../layouts/Layout";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";
import EditCustomer from "./EditCustomer";
import Moment from "react-moment";

const DetailsCustomer = () => {
  const {
    customer,
    address,
    orders,
    success,
    getCustomer,
    getEntities,
    editShippingAddress,
    removeShippingAddress,
    getComoSeEnteraron,
  } = useContext(CustomersContext);

  const { id } = useParams();
  const {
    full_name,
    phone,
    email,
    letterhead,
    como_se_enteraron
  } = customer;

  //carga los estados cuando se monta el componente
  useEffect(() => {
    getEntities();
    getComoSeEnteraron();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getCustomer(id);
    // eslint-disable-next-line
  }, [success]);

  const showConfirmMessage = (address) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Una dirección eliminada no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeShippingAddress(address);
      }
    });
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Información del cliente</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/customers">Clientes</Link>
                  </li>
                  <li className="breadcrumb-item active">Infomación</li>
                </ul>
                <ButtonMobileMenu />
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle />
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12">
                <div className="card mcard_4">
                  <div className="body">
                    <div className="user">
                      <small className="text-muted">Cliente:</small>
                      <p> {full_name}</p>
                      <hr />
                      <small className="text-muted">Correo: </small>
                      <p> {email}</p>
                      <hr />
                      <small className="text-muted">Teléfono: </small>
                      <p>{phone}</p>
                      <hr />
                      <small className="text-muted">¿Cómo se entero?: </small>
                      <p>
                        {como_se_enteraron?.opcion}
                      </p>
                      <hr />
                      <small className="text-muted">
                        Tipo de hoja membretada:{" "}
                      </small>
                      <p>
                        {letterhead === 1
                            ? "PBK"
                            : "CAMCA"}
                      </p>
                      <hr />
                      <div className="text-center">
                        <button
                          className="btn btn-primary btn-round"
                          data-toggle="modal"
                          data-target="#editCustomer"
                        >
                          <i className="zmdi zmdi-edit" /> Editar Información
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="card">
                  <div className="header">
                    <h2>
                      <strong>DIRECCIONES</strong> DE ENVÍO
                    </h2>
                    <ul className="header-dropdown">
                      <li className="dropdown">
                        <a
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="zmdi zmdi-more" />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right">
                          <li>
                            <a
                              data-toggle="modal"
                              data-target="#addShippingAddess"
                            >
                              <i className="zmdi zmdi-plus" /> Agregar Nueva
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <ul className="comment-reply list-unstyled">
                      {address?.length > 0 ? (
                        address?.map((shippingAddress, index) => {
                          return (
                            <div key={`cont-${index}`}>
                              <li>
                                <div className="text-box">
                                  <div className="replybutton">
                                    <div className="btn-group">
                                      <span
                                        className="btn btn-outline-primary btn-sm"
                                        data-toggle="modal"
                                        data-target="#updateShippingAddess"
                                        onClick={() =>
                                          editShippingAddress(
                                            shippingAddress.id
                                          )
                                        }
                                      >
                                        <i className="zmdi zmdi-edit" />
                                      </span>
                                      <span
                                        className="btn btn-outline-danger btn-sm"
                                        onClick={() =>
                                          showConfirmMessage(shippingAddress.id)
                                        }
                                      >
                                        <i className="zmdi zmdi-delete" />
                                      </span>
                                    </div>
                                  </div>
                                  <div className="row text-small">
                                    <div className="col-lg-7 mb-2">
                                      <span className="text-muted">
                                        Dirección
                                      </span>
                                      <br />
                                      {shippingAddress.street} #
                                      {shippingAddress.number_out},{" "}
                                      {shippingAddress.number_int
                                        ? "interior " +
                                          shippingAddress.number_int +
                                          ","
                                        : ""}{" "}
                                      {shippingAddress.location}{" "}
                                    </div>
                                    <div className="col-lg-3 mb-2">
                                      <span className="text-muted">
                                        Ciudad / Estado
                                      </span>
                                      <br />
                                      {shippingAddress.municipality.name},{" "}
                                      {shippingAddress.municipality.state.name}{" "}
                                    </div>
                                    <div className="col-lg-2 mb-2">
                                      <span className="text-muted">C.P.</span>
                                      <br />
                                      {shippingAddress.postal_code}
                                    </div>
                                    <div className="col-lg-12 mb-2">
                                      <span className="text-muted">
                                        Referencias
                                      </span>
                                      <br />
                                      {shippingAddress.reference
                                        ? shippingAddress.reference
                                        : ""}
                                    </div>
                                  </div>
                                </div>
                              </li>
                              <hr />
                            </div>
                          );
                        })
                      ) : (
                        <div className="col-lg-12">
                          <div className="alert alert-warning" role="alert">
                            No hay direcciones de envío registradas
                          </div>
                        </div>
                      )}
                    </ul>
                  </div>
                </div>
                <div className="card">
                  <div className="header">
                    <h2>
                      <strong>ORDENES</strong> RECIENTES
                    </h2>
                  </div>
                  <div className="body">
                    <div className="table-responsive">
                      <table className="table table-hover c_table">
                        <thead>
                          <tr>
                            <th>#</th>
                            <th>Emitida</th>
                            <th>Total</th>
                            <th>F. Entrega</th>
                            <th>Orden</th>
                            <th>Status</th>
                            <th>Status Pago</th>
                          </tr>
                        </thead>
                        <tbody>
                          {!orders.length ? (
                            <tr>
                              <td align="center" colSpan={9}>
                                No se encuentran Ordenes Registradas
                              </td>
                            </tr>
                          ) : (
                            orders.map((order, index) => (
                              <tr key={index}>
                                <td>
                                  <strong>{order.id}</strong>
                                </td>
                                <td>
                                  <Moment format="DD/MM/YYYY">
                                    {order.created_at}
                                  </Moment>
                                </td>
                                <td>
                                  {new Intl.NumberFormat("es-MX", {
                                    style: "currency",
                                    currency: "MXN",
                                    minimumFractionDigits: 2,
                                  }).format(order.total)}
                                </td>
                                <td>
                                  <Moment format="DD/MM/YYYY">
                                    {order.expires}
                                  </Moment>
                                </td>
                                <td>
                                  {order.type_of_sale === 1 ? (
                                    <div className="status local">
                                      {" "}
                                      <i className="zmdi zmdi-circle"></i> local{" "}
                                    </div>
                                  ) : order.type_of_sale === 2 ? (
                                    <div className="status ecommerce">
                                      {" "}
                                      <i className="zmdi zmdi-circle"></i>{" "}
                                      online{" "}
                                    </div>
                                  ) : null}
                                </td>
                                <td>
                                  {order.status === 0 ? (
                                    <span className="badge badge-default">
                                      Rechazada
                                    </span>
                                  ) : order.status === 1 ? (
                                    <span className="badge badge-warning">
                                      Pendiente
                                    </span>
                                  ) : order.status === 2 ? (
                                    <span className="badge badge-success">
                                      Aprobada
                                    </span>
                                  ) : order.status === 3 ? (
                                    <span className="badge badge-primary">
                                      En Producción
                                    </span>
                                  ) : order.status === 4 ? (
                                    <span className="badge badge-info">
                                      Pedido listo
                                    </span>
                                  ) : order.status === 5 ? (
                                    <span className="badge badge-danger">
                                      Completada
                                    </span>
                                  ) : null}
                                </td>
                                <td>
                                  {order.status >= 2 ? (
                                    <>
                                      {order.payment_status === 1 ? (
                                        <span className="badge bg-deep-orange">
                                          Cuenta por cobrar
                                        </span>
                                      ) : order.payment_status === 2 ? (
                                        <span className="badge bg-amber">
                                          Pago Incompleto
                                        </span>
                                      ) : order.payment_status === 3 ? (
                                        <span className="badge bg-teal">
                                          Pagado
                                        </span>
                                      ) : null}
                                    </>
                                  ) : (
                                    <span className="badge bg-lime">
                                      No aplica
                                    </span>
                                  )}
                                </td>
                              </tr>
                            ))
                          )}
                        </tbody>
                      </table>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddShippingAddress />
      <EditShippingAddress />
      <EditCustomer cliente={customer} />
    </Layout>
  );
};

export default DetailsCustomer;
