import React, { useContext, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import OrdersContext from "../../../contexts/orders/ordersContext";
import AddCustomer from "./AddCustomer";
import Swal from "sweetalert2";
import TableProducts from "./TableProducts";

const OrderDetails = () => {
  const {
    subtotal,
    discount,
    iva,
    totalSale,
    customers,
    addOrder,
    updateDiscountRate,
  } = useContext(OrdersContext);

  const { register, errors, handleSubmit, control, setValue } = useForm();

  const [customer, setCustomer] = useState(null);
  const [discounts, setDiscounts] = useState(false);

  const optionsCustomers = customers.map((customer) => {
    return { value: customer.id, label: customer.full_name };
  });

  const handleChangeCustomer = (e) => {
    setValue("customer_id", e, { shouldValidate: true });
    const selectedCustomer = customers.find(
      (customer) => customer.id === e.value
    );
    setCustomer(selectedCustomer);
  };

  const onSubmit = (data) => {
    if (totalSale > 0) {
      Swal.fire({
        title: "¿La orden se guardara?",
        icon: "success",
        showCancelButton: true,
        confirmButtonColor: "#3085d6",
        cancelButtonColor: "#d33",
        confirmButtonText: "Si, Confirmar!",
        cancelButtonText: "No, cancelar!",
      }).then((result) => {
        if (result.isConfirmed) {
          addOrder(data);
        }
      });
    } else {
      Swal.fire({
        title: "Error upss",
        text: "Aun no has agregado productos a la orden",
        icon: "warning",
        showConfirmButton: true,
      });
    }
  };

  const handleShowDiscounts = () => {
    setDiscounts(!discounts);
    updateDiscountRate(0);
  };

  const handleChangeDiscount = (e) => {
    updateDiscountRate(Number(e.target.value));
  };

  return (
    <>
      <div className="card">
        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="row">
              <div className="col-md-12 mb-3">
                <div className="row">
                  <div className="col-md-12">
                    <div className="form-group">
                      <label>Selecciona un cliente</label>
                      <div className="input-group">
                        <Controller
                          name="customer_id"
                          control={control}
                          defaultValue=""
                          render={({ field }) => (
                            <div className="react-select form-control p-0">
                              <Select
                                {...field}
                                placeholder="Selecciona un cliente"
                                options={optionsCustomers}
                                onChange={(e) => handleChangeCustomer(e)}
                              />
                            </div>
                          )}
                          rules={{
                            required:
                              "Selecciona un cliente antes de continuar",
                          }}
                        />

                        <div className="input-group-append">
                          <span
                            className="input-group-text"
                            data-toggle="modal"
                            data-target="#addCustomer"
                          >
                            <i className="zmdi zmdi-account-add" />
                          </span>
                        </div>
                      </div>
                      {errors?.customer_id ? (
                        <label className="error">
                          {errors?.customer_id?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-7">
                    <div className="form-group">
                      <input
                        name="email"
                        type="text"
                        readOnly
                        defaultValue={customer ? customer.email : ""}
                        className="form-control"
                        placeholder="Correo"
                      />
                      {errors?.email ? (
                        <label className="error">
                          {errors?.email?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-md-5">
                    <div className="form-group">
                      <input
                        name="phone"
                        type="text"
                        readOnly
                        defaultValue={customer ? customer.phone : ""}
                        className="form-control"
                        placeholder="Teléfono"
                      />
                      {errors?.phone ? (
                        <label className="error">
                          {errors?.phone?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-md-12 mb-4">
                <TableProducts />
              </div>
              <div className="col-md-7">
                <div className="row">
                  <div className="col-md-12">
                  <div className="form-group">
                    <div className="checkbox">
                      <input
                        id="checkbox1"
                        type="checkbox"
                        value={discounts}
                        checked={discounts ? true : false}
                        onChange={() => handleShowDiscounts()}
                      />
                      <label htmlFor="checkbox1" className="m-r-20">
                        Descuentos
                      </label>
                    </div>
                  </div>
                  </div>
                  {discounts && (
                    <div className="col-md-12">
                      <div className="form-group">
                        <div className="radio inlineblock m-r-20">
                          <input
                            type="radio"
                            name="discount"
                            id="discount-1"
                            className="with-gap"
                            value={5}
                            onClick={(e) => handleChangeDiscount(e)}
                          />
                          <label htmlFor="discount-1">5%</label>
                        </div>
                        <div className="radio inlineblock m-r-20">
                          <input
                            type="radio"
                            name="discount"
                            id="discount-2"
                            className="with-gap"
                            value={10}
                            onClick={(e) => handleChangeDiscount(e)}
                          />
                          <label htmlFor="discount-2">10%</label>
                        </div>
                        <div className="radio inlineblock m-r-20">
                          <input
                            type="radio"
                            name="discount"
                            id="discount-3"
                            className="with-gap"
                            value={15}
                            onClick={(e) => handleChangeDiscount(e)}
                          />
                          <label htmlFor="discount-3">15%</label>
                        </div>
                        <div className="radio inlineblock">
                          <input
                            type="radio"
                            name="discount"
                            id="discount-4"
                            className="with-gap"
                            value={20}
                            onClick={(e) => handleChangeDiscount(e)}
                          />
                          <label htmlFor="discount-4">20%</label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
                <div className="form-group">
                  <label>Comentarios / Notas</label>
                  <textarea
                    name="notes"
                    defaultValue=""
                    rows={5}
                    placeholder="Agrega aqui un comentario (opcional)"
                    className="form-control"
                    ref={register({
                      required: {
                        value: false,
                        message: "Los comentarios son equeridos",
                      },
                    })}
                  />
                </div>
              </div>
              <div className="col-md-5 text-right">
                <div className="pt-4 mb-3 wmin-md-400 ml-auto">
                  <div className="table-responsive">
                    <table className="table">
                      <tbody>
                        <tr>
                          <th className="text-left">Subtotal:</th>
                          <td className="text-right">
                            {new Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                              minimumFractionDigits: 2,
                            }).format(subtotal)}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-left">Descuento:</th>
                          <td className="text-right">
                            {new Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                              minimumFractionDigits: 2,
                            }).format(discount)}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-left">
                            IVA:{" "}
                            <span className="font-weight-normal">(16%)</span>
                          </th>
                          <td className="text-right">
                            {new Intl.NumberFormat("es-MX", {
                              style: "currency",
                              currency: "MXN",
                              minimumFractionDigits: 2,
                            }).format(iva)}
                          </td>
                        </tr>
                        <tr>
                          <th className="text-left">Total:</th>
                          <td className="text-right text-primary">
                            <h5 className="font-weight-semibold">
                              {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                                minimumFractionDigits: 2,
                              }).format(totalSale)}
                            </h5>
                          </td>
                        </tr>
                      </tbody>
                    </table>
                  </div>
                  <div className="text-right mt-3">
                    <button type="submit" className="btn btn-primary btn-block">
                      <b>
                        <i className="zmdi zmdi-shopping-cart mr-1" />
                      </b>{" "}
                      Guardar Orden
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <AddCustomer />
    </>
  );
};

export default OrderDetails;
