import { ADD_USER, EDIT_USER, GET_USERS, REMOVE_USER, UPDATE_USER } from "../../types"


export default function usersReducer(state, action) {
    switch (action.type) {
        case ADD_USER:
            return{
                ...state,
                success: true,
            }

        case GET_USERS:
            return{
                ...state,
                users: action.payload,
                success:false,
            }
        
        case EDIT_USER:
            return {
                ...state,
                selectedUser: state.users.find(user => user.id === action.payload),
            }
        
        case UPDATE_USER:
            return{
                ...state,
                success: true,
                selectedUser: [],
            }

        case REMOVE_USER:
            return {
                ...state,
                users: state.users.filter(
                (user) => user.id !== action.payload
                ),
            }

        default:
            return state;
    }
}
