import { ADD_OPTION, EDIT_OPTION, GET_OPTIONS, REMOVE_OPTION, UPDATE_OPTION } from "../../types"

export default function optionsReducer(state, action) {
    switch (action.type) {
        case ADD_OPTION:
            return{
                ...state,
                success: true,
            }

        case GET_OPTIONS:
            return{
                ...state,
                options: action.payload,
                success:false,
            }

        case EDIT_OPTION:
            return {
                ...state,
                selectedOption: state.options.find(option => option.id === action.payload),
            }

        case UPDATE_OPTION:
            return{
                ...state,
                success: true,
                selectedOption: [],
            }

        case REMOVE_OPTION:
            return {
                ...state,
                options: state.options.filter(
                (option) => option.id !== action.payload
                ),
            }

        default:
            return state;
    }
}
