import React, { useContext, useEffect, useState } from "react";
import { Controller, useForm } from "react-hook-form";
import ProvidersContext from "../../contexts/providers/providersContext";
import Layout from "../../layouts/Layout";
import { Link, useNavigate } from "react-router-dom";
import { TagsInput } from "react-tag-input-component";
import Select from "react-select";
import RightButtonToggle from "../../components/RightButtonToggle";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";

const AddProvider = () => {
  const navigate = useNavigate();
  const {
    entities,
    municipalities,
    getMunicipalities,
    getEntities,
    addProvider,
    success,
  } = useContext(ProvidersContext);

  const { register, errors, handleSubmit, reset, control, setValue } =
    useForm();

  const [tags, setTags] = useState([]);

  useEffect(() => {
    getEntities();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de clientes
    if (success) {
      navigate("/proveedores");
    }
    //eslint-disable-next-line
  }, [success]);

  //funcion que se encarga de listar los estados en el select
  const optionsEntities = entities.map((entity) => {
    return { value: entity.id, label: entity.name };
  });

  //funcion que se encarga de listar los municipios en el select
  const optionsMunicipalities = municipalities.map((municipality) => {
    return { value: municipality.id, label: municipality.name };
  });

  // Lista de los nombres de bancos que operan en mexico
  const bancoOptions = [
    { value: "abc-capital", label: "ABC Capital" },
    { value: "actinver", label: "Actinver" },
    { value: "afirme", label: "Afirme" },
    { value: "american-express", label: "American Express" },
    { value: "autofin", label: "Autofin" },
    { value: "banca-mifel", label: "Banca Mifel" },
    { value: "banco-ahorro-famsa", label: "Banco Ahorro Famsa" },
    { value: "banco-azteca", label: "Banco Azteca" },
    { value: "banco-bancrea", label: "Banco Bancrea" },
    { value: "banco-base", label: "Banco Base" },
    { value: "banco-credit-suisse", label: "Banco Credit Suisse" },
    { value: "banco-del-banjio", label: "Banco del Banjío" },
    { value: "bancoppel", label: "Bancoppel" },
    { value: "bank-of-america", label: "Bank of America" },
    { value: "bank-of-tokyo-mitsubishi", label: "Bank of Tokyo-Mitsubishi" },
    { value: "banorte-ixe", label: "Banorte / IXE" },
    { value: "banregio", label: "Banregio" },
    { value: "bansi", label: "Bansi" },
    { value: "barclays", label: "Barclays" },
    { value: "bbva-bancomer", label: "BBVA Bancomer" },
    { value: "ci-banco", label: "CI Banco" },
    { value: "citibanamex", label: "Citibanamex" },
    { value: "compartamos", label: "Compartamos" },
    { value: "consubanco", label: "Consubanco" },
    { value: "deutsche-bank", label: "Deutsche Bank" },
    { value: "donde-banco", label: "Dondé Banco" },
    { value: "hsbc-mexico", label: "HSBC México" },
    { value: "icbc", label: "ICBC" },
    { value: "inbursa", label: "Inbursa" },
    { value: "inmobiliario-mexicano", label: "Inmobiliario Mexicano" },
    { value: "inter-banco", label: "Inter Banco" },
    { value: "interacciones", label: "Interacciones" },
    { value: "investa-bank", label: "Investa Bank" },
    { value: "invex", label: "Invex" },
    { value: "jp-morgan", label: "JP Morgan" },
    { value: "mizuho", label: "Mizuho" },
    { value: "monex", label: "Monex" },
    { value: "multiva", label: "Multiva" },
    { value: "s3", label: "S3" },
    { value: "sabadell", label: "Sabadell" },
    { value: "santander", label: "Santander" },
    { value: "scotiabank", label: "Scotiabank" },
    { value: "shinhan", label: "Shinhan" },
    { value: "ubs", label: "UBS" },
    { value: "ve-por-mas", label: "Ve por Más" },
    { value: "volkswagen-bank", label: "Volkswagen Bank" },
  ];

  //funcion que cambia los municipios del select
  const handleChangeEntity = (value) => {
    setValue("estado", value, { shouldValidate: true });
    setValue("municipio_id", "");
    getMunicipalities(value.value);
  };

  const onSubmit = (data) => {
    data.etiquetas = tags;
    addProvider(data);
    reset();
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Registrar Proveedor </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/proveedores"> Proveedores </Link>
                  </li>
                  <li className="breadcrumb-item active">Nuevo</li>
                </ul>
               <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="header">
                      <h2>
                        <small>Completa la información necesaria.</small>
                      </h2>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Nombre Comercial *</label>
                            <input
                              name="nombre_comercial"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: true,
                                  message:
                                    "El campo nombre comercial es requerido",
                                },
                              })}
                              className={
                                errors.nombre_comercial
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="Nombre Comercial"
                            />
                            {errors?.nombre_comercial ? (
                              <label className="error">
                                {errors?.nombre_comercial?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Razón Social *</label>
                            <input
                              name="razon_social"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: true,
                                  message: "El campo razón social es requerido",
                                },
                              })}
                              className={
                                errors.razon_social
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="Razón social"
                            />
                            {errors?.razon_social ? (
                              <label className="error">
                                {errors?.razon_social?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Rubro/Giro*</label>
                            <input
                              name="giro"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: true,
                                  message: "El campo rubro/giro es requerido",
                                },
                              })}
                              className={
                                errors.giro
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="Rubro/Giro"
                            />
                            {errors?.giro ? (
                              <label className="error">
                                {errors?.giro?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>
                              Categorías{" "}
                              <small>
                                (lona, vinil, DTF, promocionales, papel)
                              </small>
                            </label>
                            <TagsInput
                              value={tags}
                              onChange={setTags}
                              name="etiquetas"
                              placeHolder="presiona enter para agregar"
                            />
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <div className="form-group">
                            <label>Observaciones</label>
                            <input
                              name="observaciones"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: false,
                                  message:
                                    "El campo observaciones es requerido",
                                },
                              })}
                              className={
                                errors.observaciones
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="Observaciones "
                            />
                            {errors?.observaciones ? (
                              <label className="error">
                                {errors?.observaciones?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <h2 className="card-inside-title">
                            INFORMACIÓN DE CONTACTO
                          </h2>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Nombre del Contacto *</label>
                            <input
                              name="nombre_contacto"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: true,
                                  message:
                                    "El campo Nombre del contacto es requerido",
                                },
                              })}
                              className={
                                errors.nombre_contacto
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="Nombre del contacto "
                            />
                            {errors?.nombre_contacto ? (
                              <label className="error">
                                {errors?.nombre_contacto?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>Teléfono *</label>
                            <input
                              name="telefono"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: true,
                                  message: "El campo Teléfono es requerido",
                                },
                              })}
                              className={
                                errors.telefono
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="Teléfono "
                            />
                            {errors?.telefono ? (
                              <label className="error">
                                {errors?.telefono?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>WhatsApp</label>
                            <input
                              name="whatsapp"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: false,
                                  message: "El campo WhatsApp es requerido",
                                },
                              })}
                              className={
                                errors.whatsapp
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="WhatsApp "
                            />
                            {errors?.whatsapp ? (
                              <label className="error">
                                {errors?.whatsapp?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Correo *</label>
                            <input
                              name="correo"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: true,
                                  message: "El campo Correo es requerido",
                                },
                              })}
                              className={
                                errors.correo
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="Correo"
                            />
                            {errors?.correo ? (
                              <label className="error">
                                {errors?.correo?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Teléfono 2</label>
                            <input
                              name="telefono2"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: false,
                                  message: "El campo Teléfono 2 es requerido",
                                },
                              })}
                              className={
                                errors.telefono2
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="Teléfono 2 "
                            />
                            {errors?.telefono2 ? (
                              <label className="error">
                                {errors?.telefono2?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Correo 2</label>
                            <input
                              name="correo2"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: false,
                                  message: "El campo Correo 2 es requerido",
                                },
                              })}
                              className={
                                errors.correo2
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="Teléfono 2 "
                            />
                            {errors?.correo2 ? (
                              <label className="error">
                                {errors?.correo2?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Dirección *</label>
                            <input
                              name="direccion"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: true,
                                  message: "El campo Dirección es requerido",
                                },
                              })}
                              className={
                                errors.direccion
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="Dirección "
                            />
                            {errors?.direccion ? (
                              <label className="error">
                                {errors?.direccion?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>Estado *</label>
                            <Controller
                              name="estado"
                              control={control}
                              defaultValue=""
                              render={({ field }) => (
                                <Select
                                  {...field}
                                  placeholder="Selecciona una opción"
                                  options={optionsEntities}
                                  onChange={(e) => handleChangeEntity(e)}
                                />
                              )}
                              rules={{
                                required: "El Estado es un campo requerido ",
                              }}
                            />
                            {errors?.estado ? (
                              <label className="error">
                                {errors?.estado?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>Municipio *</label>
                            <Controller
                              name="municipio_id"
                              control={control}
                              defaultValue=""
                              placeholder="Selecciona una opción"
                              options={optionsMunicipalities}
                              as={<Select />}
                              rules={{
                                required: "El Municipio es un campo Requerido",
                              }}
                            />
                            {errors?.municipio_id ? (
                              <label className="error">
                                {errors?.municipio_id?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-2">
                          <div className="form-group">
                            <label>C. Postal *</label>
                            <input
                              name="codigo_postal"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: true,
                                  message:
                                    "El campo Código Postal es requerido",
                                },
                              })}
                              className={
                                errors.codigo_postal
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="CP "
                            />
                            {errors?.codigo_postal ? (
                              <label className="error">
                                {errors?.codigo_postal?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <h2 className="card-inside-title">DATOS BANCARIOS</h2>
                        </div>
                        <div className="col-lg-4">
                          <div className="form-group">
                            <label>Banco *</label>
                            <Controller
                              name="banco"
                              control={control}
                              defaultValue=""
                              placeholder="Selecciona una opción"
                              options={bancoOptions}
                              as={<Select />}
                              rules={{
                                required: {
                                  value: true,
                                  message: "El campo Banco es requerido",
                                },
                              }}
                            />
                            {errors?.banco ? (
                              <label className="error">
                                {errors?.banco?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-3">
                          <div className="form-group">
                            <label>No. Cuenta *</label>
                            <input
                              name="numero_cuenta"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: true,
                                  message: "El campo No. Cuenta es requerido",
                                },
                              })}
                              className={
                                errors.numero_cuenta
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="No. Cuenta"
                            />
                            {errors?.numero_cuenta ? (
                              <label className="error">
                                {errors?.numero_cuenta?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-5">
                          <div className="form-group">
                            <label>CLABE *</label>
                            <input
                              name="clabe"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: false,
                                  message: "El campo CLABE es requerido",
                                },
                              })}
                              className={
                                errors.clabe
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="CLABE"
                            />
                            {errors?.clabe ? (
                              <label className="error">
                                {errors?.clabe?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Titular *</label>
                            <input
                              name="nombre_titular"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: true,
                                  message: "El campo Titular es requerido",
                                },
                              })}
                              className={
                                errors.nombre_titular
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                              placeholder="Nombre del titular"
                            />
                            {errors?.nombre_titular ? (
                              <label className="error">
                                {errors?.nombre_titular?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-12">
                          <button
                            type="submit"
                            className="btn btn-danger btn-round waves-effect"
                          >
                            GUARDAR PROVEEDOR
                          </button>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AddProvider;
