import React, { useContext, useEffect, useState } from "react";
import OrdersContext from "../../../contexts/orders/ordersContext";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
const FormProduct = () => {
  const {
    getProducts,
    products,
    getProduct,
    product,
    sizes,
    materialProduct,
    printing,
    options,
    prices,
    addProduct,
    resetProduct,
  } = useContext(OrdersContext);
  const { register, errors, handleSubmit, control, setValue, reset } =
    useForm();

  const [amount, setAmount] = useState(0);
  const [base, setBase] = useState(1);
  const [altura, setAltura] = useState(1);
  const [area, setArea] = useState(1);
  const [quantity, setQuantity] = useState(1);
  const [sizeP, setSize] = useState({});
  const [materialP, setMaterial] = useState({});
  const [printP, setPrinting] = useState({});
  const [optionP, setOption] = useState({});
  const [priceOption, setPriceOption] = useState(0);
  const [measure, setMeasure] = useState("serv.");

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line 
  }, []);

  const handleChangeProduct = (e) => {
    getProduct(e.value);
    setValue("product_id", e, { shouldValidate: true });
  };

  const handleChangeSize = (e) => {
    let index = e.target.selectedIndex;
    let label = e.target.options[index].text;
    let value = e.target.value;

    setSize({ value, label });
  };

  const handleChangeMaterial = (e) => {
    let index = e.target.selectedIndex;
    let label = e.target.options[index].text;
    let value = e.target.value;

    setMaterial({ value, label });
  };

  const handleChangePrinting = (e) => {
    let index = e.target.selectedIndex;
    let label = e.target.options[index].text;
    let value = e.target.value;
    setPrinting({ value, label });
  };

  const handleChangeOption = (e) => {
    let index = e.target.selectedIndex;
    let label = e.target.options[index].text;
    let value = e.target.value;
    setOption({ value, label });
    handleChangePriceOption(value);
  };

  const handleChangePriceOption = (op) => {
    let priceOp = 0;

    for (let index = 0; index < options.length; index++) {
      if (options[index].option_id == op) {
        priceOp = options[index].price;
      }
    }
    setPriceOption(priceOp);
  };

  const handleChangeQuantity = (e) => {
    setQuantity(Number(e.target.value));
  };

  const handleChangeBase = (e) => {
    setBase(e.target.value);
  };

  const handleChangeAltura = (e) => {
    setAltura(e.target.value);
  };

  const handleChangeMeasure = (e) => {
    setMeasure(e.target.value);
  };

  useEffect(() => {
    if (product) {
      if (product.status === 1) {
        setValue("description", "");
        switch (product.product_type) {
          case "simple":
            setSize({ value: sizes[0].size_id, label: sizes[0].size.name });
            setMaterial({
              value: materialProduct[0].material_id,
              label: materialProduct[0].material.name,
            });
            setPrinting({
              value: printing[0].printing_id,
              label: printing[0].printing.name,
            });
            setOption({
              value: options[0].option_id,
              label: options[0].option.name,
            });
            setPriceOption(options[0].price);
            setQuantity(1);
            setValue("quantity", 1, { shouldDirty: true });
            break;

          case "byarea":
            setMaterial({
              value: materialProduct[0].material_id,
              label: materialProduct[0].material.name,
            });
            setPrinting({
              value: printing[0].printing_id,
              label: printing[0].printing.name,
            });
            setOption({
              value: options[0].option_id,
              label: options[0].option.name,
            });
            setPriceOption(options[0].price);
            setQuantity(1);
            setValue("quantity", 1, { shouldDirty: true });
            break;

          case "offset":
            setSize({ value: sizes[0].size_id, label: sizes[0].size.name });
            setMaterial({
              value: materialProduct[0].material_id,
              label: materialProduct[0].material.name,
            });
            setPrinting({
              value: printing[0].printing_id,
              label: printing[0].printing.name,
            });
            setOption({
              value: options[0].option_id,
              label: options[0].option.name,
            });
            setPriceOption(options[0].price);
            setQuantity(Number(product.units[0]));
            setValue("quantity", Number(product.units[0]), {
              shouldDirty: true,
            });
            break;

          case "service":
            setAmount(product.price);
            setQuantity(1);
            setValue("quantity", 1, { shouldDirty: true });
            break;

          default:
            break;
        }
      } else {
        setAmount(product.price);
        setQuantity(1);
        setValue("quantity", 1, { shouldDirty: true });
        setValue("description", product.description);
        if (product.product_type !== "service") {
          if (materialProduct.length) {
            setMaterial({
              value: materialProduct[0].material_id,
              label: materialProduct[0].material.name,
            });
          }
        }
      }
    }
    // eslint-disable-next-line
  }, [product]);

  useEffect(() => {
    if (product && product.product_type === "offset") {
      setQuantity(Number(product.units[0]));
      setValue("quantity", Number(product.units[0]), { shouldDirty: true });
    }
    // eslint-disable-next-line
  }, [materialP, sizeP, printP]);

  useEffect(() => {
    setArea(parseFloat(base) * parseFloat(altura) || 0);
    // eslint-disable-next-line
  }, [base, altura]);

  useEffect(() => {
    if (product) {
      calculatePrice();
    }
    // eslint-disable-next-line
  }, [area, quantity, materialP, sizeP, printP, optionP]);

  //funcion que se encarga de listar los productos en el select
  const optionsProducts = products.map((product) => {
    return { value: product.id, label: product.name };
  });

  const optionsSize = sizes.map((size, index) => (
    <option key={index} value={size.size.id}>
      {size.size.name}
    </option>
  ));

  const optionsMaterial = materialProduct.map((opmaterial, index) => (
    <option key={index} value={opmaterial.material.id}>
      {opmaterial.material.name}
    </option>
  ));

  const optionsPrinting = printing.map((print, index) => (
    <option key={index} value={print.printing.id}>
      {print.printing.name}
    </option>
  ));

  const optionsAcabados = options.map((op, index) => (
    <option key={index} value={op.option.id}>
      {op.option.name}
    </option>
  ));

  const calculatePrice = () => {
    if (product.status === 1) {
      switch (product.product_type) {
        case "simple":
          let priceSimple = 0;
          for (let index = 0; index < prices.length; index++) {
            if (
              sizeP.value == prices[index].size_id &&
              materialP.value == prices[index].material_id &&
              printP.value == prices[index].printing_id
            ) {
              priceSimple = prices[index].price;
            }
          }
          let final_price_simple = 0;
          final_price_simple = (priceSimple + priceOption) * Number(quantity);
          setAmount(final_price_simple);
          break;

        case "byarea":
          let priceByarea = product.price;
          let priceArea = area * priceByarea;
          let final_price_area = 0;

          final_price_area = (priceArea + priceOption) * Number(quantity);
          setAmount(final_price_area);
          break;

        case "offset":
          let priceOffset = 0;

          for (let index = 0; index < prices.length; index++) {
            if (
              quantity == prices[index].quantity &&
              sizeP.value == prices[index].size_id &&
              materialP.value == prices[index].material_id &&
              printP.value == prices[index].printing_id
            ) {
              priceOffset = prices[index].price;
            }
          }
          let final_price_offset = 0;

          final_price_offset = priceOffset + priceOption;
          setAmount(final_price_offset);
          break;

        case "service":
          let priceService = product.price;
          let final_price_service = 0;

          if (measure === "m2") {
            final_price_service = priceService * area * Number(quantity);
          } else {
            final_price_service = priceService * Number(quantity);
          }

          setAmount(final_price_service);
          break;

        default:
          break;
      }
    } else {
      switch (product.product_type) {
        case "simple":
          let priceSimple = product.price;
          let final_price_simple = 0;

          final_price_simple = priceSimple * Number(quantity);
          setAmount(final_price_simple);
          break;

        case "byarea":
          let priceByarea = product.price;
          let priceArea = area * priceByarea;
          let final_price_area = 0;

          final_price_area = priceArea * Number(quantity);
          setAmount(final_price_area);
          break;

        case "offset":
          let priceOffset = product.price;
          let final_price_offset = 0;

          final_price_offset = priceOffset * Number(quantity);
          setAmount(final_price_offset);
          break;

        case "service":
          let priceService = product.price;
          let final_price_service = 0;

          if (measure === "m2") {
            final_price_service = priceService * area * Number(quantity);
          } else {
            final_price_service = priceService * Number(quantity);
          }

          setAmount(final_price_service);
          break;

        default:
          break;
      }
    }
  };

  const handleResetForm = () => {
    setAmount(0);
    setBase(1);
    setAltura(1);
    setArea(1);
    setQuantity(1);
    setSize({});
    setMaterial({});
    setPrinting({});
    setOption({});
  };

  const onSubmit = async (data) => {
    data.product = product;
    data.size = sizeP;
    data.material = materialP;
    data.printing = printP;
    data.option = optionP;
    data.price = amount / quantity;
    data.final_price = amount;
    if (product.product_type === "byarea" || measure === "m2") {
      data.area = area.toFixed(2);
    }
    addProduct(data);
    reset({});
    resetProduct();
    handleResetForm();
  };

  return (
    <>
      <div className="card">
        <div className="body">
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="form-group">
              <label>Producto</label>
              <Controller
                name="product_id"
                control={control}
                defaultValue=""
                render={({ field }) => (
                  <Select
                    {...field}
                    placeholder="Selecciona un producto"
                    options={optionsProducts}
                    onChange={(e) => handleChangeProduct(e)}
                  />
                )}
                rules={{
                  required: "Selecciona un producto antes de continuar",
                }}
              />
              {errors?.product_id ? (
                <label className="error">{errors?.product_id?.message}</label>
              ) : null}
            </div>
            {product ? (
              <>
                {product && product.product_type !== "service" ? (
                  <>
                    {product && product.product_type === "byarea" ? (
                      <>
                        <div className="form-group">
                          <label>Medidas</label>
                          <div className="row">
                            <div className="col-4">
                              <label>Base (m):</label>
                              <input
                                type="text"
                                defaultValue={base}
                                className={
                                  errors.base
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                                placeholder="Base"
                                name="base"
                                ref={register({
                                  required: "Ingresa la base.",
                                  pattern: {
                                    value:
                                      /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                                    message: "No puede ser un valor negativo",
                                  },
                                })}
                                onChange={(e) => handleChangeBase(e)}
                              />
                              <div className="invalid-feedback">
                                {errors.base?.message}
                              </div>
                            </div>
                            <div className="col-4">
                              <label>Altura (m):</label>
                              <input
                                type="text"
                                defaultValue={altura}
                                className={
                                  errors.altura
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                                placeholder="Altura"
                                name="altura"
                                ref={register({
                                  required: "Ingresa la Altura.",
                                  pattern: {
                                    value:
                                      /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                                    message: "No puede ser un valor negativo",
                                  },
                                })}
                                onChange={(e) => handleChangeAltura(e)}
                              />
                              <div className="invalid-feedback">
                                {errors.altura?.message}
                              </div>
                            </div>
                            <div className="col-4">
                              <label>Área (m²):</label>
                              <h5 style={{ marginTop: "10px" }}>
                                {area.toFixed(2)}m²
                              </h5>
                            </div>
                          </div>
                        </div>
                      </>
                    ) : (
                      product.status === 1 && (
                        <div className="form-group">
                          <label>Tamaño</label>
                          <select
                            defaultValue={sizeP}
                            className={
                              errors.size
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                            {...register("size", {
                              required: "El tamaño es requerido.",
                            })}
                            name="size"
                            ref={register({
                              required: "El tamaño es requerido.",
                            })}
                            onChange={(e) => handleChangeSize(e)}
                          >
                            {optionsSize}
                          </select>
                          <div className="invalid-feedback">
                            {errors.size?.message}
                          </div>
                        </div>
                      )
                    )}
                    {product.status === 1 && (
                      <>
                        <div className="form-group">
                          <label>Material</label>
                          <select
                            defaultValue={materialP}
                            className={
                              errors.material
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                            name="material"
                            ref={register({
                              required: "El material es requerido.",
                            })}
                            onChange={(e) => handleChangeMaterial(e)}
                          >
                            {optionsMaterial}
                          </select>
                          <div className="invalid-feedback">
                            {errors.material?.message}
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Impresión</label>
                          <select
                            defaultValue={printP}
                            className={
                              errors.print
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                            name="print"
                            ref={register({
                              required: "El tipo de impresión es requerido.",
                            })}
                            onChange={(e) => handleChangePrinting(e)}
                          >
                            {optionsPrinting}
                          </select>
                          <div className="invalid-feedback">
                            {errors.print?.message}
                          </div>
                        </div>
                        <div className="form-group">
                          <label>Acabados</label>
                          <select
                            defaultValue={optionP}
                            className={
                              errors.option
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                            name="option"
                            ref={register({
                              required: "El acabado es requerido.",
                            })}
                            onChange={(e) => handleChangeOption(e)}
                          >
                            {optionsAcabados}
                          </select>
                          <div className="invalid-feedback">
                            {errors.option?.message}
                          </div>
                        </div>
                      </>
                    )}
                  </>
                ) : null}

                {product &&
                product.status === 1 &&
                product.product_type === "offset" ? (
                  <div className="form-group">
                    <label> Cantidad </label>
                    <select
                      className={
                        errors.quantity
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      name="quantity"
                      value={quantity}
                      ref={register({
                        required: "La cantidad es requerida.",
                      })}
                      onChange={(e) => handleChangeQuantity(e)}
                    >
                      {product.units.map((unit, index) => {
                        return (
                          <option key={index} value={unit}>
                            {unit}
                          </option>
                        );
                      })}
                    </select>
                    <div className="invalid-feedback">
                      {errors.quantity?.message}
                    </div>
                  </div>
                ) : (product && product.product_type === "simple") ||
                  product.product_type === "byarea" ? (
                  <div className="form-group">
                    <label> Cantidad </label>
                    <input
                      className={
                        errors.quantity
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      type="number"
                      min={1}
                      value={quantity}
                      name="quantity"
                      ref={register({
                        required: "La cantidad es requerida.",
                        min: {
                          value: 1,
                          message: "El campo debe ser minimo 1",
                        },
                      })}
                      onChange={(e) => handleChangeQuantity(e)}
                    />
                    <div className="invalid-feedback">
                      {errors.quantity?.message}
                    </div>
                  </div>
                ) : product && product.product_type === "service" ? (
                  <div className="row">
                    <div className="form-group col-12">
                      <label>Precio por</label>
                      <select
                        className={
                          errors.measure
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        name="measure"
                        ref={register({
                          required: "La unidad de medida es requerida.",
                        })}
                        defaultValue={measure}
                        onChange={(e) => handleChangeMeasure(e)}
                      >
                        <option value="serv.">serv.</option>
                        <option value="m2">m2</option>
                        <option value="hrs.">hrs.</option>
                      </select>
                      <div className="invalid-feedback">
                        {errors.measure?.message}
                      </div>
                    </div>
                    {measure === "m2" ? (
                      <>
                        <div className="form-group col-6">
                          <label>Base (m):</label>
                          <input
                            type="text"
                            defaultValue={base}
                            className={
                              errors.base
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                            placeholder="Base"
                            name="base"
                            ref={register({
                              required: "Ingresa la base.",
                              pattern: {
                                value:
                                  /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                                message: "No puede ser un valor negativo",
                              },
                            })}
                            onChange={(e) => handleChangeBase(e)}
                          />
                          <div className="invalid-feedback">
                            {errors.base?.message}
                          </div>
                        </div>
                        <div className="form-group col-6">
                          <label>Altura (m):</label>
                          <input
                            type="text"
                            defaultValue={altura}
                            className={
                              errors.altura
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                            placeholder="Altura"
                            name="altura"
                            ref={register({
                              required: "Ingresa la Altura.",
                              pattern: {
                                value:
                                  /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                                message: "No puede ser un valor negativo",
                              },
                            })}
                            onChange={(e) => handleChangeAltura(e)}
                          />
                          <div className="invalid-feedback">
                            {errors.altura?.message}
                          </div>
                        </div>
                      </>
                    ) : null}
                    <div className="form-group col-12">
                      <label> Cantidad </label>
                      <input
                        className={
                          errors.quantity
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        type="number"
                        value={quantity}
                        name="quantity"
                        ref={register({
                          required: "La cantidad es requerida.",
                        })}
                        onChange={(e) => handleChangeQuantity(e)}
                      />
                      <div className="invalid-feedback">
                        {errors.quantity?.message}
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="form-group">
                    <label> Cantidad </label>
                    <input
                      className={
                        errors.quantity
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      type="number"
                      min={1}
                      value={quantity}
                      name="quantity"
                      ref={register({
                        required: "La cantidad es requerida.",
                        min: {
                          value: 1,
                          message: "El campo debe ser minimo 1",
                        },
                      })}
                      onChange={(e) => handleChangeQuantity(e)}
                    />
                    <div className="invalid-feedback">
                      {errors.quantity?.message}
                    </div>
                  </div>
                )}
                <div className="form-group">
                  <label>Comentarios (opcional)</label>
                  <textarea
                    name="description"
                    rows={5}
                    ref={register({
                      required: {
                        value: false,
                        message: "Los comentarios son equeridos",
                      },
                    })}
                    className={
                      errors.description
                        ? "form-control is-invalid no-resize"
                        : "form-control no-resize"
                    }
                  ></textarea>
                  <div className="invalid-feedback">
                    {errors.description?.message}
                  </div>
                </div>
                <div className="form-group">
                  <h3 className="mb-0 text-primary">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 2,
                    }).format(amount)}
                  </h3>
                </div>
                <div className="form-group">
                  <button
                    className="btn btn-danger btn-block"
                    type="submit"
                    disabled={
                      amount === 0 
                        ? true
                        : false
                    }
                  >
                    <i className="zmdi zmdi-plus"></i> Agregar Producto
                  </button>
                </div>
              </>
            ) : null}
          </form>
        </div>
      </div>
    </>
  );
};

export default FormProduct;
