import React, { useContext, useEffect } from "react";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import AuthContext from "../../contexts/auth/authContext";
import LogoPBK from "../../assets/images/logo.png"
import IconPBK from "../../assets/images/icon.png"
import PwaInstallPopupIOS from 'react-pwa-install-ios';

const Login = () => {

  const navigate = useNavigate();

  const { userLogin, authenticated } = useContext(AuthContext);

  const { register, errors, handleSubmit, reset } = useForm();

    //cuando el usuario es valido y sera redirigo al admin
    useEffect(() => {
      if(authenticated){
        navigate("/");
      }
      if(localStorage.getItem('token')){
        navigate("/");
      }

        // eslint-disable-next-line
    }, [authenticated]);


  const onSubmit = (data) => {
    userLogin(data);
    reset();
  };

  return (
    <>
      <div className="authentication">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <form
                className="card auth_form"
                onSubmit={handleSubmit(onSubmit)}
              >
                <div className="header">
                  <img className="logo" src={LogoPBK} alt="logo" />
                  <h6 className="mt-4">Inicia Sesión</h6>
                </div>
                <div className="body">
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        type="text"
                        name="email"
                        ref={register({
                          required: {
                            value: true,
                            message: "El correo del usuario es requerido",
                          },
                        })}
                        className={
                          errors.email
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Correo"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="zmdi zmdi-email" />
                        </span>
                      </div>
                    </div>
                    {errors?.email ? (
                      <label className="error">{errors?.email?.message}</label>
                    ) : null}
                  </div>
                  <div className="form-group">
                    <div className="input-group">
                      <input
                        type="password"
                        name="password"
                        ref={register({
                          required: {
                            value: true,
                            message: "La contraseña del usuario es requerido",
                          },
                        })}
                        className={
                          errors.password
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Contraseña"
                      />
                      <div className="input-group-append">
                        <span className="input-group-text">
                          <i className="zmdi zmdi-lock" />
                        </span>
                      </div>
                    </div>
                    {errors?.password ? (
                      <label className="error">
                        {errors?.password?.message}
                      </label>
                    ) : null}
                  </div>
                  <button
                    className="btn btn-primary btn-block waves-effect waves-light"
                    type="submit"
                  >
                    INICIAR SESION
                  </button>
                </div>
              </form>
              <div className="copyright text-center">
                {"Copyright © "} {new Date().getFullYear()}
                <span>
                  <a href="https://dicreativo.mx/"> DI CREATIVO</a>
                </span>
              </div>
            </div>
            <div className="col-lg-8 col-sm-12">
              <div className="card">
                <img src="assets/images/signup.svg" alt="Sign In" />
              </div>
            </div>
          </div>
        </div>
      </div>
      <PwaInstallPopupIOS
        delay={3}
        lang="es"
        appIcon={IconPBK}
        appName="PBK Ecommerce"
      ></PwaInstallPopupIOS>
    </>
  );
};

export default Login;
