import { ADD_FILES_PRINTING, GET_FILES_PRINTING, GET_ORDERS, REMOVE_FILE_PRINTING, SHOW_ORDER } from "../../types";


export default function designReducer(state, action) {
  switch (action.type) {

    case GET_ORDERS:
      return {
        ...state,
        orders: action.payload.data,
        current_page: action.payload.current_page,
        per_page: action.payload.per_page,
        total: action.payload.total,
        success: false,
        order: [],
        details: [],
        archivos: []
      };

      case SHOW_ORDER:
        return {
            ...state,
            success: false,
            order: action.payload.order,
            details: action.payload.details,
        }

      case ADD_FILES_PRINTING:
        return{
            ...state
        }

      case GET_FILES_PRINTING:
        return{
            ...state,
            archivos: action.payload,
        }

      case REMOVE_FILE_PRINTING:
        return {
            ...state,
            archivos: state.archivos.filter(
            (archivo) => archivo.id !== action.payload
            ),
        }

    default:
      return state;
  }
}
