import React, { useContext, useEffect, useState } from 'react'
import ExpensesContext from '../../contexts/expenses/expensesContext';
import { Link, useNavigate, useParams } from "react-router-dom";
import Layout from '../../layouts/Layout';
import AddTipoGasto from './AddTipoGasto';
import { Controller, useForm } from 'react-hook-form';
import Select from "react-select";
import ButtonMobileMenu from '../../components/ButtonMobileMenu';
import RightButtonToggle from '../../components/RightButtonToggle';

const EditExpense = () => {
  const { editExpense, success, success2, getTiposGastos, tiposgastos, selectedExpense, updateExpense } = useContext(ExpensesContext);
  const navigate = useNavigate();
  const { register, errors, handleSubmit, control, reset, setValue } = useForm();
  const [tipo_pago, setTipoPago] = useState("");
  const [tipo_comprobante, setTipoComprobante] = useState("");
  const { id } = useParams();

  useEffect(() => {
    editExpense(id);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (selectedExpense.tipo_gasto) {
      setValue(
        "tipo_gasto_id",
        { value: selectedExpense.tipo_gasto.id, label: selectedExpense.tipo_gasto.nombre },
        { shouldValidate: true }
      );
      setTipoPago(selectedExpense.tipo_pago);
      setTipoComprobante(selectedExpense.tipo_comprobante);
    }
    // eslint-disable-next-line
  }, [selectedExpense]);

  useEffect(() => {
    getTiposGastos();
    // eslint-disable-next-line
  }, [success2]);

  useEffect(() => {
    if (success) {
      navigate("/gastos");
    }
    //eslint-disable-next-line
  }, [success]);

  const optionsTiposGastos = tiposgastos.map((tipo) => {
    return { value: tipo.id, label: tipo.nombre };
  });

  const onSubmit = (data) => {
    updateExpense({ ...data, tipo_gasto_id: data.tipo_gasto_id.value, id: id });
    reset();
  };

  return (
    <Layout>
    <section className="content">
      <div className="body_scroll">
        <div className="block-header">
          <div className="row">
            <div className="col-lg-7 col-md-6 col-sm-12">
              <h2>Editar Gasto</h2>
              <ul className="breadcrumb">
                <li className="breadcrumb-item">
                  <i className="zmdi zmdi-home" /> Home
                </li>
                <li className="breadcrumb-item">
                  <Link to="/gastos"> Lista de gastos </Link>
                </li>
                <li className="breadcrumb-item active">Nueva</li>
              </ul>
              <ButtonMobileMenu/>
            </div>
            <div className="col-lg-5 col-md-6 col-sm-12">
             <RightButtonToggle/>
            </div>
          </div>
        </div>
        <div className="container-fluid">
          <div className="row clearfix">
            <div className="col-lg-12">
              <div className="card">
                <div className="body">
                  <div className="header">
                    <h2>
                      <small>Completa la información necesaria.</small>
                    </h2>
                  </div>
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="row">
                      <div className="col-lg-9">
                        <div className="form-group">
                          <label>Tipo</label>
                          <div className="input-group">
                            <Controller
                              name="tipo_gasto_id"
                              control={control}
                              placeholder="Selecciona una opción *"
                              defaultValue
                              options={optionsTiposGastos}
                              className="react-select form-control p-0"
                              as={<Select />}
                              rules={{ required: "Selecciona un tipo de gasto para continuar " }}
                            />
                            <div className="ml-2 input-group-append">
                              <span
                                className="input-group-text"
                                data-toggle="modal"
                                data-target="#addTipoGasto"
                              >
                                <i className="zmdi zmdi-plus" />
                              </span>
                            </div>
                          </div>
                          {errors?.tipo_gasto_id ? (
                            <label className="error">
                              {errors?.tipo_gasto_id?.message}
                            </label>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-3">
                          <label>Fecha del gasto</label>
                          <div className="form-group">
                            <input
                              type="date"
                              name="fecha_gasto"
                              defaultValue={ selectedExpense ? selectedExpense.fecha_gasto : ''}
                              ref={register({
                                required: {
                                  value: true,
                                  message: "La fecha es obligatoria",
                                },
                              })}
                              className={
                                errors.fecha_gasto
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                            />
                            {errors?.fecha_gasto ? (
                              <label className="error">
                                {errors?.fecha_gasto?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      <div className="col-lg-3">
                          <div className="form-group">
                            <label>Comprobante</label>
                            <select
                              name="tipo_comprobante"
                              value={ tipo_comprobante }
                              className={
                                errors.tipo_comprobante
                                  ? "form-control bootstrap-select is-invalid"
                                  : "form-control bootstrap-select"
                              }
                              ref={register({
                                required: "Selecciona un tipo de comprobante para continuar ",
                              })}
                              onChange={(e) => setTipoComprobante(e.target.value)}
                            >
                              <option value="" disabled>
                                Selecciona un tipo
                              </option>
                              <option value="FACTURA">FACTURA</option>
                              <option value="NOTA DE REMISION">
                                NOTA DE REMISION
                              </option>
                              <option value="OTRO">OTRO</option>
                            </select>
                            {errors?.tipo_comprobante ? (
                              <label className="error">
                                {errors?.tipo_comprobante?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      <div className="col-lg-3">
                          <div className="form-group">
                            <label>Método de pago</label>
                            <select
                              name="tipo_pago"
                              value={ tipo_pago }
                              className={
                                errors.tipo_pago
                                  ? "form-control bootstrap-select is-invalid"
                                  : "form-control bootstrap-select"
                              }
                              ref={register({
                                required: "El tipo de pago es requerido.",
                              })}
                              onChange={(e) => setTipoPago(e.target.value)}
                            >
                              <option value="EFECTIVO">EFECTIVO</option>
                              <option value="TRANSFERENCIA">
                              TRANSFERENCIA
                              </option>
                              <option value="TARJETA">TARJETA</option>
                              <option value="OTRO">OTRO</option>
                            </select>
                            {errors?.tipo_pago ? (
                              <label className="error">
                                {errors?.tipo_pago?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      <div className="col-lg-3">
                        <div className="form-group">
                          <label>Monto</label>
                          <input
                            name="amount"
                            defaultValue={ selectedExpense ? selectedExpense.amount : ''}
                            ref={register({
                              required: {
                                value: true,
                                message: "El monto es requerido",
                              },
                              pattern: {
                                value:
                                  /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                                message:
                                  "El monto no puede ser un valor negativo",
                              },
                            })}
                            className={
                              errors.amount
                                ? "form-control is-invalid"
                                : "form-control"
                            }
                          />
                          {errors?.amount ? (
                            <label className="error">
                              {errors?.amount?.message}
                            </label>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-3">
                          <div className="form-group">
                            <label>Folio fiscal</label>
                            <input
                              name="folio_fiscal"
                              defaultValue={ selectedExpense ? selectedExpense.folio_fiscal : ''}
                              ref={register({
                                required: {
                                  value: false,
                                  message: "El folio fiscal es requerido",
                                }
                              })}
                              className={
                                errors.folio_fiscal
                                  ? "form-control is-invalid"
                                  : "form-control"
                              }
                            />
                            {errors?.folio_fiscal ? (
                              <label className="error">
                                {errors?.folio_fiscal?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      <div className="col-lg-12">
                        <div className="form-group">
                          <label>Concepto</label>
                          <textarea
                            name="description"
                            defaultValue={ selectedExpense ? selectedExpense.description : ''}
                            rows={3}
                            ref={register({
                              required: {
                                value: true,
                                message: "El concepto del gasto es requerido",
                              },
                            })}
                            className={
                              errors.description
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                          />
                          {errors?.description ? (
                            <label className="error">
                              {errors?.description?.message}
                            </label>
                          ) : null}
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <button
                          type="submit"
                          className="btn btn-danger btn-round waves-effect"
                        >
                          GUARDAR CAMBIOS
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <AddTipoGasto />
  </Layout>
  )
}

export default EditExpense