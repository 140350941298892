/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from 'react';
import MeasurementsContext from '../../contexts/measurements/measurementsContext';
import Swal from 'sweetalert2'
import AddMeasurement from './AddMeasurement';
import EditMeasurement from './EditMeasurement';
import { Link } from 'react-router-dom';
import Layout from '../../layouts/Layout';
import ButtonMobileMenu from '../../components/ButtonMobileMenu';
import RightButtonToggle from '../../components/RightButtonToggle';
import DataTable from "react-data-table-component";

const MeasurementsList = () => {

  const listMeasurements = useContext(MeasurementsContext);
  const { measurements, getMeasurements, success, editMeasurement, removeMeasurement } = listMeasurements;

  useEffect(() => {
    getMeasurements();
    // eslint-disable-next-line
  }, [success])

  const [filterText, setFilterText] = useState("");
  const filteredItems = measurements.filter(
    (item) =>
      item.name
        .toLowerCase()
        .includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        backgroundColor: "#5cc5cd",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
  };

  const columns = [
    {
      name: "#",
      selector: (row) => row.id,
      sortable: true,
      width: "80px",
    },
    {
      name: "Unidad",
      selector: (row) => row.name,
      sortable: true,
    },
    {
      name: "Descripción",
      selector: (row) => row.description,
      sortable: true,
    },
    {
      name: "Abreviatura",
      selector: (row) => row.acronym,
      sortable: true,
      width: "150px",
    },
    {
      name: "Acciones",
      selector: (row) => row.id,
      sortable: false,
      cell: (row) => (
        <div className="d-flex justify-content-center">
          <button className="btn btn-primary btn-sm" data-toggle="modal" data-target="#editMeasurement" onClick={ () => editMeasurement(row.id)}><i className="zmdi zmdi-edit"></i></button>
          <button className="btn btn-danger btn-sm" onClick={ () => showConfirmMessage(row.id)}><i className="zmdi zmdi-delete"></i></button>
        </div>
      ),
      width: "150px",
    },
  ];

  const showConfirmMessage = (measurement) => {
    Swal.fire({
      title: '¿Estas seguro?',
      text: "Una Unidad de medida no se puede Recuperar",
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Si, Eliminar!',
      cancelButtonText: 'No, cancelar!',
    }).then((result) => {
      if (result.isConfirmed) {
        removeMeasurement(measurement)
      }
    })
  }

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Unidades de medida</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Unidades</li>
                  <li className="breadcrumb-item active">lista</li>
                </ul>
                <ButtonMobileMenu/>
                <div className="chat_window">
                  <button
                    className="list_btn btn btn-danger btn-round float-md-right"
                    data-toggle="modal" data-target="#addMeasurement"
                  >
                    <i className="ti-plus" />
                  </button>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
                  <button className="btn btn-danger float-right" data-toggle="modal" data-target="#addMeasurement">
                    <i className="zmdi zmdi-plus" /> Nueva
                  </button>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                <div className="body">
                  <div className="input-box">
                    <i className="ti-search" />
                    <input
                      type="text"
                      placeholder="Buscar aqui..."
                      value={filterText}
                      onChange={(e) => setFilterText(e.target.value)}
                    />
                    <button className="button" onClick={handleClear}>
                      X
                    </button>
                  </div>
                  <DataTable
                    customStyles={tableCustomStyles}
                    columns={columns}
                    data={filteredItems}
                    noDataComponent="No se encontraron registros"
                    pagination
                  />
                </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddMeasurement/>
      <EditMeasurement/>
    </Layout>
  );
};

export default MeasurementsList;
