import React, { useReducer } from "react";
import MethodGet from "../../services/api";
import { GET_DATA_DASHBOARD, GET_DATA_GENERAL_REPORT } from "../../types";
import DashboardContext from "./dashboardContext";
import dashboardReducer from "./dashboardReducer";
import Swal from "sweetalert2";
import fileDownload from "js-file-download";
import moment from "moment";

const DashboardState = (props) => {
 // estado inicial
 const initialSate = {
    local_customers: 0,
    online_customers: 0,
    total_orders_local: 0,
    total_orders_online: 0,
    today_income: 0,
    today_expense: 0,
    today_utility: 0,
    total_ingresos_efectivo: 0,
    total_ingresos_transferencia: 0,
    bestSellers: [],
    recent_orders: [],
    expense: 0,
    income: 0,
    utility: 0,
    dataset: [],
    movimientos: [],
  };

  const [state, dispatch] = useReducer( dashboardReducer, initialSate);

  //obtener la data del dashboard
  const getDashboard = () => {
    let url = "dashboard";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_DATA_DASHBOARD,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };


  //obtener la data del reporte general
  const getGeneralReport = (fechaInicial, fechaFinal) => {
    let url = `general-report?startDate=${fechaInicial}&endDate=${fechaFinal}`;
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_DATA_GENERAL_REPORT,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //exportar el reporte en PDF de Corte de caja
  const exportReportCaja = (fechaInicial, fechaFinal, type_file)=> {
    let url = `reportg-pdf?startDate=${fechaInicial}&endDate=${fechaFinal}&type_file=${type_file}`;
    //filename agrega la fecha actual format ada para que no se repita cada vez que se descarga el pdf
    var filename= 'reporte_ingresos_egresos_'+ moment().format('DD-MM-YYYY') + `.${type_file}`;

    Swal.fire({
      title: "Generando...",
      html: "Espera un momento por favor.",
      allowEscapeKey: false,
      allowOutsideClick: false,
      didOpen: () => {
        Swal.showLoading();
        MethodGet(url, { responseType: "blob" })
          .then((response) => {
            fileDownload(response.data, filename);
            Swal.close();
          })
          .catch((error) => {
            console.log(error);
            Swal.close();
          });
      },
    });
  };

  return (
    <DashboardContext.Provider
      value={{
        local_customers: state.local_customers,
        online_customers: state.online_customers,
        total_orders_local: state.total_orders_local,
        total_orders_online: state.total_orders_online,
        today_income: state.today_income,
        today_expense: state.today_expense,
        today_utility: state.today_utility,
        bestSellers: state.bestSellers,
        recent_orders: state.recent_orders,

        expense: state.expense,
        income: state.income,
        utility: state.utility,
        total_ingresos_efectivo: state.total_ingresos_efectivo,
        total_ingresos_transferencia: state.total_ingresos_transferencia,
        dataset: state.dataset,
        movimientos: state.movimientos,
        getDashboard,
        getGeneralReport,
        exportReportCaja
      }}
    >
      {props.children}
    </DashboardContext.Provider>
  );
};

export default DashboardState;
