import React, { useReducer } from "react";
import AuthContext from "./authContext";
import AuthReducer from "./authReducer";
import Swal from "sweetalert2";
import {
  AUTHENTICATED_USER,
  FAILED_LOGIN,
  LOGOUT,
  SUCCESS_LOGIN,
} from "../../types";
import clienteAxios from "../../services/axios";
import tokenAuth from "../../services/tokenAuth";
import { MethodPost } from "../../services/api";

const AuthState = (props) => {
  //Agregar state inicial
  const initialState = {
    token: localStorage.getItem("token"),
    authenticated: null,
    user: null,
    cargando: true,
  };

  const [state, dispatch] = useReducer(AuthReducer, initialState);

  //OBTENER EL USUARIO AUTENTICADO
  const authenticatedUser = async () => {
    const token = localStorage.getItem("token");
    if (token) {
      tokenAuth(token);
    }
    try {
      const res = await clienteAxios.get("get-user");
      dispatch({
        type: AUTHENTICATED_USER,
        payload: res.data,
      });
    } catch (error) {
      dispatch({
        type: FAILED_LOGIN,
      });
    }
  };

  //INICIAR SESIÓN
  const userLogin = async (data) => {
    try {
      const res = await clienteAxios.post("/login", data);
      dispatch({
        type: SUCCESS_LOGIN,
        payload: res.data,
      });

      authenticatedUser();
    } catch (error) {
      Swal.fire(
        "Inició de Sesión Fallido",
        error.response.data.message,
        "error"
      );
    }
  };

  //CERRAR SESIÓN
  const logout = () => {
    let url = `logout`;
    MethodPost(url);

    dispatch({
      type: LOGOUT,
    });
  };

  return (
    <AuthContext.Provider
      value={{
        token: state.token,
        authenticated: state.authenticated,
        user: state.user,
        cargando: state.cargando,
        userLogin,
        authenticatedUser,
        logout,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthState;
