import React, { useReducer } from "react";
import SizesContext from "./sizesContext";
import sizesReducer from "./sizesReducer";
import { ADD_SIZE, EDIT_SIZE, GET_SIZES, REMOVE_SIZE, UPDATE_SIZE } from "../../types";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";

const SizesSatate = (props) => {
  // estado inicial
  const initialSate = {
    sizes: [],
    selectedSize: [],
    success: false,
  };

  const [state, dispatch] = useReducer(sizesReducer, initialSate);

  //obtener los tamaños de producto
  const getSizes = () => {
    let url = `sizes`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_SIZES,
        payload: response.data.data,
      });
    });
  };

  const addSize = (size) => {
    let url = "/sizes";
    MethodPost(url, size)
      .then((response) => {
        dispatch({
          type: ADD_SIZE,
        });
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR TAMAÑO A EDITAR
  const editSize = (size) => {
    dispatch({
      type: EDIT_SIZE,
      payload: size,
    });
  };

  //Actualizar un tamaño
  const updateSize = (data) => {
    let url = `sizes/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        dispatch({
          type: UPDATE_SIZE,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar tamaño
  const removeSize = (id) => {
    let url = `sizes/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: 'Eliminado',
        text:  response.data.message,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false
    });
      dispatch({
        type: REMOVE_SIZE,
        payload: id,
      });
    });
  };

  return (
    <SizesContext.Provider
      value={{
        sizes: state.sizes,
        success: state.success,
        selectedSize: state.selectedSize,
        getSizes,
        addSize,
        editSize,
        removeSize,
        updateSize,
      }}
    >
      {props.children}
    </SizesContext.Provider>
  );
};

export default SizesSatate;
