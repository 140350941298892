import React, { useContext, useState } from "react";
import { useForm } from "react-hook-form";
import CarouselContext from "../../contexts/carousel/carouselContext";
import DefaultImage from "../../assets/images/default_image.png";

const EditSlide = () => {
  const { selectedSlide, updateSlide } = useContext(CarouselContext);
  const { register, errors, handleSubmit, reset } = useForm();
  const [photo, setPhoto] = useState({
    urlPhoto: DefaultImage,
    image: "",
  });

  const handleCloseModal = () => {
    window.$("#editSlide").modal("hide");
  };

  //funcion para guardar la imagen y obtener la vista previa
  const handleChangePhoto = (e) => {
    setPhoto({
      urlPhoto: URL.createObjectURL(e.target.files[0]),
      image: e.target.files[0],
    });
  };

  const onSubmit = (data) => {
    data.id = selectedSlide.id;
    data.image_url = photo.image;
    updateSlide(data);
    reset();
    setPhoto({ urlPhoto: DefaultImage, image: "" });
    handleCloseModal();
  };

  return (
    <>
      <div className="modal fade" id="editSlide" tabIndex={-1} role="dialog">
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title" id="defaultModalLabel">
                Actualizar Slide
              </h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Descripción</label>
                      <input
                        name="description"
                        defaultValue={
                          selectedSlide ? selectedSlide.description : ""
                        }
                        ref={register({
                          required: {
                            value: true,
                            message: "La descripción es requerida",
                          },
                          maxLength: {
                            value: 80,
                            message: "Maximo 80 caracteres",
                          },
                        })}
                        className={
                          errors.description
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Descripción corta *"
                      />
                      {errors?.description ? (
                        <label className="error">
                          {errors?.description?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>URL de destino</label>
                      <div className="input-group masked-input">
                        <div className="input-group-prepend">
                          <span
                            className="input-group-text"
                            style={{ fontSize: "13px" }}
                          >
                            https://www.pbkimpresos.com/
                          </span>
                        </div>
                        <input
                          type="text"
                          name="destination_url"
                          defaultValue={
                            selectedSlide ? selectedSlide.destination_url : ""
                          }
                          ref={register({
                            required: {
                              value: true,
                              message: "La url de destino es requerida",
                            },
                          })}
                          className={
                            errors.destination_url
                              ? "form-control is-invalid"
                              : "form-control "
                          }
                          placeholder="producto/lona-front"
                        />
                      </div>

                      {errors?.destination_url ? (
                        <label className="error">
                          {errors?.destination_url?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Imagen</label>
                      <input
                        type="file"
                        name="image_url"
                        ref={register({
                          required: {
                            value: false,
                            message: "El archivo de la imagen es requerido",
                          },
                        })}
                        className={
                          errors.image_url
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        onChange={handleChangePhoto}
                      />
                      {errors?.image_url ? (
                        <label className="error">
                          {errors?.image_url?.message}
                        </label>
                      ) : null}
                    </div>
                    <img
                      src={photo.urlPhoto}
                      alt="slider_img"
                      className="img-fluid mb-2"
                    />
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  ACTUALIZAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditSlide;
