import { ADD_EXPENSE, ADD_TYPE_EXPENSE, EDIT_EXPENSE, GET_EXPENSES, GET_TYPES_EXPENSES, REMOVE_EXPENSE, UPDATE_EXPENSE } from "../../types"


export default function expensesReducer(state, action) {
    switch (action.type) {
        case ADD_EXPENSE:
            return{
                ...state,
                success: true,
            }

        case GET_EXPENSES:
            return{
                ...state,
                expenses: action.payload,
                success:false,
                success2:false,
                selectedExpense: [],
                tiposgastos: []
            }
        
        case EDIT_EXPENSE:
            return {
                ...state,
                selectedExpense: action.payload,
            }
        
        case UPDATE_EXPENSE:
            return{
                ...state,
                success: true,
                selectedExpense: [],
            }

        case REMOVE_EXPENSE:
            return {
                ...state,
                expenses: state.expenses.filter(
                (expense) => expense.id !== action.payload
                ),
            }

        case GET_TYPES_EXPENSES:
            return{
                ...state,
                success2: false,
                tiposgastos: action.payload
            } 
        
        case ADD_TYPE_EXPENSE:
            return{
                ...state,
                success2: true,
            }

        default:
            return state;
    }
}
