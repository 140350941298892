import React, { useContext, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import OrdersContext from "../../../contexts/orders/ordersContext";

const EditItem = ({ selected, setSelected, setOnEdit }) => {
  const { updateProduct } = useContext(OrdersContext);
  const { register, errors, handleSubmit, reset } = useForm();

  const [amount, setAmount] = useState(
    selected && selected.amount ? selected.amount : 0
  );
  const [priceunit, setPriceUnit] = useState(
    selected && selected.unit_price ? selected.unit_price : 0
  );
  const [quantity, setQuantity] = useState(
    selected && selected.quantity ? Number(selected.quantity) : 1
  );
  const [base, setBase] = useState(
    selected && selected.base ? Number(selected.base) : 1
  );
  const [altura, setAltura] = useState(
    selected && selected.altura ? Number(selected.altura) : 1
  );
  const [area, setArea] = useState(
    selected && selected.area ? Number(selected.area) : 1
  );

  const [measure, setMeasure] = useState(
    selected && selected.options?.measure?.name
      ? selected.options?.measure?.name
      : "serv."
  );

  const handleChangeBase = (e) => {
    setBase(e.target.value);
  };

  const handleChangeAltura = (e) => {
    setAltura(e.target.value);
  };

  const handleChangeQuantity = (e) => {
    setQuantity(e.target.value);
  };

  const handleChangeMeasure = (e) => {
    setMeasure(e.target.value);
  };

  const handleChangePrice = (e) => {
    let up_unit_price = e.target.value;
    setPriceUnit(up_unit_price);
    let final_price = up_unit_price * Number(quantity);
    setAmount(final_price);
  };

  const handleChangeAmount = () => {
    switch (selected?.product.product_type) {
      case "simple":
        let final_price_simple = priceunit * Number(quantity);
        setAmount(final_price_simple);
        break;

      case "byarea":
        let priceArea = Number(area) * Number(selected.product.price);
        let final_price_area = priceArea * Number(quantity);
        setPriceUnit(priceArea.toFixed(2));
        setAmount(final_price_area);
        break;

      case "offset":
        let final_price_offset = priceunit * Number(quantity);
        setAmount(final_price_offset);
        break;

      case "service":
        let priceService = selected.product.price;
        let final_price_service = 0;

        if (measure === "m2") {
          let price_area_service = Number(area) * Number(selected.product.price);
          final_price_service = price_area_service * Number(quantity);
          setPriceUnit(price_area_service.toFixed(2));
        } else {
          final_price_service = priceService * Number(quantity);
          setPriceUnit(priceService.toFixed(2));
        }

        setAmount(final_price_service);
        break;

      default:
        break;
    }
  };

  useEffect(() => {
    //area solo 2 decimales
    let pre_area = parseFloat(base) * parseFloat(altura) || 0;
    setArea(pre_area.toFixed(2));
    // eslint-disable-next-line
  }, [base, altura]);

  useEffect(() => {
    handleChangeAmount();
    // eslint-disable-next-line
  }, [quantity, area]);

  const handleCloseModal = () => {
    window.$("#editModal").modal("hide");
    setOnEdit(false);
  };

  const onSubmit = async (data) => {
    selected.quantity = Number(data.quantity);
    selected.unit_price = data.unit_price;
    selected.amount = amount;
    selected.description = data.description;
    selected.base = data.base;
    selected.altura = data.altura;
    selected.area = area;
    if (selected?.product?.product_type === "service") {
      if (measure === "m2") {
        selected.options = {
          size: { base: data.base, altura: data.altura },
          measure: { name: data.measure },
        };
      } else {
        selected.options = {
          measure: { name: data.measure },
        };
      }

    }
    updateProduct(selected);
    reset({});
    setSelected([]);
    window.$("#editModal").modal("hide");
    setOnEdit(false);
  };

  return (
    <>
      <div
        className="modal fade"
        id="editModal"
        tabIndex={-1}
        role="dialog"
        aria-hidden="true"
        data-backdrop="static"
        data-keyboard="false"
      >
        <div className="modal-dialog modal-md" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title" id="smallModalLabel">
                Editar Producto
              </h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body row">
                <div className="form-group col-lg-12">
                  <p>Puedes editar la cantidad o el precio del producto</p>
                  <label>Producto</label>
                  <select className="form-control" disabled>
                    <option
                      value={
                        selected && selected.product?.id
                          ? selected.product.id
                          : ""
                      }
                    >
                      {selected && selected.product?.name
                        ? selected.product.name
                        : ""}
                    </option>
                  </select>
                </div>
                {selected && selected.product?.product_type === "byarea" ? (
                  <>
                    <div className="form-group col-lg-12">
                      <label>Medidas</label>
                      <div className="row">
                        <div className="col-4">
                          <label>
                            <small>Base (m):</small>
                          </label>
                          <input
                            type="text"
                            defaultValue={base}
                            className={
                              errors.base
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                            placeholder="Base"
                            name="base"
                            ref={register({
                              required: "Ingresa la base.",
                              pattern: {
                                value:
                                  /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                                message: "No puede ser un valor negativo",
                              },
                            })}
                            onChange={(e) => handleChangeBase(e)}
                          />
                          <div className="invalid-feedback">
                            {errors.base?.message}
                          </div>
                        </div>
                        <div className="col-4">
                          <label>
                            <small>Altura (m):</small>
                          </label>
                          <input
                            type="text"
                            defaultValue={altura}
                            className={
                              errors.altura
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                            placeholder="Altura"
                            name="altura"
                            ref={register({
                              required: "Ingresa la Altura.",
                              pattern: {
                                value:
                                  /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                                message: "No puede ser un valor negativo",
                              },
                            })}
                            onChange={(e) => handleChangeAltura(e)}
                          />
                          <div className="invalid-feedback">
                            {errors.altura?.message}
                          </div>
                        </div>
                        <div className="col-4">
                          <label>
                            <small>Área (m2):</small>
                          </label>
                          <h5 style={{ marginTop: "4px" }}>{area}m2</h5>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}

                {selected && selected.product?.product_type === "service" ? (
                  <>
                    <div className="form-group col-12">
                      <label>Precio por</label>
                      <select
                        className={
                          errors.measure
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        name="measure"
                        ref={register({
                          required: "La unidad de medida es requerida.",
                        })}
                        defaultValue={measure}
                        onChange={(e) => handleChangeMeasure(e)}
                      >
                        <option value="serv.">serv.</option>
                        <option value="m2">m2</option>
                        <option value="hrs.">hrs.</option>
                      </select>
                      <div className="invalid-feedback">
                        {errors.measure?.message}
                      </div>
                    </div>
                    {measure === "m2" ? (
                      <>
                        <div className="form-group col-6">
                          <label>Base (m):</label>
                          <input
                            type="text"
                            defaultValue={base}
                            className={
                              errors.base
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                            placeholder="Base"
                            name="base"
                            ref={register({
                              required: "Ingresa la base.",
                              pattern: {
                                value:
                                  /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                                message: "No puede ser un valor negativo",
                              },
                            })}
                            onChange={(e) => handleChangeBase(e)}
                          />
                          <div className="invalid-feedback">
                            {errors.base?.message}
                          </div>
                        </div>
                        <div className="form-group col-6">
                          <label>Altura (m):</label>
                          <input
                            type="text"
                            defaultValue={altura}
                            className={
                              errors.altura
                                ? "form-control is-invalid"
                                : "form-control "
                            }
                            placeholder="Altura"
                            name="altura"
                            ref={register({
                              required: "Ingresa la Altura.",
                              pattern: {
                                value:
                                  /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                                message: "No puede ser un valor negativo",
                              },
                            })}
                            onChange={(e) => handleChangeAltura(e)}
                          />
                          <div className="invalid-feedback">
                            {errors.altura?.message}
                          </div>
                        </div>
                      </>
                    ) : null}
                  </>
                ) : null}
                <div className="form-group col-lg-4">
                  <label> Cantidad </label>
                  <input
                    className={
                      errors.quantity
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    type="number"
                    min={1}
                    defaultValue={quantity}
                    name="quantity"
                    ref={register({
                      required: "La cantidad es requerida.",
                      min: {
                        value: 1,
                        message: "El campo debe ser minimo 1",
                      },
                    })}
                    onChange={(e) => handleChangeQuantity(e)}
                  />
                  <div className="invalid-feedback">
                    {errors.quantity?.message}
                  </div>
                </div>
                <div className="form-group col-lg-4">
                  <label>Precio unit.</label>
                  <input
                    className={
                      errors.unit_price
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    type="text"
                    value={priceunit}
                    name="unit_price"
                    ref={register({
                      required: {
                        value: true,
                        message: "El campo precio es requerido",
                      },
                      pattern: {
                        value:
                          /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                        message:
                          "El campo precio no puede ser un valor negativo",
                      },
                    })}
                    onChange={(e) => handleChangePrice(e)}
                  />
                  <div className="invalid-feedback">
                    {errors.unit_price?.message}
                  </div>
                </div>
                <div className="form-group col-lg-4">
                  <label>Total</label>
                  <h5 className="mb-0 text-primary">
                    {new Intl.NumberFormat("es-MX", {
                      style: "currency",
                      currency: "MXN",
                      minimumFractionDigits: 2,
                    }).format(amount)}
                  </h5>
                </div>
                <div className="form-group col-lg-12">
                  <label>Descripción (opcional)</label>
                  <textarea
                    name="description"
                    rows={4}
                    ref={register({
                      required: {
                        value: false,
                        message: "La descripción es requerida",
                      },
                    })}
                    defaultValue={selected ? selected.description : ""}
                    className={
                      errors.description
                        ? "form-control is-invalid no-resize"
                        : "form-control no-resize"
                    }
                  ></textarea>
                  <div className="invalid-feedback">
                    {errors.description?.message}
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  Actualizar Producto
                </button>
                <button
                  type="button"
                  className="btn btn-default waves-effect"
                  onClick={handleCloseModal}
                >
                  Cancelar
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditItem;
