import React, { useReducer } from "react";
import MeasurementsContext from "./measurementsContext";
import MeasurementsReducer from "./measurementsReducer";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import $ from "jquery";
import "bootstrap-notify";
import Swal from "sweetalert2";
import { ADD_MEASUREMENT, EDIT_MEASUREMENT, GET_MEASUREMENTS, REMOVE_MEASUREMENT, UPDATE_MEASUREMENT } from "../../types";

const MeasurementsSatate = (props) => {
  // estado inicial
  const initialSate = {
    measurements: [],
    selectedMeasurement: [],
    success: false,
  };

  const [state, dispatch] = useReducer(MeasurementsReducer, initialSate);

  //obtener las unidades de medida
  const getMeasurements = () => {
    let url = `measurements`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_MEASUREMENTS,
        payload: response.data.data,
      });
    });
  };


  //Agregar nueva unidad
  const addMeasurement = (data) => {
    let url = "/measurements";
    MethodPost(url, data)
      .then((response) => {
        dispatch({
          type: ADD_MEASUREMENT,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR UNIDAD DE MEDIDA A EDITAR
  const editMeasurement = (measurement) => {
    dispatch({
      type: EDIT_MEASUREMENT,
      payload: measurement,
    });
  };

  //Actualizar una unidad de medida
  const updateMeasurement = (data) => {
    let url = `measurements/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
        dispatch({
          type: UPDATE_MEASUREMENT,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar unidad de medida
  const removeMeasurement = (id) => {
    let url = `measurements/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: 'Eliminado',
        text:  response.data.message,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false
    });
      dispatch({
        type: REMOVE_MEASUREMENT,
        payload: id,
      });
    });
  };

  return (
    <MeasurementsContext.Provider
      value={{
        measurements: state.measurements,
        success: state.success,
        selectedMeasurement: state.selectedMeasurement,
        getMeasurements,
        addMeasurement,
        editMeasurement,
        removeMeasurement,
        updateMeasurement,
      }}
    >
      {props.children}
    </MeasurementsContext.Provider>
  );
};

export default MeasurementsSatate;
