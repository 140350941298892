import React, { useContext, useEffect, useState } from "react";
import Select from 'react-select'
import { Link, useNavigate } from "react-router-dom";
import { useForm, Controller } from "react-hook-form";
import adresImg from "../../assets/images/address.svg";
import CustomersContext from "../../contexts/customers/customersContext";
import Layout from "../../layouts/Layout";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const AddCustomer = () => {

  const navigate = useNavigate();

  const { entities, getEntities, municipalities, getMunicipalities, addCustomer, getComoSeEnteraron, opcionesComoSeEnteraron, success } = useContext(CustomersContext);

  const { register, errors, handleSubmit, setValue, control } = useForm();

  const [direction, setDirection] = useState(false);

  //funcion que se encarga de listar los estados en el select
  const optionsEntities = entities.map(entity => {
    return {value:entity.id , label: entity.name};
  });

   //funcion que se encarga de listar los municipios en el select
   const optionsMunicipalities = municipalities.map(municipality => {
    return {value:municipality.id , label: municipality.name};
});

//funcion que se encarga de listar las opciones de como se enteraron
const optionsReferences = opcionesComoSeEnteraron.map((referencia, index) => (
  <option key={index} value={referencia.id}>
    {referencia.opcion}
  </option>
));

  //carga los estados cuando se monta el componente
  useEffect(() => {
    getEntities(); 
    getComoSeEnteraron();
    //eslint-disable-next-line
  }, [])

  useEffect(() => {
    //si success retorna verdadero la pagina redireccionara al index de clientes
    if (success) {
      navigate("/customers"); 
    }
    //eslint-disable-next-line
  }, [success]);


  const handleCheckbox = () => {
    setDirection(!direction);
  };

  //funcion que cambia los municipios del select
  const handleChangeEntity = (value) => {
    setValue("entity", value , { shouldValidate: true});
    setValue("municipality_id", '');
    getMunicipalities(value.value);
}

  const onSubmit = (data) => {
    data.address =  direction;
    addCustomer(data)
  }

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Agregar Cliente</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item">
                    {" "}
                    <Link to="/customers"> Clientes </Link>
                  </li>
                  <li className="breadcrumb-item active">Nuevo</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
               <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12 col-sm-12">
                <div className="card">
                  <div className="body">
                    <h2 className="card-inside-title">Datos del cliente</h2>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="form-group">
                        <label>Nombre completo</label>
                        <input
                          type="text"
                          name="full_name"
                          placeholder="Ingrese el nombre completo del cliente"
                          ref={register({
                            required: {
                              value: true,
                              message: "El Nombre del cliente es requerido",
                            }
                          })}
                          className={
                            errors.full_name
                              ? "form-control is-invalid"
                              : "form-control "
                          }
                        />
                        {errors?.full_name ? (
                        <label className="error">
                          {errors?.full_name?.message}
                        </label>
                      ) : null}
                      </div>
                      <div className="form-group">
                        <label>Teléfono</label>
                        <input
                          type="text"
                          name="phone"
                          placeholder="Ingrese un teléfono"
                          ref={register({
                            required: {
                              value: true,
                              message: "El Teléfono es requerido",
                            },
                            maxLength: {
                              value: 10,
                              message: "Maximo 10 digitos",
                            },
                            minLength: {
                              value: 10,
                              message: "Minimo 10 digitos",
                            },
                            pattern: {
                              value: /^[0-9\b]+$/,
                              message: "El campo debe ser numerico",
                            },
                          })}
                          className={
                            errors.phone
                              ? "form-control is-invalid"
                              : "form-control "
                          }
                        />
                        {errors?.phone ? (
                        <label className="error">
                          {errors?.phone?.message}
                        </label>
                      ) : null}
                      </div>
                      <div className="form-group">
                        <label>Email</label>
                        <input
                          type="email"
                          name="email"
                          placeholder="Ingrese una dirección de correo"
                          ref={register({ required: {
                            value: true,
                            message: "El Email es requerido",
                          },
                          pattern: {
                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                            message: "Dirección de correo electrónico no válida",
                          }, })}
                        className={
                          errors.email
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        />
                        {errors?.email ? (
                        <label className="error">
                          {errors?.email?.message}
                        </label>
                      ) : null}
                      </div>
                      <div className="form-group">
                        <label>¿Cómo se enteraron de nosotros?</label>
                          <select
                          name="opcion_como_se_enteraron_id"
                          ref={register({ required: { 
                            value: true,
                            message: "Este campo es requerido",
                            }, })}
                            className={
                              errors.opcion_como_se_enteraron_id
                              ? "form-control is-invalid"
                              : "form-control "
                              }
                              >
                                <option value="">Selecciona una opción</option>
                                {optionsReferences}
                              </select>
                        {errors?.opcion_como_se_enteraron_id ? (
                          <label className="error">
                            {errors?.opcion_como_se_enteraron_id?.message}
                          </label>
                        ) : null}
                      </div>
                      <div className="checkbox">
                        <input
                          id="address"
                          type="checkbox"
                          onChange={handleCheckbox}
                        />
                        <label htmlFor="address">
                          Agregar dirección de envío
                        </label>
                      </div>
                      <button
                        className="btn btn-danger btn-block mt-4"
                        type="submit"
                      >
                        GUARDAR
                      </button>
                    </form>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12 col-sm-12">
                <div className="card">
                  <div className="body">
                    {direction ? (
                      <form>
                        <h2 className="card-inside-title">
                          Dirección de envío
                        </h2>
                        <div className="row clearfix">
                          <div className="col-sm-4">
                            <label>Calle</label>
                            <div className="form-group">
                              <input
                                name="street"
                                type="text"
                                placeholder="Ej. Av. Insurgentes"
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "El nombre de la calle es requerida",
                                  }
                                })}
                                className={
                                  errors.street
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                              />
                              {errors?.street ? (
                                <label className="error">
                                  {errors?.street?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <label>No. Ext</label>
                            <div className="form-group">
                              <input
                                name="number_out"
                                type="text"
                                placeholder="240"
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "El No. Exterior es requerido",
                                  },
                                  pattern: {
                                    value: /^[0-9\b]+$/,
                                    message: 'El campo debe ser numerico'
                                  }
                                })}
                                className={
                                  errors.number_out
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                              />
                              {errors?.number_out ? (
                                <label className="error">
                                  {errors?.number_out?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-2">
                            <label>No. Int</label>
                            <div className="form-group">
                              <input
                                name="number_int"
                                type="text"
                                placeholder="12"
                                ref={register({
                                  required: {
                                    value: false,
                                    message: "El No. Interior es requerido",
                                  },
                                  pattern: {
                                    value: /^[0-9\b]+$/,
                                    message: 'El campo debe ser numerico'
                                  }
                                })}
                                className={
                                  errors.number_int
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                              />
                              {errors?.number_int ? (
                                <label className="error">
                                  {errors?.number_int?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-4">
                            <label>Colonia</label>
                            <div className="form-group">
                              <input
                                name="location"
                                type="text"
                                placeholder="San Francisco"
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "La colonia es requerida",
                                  }
                                })}
                                className={
                                  errors.location
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                              />
                              {errors?.location ? (
                                <label className="error">
                                  {errors?.location?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <div className="row clearfix">
                          <div className="col-sm-4">
                            <label>Estado</label>
                            <div className="form-group">
                            <Controller
                              name="entity"
                              control={control}
                              defaultValue
                              render={({ field }) => <Select 
                                {...field} 
                                placeholder="Selecciona una opción"
                                options={optionsEntities} 
                                onChange={ e => handleChangeEntity(e)}
                              />
                              
                              }
                              rules={{ required: "El Estado es un campo requerido " }}
                              
                            />
                            {errors?.entity ? (
                                <label className="error">
                                  {errors?.entity?.message}
                                </label>
                              ) : null}
                              </div>
                          </div>
                          <div className="col-sm-4">
                            <label>Municipio</label>
                            <div className="form-group">
                            <Controller
                                name="municipality_id"
                                control={control}
                                placeholder="Selecciona una opción"
                                defaultValue
                                options={optionsMunicipalities}
                                as={<Select/>}
                                rules={{ required: "El Municipio es un campo Requerido" }}
                            />
                               {errors?.municipality_id ? (
                                <label className="error">
                                  {errors?.municipality_id?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                          <div className="col-sm-3">
                            <label>Código Postal</label>
                            <div className="form-group">
                              <input
                                name="postal_code"
                                type="text"
                                placeholder="54200"
                                ref={register({
                                  required: {
                                    value: true,
                                    message: "El Código Postal es requerido",
                                  },
                                  maxLength: {
                                      value: 5,
                                      message: "Maximo 5 caracteres",
                                  },
                                  minLength: {
                                      value: 1,
                                      message: 'Minimo 0 caracteres'
                                  },
                                  pattern:{
                                      value: /^[0-9\b]+$/,
                                      message: 'El campo debe ser numerico'
                                  }
                                })}
                                className={
                                  errors.postal_code
                                    ? "form-control is-invalid"
                                    : "form-control "
                                }
                              />
                              {errors?.postal_code ? (
                                <label className="error">
                                  {errors?.postal_code?.message}
                                </label>
                              ) : null}
                            </div>
                          </div>
                        </div>
                        <label>Referencias</label>
                        <div className="form-group">
                          <textarea
                            name="reference"
                            rows={3}
                            placeholder="Ej. Se encuentra a lado de un oxxo..."
                            ref={register({
                              required: {
                                value: false,
                                message: "La Referencia es Requerida",
                                },
                                maxLength: {
                                value: 150,
                                message: "Maximo 150 caracteres",
                                }
                            })}
                            className={
                              errors.reference
                                ? "form-control is-invalid no-resize"
                                : "form-control no-resize"
                            }
                          />
                          {errors?.reference ? (
                            <label className="error">
                              {errors?.reference?.message}
                            </label>
                          ) : null}
                        </div>
                      </form>
                    ) : (
                      <img src={adresImg} alt="img" />
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AddCustomer;
