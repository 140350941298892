import React, { useContext, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import ProductsContext from "../../contexts/products/productsContext";
import DefaultImage from "../../assets/images/default_image.png";
import ProductPrice from "./detail/ProductPrice";
import AddSize from "./detail/AddSize";
import AddMaterial from "./detail/AddMaterial";
import AddPrinting from "./detail/AddPrinting";
import AddOption from "./detail/AddOption";
import UpdateProduct from "./detail/UpdateProduct";
import UploadImages from "./detail/UploadImages";
import Layout from "../../layouts/Layout";
import UpdatePrice from "./detail/UpdatePrice";
import UpdateOption from "./detail/UpdateOption";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const DetailProduct = () => {
  const {
    getProduct,
    product,
    imagesProduct,
    sizesProduct,
    materialProduct,
    printingProduct,
    optionsProduct,
    prices,
    success,
    getFamilies,
    getMaterials,
    getSizes,
    getPrintings,
    getOptions,
    deletePrice,
    deleteSize,
    deleteMaterial,
    deletePrinting,
    deleteOption,
    deleteImage,
    editPrice,
    editOption,
  } = useContext(ProductsContext);

  const { id } = useParams();

  useEffect(() => {
    getFamilies();
    getMaterials();
    getSizes();
    getPrintings();
    getOptions();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    getProduct(id);
    // eslint-disable-next-line
  }, [success]);

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Detalle del producto</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item">
                    {" "}
                    <Link to="/products"> Productos </Link>
                  </li>
                  <li className="breadcrumb-item active">
                    Dtalles del producto
                  </li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="row">
                      <div className="col-xl-4 col-lg-4 col-md-12">
                        <div className="preview preview-pic tab-content">
                          {imagesProduct.length ? (
                            imagesProduct.map((image, index) => (
                              <div
                                key={index}
                                className={
                                  index === 0 ? "tab-pane active" : "tab-pane"
                                }
                                id={`product_${image.id}`}
                              >
                                <img
                                  src={image.url}
                                  className="img-fluid"
                                  alt="product_image"
                                />
                              </div>
                            ))
                          ) : (
                            <div className="tab-pane active" id="product_1">
                              <img
                                src={DefaultImage}
                                className="img-fluid"
                                alt="product_image"
                              />
                            </div>
                          )}
                        </div>
                        <ul className="preview thumbnail nav nav-tabs">
                          {imagesProduct.length ? (
                            imagesProduct.map((image, index) => (
                              <li key={index} className="nav-item">
                                <a
                                  className={
                                    index === 0 ? "nav-link active" : "nav-link"
                                  }
                                  data-toggle="tab"
                                  href={`#product_${image.id}`}
                                >
                                  <img src={image.url} alt="product_image" />
                                </a>
                              </li>
                            ))
                          ) : (
                            <li className="nav-item">
                              <a
                                className="nav-link active"
                                data-toggle="tab"
                                href="#product_1"
                              >
                                <img src={DefaultImage} alt="product_image" />
                              </a>
                            </li>
                          )}
                        </ul>
                      </div>
                      <div className="col-xl-8 col-lg-8 col-md-12">
                        <div className="product details">
                          <h5 className="product-title mb-0">
                            {" "}
                            {product ? product.name : ""}{" "}
                          </h5>
                          <h5 className="price mt-1">
                            <span className="col-teal">
                              {" "}
                              {product.family ? product.family.name : ""}{" "}
                            </span>
                          </h5>

                          <hr />
                          <h6>Descripción:</h6>
                          <p className="product-description">
                            {" "}
                            {product ? product.description : ""}{" "}
                          </p>
                          {product && product.price ? (
                            <h5>
                              {new Intl.NumberFormat("es-MX", {
                                style: "currency",
                                currency: "MXN",
                                minimumFractionDigits: 2,
                              }).format(product.price)}
                            </h5>
                          ) : null}

                          <div className="action">
                            <button
                              className="btn btn-primary waves-effect"
                              type="button"
                              data-toggle="modal"
                              data-target="#updateProduct"
                            >
                              Editar
                            </button>
                            <button
                              className="btn btn-info waves-effect"
                              type="button"
                              data-toggle="modal"
                              data-target="#uploadImages"
                            >
                              <i className="zmdi zmdi-collection-image" />
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {product && product.status === 1 ? (
                product &&
                product.product_type !== "service" && (
                  <div className="col-lg-12">
                    <div className="card">
                      <div className="body">
                        <ul className="nav nav-tabs">
                          {product && product.product_type !== "byarea" ? (
                            <>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#prices"
                                >
                                  Precios
                                </a>
                              </li>
                              <li className="nav-item">
                                <a
                                  className="nav-link"
                                  data-toggle="tab"
                                  href="#sizes"
                                >
                                  Tamaños
                                </a>
                              </li>
                            </>
                          ) : null}
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#material"
                            >
                              Material
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#printing"
                            >
                              Impresión
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#options"
                            >
                              Acabados
                            </a>
                          </li>
                          <li className="nav-item">
                            <a
                              className="nav-link"
                              data-toggle="tab"
                              href="#gallery"
                            >
                              Galeria
                            </a>
                          </li>
                        </ul>
                      </div>
                    </div>
                    <div>
                      <div className="tab-content">
                        {product && product.product_type !== "byarea" ? (
                          <>
                            <div className="tab-pane" id="prices">
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="card">
                                  <div className="header">
                                    <h2>
                                      <strong>Lista de Precios</strong>
                                    </h2>
                                    <button
                                      className="btn btn-primary btn-round btn-sm float-right"
                                      data-toggle="modal"
                                      data-target="#productPrice"
                                    >
                                      <i className="zmdi zmdi-plus-circle"></i>{" "}
                                      precio
                                    </button>
                                  </div>
                                  <div className="table-responsive">
                                    <table className="table table-hover c_table">
                                      <thead>
                                        <tr>
                                          <th style={{ width: 60 }}>#</th>
                                          <th>Tamaño</th>
                                          <th>Material</th>
                                          <th>Impresión</th>
                                          <th>Cantidad</th>
                                          <th>Precio</th>
                                          <th>Acción</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {prices.length
                                          ? prices.map((price, index) => (
                                              <tr key={index}>
                                                <td> {index + 1} </td>
                                                <td> {price.size.name} </td>
                                                <td> {price.material.name} </td>
                                                <td>
                                                  {" "}
                                                  {price.printing?.name
                                                    ? price.printing.name
                                                    : null}{" "}
                                                </td>
                                                <td> {price.quantity} </td>
                                                <td>
                                                  {new Intl.NumberFormat(
                                                    "es-MX",
                                                    {
                                                      style: "currency",
                                                      currency: "MXN",
                                                      minimumFractionDigits: 2,
                                                    }
                                                  ).format(price.price)}
                                                </td>
                                                <td>
                                                  <button
                                                    className="btn btn-primary btn-sm"
                                                    data-toggle="modal"
                                                    data-target="#UpPrice"
                                                    onClick={() =>
                                                      editPrice(price.id)
                                                    }
                                                  >
                                                    <i className="zmdi zmdi-edit"></i>
                                                  </button>
                                                  <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() =>
                                                      deletePrice(price.id)
                                                    }
                                                  >
                                                    <i className="zmdi zmdi-delete"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))
                                          : null}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div className="tab-pane" id="sizes">
                              <div className="col-sm-12 col-md-12 col-lg-12">
                                <div className="card">
                                  <div className="header">
                                    <h2>
                                      <strong>Tamaños</strong>
                                    </h2>
                                    <button
                                      className="btn btn-primary btn-round btn-sm float-right"
                                      data-toggle="modal"
                                      data-target="#addSize"
                                    >
                                      <i className="zmdi zmdi-plus"></i> tamaño
                                    </button>
                                  </div>
                                  <div className="table-responsive">
                                    <table className="table table-hover c_table">
                                      <thead>
                                        <tr>
                                          <th style={{ width: 60 }}>#</th>
                                          <th>Nombre</th>
                                          <th>Acción</th>
                                        </tr>
                                      </thead>
                                      <tbody>
                                        {sizesProduct.length
                                          ? sizesProduct.map((size, index) => (
                                              <tr key={index}>
                                                <td> {index + 1} </td>
                                                <td> {size.size.name} </td>
                                                <td>
                                                  <button
                                                    className="btn btn-danger btn-sm"
                                                    onClick={() =>
                                                      deleteSize(size.id)
                                                    }
                                                  >
                                                    <i className="zmdi zmdi-delete"></i>
                                                  </button>
                                                </td>
                                              </tr>
                                            ))
                                          : null}
                                      </tbody>
                                    </table>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : null}
                        <div className="tab-pane" id="material">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="card">
                              <div className="header">
                                <h2>
                                  <strong>Material</strong>
                                </h2>
                                <button
                                  className="btn btn-primary btn-round btn-sm float-right"
                                  data-toggle="modal"
                                  data-target="#addMaterial"
                                >
                                  <i className="zmdi zmdi-plus"></i> material
                                </button>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-hover c_table">
                                  <thead>
                                    <tr>
                                      <th style={{ width: 60 }}>#</th>
                                      <th>Nombre</th>
                                      <th>Acción</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {materialProduct.length
                                      ? materialProduct.map(
                                          (material, index) => (
                                            <tr key={index}>
                                              <td> {index + 1} </td>
                                              <td>
                                                {" "}
                                                {material.material.name}{" "}
                                              </td>
                                              <td>
                                                <button
                                                  className="btn btn-danger btn-sm"
                                                  onClick={() =>
                                                    deleteMaterial(material.id)
                                                  }
                                                >
                                                  <i className="zmdi zmdi-delete"></i>
                                                </button>
                                              </td>
                                            </tr>
                                          )
                                        )
                                      : null}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="printing">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="card">
                              <div className="header">
                                <h2>
                                  <strong>Impresión</strong>
                                </h2>
                                <button
                                  className="btn btn-primary btn-round btn-sm float-right"
                                  data-toggle="modal"
                                  data-target="#addPrinting"
                                >
                                  <i className="zmdi zmdi-plus"></i> impresión
                                </button>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-hover c_table">
                                  <thead>
                                    <tr>
                                      <th style={{ width: 60 }}>#</th>
                                      <th>Nombre</th>
                                      <th>Acción</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {printingProduct.length
                                      ? printingProduct.map((print, index) => (
                                          <tr key={index}>
                                            <td> {index + 1} </td>
                                            <td> {print.printing.name} </td>
                                            <td>
                                              <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() =>
                                                  deletePrinting(print.id)
                                                }
                                              >
                                                <i className="zmdi zmdi-delete"></i>
                                              </button>
                                            </td>
                                          </tr>
                                        ))
                                      : null}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="options">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="card">
                              <div className="header">
                                <h2>
                                  <strong>Acabados</strong>
                                </h2>
                                <button
                                  className="btn btn-primary btn-round btn-sm float-right"
                                  data-toggle="modal"
                                  data-target="#addOption"
                                >
                                  <i className="zmdi zmdi-plus"></i> acabado
                                </button>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-hover c_table">
                                  <thead>
                                    <tr>
                                      <th style={{ width: 60 }}>#</th>
                                      <th>Nombre</th>
                                      <th>Costo ($)</th>
                                      <th>Acción</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {optionsProduct.length
                                      ? optionsProduct.map((option, index) => (
                                          <tr key={index}>
                                            <td> {index + 1} </td>
                                            <td> {option.option.name} </td>
                                            <td>
                                              {option.price > 0
                                                ? new Intl.NumberFormat(
                                                    "es-MX",
                                                    {
                                                      style: "currency",
                                                      currency: "MXN",
                                                      minimumFractionDigits: 2,
                                                    }
                                                  ).format(option.price)
                                                : "NO"}
                                            </td>
                                            <td>
                                              <button
                                                className="btn btn-primary btn-sm"
                                                data-toggle="modal"
                                                data-target="#UpPriceOption"
                                                onClick={() =>
                                                  editOption(option.id)
                                                }
                                              >
                                                <i className="zmdi zmdi-edit"></i>
                                              </button>
                                              <button
                                                className="btn btn-danger btn-sm"
                                                onClick={() =>
                                                  deleteOption(option.id)
                                                }
                                              >
                                                <i className="zmdi zmdi-delete"></i>
                                              </button>
                                            </td>
                                          </tr>
                                        ))
                                      : null}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="tab-pane" id="gallery">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="card">
                              <div className="header">
                                <h2>
                                  <strong>Imagenes de Producto</strong>
                                </h2>
                              </div>
                              <div className="content file_manager mt-4">
                                <div className="row clearfix">
                                  {imagesProduct.length ? (
                                    imagesProduct.map((image, index) => (
                                      <div
                                        className="col-lg-3 col-md-4 col-sm-12"
                                        key={index}
                                      >
                                        <div className="card">
                                          <div className="file">
                                            <div className="hover">
                                              <button
                                                type="button"
                                                className="btn btn-icon btn-icon-mini btn-round btn-danger"
                                                onClick={() =>
                                                  deleteImage(image.id)
                                                }
                                              >
                                                <i className="zmdi zmdi-delete" />
                                              </button>
                                            </div>
                                            <div className="image">
                                              <img
                                                src={image.url}
                                                alt="img_product"
                                                className="img-fluid"
                                              />
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    ))
                                  ) : (
                                    <p>No se encuentran imagenes</p>
                                  )}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                )
              ) : (
                <div className="col-lg-12">
                  <div className="card">
                    <div className="body">
                      <ul className="nav nav-tabs">
                        {product &&
                          product.product_type !== "service" && (
                            <li className="nav-item">
                              <a
                                className="nav-link"
                                data-toggle="tab"
                                href="#material"
                              >
                                Material
                              </a>
                            </li>
                          )}
                        <li className="nav-item">
                          <a
                            className="nav-link"
                            data-toggle="tab"
                            href="#gallery"
                          >
                            Galeria
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                  <div>
                    <div className="tab-content">
                      {product && product.product_type !== "service" && (
                        <div className="tab-pane" id="material">
                          <div className="col-sm-12 col-md-12 col-lg-12">
                            <div className="card">
                              <div className="header">
                                <h2>
                                  <strong>Material</strong>
                                </h2>
                                <button
                                  className="btn btn-primary btn-round btn-sm float-right"
                                  data-toggle="modal"
                                  data-target="#addMaterial"
                                >
                                  <i className="zmdi zmdi-plus"></i> material
                                </button>
                              </div>
                              <div className="table-responsive">
                                <table className="table table-hover c_table">
                                  <thead>
                                    <tr>
                                      <th style={{ width: 60 }}>#</th>
                                      <th>Nombre</th>
                                      <th>Acción</th>
                                    </tr>
                                  </thead>
                                  <tbody>
                                    {materialProduct.length
                                      ? materialProduct.map(
                                          (material, index) => (
                                            <tr key={index}>
                                              <td> {index + 1} </td>
                                              <td>
                                                {" "}
                                                {material.material.name}{" "}
                                              </td>
                                              <td>
                                                <button
                                                  className="btn btn-danger btn-sm"
                                                  onClick={() =>
                                                    deleteMaterial(material.id)
                                                  }
                                                >
                                                  <i className="zmdi zmdi-delete"></i>
                                                </button>
                                              </td>
                                            </tr>
                                          )
                                        )
                                      : null}
                                  </tbody>
                                </table>
                              </div>
                            </div>
                          </div>
                        </div>
                      )}
                      <div className="tab-pane" id="gallery">
                        <div className="col-sm-12 col-md-12 col-lg-12">
                          <div className="card">
                            <div className="header">
                              <h2>
                                <strong>Imagenes de Producto</strong>
                              </h2>
                            </div>
                            <div className="content file_manager mt-4">
                              <div className="row clearfix">
                                {imagesProduct.length ? (
                                  imagesProduct.map((image, index) => (
                                    <div
                                      className="col-lg-3 col-md-4 col-sm-12"
                                      key={index}
                                    >
                                      <div className="card">
                                        <div className="file">
                                          <div className="hover">
                                            <button
                                              type="button"
                                              className="btn btn-icon btn-icon-mini btn-round btn-danger"
                                              onClick={() =>
                                                deleteImage(image.id)
                                              }
                                            >
                                              <i className="zmdi zmdi-delete" />
                                            </button>
                                          </div>
                                          <div className="image">
                                            <img
                                              src={image.url}
                                              alt="img_product"
                                              className="img-fluid"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  ))
                                ) : (
                                  <p>No se encuentran imagenes</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
      <UpdateProduct />
      <UploadImages />
      <ProductPrice />
      <AddSize />
      <AddMaterial />
      <AddPrinting />
      <AddOption />
      <UpdatePrice />
      <UpdateOption />
    </Layout>
  );
};

export default DetailProduct;
