/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import MaterialsContext from "../../contexts/materials/materialsContext";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";
import DataTable from "react-data-table-component";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const MaterialsList = () => {
  const listMaterials = useContext(MaterialsContext);
  const { materials, getMaterials, success, removeMaterial } = listMaterials;

  useEffect(() => {
    getMaterials();
    // eslint-disable-next-line
  }, [success]);

  const tableCustomStyles = {
    headCells: {
      style: {
        fontSize: "12px",
        backgroundColor: "#5cc5cd",
        color: "#fff",
        fontWeight: "bold",
        textTransform: "uppercase",
      },
    },
  };


  const [filterText, setFilterText] = useState("");
  const filteredItems = materials.filter(
    (item) =>
      item.name
        .toLowerCase()
        .includes(filterText.toLowerCase())
  );

  const handleClear = () => {
    if (filterText) {
      setFilterText("");
    }
  };

  const columns = [
    {
      name: "Material",
      selector: (row) => row.name,
      sortable: true,
      width: "300px",
      wrap: true
    },
    {
      name: "Descripción",
      selector: (row) => row.description,
      sortable: true,
      wrap: true
    },
    {
      name: "Unidad Venta",
      selector: (row) => row.sale_unit?.name,
      sortable: true,
    },
    {
      name: "Control Inventario",
      cell: (row) => (
        <>
          {row.is_inventory ? (
            <span className="badge bg-light-green">Inventariable</span>
          ) : (
            <span className="badge bg-amber">No inventariable</span>
          )}
        </>
      ),
      sortFunction:
      (a, b) => (a.is_inventory ? 1 : 0) - (b.is_inventory ? 1 : 0),

      sortable: true,
    },
    {
      name: "Acciones",
      selector: (row) => (
        <>
          <Link to={`/material/edit/${row.id}`}>
            <button className="btn btn-primary btn-sm">
              <i className="zmdi zmdi-edit"></i>
            </button>
          </Link>
          &nbsp;
          <button
            className="btn btn-danger btn-sm"
            onClick={() => showConfirmMessage(row.id)}
          >
            <i className="zmdi zmdi-delete"></i>
          </button>
        </>
      ),
    },
  ];

  const showConfirmMessage = (material) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Tambien se eliminara el inventario asociado a este material",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeMaterial(material);
      }
    });
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Materiales </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Materiales</li>
                  <li className="breadcrumb-item active">lista</li>
                </ul>
                <ButtonMobileMenu/>
                <div className="chat_window">
                  <Link to="/material/add">
                    <button className="list_btn btn btn-danger btn-round float-md-right">
                      <i className="ti-plus" />
                    </button>
                  </Link>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
               <RightButtonToggle/>
                <Link to="/material/add">
                  <button className="btn btn-danger float-right">
                    <i className="zmdi zmdi-plus" /> Nuevo
                  </button>
                </Link>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="input-box">
                      <i className="ti-search" />
                      <input
                        type="text"
                        placeholder="Buscar aqui..."
                        value={filterText}
                        onChange={(e) => setFilterText(e.target.value)}
                      />
                      <button className="button" onClick={handleClear}>
                        X
                      </button>
                    </div>
                    <DataTable
                      customStyles={tableCustomStyles}
                      columns={columns}
                      data={filteredItems}
                      noDataComponent="No se encontraron registros"
                      pagination
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default MaterialsList;
