/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import DiscountsContext from "../../contexts/discounts/discountsContext";
import Pagination from "react-js-pagination";
import Swal from "sweetalert2";
import { Link } from "react-router-dom";
import Layout from "../../layouts/Layout";
import AddDiscount from "./AddDiscount";
import EditDiscount from "./EditDiscount";
import Moment from "react-moment";
import SearchBar from "../../components/SearchBar";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const DiscountsList = () => {
  const listDiscounts = useContext(DiscountsContext);
  const {
    discounts,
    getDiscounts,
    products,
    getProducts,
    success,
    current_page,
    total,
    per_page,
    editDiscount,
    removeDiscount,
  } = listDiscounts;

  useEffect(() => {
    getDiscounts();
    // eslint-disable-next-line
  }, [success]);

  useEffect(() => {
    getProducts();
    // eslint-disable-next-line
  }, []);


  const [searchTerm, setSearchTerm] = useState("");

  const handleChangeSearch = (e) => {
     setSearchTerm(e.target.value);
  };

  const handleSearch = (e) => {
    e.preventDefault();
    const PageNumber = 1;
    getDiscounts(PageNumber, searchTerm);
 };

  //funcion que se encarga de listar los productos en el select
  const optionsProducts = products.map((product) => {
    return { value: product.id, label: product.name };
  });

  const handleChangePage = (PageNumber) => {
    getDiscounts(PageNumber, searchTerm);
  };

  const showConfirmMessage = (discount) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "Un descuento no se puede recuperar",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        removeDiscount(discount);
      }
    });
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2> Descuentos </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <Link to="/">
                      <i className="zmdi zmdi-home" /> Home
                    </Link>
                  </li>
                  <li className="breadcrumb-item">Descuentos</li>
                  <li className="breadcrumb-item active">lista</li>
                </ul>
                <ButtonMobileMenu/>
                <div className="chat_window">
                  <RightButtonToggle/>
                </div>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <button
                  className="btn btn-primary float-right right_icon_toggle_btn"
                  type="button"
                >
                  <i className="zmdi zmdi-arrow-right" />
                </button>
                <button
                  className="btn btn-danger float-right"
                  data-toggle="modal"
                  data-target="#addDiscount"
                >
                  <i className="zmdi zmdi-plus" /> Nuevo
                </button>
              </div>
            </div>
          </div>
          <div className="row clearfix">
            <div className="col-lg-12">
             <SearchBar 
              searchTerm={searchTerm}
              handleChangeSearch={handleChangeSearch}
              handleSearch={handleSearch}
              placeholder="busque un descuento por producto aqui.."
             />
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="table-responsive">
                    <table className="table table-hover product_item_list c_table theme-color mb-0">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Producto</th>
                          <th>Descuento</th>
                          <th>Inicio</th>
                          <th>Expira</th>
                          <th>Status</th>
                          <th data-breakpoints="sm xs md">Acciones</th>
                        </tr>
                      </thead>
                      <tbody>
                        {!discounts.length ? (
                          <tr>
                            <td align="center" colSpan={7}>
                              No se encuentran Descuentos Registrados
                            </td>
                          </tr>
                        ) : (
                          discounts.map((discount) => (
                            <tr key={discount.id}>
                              <td>{discount.id}</td>
                              <td>{discount.product.name}</td>
                              <td>
                                {new Intl.NumberFormat("es-MX", {
                                    style: "percent",
                                  }).format(discount.discount/100)}
                              </td>
                              <td>
                                <Moment format="DD/MM/YYYY">
                                  {discount.start_date}
                                </Moment>
                              </td>
                              <td>
                                <Moment format="DD/MM/YYYY">
                                  {discount.end_date}
                                </Moment>
                              </td>
                              <td>
                                {discount.end_date >= discount.today ? (
                                  <span className="badge badge-warning">
                                    Activo
                                  </span>
                                ) : (
                                  <span className="badge badge-danger">
                                    Expirado
                                  </span>
                                )}
                              </td>
                              <td className="footable-last-visible">
                                <button
                                  className="btn btn-primary btn-sm"
                                  data-toggle="modal"
                                  data-target="#editDiscount"
                                  onClick={() => editDiscount(discount.id)}
                                >
                                  <i className="zmdi zmdi-edit"></i>
                                </button>
                                <button
                                  className="btn btn-danger btn-sm"
                                  onClick={() =>
                                    showConfirmMessage(discount.id)
                                  }
                                >
                                  <i className="zmdi zmdi-delete"></i>
                                </button>
                              </td>
                            </tr>
                          ))
                        )}
                      </tbody>
                    </table>
                  </div>
                </div>
                {total ? (
                  <div className="card">
                    <div className="body">
                      <div className="pagination pagination-danger justify-content-center m-b-0">
                        <Pagination
                          activePage={current_page}
                          totalItemsCount={total}
                          itemsCountPerPage={per_page}
                          onChange={(pageNumber) =>
                            handleChangePage(pageNumber)
                          }
                          itemClass="page-item"
                          linkClass="page-link"
                        />
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </section>
      <AddDiscount optionsProducts={optionsProducts} />
      <EditDiscount optionsProducts={optionsProducts} />
    </Layout>
  );
};

export default DiscountsList;
