import React, { useReducer } from "react";
import { ADD_CUSTOMER, ADD_SHIPPING_ADDRESS, EDIT_CUSTOMER, EDIT_SHIPPING_ADDRESS, GET_CUSTOMERS, GET_ENTITIES, GET_MUNICIPALITIES, GET_OPTIONS_REFERENCES, REMOVE_CUSTOMER, REMOVE_SHIPPING_ADDRESS, SHOW_CUSTOMER, UPDATE_CUSTOMER, UPDATE_SHIPPING_ADDRESS } from '../../types';
import customersReducer from "./customersReducer";
import CustomersContext from "./customersContext";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import $ from "jquery";
import "bootstrap-notify";
import Swal from "sweetalert2";


const CustomersState = (props) => {
  // estado inicial
  const initialSate = {
    customers: [],
    customer: [],
    address: [],
    orders: [],
    entities: [],
    municipalities: [],
    selectedCustomer: [],
    selectedAddress: [],
    opcionesComoSeEnteraron: [],
    success: false,
  };

  const [state, dispatch] = useReducer(customersReducer, initialSate);

  //obtener los clientes
  const getCustomers = () => {
    let url = `customers`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_CUSTOMERS,
        payload: response.data.data,
      });
    });
  };


  const addCustomer = (customer) => {

    let url = "/customers";
    MethodPost(url, customer)
      .then((response) => {
        dispatch({
          type: ADD_CUSTOMER,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR CLIENTE A EDITAR
  const editCustomer = (customer) => {
    dispatch({
      type: EDIT_CUSTOMER,
      payload: customer,
    });
  };

  //Actualizar un CLIENTE
  const updateCustomer = (data) => {
    let url = `customers/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
        dispatch({
          type: UPDATE_CUSTOMER,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar CLIENTE
  const removeCustomer = (id) => {
    let url = `customers/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: "Eliminado",
        text: response.data.message,
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
      });
      dispatch({
        type: REMOVE_CUSTOMER,
        payload: id,
      });
    });
  };

  //obtener detalle del cliente
  const getCustomer = (customer) => {
    let url = `customers/${customer}`;
    MethodGet(url).then((response) => {
      dispatch({
        type: SHOW_CUSTOMER,
        payload: response.data,
      });
    });
  };

  //Agregar nueva direccion de envio
  const addShippingAddess = (address) => {
    let url = "/shipping-adress";
    MethodPost(url, address)
      .then((response) => {
        dispatch({
          type: ADD_SHIPPING_ADDRESS,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR DIRECCION DE ENVIO A EDITAR
  const editShippingAddress = (address) => {
    dispatch({
      type: EDIT_SHIPPING_ADDRESS,
      payload: address,
    });
  };

  //Actualizar una dirección de envio
  const updateShippingAddess = (data) => {
    let url = `shipping-adress/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
        dispatch({
          type: UPDATE_SHIPPING_ADDRESS,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

     //Eliminar Dirección de envio
     const removeShippingAddress = (id) => {
      let url = `shipping-adress/${id}`;
      MethodDelete(url).then((response) => {
        Swal.fire({
          title: "Eliminado",
          text: response.data.message,
          icon: "success",
          timer: 3000,
          showConfirmButton: false,
        });
        dispatch({
          type:  REMOVE_SHIPPING_ADDRESS,
          payload: id,
        });
      });
    };

  //Obtener la data para el select Estados
  const getEntities = () => {
    let url = "entities";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_ENTITIES,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Obtener la data para el select Municipios
  const getMunicipalities = (state) => {
    let url = `municipalities/${state}`;
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_MUNICIPALITIES,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

   //Obtener la data para el select de referencias
  const getComoSeEnteraron = () => {
    let url = "get-references";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_OPTIONS_REFERENCES,
          payload: response.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <CustomersContext.Provider
      value={{
        customers: state.customers,
        customer: state.customer,
        address: state.address,
        orders: state.orders,
        entities: state.entities,
        municipalities: state.municipalities,
        success: state.success,
        selectedCustomer: state.selectedCustomer,
        selectedAddress: state.selectedAddress,
        opcionesComoSeEnteraron: state.opcionesComoSeEnteraron,
        getCustomers,
        getCustomer,
        getEntities,
        getMunicipalities,
        addCustomer,
        editCustomer,
        removeCustomer,
        updateCustomer,
        addShippingAddess,
        editShippingAddress,
        updateShippingAddess,
        removeShippingAddress,
        getComoSeEnteraron
      }}
    >
      {props.children}
    </CustomersContext.Provider>
  );
};

export default CustomersState;
