import React, { useReducer } from "react";
import UsersContext from "./usersContext";
import UsersReducer from "./usersReducer";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { ADD_USER, EDIT_USER, GET_USERS, REMOVE_USER, UPDATE_USER } from "../../types";


const UsersState = (props) => {
 // estado inicial
 const initialSate = {
    users: [],
    selectedUser: [],
    success: false
  };

  const [state, dispatch] = useReducer(UsersReducer, initialSate);

  //obtener los usuarios
  const getUsers = () => {
    let url = `users`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_USERS,
        payload: response.data.data,
      });
    });
  };

  //AGREGAR NUEVO USUARIO
  const addUser = (user) => {

    const formData = new FormData();
    formData.append('name',user.name);
    formData.append('phone',user.phone);
    formData.append('email',user.email);
    formData.append('password', user.password);
    formData.append('user_role', user.user_role);
    formData.append('profile_picture', user.profile_picture);
    let url = "/users";
    MethodPost(url, formData)
      .then((response) => {
        dispatch({
          type: ADD_USER,
        });
        toast.success(response.data.message, { theme: "colored" });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR USUARIO A EDITAR
  const editUser = (user) => {
    dispatch({
      type: EDIT_USER,
      payload: user,
    });
  };

  //Actualizar un usuario
  const updateUser = (data) => {
    let url = `users/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        toast.success(response.data.message, { theme: "colored" });
        dispatch({
          type: UPDATE_USER,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar Usuario
  const removeUser = (id) => {
    let url = `users/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: 'Eliminado',
        text:  response.data.message,
        icon: 'success',
        timer: 3000,
        showConfirmButton: false
    });
      dispatch({
        type: REMOVE_USER,
        payload: id,
      });
    });
  };

  return (
    <UsersContext.Provider
      value={{
        users: state.users,
        success: state.success,
        selectedUser: state.selectedUser,
        getUsers,
        addUser,
        editUser,
        removeUser,
        updateUser,
      }}
    >
      {props.children}
    </UsersContext.Provider>
  );
};

export default UsersState;
