import { AUTHENTICATED_USER, FAILED_LOGIN, LOGOUT, SUCCESS_LOGIN } from "../../types/index";


export default function AuthReducer (state, action)  {
  switch (action.type) {
    case SUCCESS_LOGIN:
      localStorage.setItem('token',action.payload.token);
      localStorage.setItem('expires_in',action.payload.expires_in);
      return {
          ...state,
          authenticated:true,
          cargando:false,
        }

    case AUTHENTICATED_USER:
      localStorage.setItem('user',JSON.stringify(action.payload.user))
      return{
        ...state,
        authenticated: true,
        cargando:false,
        user: action.payload.user
      }

     case FAILED_LOGIN:
      case LOGOUT:
        localStorage.removeItem('token');
        localStorage.removeItem('expires_in');
        localStorage.removeItem('user');
        return {
          ...state,
          token: null,
          user:null,
          authenticated:null,
          cargando:false,
        }

    default:
      return state;
  }
};
