import React from "react";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import FamiliesSatate from "../contexts/families/familiesSatate";
import MaterialsSatate from "../contexts/materials/materialsState";
import ProvidersSatate from "../contexts/providers/providersState";
import CustomersSatate from "../contexts/customers/customersState";
import MeasurementsState from "../contexts/measurements/measurementsState";
import UsersState from "../contexts/users/usersState";
import ProductsState from "../contexts/products/productsState";
import OrdersState from "../contexts/orders/ordersState";
import CouponsState from "../contexts/coupons/couponsState";
import CarouselState from "../contexts/carousel/carouselState";
import DiscountsState from "../contexts/discounts/discountsState";
import ExpensesState from "../contexts/expenses/expensesState";
import SizesSatate from "../contexts/sizes/sizesState";
import PrintingsSatate from "../contexts/printings/printingsState";
import OptionsSatate from "../contexts/options/optionsState";
import DesignSatate from "../contexts/design/designState";

import FamiliesList from "../pages/Families/FamiliesList";
import MaterialsList from "../pages/Materials/MaterialsList";
import ProvidersList from "../pages/Providers/ProvidersList";
import AddProvider from "../pages/Providers/AddProvider";
import CustomersList from "../pages/Customers/CustomersList";
import AddCustomer from "../pages/Customers/AddCustomer";
import DetailsCustomer from "../pages/Customers/DetailsCustomer";
import MeasurementsList from "../pages/Measures/MeasurementsList";
import UsersList from "../pages/Users/UsersList";
import ProductsList from "../pages/Products/ProductsList";
import DetailProduct from "../pages/Products/DetailProduct";
import AddProduct from "../pages/Products/AddProduct";
import NotFoundPage from "../pages/Errors/NotFoundPage";
import OrdersList from "../pages/Orders/OrdersList";
import AddOrder from "../pages/Orders/AddOrder";
import OrderDetails from "../pages/Orders/OrderDetails";
import CouponsList from "../pages/Coupons/CouponsList";
import DiscountsList from "../pages/Discounts/DiscountsList";
import ExpensesList from "../pages/Expenses/ExpensesList";
import OrdersProduction from "../pages/Production/OrdersList";
import OrderDetailsProduction from "../pages/Production/OrderDetails";
import OrdersDesing from "../pages/Desing/OrdersList";
import OrderDetailsDesing from "../pages//Desing/OrderDetails";

import PrivateRoute from "./PrivateRoute";
import Login from "../pages/auth/Login";
import tokenAuth from "../services/tokenAuth";
import Slides from "../pages/Carousel/Slides";
import InventoriesList from "../pages/Inventories/InventoriesList";
import InventoriesState from "../contexts/inventories/inventoriesState";
import DashboardState from "../contexts/dashboard/dashboardState";
import Home from "../layouts/Home";
import ProductionSatate from "../contexts/production/productionState";
import OrderEdit from "../pages/Orders/OrderEdit";
import SizesList from "../pages/Sizes/SizesList";
import PrintingsList from "../pages/Printings/PrintingsList";
import OptionsList from "../pages/Options/OptionsList";
import PurchasesList from "../pages/Purchases/PurchasesList";
import PurchasesState from "../contexts/purchases/purchasesState";
import AddPurchase from "../pages/Purchases/AddPurchase";
import DetailPurchase from "../pages/Purchases/DetailPurchase";
import EditPurchase from "../pages/Purchases/EditPurchase";
import StockHistory from "../pages/Inventories/StockHistory";
import AddExpense from "../pages/Expenses/AddExpense";
import EditExpense from "../pages/Expenses/EditExpense";

import RespaldarOrdenes from "../pages/Respaldar/RespaldarOrdenes";
import DetailProvider from "../pages/Providers/DetailProvider";
import AddMaterial from "../pages/Materials/AddMaterial";
import EditMaterial from "../pages/Materials/EditMaterial";

//Revisar si tenemos un token
const token = localStorage.getItem("token");
if (token) {
  tokenAuth(token);
}

const AppRouter = () => {
  return (
    <Router>
      <DashboardState>
        <DesignSatate>
          <ProductionSatate>
            <InventoriesState>
              <ExpensesState>
                <DiscountsState>
                  <CarouselState>
                    <CustomersSatate>
                      <ProvidersSatate>
                        <MaterialsSatate>
                          <FamiliesSatate>
                            <MeasurementsState>
                              <UsersState>
                                <ProductsState>
                                  <SizesSatate>
                                    <PrintingsSatate>
                                      <OptionsSatate>
                                        <CouponsState>
                                          <OrdersState>
                                            <PurchasesState>
                                              <Routes>
                                                <Route
                                                  exact
                                                  path="/"
                                                  element={
                                                    <PrivateRoute>
                                                      <Home />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/families"
                                                  element={
                                                    <PrivateRoute>
                                                      <FamiliesList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/materiales"
                                                  element={
                                                    <PrivateRoute>
                                                      <MaterialsList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/material/add"
                                                  element={
                                                    <PrivateRoute>
                                                      <AddMaterial />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/material/edit/:id"
                                                  element={
                                                    <PrivateRoute>
                                                      <EditMaterial />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/proveedores"
                                                  element={
                                                    <PrivateRoute>
                                                      <ProvidersList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/proveedor/nuevo"
                                                  element={
                                                    <PrivateRoute>
                                                      <AddProvider />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/proveedor/:id/detail"
                                                  element={
                                                    <PrivateRoute>
                                                      <DetailProvider />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/customers"
                                                  element={
                                                    <PrivateRoute>
                                                      <CustomersList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/customers/add"
                                                  element={
                                                    <PrivateRoute>
                                                      <AddCustomer />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/customers/detail/:id"
                                                  element={
                                                    <PrivateRoute>
                                                      <DetailsCustomer />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/measurement"
                                                  element={
                                                    <PrivateRoute>
                                                      <MeasurementsList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/users"
                                                  element={
                                                    <PrivateRoute>
                                                      <UsersList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/products"
                                                  element={
                                                    <PrivateRoute>
                                                      <ProductsList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/product-add"
                                                  element={
                                                    <PrivateRoute>
                                                      <AddProduct />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/product-detail/:id"
                                                  element={
                                                    <PrivateRoute>
                                                      <DetailProduct />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/sizes"
                                                  element={
                                                    <PrivateRoute>
                                                      <SizesList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/printing"
                                                  element={
                                                    <PrivateRoute>
                                                      <PrintingsList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/options"
                                                  element={
                                                    <PrivateRoute>
                                                      <OptionsList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/coupons"
                                                  element={
                                                    <PrivateRoute>
                                                      <CouponsList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/discounts"
                                                  element={
                                                    <PrivateRoute>
                                                      <DiscountsList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/gastos"
                                                  element={
                                                    <PrivateRoute>
                                                      <ExpensesList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/respaldar-ordenes"
                                                  element={
                                                    <PrivateRoute>
                                                      <RespaldarOrdenes />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/gasto-add"
                                                  element={
                                                    <PrivateRoute>
                                                      <AddExpense />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/gasto-edit/:id"
                                                  element={
                                                    <PrivateRoute>
                                                      <EditExpense />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/orders"
                                                  element={
                                                    <PrivateRoute>
                                                      <OrdersList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/inventories"
                                                  element={
                                                    <PrivateRoute>
                                                      <InventoriesList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/inventory-history/:id"
                                                  element={
                                                    <PrivateRoute>
                                                      <StockHistory />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/compras"
                                                  element={
                                                    <PrivateRoute>
                                                      <PurchasesList />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/compra-add"
                                                  element={
                                                    <PrivateRoute>
                                                      <AddPurchase />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/compras/detail/:id"
                                                  element={
                                                    <PrivateRoute>
                                                      <DetailPurchase />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/compras/edit/:id"
                                                  element={
                                                    <PrivateRoute>
                                                      <EditPurchase />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/order-add"
                                                  element={
                                                    <PrivateRoute>
                                                      <AddOrder />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/order-detail/:id"
                                                  element={
                                                    <PrivateRoute>
                                                      <OrderDetails />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/order-edit/:id"
                                                  element={
                                                    <PrivateRoute>
                                                      <OrderEdit />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/orders-production"
                                                  element={
                                                    <PrivateRoute>
                                                      <OrdersProduction />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/order-production/:id"
                                                  element={
                                                    <PrivateRoute>
                                                      <OrderDetailsProduction />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/orders-desing"
                                                  element={
                                                    <PrivateRoute>
                                                      <OrdersDesing />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/order-desing/:id"
                                                  element={
                                                    <PrivateRoute>
                                                      <OrderDetailsDesing />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/carousel"
                                                  element={
                                                    <PrivateRoute>
                                                      <Slides />
                                                    </PrivateRoute>
                                                  }
                                                />
                                                <Route
                                                  exact
                                                  path="/login"
                                                  element={<Login />}
                                                />
                                                <Route
                                                  path="*"
                                                  element={<NotFoundPage />}
                                                />
                                              </Routes>
                                            </PurchasesState>
                                          </OrdersState>
                                        </CouponsState>
                                      </OptionsSatate>
                                    </PrintingsSatate>
                                  </SizesSatate>
                                </ProductsState>
                              </UsersState>
                            </MeasurementsState>
                          </FamiliesSatate>
                        </MaterialsSatate>
                      </ProvidersSatate>
                    </CustomersSatate>
                  </CarouselState>
                </DiscountsState>
              </ExpensesState>
            </InventoriesState>
          </ProductionSatate>
        </DesignSatate>
      </DashboardState>
    </Router>
  );
};

export default AppRouter;
