import React, { useContext, useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import MaterialsContext from "../../contexts/materials/materialsContext";
import Layout from "../../layouts/Layout";
import { Link, useNavigate } from "react-router-dom";
import Select from "react-select";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";
import RightButtonToggle from "../../components/RightButtonToggle";

const AddMaterial = () => {
  const { success, addMaterial, measurements, getMeasurements, } = useContext(MaterialsContext);
  const { register, errors, handleSubmit, reset, control } = useForm();
  const navigate = useNavigate();

  useEffect(() => {
    getMeasurements();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (success) {
      navigate("/materiales");
    }
    //eslint-disable-next-line
  }, [success]);

  //funcion que se encarga de listar las unidades de medida en el select
  const optionsMeasurements = measurements.map((measurement) => {
    return {
      value: measurement.id,
      label: `${measurement.name} (${measurement.acronym}) `,
    };
  });

  const onSubmit = (data) => {
    addMaterial({ ...data,
      sale_unit : parseInt(data.sale_unit.value),
     });
    reset();
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>Agregar Material </h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/materiales"> Materiales </Link>
                  </li>
                  <li className="breadcrumb-item active">Nuevo</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
               <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-12">
                <div className="card">
                  <div className="body">
                    <div className="header">
                      <h2>
                        <small>Completa la información necesaria.</small>
                      </h2>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Nombre del Material</label>
                            <input
                              name="name"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: true,
                                  message:
                                    "El nombre del Material es requerido",
                                },
                              })}
                              className={
                                errors.name
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                            />
                            {errors?.name ? (
                              <label className="error">
                                {errors?.name?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Descripción (opcional)</label>
                            <input
                              name="description"
                              autoComplete="off"
                              ref={register({
                                required: {
                                  value: false,
                                  message:
                                  "La descripción es opcional.",
                                  
                                },
                              })}
                              className={
                                errors.description
                                  ? "form-control is-invalid"
                                  : "form-control "
                              }
                            />
                            {errors?.description ? (
                              <label className="error">
                                {errors?.description?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Unidad de venta</label>
                            <Controller
                              name="sale_unit"
                              control={control}
                              placeholder="Selecciona una unidad"
                              defaultValue=""
                              options={optionsMeasurements}
                              as={<Select />}
                              rules={{
                                required: "La unidad de medida es un campo requerido",
                              }}
                            />
                            {errors?.sale_unit ? (
                              <label className="error">
                                {errors?.sale_unit?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-lg-6">
                          <div className="form-group">
                            <label>Control de inventario</label>
                            <div className="custom-control custom-switch">
                              <input
                                name="is_inventory"
                                type="checkbox"
                                className="custom-control-input"
                                id="customSwitch2"
                                ref={register()}
                              />
                              <label
                                className="custom-control-label"
                                htmlFor="customSwitch2"
                              >
                              </label>
                            </div>
                            {errors?.is_inventory ? (
                              <label className="error">
                                {errors?.is_inventory?.message}
                              </label>
                            ) : null}
                          </div>
                        </div>
                      <div className="col-lg-12">
                        <button
                          type="submit"
                          className="btn btn-danger btn-round waves-effect"
                        >
                          GUARDAR
                        </button>
                      </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
};

export default AddMaterial;
