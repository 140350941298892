import React, { useContext, useEffect } from "react";
import OrdersContext from "../../contexts/orders/ordersContext";

const ModalStock = () => {
  const { out_stock, insufficient_material, closeModalOutStock } =
    useContext(OrdersContext);
  useEffect(() => {
    if (out_stock) {
      window.$("#ModalStock").modal("show");
    }
    // eslint-disable-next-line
  }, [out_stock]);

  const handleCloseModal = () => {
    closeModalOutStock();
    window.$("#ModalStock").modal("hide");
  };
  return (
    <>
      <div
        className="modal fade"
        data-backdrop="static"
        id="ModalStock"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog modal-dialog-centered" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title">Stock Insuficiente</h4>
            </div>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="alert bg-danger" role="alert">
                    <div className="container">
                      <div className="alert-icon">
                        <i className="zmdi zmdi-alert-circle-o" />
                      </div>
                      No se puede realizar la venta. <br />
                      No cuenta con el material suficiente en stock para
                      su producción.
                    </div>
                  </div>
                  <div className="table-responsive">
                    <table className="table table-sm table-bordered">
                      <thead>
                        <tr>
                          <th>#</th>
                          <th>Material</th>
                          <th>Estado</th>
                        </tr>
                      </thead>
                      <tbody>
                        {insufficient_material.map((material, index) => (
                          <tr key={index}>
                            <th>{index + 1}</th>
                            <td>{material.material.name}</td>
                            <td>
                              <span className="badge badge-danger">
                                Insuficiente
                              </span>
                            </td>
                          </tr>
                        ))}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="button"
                className="btn btn-danger btn-round waves-effect"
                onClick={handleCloseModal}
              >
                CERRAR
              </button>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ModalStock;
