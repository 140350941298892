import React, { useContext } from "react";
import { useForm } from "react-hook-form";
import InventoriesContext from "../../contexts/inventories/inventoriesContext";

const EditInventory = () => {
  const { selectedInventory, updateInventory } =
    useContext(InventoriesContext);

  const { register, errors, handleSubmit, reset } =
    useForm();

  const handleCloseModal = () => {
    window.$("#editInventory").modal("hide");
  };

  const onSubmit = (data) => {
    data.id = selectedInventory.id;
    updateInventory(data);
    reset();
    handleCloseModal();
  };

  return (
    <>
      <div
        className="modal fade"
        id="editInventory"
        tabIndex={-1}
        role="dialog"
      >
        <div className="modal-dialog" role="document">
          <div className="modal-content">
            <div className="modal-header">
              <h4 className="title" id="defaultModalLabel">
                Actualizar Inventario
              </h4>
            </div>
            <form onSubmit={handleSubmit(onSubmit)}>
              <div className="modal-body">
                <div className="row">
                  <div className="col-lg-12">
                    <div className="form-group">
                      <label>Material</label>
                      <input
                        name="material"
                        type="text"
                        ref={register({
                          required: {
                            value: false,
                            message: "Nombre del material",
                          },
                        })}
                        className="form-control"
                        defaultValue={
                          selectedInventory ? selectedInventory.material?.name : ""
                        }
                        readOnly
                      />
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Cantidad mínima</label>
                      <input
                        name="min_quantity"
                        type="text"
                        defaultValue={
                          selectedInventory
                            ? selectedInventory.min_quantity
                            : ""
                        }
                        ref={register({
                          required: {
                            value: true,
                            message: "La cantidad minima de stock es requerida",
                          },
                          min: {
                            value: 1,
                            message:
                              "El campo cantidad minima debe ser mayor a 1",
                          },
                          pattern: {
                            value: /^[0-9]+([.])?([0-9]+)?$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.min_quantity
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Cantidad minima *"
                      />
                      {errors?.min_quantity ? (
                        <label className="error">
                          {errors?.min_quantity?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Cantidad óptima</label>
                      <input
                        name="optimal_quantity"
                        type="text"
                        defaultValue={
                          selectedInventory
                            ? selectedInventory.optimal_quantity
                            : ""
                        }
                        ref={register({
                          required: {
                            value: true,
                            message: "La cantidad óptima de stock es requerida",
                          },
                          min: {
                            value: 1,
                            message:
                              "El campo cantidad óptima debe ser mayor a 1",
                          },
                          pattern: {
                            value: /^[0-9]+([.])?([0-9]+)?$/,
                            message: "El campo debe ser numerico",
                          },
                        })}
                        className={
                          errors.min_quantity
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Cantidad óptima *"
                      />
                      {errors?.min_quantity ? (
                        <label className="error">
                          {errors?.optimal_quantity?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer">
                <button
                  type="submit"
                  className="btn btn-danger btn-round waves-effect"
                >
                  ACTUALIZAR
                </button>
                <button
                  type="button"
                  className="btn btn-default btn-round waves-effect"
                  onClick={handleCloseModal}
                >
                  CANCELAR
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default EditInventory;
