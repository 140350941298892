import React, { useContext } from 'react'
import { useForm } from 'react-hook-form';
import OptionsContext from '../../contexts/options/optionsContext';

const EditOption = () => {
    const { selectedOption, updateOption } = useContext(OptionsContext);

    const { register, errors, handleSubmit, reset } = useForm();
  
    const handleCloseModal = () => {
      window.$("#editOption").modal("hide");
    };
  
    const onSubmit = (data) => {
      data.id = selectedOption.id;
      updateOption(data);
      reset();
      handleCloseModal();
    };

  return (
    <div className="modal fade" id="editOption" tabIndex={-1} role="dialog">
    <div className="modal-dialog" role="document">
      <div className="modal-content">
        <div className="modal-header">
          <h4 className="title" id="largeModalLabel">
            Actulizar Acabado
          </h4>
        </div>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="modal-body">
            <div className="row">
              <div className="col-lg-12">
                <div className="form-group">
                  <label>Nombre</label>
                  <input
                    name="name"
                    type="text"
                    ref={register({
                      required: {
                        value: true,
                        message: "El campo Nombre es requerido",
                      },
                    })}
                    className={
                      errors.name
                        ? "form-control is-invalid"
                        : "form-control "
                    }
                    placeholder="Nombre del acabado *"
                    defaultValue={selectedOption ? selectedOption.name : ""}
                  />
                  {errors?.name ? (
                    <label className="error">{errors?.name?.message}</label>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
          <div className="modal-footer">
            <button
              type="submit"
              className="btn btn-danger btn-round waves-effect"
            >
              ACTUALIZAR
            </button>
            <button
              type="button"
              className="btn btn-default btn-round waves-effect"
              onClick={handleCloseModal}
            >
              CANCELAR
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
  )
}

export default EditOption