import React, { useContext, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import ProductsContext from "../../../contexts/products/productsContext";

const UpdatePrice = () => {
  const {
    selectedPrice,
    sizesProduct,
    materialProduct,
    printingProduct,
    updatePrice,
  } = useContext(ProductsContext);
  const { register, errors, handleSubmit, reset, control, setValue } =
    useForm();

  const optionsSize = sizesProduct.map((opsize) => {
    return { value: opsize.size.id, label: opsize.size.name };
  });

  const optionsMaterial = materialProduct.map((opmaterial) => {
    return { value: opmaterial.material.id, label: opmaterial.material.name };
  });

  const optionsPrinting = printingProduct.map((print) => {
    return { value: print.printing.id, label: print.printing.name };
  });



  useEffect(() => {
    if (selectedPrice.size) {
      setValue(
        "size_id",
        { value: selectedPrice.size.id, label: selectedPrice.size.name },
        { shouldDirty: true }
      );
      setValue(
        "material_id",
        {
          value: selectedPrice.material.id,
          label: selectedPrice.material.name,
        },
        { shouldDirty: true }
      );
      setValue(
        "printing_id",
        {
          value: selectedPrice.printing?.id ? selectedPrice.printing.id : "",
          label: selectedPrice.printing?.name ? selectedPrice.printing.name : "",
        },
        { shouldDirty: true }
      );
    }
    // eslint-disable-next-line
  }, [selectedPrice]);

  const handleCloseModal = () => {
    window.$("#UpPrice").modal("hide");
  };

  const onSubmit = (data) => {
    data.id = selectedPrice.id;
    updatePrice(data);
    reset({});
    handleCloseModal();
  };

  return (
    <div className="modal fade" id="UpPrice" tabIndex={-1} role="dialog">
      <div className="modal-dialog" role="document">
        <div className="modal-content">
          <div className="modal-header">
            <h4 className="title" id="largeModalLabel">
              Actualizar Precio
            </h4>
          </div>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div className="modal-body">
              <div className="row">
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Tamaño</label>
                    <Controller
                      name="size_id"
                      control={control}
                      placeholder="Selecciona una opción"
                      defaultValue=""
                      options={optionsSize}
                      as={<Select />}
                      rules={{
                        required: "Selecciona un tamaño de la lista",
                      }}
                    />
                    {errors?.size_id ? (
                      <label className="error">
                        {errors?.size_id?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Material</label>
                    <Controller
                      name="material_id"
                      control={control}
                      placeholder="Selecciona una opción"
                      defaultValue=""
                      options={optionsMaterial}
                      as={<Select />}
                      rules={{
                        required: "Selecciona un material de la lista",
                      }}
                    />
                    {errors?.material_id ? (
                      <label className="error">
                        {errors?.material_id?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="form-group">
                    <label>Impresión</label>
                    <Controller
                      name="printing_id"
                      control={control}
                      placeholder="Selecciona una opción"
                      defaultValue=""
                      options={optionsPrinting}
                      as={<Select />}
                      rules={{
                        required: "Selecciona una impresión de la lista",
                      }}
                    />
                    {errors?.printing_id ? (
                      <label className="error">
                        {errors?.printing_id?.message}
                      </label>
                    ) : null}
                  </div>
                </div>
             
                  <div className="col-lg-6">
                    <div className="form-group">
                      <label>Cantidad</label>
                      <input
                        name="quantity"
                        type="number"
                        min={1}
                        defaultValue={
                          selectedPrice ? selectedPrice.quantity : ""
                        }
                        ref={register({
                          required: {
                            value: true,
                            message: "El campo Cantidad es requerido",
                          },
                          minLength: {
                            value: 1,
                            message: "El campo Cantidad debe ser minimo 1",
                          },
                        })}
                        className={
                          errors.quantity
                            ? "form-control is-invalid"
                            : "form-control "
                        }
                        placeholder="Cantidad *"
                      />
                      {errors?.quantity ? (
                        <label className="error">
                          {errors?.quantity?.message}
                        </label>
                      ) : null}
                    </div>
                  </div>
                <div className="col-lg-6">
                  <div className="form-group">
                    <label>Precio</label>
                    <input
                      name="price"
                      type="text"
                      defaultValue={selectedPrice ? selectedPrice.price : ""}
                      ref={register({
                        required: {
                          value: true,
                          message: "El campo precio es requerido",
                        },
                        pattern: {
                          value:
                            /^([1-9][0-9]{,2}(,[0-9]{3})*|[0-9]+)(\.[0-9]{1,9})?$/,
                          message:
                            "El campo precio no puede ser un valor negativo",
                        },
                      })}
                      className={
                        errors.price
                          ? "form-control is-invalid"
                          : "form-control "
                      }
                      placeholder="Precio *"
                    />
                    {errors?.price ? (
                      <label className="error">{errors?.price?.message}</label>
                    ) : null}
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer">
              <button
                type="submit"
                className="btn btn-danger btn-round waves-effect"
              >
                ACTUALIZAR
              </button>
              <button
                type="button"
                className="btn btn-default btn-round waves-effect"
                onClick={handleCloseModal}
              >
                CANCELAR
              </button>
            </div>
          </form>
        </div>
      </div>
    </div>
  );
};

export default UpdatePrice;
