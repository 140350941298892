/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useContext, useEffect, useState } from "react";
import Layout from "../../layouts/Layout";
import { Link, useParams } from "react-router-dom";
import ProvidersContext from "../../contexts/providers/providersContext";
import EditProvider from "./EditProvider";
import ModalAddContact from "./forms/ModalAddContact";
import ModalEditContact from "./forms/ModalEditContact";
import Swal from "sweetalert2";
import ModalAddAccount from "./forms/ModalAddAccount";
import ModalEditAccount from "./forms/ModalEditAccount";
import RightButtonToggle from "../../components/RightButtonToggle";
import ButtonMobileMenu from "../../components/ButtonMobileMenu";

const DetailProvider = () => {
  const { id } = useParams();

  const {
    getProvider,
    selectedProvider,
    getEntities,
    success,
    deleteProviderContact,
    deleteProviderBankAccount,
  } = useContext(ProvidersContext);
  // como renombrar una variable
  const {
    nombre_comercial,
    razon_social,
    giro,
    etiquetas,
    observaciones,
    contactos,
    cuentas,
  } = selectedProvider;

  const [dataContactEdit, setModalContactEdit] = useState(null);
  const [dataAccountEdit, setModalAccountEdit] = useState(null);

  useEffect(() => {
    getEntities();
    //eslint-disable-next-line
  }, []);

  useEffect(() => {
    getProvider(id);
    // eslint-disable-next-line
  }, [success]);

  const handleEditContact = (rowData) => {
    setModalContactEdit(rowData);
    window.$("#editContact").modal("show");
  };

  const handleEditAccount = (rowData) => {
    setModalAccountEdit(rowData);
    window.$("#editAccount").modal("show");
  };

  // funcion para eliminar un contacto proveedor
  const handleDeleteContact = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProviderContact(id);
      }
    });
  };

  // funcion para eliminar una cuenta de proveedor
  const handleDeleteAccount = (id) => {
    Swal.fire({
      title: "¿Estas seguro?",
      text: "No podrás revertir esto!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonColor: "#3085d6",
      cancelButtonColor: "#d33",
      confirmButtonText: "Si, Eliminar!",
      cancelButtonText: "No, cancelar!",
    }).then((result) => {
      if (result.isConfirmed) {
        deleteProviderBankAccount(id);
      }
    });
  };

  return (
    <Layout>
      <section className="content">
        <div className="body_scroll">
          <div className="block-header">
            <div className="row">
              <div className="col-lg-7 col-md-6 col-sm-12">
                <h2>{nombre_comercial}</h2>
                <ul className="breadcrumb">
                  <li className="breadcrumb-item">
                    <i className="zmdi zmdi-home" /> Home
                  </li>
                  <li className="breadcrumb-item">
                    <Link to="/proveedores">Proveedores</Link>
                  </li>
                  <li className="breadcrumb-item active">Infomación</li>
                </ul>
                <ButtonMobileMenu/>
              </div>
              <div className="col-lg-5 col-md-6 col-sm-12">
                <RightButtonToggle/>
              </div>
            </div>
          </div>
          <div className="container-fluid">
            <div className="row clearfix">
              <div className="col-lg-4 col-md-12">
                <div className="card mcard_4">
                  <div className="body">
                    <div className="user">
                      <h5 className="mt-3 mb-1">{nombre_comercial}</h5>
                      <small className="text-muted">Razón Social:</small>
                      <p>{razon_social}</p>
                      <hr />
                      <small className="text-muted">Giro: </small>
                      <p>{giro}</p>
                      <hr />
                      <small className="text-muted">Etiquetas: </small>
                      <p>
                        {etiquetas?.map((etiqueta, index) => {
                          return (
                            <span
                              key={index}
                              className="tag badge badge-info mr-1"
                              color="primary"
                            >
                              {etiqueta}
                            </span>
                          );
                        })}
                      </p>
                      <hr />
                      <small className="text-muted">Observaciones: </small>
                      <p>{observaciones}</p>
                      <hr />
                      <div className="text-center">
                        <button
                          className="btn btn-primary btn-round"
                          data-toggle="modal"
                          data-target="#editProvider"
                        >
                          <i className="zmdi zmdi-edit" /> Editar Información
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-8 col-md-12">
                <div className="card">
                  <div className="header">
                    <h2>
                      <strong>INFORMACIÓN</strong> CONTACTO
                    </h2>
                    <ul className="header-dropdown">
                      <li className="dropdown">
                        <a
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="zmdi zmdi-more" />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right">
                          <li>
                            <a data-toggle="modal" data-target="#addContact">
                              <i className="zmdi zmdi-plus" /> Agregar nuevo
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <ul className="comment-reply list-unstyled">
                      {contactos?.map((contacto, index) => {
                        return (
                          <div key={`cont-${index}`}>
                            <li>
                              <div className="text-box">
                                <div className="replybutton">
                                  <div className="btn-group">
                                    <span
                                      className="btn btn-outline-primary btn-sm"
                                      onClick={() =>
                                        handleEditContact(contacto)
                                      }
                                    >
                                      <i className="zmdi zmdi-edit" />
                                    </span>
                                    <span
                                      className="btn btn-outline-danger btn-sm"
                                      onClick={() =>
                                        handleDeleteContact(contacto.id)
                                      }
                                    >
                                      <i className="zmdi zmdi-delete" />
                                    </span>
                                  </div>
                                </div>
                                <div className="row text-small">
                                  <div className="col-lg-12">
                                    <h5>{contacto.nombre_contacto}</h5>
                                  </div>
                                  <div className="col-lg-7 mb-2">
                                    <span className="text-muted">
                                      Teléfono(s)
                                    </span>
                                    <br />
                                    {contacto.telefono2
                                      ? contacto.telefono +
                                        " / " +
                                        contacto.telefono2
                                      : contacto.telefono}
                                  </div>
                                  <div className="col-lg-5 mb-2">
                                    <span className="text-muted">WhatsApp</span>
                                    <br />
                                    {contacto.whatsapp}
                                  </div>
                                  <div className="col-lg-12 mb-2">
                                    <span className="text-muted">
                                      Correo(s)
                                    </span>
                                    <br />
                                    {contacto.correo2
                                      ? contacto.correo +
                                        ", " +
                                        contacto.correo2
                                      : contacto.correo}
                                  </div>
                                  <div className="col-lg-7 mb-2">
                                    <span className="text-muted">
                                      Dirección
                                    </span>
                                    <br />
                                    {contacto.direccion}
                                  </div>
                                  <div className="col-lg-3 mb-2">
                                    <span className="text-muted">Ciudad</span>
                                    <br />
                                    {contacto.municipio.name}
                                  </div>
                                  <div className="col-lg-2 mb-2">
                                    <span className="text-muted">C.P.</span>
                                    <br />
                                    {contacto.codigo_postal}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <hr />
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                </div>
                <div className="card">
                  <div className="header">
                    <h2>
                      <strong>DATOS</strong> BANCARIOS
                    </h2>
                    <ul className="header-dropdown">
                      <li className="dropdown">
                        <a
                          className="dropdown-toggle"
                          data-toggle="dropdown"
                          role="button"
                          aria-haspopup="true"
                          aria-expanded="false"
                        >
                          <i className="zmdi zmdi-more" />
                        </a>
                        <ul className="dropdown-menu dropdown-menu-right">
                          <li>
                            <a data-toggle="modal" data-target="#addAccount">
                              <i className="zmdi zmdi-plus" /> Agregar nuevo
                            </a>
                          </li>
                        </ul>
                      </li>
                    </ul>
                  </div>
                  <div className="body">
                    <ul className="comment-reply list-unstyled">
                      {cuentas?.map((cuenta, index) => {
                        return (
                          <div key={`cuenta-${index}`}>
                            <li>
                              <div className="text-box">
                                <div className="replybutton">
                                  <div className="btn-group">
                                    <span
                                      className="btn btn-outline-primary btn-sm"
                                      onClick={() => handleEditAccount(cuenta)}
                                    >
                                      <i className="zmdi zmdi-edit" />
                                    </span>
                                    <span
                                      className="btn btn-outline-danger btn-sm"
                                      onClick={() =>
                                        handleDeleteAccount(cuenta.id)
                                      }
                                    >
                                      <i className="zmdi zmdi-delete" />
                                    </span>
                                  </div>
                                </div>
                                <div className="row text-small">
                                  <div className="col-lg-5 mb-2">
                                    <span className="text-muted">
                                      Institución Bancaria
                                    </span>
                                    <br />
                                    {cuenta.banco.label}
                                  </div>
                                  <div className="col-lg-7 mb-2">
                                    <span className="text-muted">Titular</span>
                                    <br />
                                    {cuenta.nombre_titular}
                                  </div>
                                  <div className="col-lg-5 mb-2">
                                    <span className="text-muted">
                                      No. Cuenta
                                    </span>
                                    <br />
                                    {cuenta.numero_cuenta}
                                  </div>
                                  <div className="col-lg-7 mb-2">
                                    <span className="text-muted">CLABE</span>
                                    <br />
                                    {cuenta.clabe}
                                  </div>
                                </div>
                              </div>
                            </li>
                            <hr />
                          </div>
                        );
                      })}
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <EditProvider proveedor={selectedProvider} />
      <ModalAddContact id={id} />
      <ModalEditContact dataContact={dataContactEdit} />
      <ModalAddAccount id={id} />
      <ModalEditAccount dataAccount={dataAccountEdit} />
    </Layout>
  );
};

export default DetailProvider;
