import React, { useReducer } from "react";
import { ADD_MATERIAL, EDIT_MATERIAL, GET_MATERIALS, GET_MEASUREMENTS_SELECT, REMOVE_MATERIAL, UPDATE_MATERIAL } from '../../types';
import MaterialsContext from "./materialsContext";
import MaterialsReducer from "./materialsReducer";
import MethodGet, { MethodDelete, MethodPost, MethodPut } from "../../services/api";
import $ from "jquery";
import "bootstrap-notify";
import Swal from "sweetalert2";

const MaterialsSatate = (props) => {
  // estado inicial
  const initialSate = {
    materials: [],
    selectedMaterial: [],
    measurements: [],
    success: false,
  };

  const [state, dispatch] = useReducer(MaterialsReducer, initialSate);

  //obtener todos los materiales
  const getMaterials = () => {
    let url = `materials`;
    MethodGet(url).then((response) => {
      dispatch({
        type: GET_MATERIALS,
        payload: response.data.data,
      });
    });
  };

  const addMaterial = (material) => {
    let url = "/materials";
    MethodPost(url, material)
      .then((response) => {
        dispatch({
          type: ADD_MATERIAL,
        });
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //SELECCIONAR MATERIAL A EDITAR
  const editMaterial = (material) => {
    let url = `materials/${material}/edit`;
    MethodGet(url).then((response) => {
      dispatch({
        type: EDIT_MATERIAL,
        payload: response.data,
      });
    });
  };

  //Actualizar una MATERIAL
  const updateMaterial = (data) => {
    let url = `materials/${data.id}`;
    MethodPut(url, data)
      .then((response) => {
        $.notify(
          {
            message: response.data.message,
          },
          {
            type: "alert-success",
            allow_dismiss: true,
            newest_on_top: true,
            timer: 5000,
            placement: {
              from: "bottom",
              align: "left",
            },
            animate: {
              enter: "animated fadeInDown",
              exit: "animated fadeOutUp",
            },
            template:
              '<div data-notify="container" class="bootstrap-notify-container alert alert-dismissible {0} ' +
              (true ? "" : "") +
              '" role="alert">' +
              '<button type="button" aria-hidden="true" class="close" data-notify="dismiss">×</button>' +
              '<span data-notify="icon"></span> ' +
              '<span data-notify="title">{1}</span> ' +
              '<span data-notify="message">{2}</span>' +
              '<div class="progress" data-notify="progressbar">' +
              '<div class="progress-bar progress-bar-{0}" role="progressbar" aria-valuenow="0" aria-valuemin="0" aria-valuemax="100" style="width: 0%;"></div>' +
              "</div>" +
              '<a href="{3}" target="{4}" data-notify="url"></a>' +
              "</div>",
          }
        );
        dispatch({
          type: UPDATE_MATERIAL,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  //Eliminar MATERIAL
  const removeMaterial = (id) => {
    let url = `materials/${id}`;
    MethodDelete(url).then((response) => {
      Swal.fire({
        title: "Eliminado",
        text: response.data.message,
        icon: "success",
        timer: 3000,
        showConfirmButton: false,
      });
      dispatch({
        type: REMOVE_MATERIAL,
        payload: id,
      });
    });
  };

  //Obtener la data para el select de unidades
  const getMeasurements = () => {
    let url = "measurements-all";
    MethodGet(url)
      .then((response) => {
        dispatch({
          type: GET_MEASUREMENTS_SELECT,
          payload: response.data.data,
        });
      })
      .catch((error) => {
        console.log(error);
      });
  };

  return (
    <MaterialsContext.Provider
      value={{
        materials: state.materials,
        success: state.success,
        measurements: state.measurements,
        selectedMaterial: state.selectedMaterial,
        getMaterials,
        addMaterial,
        editMaterial,
        removeMaterial,
        updateMaterial,
        getMeasurements,
      }}
    >
      {props.children}
    </MaterialsContext.Provider>
  );
};

export default MaterialsSatate;
